import React, { useState } from "react";
import { CiSliderVertical } from "react-icons/ci";
import { HiOutlineBarsArrowDown } from "react-icons/hi2";
import { BsThreeDots } from "react-icons/bs";
//import { BiChevronDown, BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import Pagination from "../../../CommonComponents/Pagination";
import WorkSkills from "../../../CommonComponents/WorkSkills";
import Education from "../../../CommonComponents/Education";
import Modal from "react-bootstrap/Modal";
import EditJobPost from "./EditJobPost";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { toast } from "react-toastify";
import authApi from "../../../../utils/authApi";
import { HiSortAscending } from "react-icons/hi";

const JobTrackerTable = ({ allJobs,getAllJobPosts, page, setPage, allPages }) => {
  //states
  const [showBox, setShowBox] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [boxPosition, setBoxPosition] = useState({});
  // const [jobs, setJobs] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [show, setShow] = useState(false);
  const [editThisPost, setEditThisPost] = useState({});
  // const [editableFields, setEditableFields] = useState({
  // workplace: { value: "Remote", isEditing: false },
  // status: { value: "Draft", isEditing: false },
  // jobtype: { value: "Full Time", isEditing: false },
  // maxSalary: { value: "$34560.00", isEditing: false },
  // location: { value: "Bangalore, India", isEditing: false },
  // });
  //react-redux

  const handleItemMouseEnter = (item, event, data) => {
    setSelectedItem(item);
    if (item === "skill") {
      setSelectedSkills(data);
    }
    const target = event.currentTarget;
    const rect = target.getBoundingClientRect();
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    const position = {
      top: rect.top + rect.height + scrollTop + 10, // Adjust this value
      left: rect.left + scrollLeft,
    };

    // Calculate horizontal center of the clicked element
    const centerX = rect.left + rect.width / 2;
    const boxWidth = "1000";
    const boxLeft = centerX - boxWidth / 2;

    setBoxPosition({
      ...position,
      left: boxLeft,
    });

    setShowBox(true);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowBox(true);
  };

  const handleBoxClose = () => {
    setShowBox(false);
    setSelectedItem(null);
  };
const deleteJobPost=async(id)=>{
  try{
authApi.delete(`recruiter/jobs/${id}`);
getAllJobPosts()
toast.success('Job post deleted successfully.')
  }catch(error){
    toast.error(error?.response?.data?.message)
  }
}

  const status = ["Draft", "Ongoing", "Paused", "Closed"];
 
  //function returns date and time
  function formatDateTimeAMPM(inputDateTime) {
    try {
      const dateTimeObj = new Date(inputDateTime);

      // Array of month names
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Get day, month, year, hour, minute, and second from the date object
      const day = dateTimeObj.getDate();
      const month = months[dateTimeObj.getMonth()];
      const year = dateTimeObj.getFullYear();
      let hours = dateTimeObj.getHours();
      const minutes = dateTimeObj.getMinutes();
      const seconds = dateTimeObj.getSeconds();

      // Determine AM or PM
      const ampm = hours >= 12 ? "PM" : "AM";

      // Convert hours to 12-hour format
      hours = hours % 12;
      hours = hours ? hours : 12; // '0' should be '12' in 12-hour format

      // Formatted date and time string
      const formattedDateTime = `${day} ${month} ${year} ${hours}:${minutes}:${seconds} ${ampm}`;

      return formattedDateTime;
    } catch (error) {
      return "-";
    }
  }

  const handleEditThis = (post) => {
    setShow(true);
    setEditThisPost(post);
  };

  //change job post status
  const handlePostStatusChange = async (e, row) => {
    try {
      await authApi.put(`recruiter/change-status/${row.id}`, { status: e.target.value });
      getAllJobPosts()
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="table-container">
        <div className="responsive-table ">
          <table className="table  mb-0">
            <thead>
              <tr>
                <th>
                  Date 
                  {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
                </th>
                <th>
                  Title 
                  {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
                </th>
                {/* <th>
                  Education{" "}
                  <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
                </th> */}
                {/* <th>
                  Skill <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
                </th> */}
                <th>
                  Workplace
                  {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
                </th>

                <th>
                  Job Type{" "}
                  {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
                </th>

                <th>
                  Sal 
                  {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
                </th>
                <th>
                  Status 
                  {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
                </th>
                <th>
                  Spent 
                  {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
                </th>
                <th>
                  Applicant
                  {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
                </th>
                <th className="text-right">
                  <CiSliderVertical className="fa-20 black fw-700" />
                </th>
              </tr>
            </thead>
            <tbody>
              {allJobs?.map((row, index) => (
                <tr className="j-gray-bg align-items-center border-18">
                  <td>{formatDateTimeAMPM(row?.created_at)}</td>

                  <td style={{ width: "150px" }}>
                    {/* <Link 
                    //  onMouseEnter={(e) =>
                    //    handleItemMouseEnter("Company Name", e)
                    //  }
                    //  onClick={() => handleItemClick("Company Name")}
                    //  className={selectedItem === "Company Name" ? "active" : ""}
                    >
                      {/* <p className="fa-14 fw-600 light-black mb-0">Acme Corp</p> */}
                    {row?.title}
                    {/* </Link> */}
                  </td>

                  {/* <td className="remotedata">
                    <Link
                      onMouseEnter={(e) => handleItemMouseEnter("education", e)}
                      onClick={(event) => handleItemClick("education")}
                      className={`gray ${
                        selectedItem === "education" ? "active" : ""
                      }`}
                    >
                      Education
                    </Link>
                  </td> */}

                  {/* <td>
                    <Link
                      onMouseEnter={(e) =>
                        handleItemMouseEnter("skill", e, row?.required_skills)
                      }
                      onClick={(event) => handleItemClick("skill")}
                      className={`gray ${
                        selectedItem === "skill" ? "active" : ""
                      }`}
                    >
                      Skill
                    </Link>
                
                  </td> */}
                  <td className="remotedata">
                    {row?.employment_type?.title}
                    {/* {editableFields.workplace.isEditing ? (
                   <select
                     value={editableFields.workplace.value}
                     onChange={(e) => handleFieldChange("workplace", e)}
                     onBlur={() => handleFieldBlur("workplace")}
                     className="form-control fa-14"
                   >
                     {workplace.map((option) => (
                       <option key={option} value={option}>
                         {option}
                       </option>
                     ))}
                   </select>
                 ) : (
                   <span onClick={() => handleFieldClick("workplace")}>
                     {editableFields.workplace.value}
                     <BiChevronDown className="ml-1 fa-20 light-black" />
                   </span>
                 )} */}
                  </td>

                  <td className="remotedata">
                    {row?.job_type?.type}
                    {/* {editableFields.jobtype.isEditing ? (
                   <select
                     value={editableFields.jobtype.value}
                     onChange={(e) => handleFieldChange("jobtype", e)}
                     onBlur={() => handleFieldBlur("jobtype")}
                     className="form-control fa-14"
                   >
                     {jobtype.map((option) => (
                       <option key={option} value={option}>
                         {option}
                       </option>
                     ))}
                   </select>
                 ) : (
                   <span onClick={() => handleFieldClick("jobtype")}>
                     {editableFields.jobtype.value}
                     <BiChevronDown className="ml-1 fa-20 light-black" />
                   </span>
                 )} */}
                  </td>

                  <td>
                    {row?.salary}
                    {/* {editableFields.maxSalary.isEditing ? (
                   <input
                     type="text"
                     value={editableFields.maxSalary.value}
                     onChange={(e) => handleFieldChange("maxSalary", e)}
                     onBlur={() => handleFieldBlur("maxSalary")}
                     className="form-control fa-14"
                   />
                 ) : (
                   <span onClick={() => handleFieldClick("maxSalary")}>
                     {editableFields.maxSalary.value}
                     <BiEdit className="ml-1 fa-18 gray" />
                   </span>
                 )} */}
                  </td>
                  <td>
                    {/* {+row?.status === 1 ? "Active" : "InActive"} */}
                    <select
                      onChange={(e) => handlePostStatusChange(e, row)}
                      // onBlur={() => handleFieldBlur("status")}
                      className="form-control fa-14"
                      value={row.status} style={{width:'150px'}}
                    >
                      {status.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {/* {editableFields.status.isEditing ? (
                   <select
                     value={editableFields.status.value}
                     onChange={(e) => handleFieldChange("status", e)}
                     onBlur={() => handleFieldBlur("status")}
                     className="form-control fa-14"
                   >
                     {status.map((option) => (
                       <option key={option} value={option}>
                         {option}
                       </option>
                     ))}
                   </select>
                 ) : (
                   <span onClick={() => handleFieldClick("status")}>
                     {editableFields.status.value}
                     <BiChevronDown className="ml-1 fa-20 light-black" />
                   </span>
                 )} */}
                  </td>
                  <td>
                    <span id={`app-title-${index}`}>oooo</span>
                    <ReactTooltip
                      anchorId={`app-title-${index}`}
                      place="bottom"
                      content={`app-title-${index}`}
                    />
                  </td>
                  <td>{row?.users?.length} Applied</td>
                  <td>
                    <div className="dropdown dropright float-right">
                      <button
                        type="button"
                        className="btn dropdown-toggle dark-gray-bg rounded-50 p-2 d-flex justify-content-center align-items-center"
                        data-toggle="dropdown"
                      >
                        <BsThreeDots className="black fa-26" />
                      </button>
                      <div className="dropdown-menu transition-0 j-gray-bg mb-0">
                        <Link
                          className="dropdown-item fa-14 light-black fw-400"
                          onClick={() => handleEditThis(row)}
                        >
                          Update
                        </Link>
                        <Link
                          to={`/job-view/${row.id}`}
                          className="dropdown-item fa-14 light-black fw-400"
                        >
                          View Candidates
                        </Link>
                        {/* <Link className="dropdown-item fa-14 light-black fw-400">
                          Repost Job
                        </Link> */}

                        <Link
                          to={`/view-job-post/${row.id}`}
                          className="dropdown-item fa-14 light-black fw-400"
                        >
                          View Job Post
                        </Link>
                        { row?.status==="Closed" &&   <Link
                          to='#'
                          onClick={()=>deleteJobPost(row?.id)}
                          className="dropdown-item fa-14 light-black fw-400"
                        >
                          Delete Post
                        </Link>}

                        {/* deleteJobPost */}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    { allJobs?.length>0 && <div className="pagination-row float-right mt-3">
        <Pagination page={page} setPage={setPage} allPages={allPages} />
      </div>}

      <div className="position-relative ">
        {showBox && (
          <div
            className={`box ${showBox ? "slide-down" : "slide-up"}`}
            style={{
              top: boxPosition.top,
              left: boxPosition.left,
            }}
          >
            {selectedItem === "Company Name" && (
              <div className="box-info">
                <p className="fa-14 gray fw-400 line-height-24">
                  As a Marketing Professional my accentuation is on
                  conversations, for which it is fundamental that we know our
                  get-together of individuals well or potentially have the
                  preparation to get some answers concerning them Pieces of
                  information aren’t actually what examination are uncovering to
                  us how ....
                </p>

                <Link to="/company" className="btn blue-btn mt-3">
                  View Profile
                </Link>
              </div>
            )}

            {selectedItem === "skill" && (
              <div className="work-experience">
                <WorkSkills selectedSkills={selectedSkills} />
              </div>
            )}

            {selectedItem === "education" && (
              <div className="work-experience">
                <Education />
              </div>
            )}
            <button className="box-close" onClick={handleBoxClose}>
              X
            </button>
          </div>
        )}
      </div>
      <>
        <Modal show={show} onHide={() => setShow(false)} size="xl">
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Edit Job Post
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditJobPost getAllJobPosts={getAllJobPosts} setShow={setShow} editThisPost={editThisPost} />
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

export default JobTrackerTable;
