import React, { useState } from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ className, id, onChange,checked,saving }) => {

  // console.log(checked);

  return (
    <div className="on-off">
      <div className={`custom-control custom-switch ${className}`}>
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          className="custom-control-input"
          id={`switch${id}`}
          disabled={saving}
        />
        <label className="custom-control-label" htmlFor={`switch${id}`}></label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
