import React from "react";
import "./Company.css";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import CompanyForm from "./CompanyForm";
import { useParams } from "react-router-dom";


const CompanyEdit = () => {
  const company=useParams()
  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="grid-container">
            <div className="left-side grid-left">
              <LeftSidebar />
            </div>

            <div className="company-content grid-middle mt-smm-0 mt-3">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">
                   
                  {company?.id?"Edit":"Add"} Company Information
                  </h3>
                </div>
                <div className="card-body">
                    <CompanyForm/>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyEdit;
