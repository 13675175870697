import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./FooterComponent.css";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { ImInstagram } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import authApi from "../../../utils/authApi";
import { toast } from "react-toastify";
import NewsletterForm from "../Newsletter/Newsletter";

function FooterComponent() {
  const [allJobTypes, setAllJobTypes] = useState([]);
  const profile = useSelector((state) => state?.user?.profile);
  const isAuth = useSelector((state) => state.auth.isLoggedIn); //constant for checking user is authenticated or not
  const loginAs = useSelector((state) => state.auth.loginAs);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGo = (value) => {
    navigate("/job-search");
    localStorage.removeItem("query");
    localStorage?.setItem("query", JSON.stringify(value));

    //to={`/job-search?job_type=${row?.type}`}
  };
  const getAllJobTypes = async () => {
    try {
      const res = await authApi.get(`get-job-type`);
      setAllJobTypes(res?.data?.jobType);
    } catch (error) {}
  };
  useEffect(() => {
    getAllJobTypes();
  }, []);

  return (
    <footer className="side-space-margin pb-4 ">
      <div className="container-fluid light-blue-bg border-radius-31 p-40">
        <div className="row">
          {loginAs === "Candidate" && (
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-xs-12">
              <div className="footer-list">
                <h6>Find Jobs</h6>
                <ul className="footer-links mt-sm-3 mt-2 mb-sm-0 mb-2">
                  {allJobTypes?.map((row, index) => (
                    <li key={index}>
                      <Link
                        onClick={() => handleGo(row)}
                      >
                        {row?.type}
                      </Link>
                    </li>
                  ))}

                  <li>
                    <Link to="/job-search">More Jobs</Link>
                  </li>
                </ul>
              </div>
            </div>
          )}

          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-xs-12">
            <div className="footer-list">
              <h6>For Company</h6>
              <ul className="footer-links mt-sm-3 mt-2 mb-sm-0 mb-2">
                <li>
                  <Link to="/about-us">Why Jobs Sight</Link>
                </li>
                {loginAs === "Recruiter" && (
                  <li>
                    <Link
                      to="/job-post" 
                    
                    >
                      Post Your Job
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/help-center">Knowledge base</Link>
                </li>

                {!loginAs && (
                  <li>
                    <Link to="#">Login</Link>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-xs-12">
            <div className="footer-list">
              <h6>Support</h6>
              <ul className="footer-links mt-sm-3 mt-2 mb-sm-0 mb-2">
                <li>
                  <Link to="/help-center">Support And Help</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-&-conditions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/packages">Pricing</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-xl-6 col-lg-3 col-md-12 col-sm-12 col-xs-12">
          <NewsletterForm/>
          </div>

          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="footer-right float-lg-right float-left ">
              <ul className="social-icons  mt-3 mb-1">
                <li>
                  <Link to="#" target="_blank">
                    <FaFacebookF />
                  </Link>
                </li>
                <li>
                  <Link to="#" target="_blank">
                    <FaLinkedinIn />
                  </Link>
                </li>
                <li>
                  <Link to="#" target="_blank">
                    <ImInstagram />
                  </Link>
                </li>
              </ul>
              <p className="designby text-right bottom-0 pr-3">
                made with designdot
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterComponent;
