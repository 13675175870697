import React, { useEffect, useState } from "react";
import LeftSidebar from "../LeftSideBar/LeftSidebar";

import SearchCadidatefillter from "./SearchCadidatefillter";
import SearchCandidateList from "./SearchCandidateList";
import CustomerSupport from "./CustomerSupport";
import authApi from "../../../../utils/authApi";
import { toast } from "react-toastify";
import axios from "axios";

const SearchCandidate = () => {
  const [allCandidates, setAllCandidates] = useState([]);
  //filter values start
  const [edu, setEdu] = useState([]);
  const [exp, setExp] = useState([]);

  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [searchSkill, setSearchSkill] = useState("");
  const [skillsLoading, setSkillsLoading] = useState(false);

  const [locations, setLocations] = useState([]);
  const [ctc, setCtc] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [locationsLoading, setLocationLoading] = useState(false);
  const [location, setLocation] = useState([]);
  //filter value end
  const [page, setPage] = useState(1);
  const [allPages, setAllPages] = useState(1);
  const [searching, setSearching] = useState(false);

  const fetchCandidates = async () => {
    setSearching(true);
    try {
      const payload = {
        education: edu[0],
        years_of_experience: exp[0],
        location: location[0],
        skill:
          selectedSkills?.length === 1 ? selectedSkills[0] : selectedSkills,
      };
      const res = await authApi.post(`recruiter/search-user`, payload);
      setSearching(false);
      setAllCandidates(res?.data?.users?.data);
      setAllPages(
        Math.ceil(res?.data?.users?.total / res?.data?.users?.per_page)
      );
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setSearching(false);
    }
  };
  let cancelToken1;
  const getLocation = async () => {
    if (typeof cancelToken1 != typeof undefined) {
      cancelToken1.cancel("Cancelled");
    }
    cancelToken1 = axios.CancelToken.source();
    try {
      if (searchQuery) {
        setLocationLoading(true);
        const res = await authApi.get(`search-state/${searchQuery}`, {
          cancelToken: cancelToken1.token,
        });
        setLocations(res?.data);
        setLocationLoading(false);
      }
    } catch (error) {
      setLocationLoading(false);
    }
  };
  useEffect(() => {
    if (searchQuery) {
      getLocation();
    } 
    return () => {
      if (cancelToken1) {
        cancelToken1.cancel("Cancelled");
      }
    };
  }, [searchQuery]);

  let cancelToken;
  const getSkills = async () => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Cancelled");
    }
    cancelToken = axios.CancelToken.source();

    try {
      if (searchSkill) {
        setSkillsLoading(true);
        const res = await authApi.get(
          `recruiter/search-skills/${searchSkill}`,
          { cancelToken: cancelToken.token }
        );
        setSkills(res?.data?.results);
        setSkillsLoading(false);
      }
    } catch (error) {
      setSkillsLoading(false);
    }
  };
  useEffect(() => {
    if (searchSkill) {
      getSkills();
    } 
    return () => {
      if (cancelToken) {
        cancelToken.cancel("Cancelled");
      }
    };
  }, [searchSkill]);


  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="job-tracker-section grid-2-container">
            <div className="">
              <LeftSidebar />
            </div>
            <div className="candidate-explor">
              <div className="grid-2-reverse gap-30 margin-top-1 ">
                <div>
                  <SearchCadidatefillter
                    allCandidates={allCandidates}
                    fetchCandidates={fetchCandidates}
                    setCtc={setCtc}
                    setLocations={setLocations}
                    setSkills={setSkills}
                    setEdu={setEdu}
                    setExp={setExp}
                    ctc={ctc}
                    locations={locations}
                    skills={skills}
                    selectedSkills={selectedSkills}
                    setSelectedSkills={setSelectedSkills}
                    skillsLoading={skillsLoading}
                    searchSkill={searchSkill}
                    setSearchSkill={setSearchSkill}
                    setLocation={setLocation}
                    location={location}
                    edu={edu}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    locationsLoading={locationsLoading}
                    exp={exp}
                    searching={searching}
                  />
                </div>
                <div
                  className="light-blue-bg  border-radius-14 text-center py-4 px-2 d-mob-none"
                  style={{ height: "fit-content" }}
                >
                  <CustomerSupport />
                </div>
              </div>

              <SearchCandidateList
                page={page}
                setPage={setPage}
                allPages={allPages}
                allCandidates={allCandidates}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchCandidate;
