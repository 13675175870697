import React, { useState } from "react";
import { CiSliderVertical } from "react-icons/ci";
import { HiOutlineBarsArrowDown } from "react-icons/hi2";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import Pagination from "../../../CommonComponents/Pagination";
import WorkSkills from "../../../CommonComponents/WorkSkills";
import Education from "../../../CommonComponents/Education";
import { toast } from "react-toastify";
import authApi from "../../../../utils/authApi";
import { IMAGEURL } from "../../../../constant";
import { HiSortAscending } from "react-icons/hi";
//import { useSelector } from "react-redux";

const ManageJobList = ({
  allApplicants,
  job,
  page,
  setPage,
  allPages,
  getAllJobPosts,
  sortBy,
  setSortBy,

  sortDir,
  setSortDir,
}) => {
  //state
  const [showBox, setShowBox] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [boxPosition, setBoxPosition] = useState({});
  const [currentEdu, setCurrentEdu] = useState([]);

  const handleItemMouseEnter = (item, event, data) => {
    setSelectedItem(item);
    if (item === "education") {
      setCurrentEdu(data?.map((ele) => ele.degree));
    }
    const target = event.currentTarget;
    const rect = target.getBoundingClientRect();
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const position = {
      top: rect.top + rect.height + scrollTop + 10, // Adjust this value
      left: rect.left + scrollLeft,
    };

    const centerX = rect.left + rect.width / 2;
    const boxWidth = "1600";
    const boxLeft = centerX - boxWidth / 2;

    setBoxPosition({
      ...position,
      left: boxLeft,
    });

    setShowBox(true);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowBox(true);
  };

  const handleBoxClose = () => {
    setShowBox(false);
    setSelectedItem(null);
  };

  //check skill matching
  const checkSkillsMatch = (candidateSkills) => {
    // Extract the 'key' values from array2
    const keysArray2 = candidateSkills?.map((item) => {
      if (item?.alternate_names) {
        if(item?.alternate_names){
          return [
            ...JSON.parse(item?.alternate_names),
            item?.title?.toLowerCase(),
          ];
        }else{
          return []
        }
       
      } else {
        return [item?.title?.toLowerCase()];
      }
    });
    // Concatenate arrays using reduce
    const resultArray = keysArray2.reduce(function (accumulator, currentArray) {
      return accumulator.concat(currentArray);
    }, []);
    let finalArray = resultArray?.filter((ele) => ele != undefined);

    const keysArray1 = job?.required_skills?.map((elem) => elem.toLowerCase());

    // Count the number of keys from array2 that are present in array1
    const countMatchingKeys = finalArray?.filter((key) =>
      keysArray1?.includes(key)
    )?.length;

    // Calculate the percentage
    const percentage = (countMatchingKeys / job?.required_skills?.length) * 100;
    return percentage.toFixed(2) + "%";
  };

  //check Required skill match
  const checkRequiredSkillsMatch = (candidateSkills) => {
let reqSkillsWithExp=[]
if(job?.skill_wise_experience){
  reqSkillsWithExp = JSON.parse(job?.skill_wise_experience);
}


    // Extract the 'key' values from array2
    const keysArray2 = candidateSkills?.map((item) => {
      if (item?.alternate_names) {
      
        const arr = JSON.parse(item?.alternate_names) || [];

        if (arr?.length > 0) {
          const newArr = arr?.map(
            (ele) =>
              `${ele?.toLowerCase()}-${
                item?.pivot?.month_or_year?.toLowerCase() == "year"
                  ? 12 * +item?.pivot?.year_of_experience
                  : item?.pivot?.year_of_experience
              }`
          );
          return [
            ...newArr,
            `${item?.title?.toLowerCase()}-${
              item?.pivot?.month_or_year?.toLowerCase() == "year"
                ? 12 * +item?.pivot?.year_of_experience
                : item?.pivot?.year_of_experience
            }`,
          ];
        }
      } else {
        return [
          `${item?.title?.toLowerCase()}-${
            item?.pivot?.month_or_year?.toLowerCase() == "year"
              ? 12 * +item?.pivot?.year_of_experience
              : item?.pivot?.year_of_experience
          }`,
        ];
      }
    });

    // Concatenate arrays using reduce
    const resultArray = keysArray2.reduce(function (accumulator, currentArray) {
      return accumulator.concat(currentArray);
    }, []);

    let finalArray = resultArray?.filter(
      (ele) => ele?.split("-")[0] != "undefined"
    );

    const keysArray1 = reqSkillsWithExp?.map(
      (elem) =>
        `${elem?.skill?.toLowerCase()}-${
          elem?.month_or_year?.toLowerCase() == "year"
            ? 12 * +elem?.experience
            : elem?.experience
        }`
    );

    // const rett=calculateOverallPercentage(keysArray1,finalArray);
    if (keysArray1?.length > 0 && finalArray?.length >= 0) {
      const rett = calculateOverallPercentage(keysArray1, finalArray);
      return rett;
    } else {
      return 0;
    }
  };

  function calculateOverallPercentage(arr1, arr2) {
    let totalPercentage = 0;
    arr2?.forEach((skillFromArr2) => {
      let [skill, durationFromArr2] = skillFromArr2.split("-");
      let matchingSkills = arr1.filter((skillFromArr1) =>
        skillFromArr1.includes(skill)
      );
      if (matchingSkills.length > 0) {
        let durationFromArr1 = matchingSkills[0].split("-")[1];
        let durationNumberFromArr1 = +durationFromArr1;
        let durationNumberFromArr2 = +durationFromArr2;
        if (durationNumberFromArr2 >= durationNumberFromArr1) {
          let percentage = 100;
          totalPercentage += percentage;
        } else {
          let percentage =
            (+durationNumberFromArr2 / +durationNumberFromArr1) * 100;
          totalPercentage += percentage;
        }
      }
    });
    return (totalPercentage / arr1?.length).toFixed(2);
  }

  // Function to calculate the difference in months between two dates
  function calculateMonthDifference(startDate, endDate) {
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();

    let monthDifference = (endYear - startYear) * 12 + (endMonth - startMonth);

    return monthDifference;
  }
  //get total Experience
  const getTotalExperience = (data) => {
    const sumOfMonths = data?.reduce((totalMonths, obj) => {
      const startDate = new Date(obj.start_date);
      let endDate;
      if (obj?.present_job) {
        endDate = new Date(); // Use current date if present_job is true
      } else {
        endDate = new Date(obj.end_date);
      }
      const monthsDifference = calculateMonthDifference(startDate, endDate);
      totalMonths += monthsDifference;
      return totalMonths;
    }, 0);
    const years = sumOfMonths / 12; // Total months converted to years
    if (years > 0) {
      return years.toFixed(1);
    } else {
      return 0;
    }
  };
  //get Present Company
  const getPresentCompany = (data) => {
    const a = data.filter((elem) => elem.present_job);
    if (a.length > 0) {
      return a[0].company_name;
    } else {
      return "-";
    }
  };

  const handleChangeStatus = async (e, data) => {
    try {
      await authApi.put(
        `recruiter/update-status/user/${data?.user_id}/job/${data?.job_id}`,
        { status: e.target.value }
      );
      getAllJobPosts();
      toast.success("Status updated successfully.");
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="job-tracker-container table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>
                Name{" "}
                {sortDir === "desc" && sortBy === "name" ? (
                  <HiOutlineBarsArrowDown
                    className="ml-1 fa-20 black"
                    onClick={() => {
                      setSortBy("name");
                      setSortDir("asc");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <HiSortAscending
                    className="ml-1 fa-20 black"
                    onClick={() => {
                      setSortBy("name");
                      setSortDir("desc");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </th>
              <th>
                Skill match
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Required Skills Match
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Current Job
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Exp.{" "}
                {sortDir === "desc" && sortBy === "years_of_experience" ? (
                  <HiOutlineBarsArrowDown
                    className="ml-1 fa-20 black"
                    onClick={() => {
                      setSortBy("years_of_experience");
                      setSortDir("asc");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <HiSortAscending
                    className="ml-1 fa-20 black"
                    onClick={() => {
                      setSortBy("years_of_experience");
                      setSortDir("desc");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </th>
              <th>
                Location
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Rated
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th className="text-right">
                <CiSliderVertical className="fa-20 black fw-700" />
              </th>
            </tr>
          </thead>
          <tbody>
            {allApplicants?.map((row, index) => (
              <tr
                key={index}
                className="j-gray-bg align-items-center border-18"
              >
                <td style={{ width: "150px" }}>
                  <Link
                    // onMouseEnter={(e) => handleItemMouseEnter("Condidate", e)}
                    // onClick={() => handleItemClick("Condidate")}
                    className={selectedItem === "Condidate" ? "active" : ""}
                  >
                    <p className="fa-14 fw-600 light-black mb-1">
                      {row?.first_name} {row?.last_name}
                    </p>
                    <p className="mb-0 small-text">
                      {" "}
                      {row?.phone ?? "Not provided"}{" "}
                    </p>
                    <p className="mb-0 small-text">
                      {" "}
                      {row?.email ?? "Not provided"}
                    </p>
                  </Link>
                </td>
                {/* <td>
                  <span className="mb-2">70%</span>
                  <div
                    className="progress"
                    style={{ height: "15px", borderRadius: "14px" }}
                  >
                    <div
                      className="progress-bar bg-dark-blue"
                      style={{ width: "70%", height: "15px" }}
                    ></div>
                  </div>
                </td> */}
                <td>
                  <div style={{ width: "100px" }}>
                    <Link
                      // onMouseEnter={(e) => handleItemMouseEnter("80%", e)}
                      // onClick={(event) => handleItemClick("80%")}
                      className={`light-black ${
                        selectedItem === "80%" ? "active" : ""
                      }`}
                    >
                      <span className="mb-2">
                        {checkSkillsMatch(row?.skills)}{" "}
                      </span>
                      <div
                        className="progress"
                        style={{ height: "15px", borderRadius: "14px" }}
                      >
                        <div
                          className="progress-bar bg-dark-blue"
                          style={{
                            width: `${checkSkillsMatch(row?.skills)}`,
                            height: "15px",
                          }}
                        ></div>
                      </div>
                    </Link>
                  </div>
                </td>
                <td>
                  {job?.skill_wise_experience &&  JSON.parse(job?.skill_wise_experience)?.length > 0 ? (
                    <div style={{ width: "100px" }}>
                      <Link
                        // onMouseEnter={(e) => handleItemMouseEnter("80%", e)}
                        // onClick={(event) => handleItemClick("80%")}
                        className={`light-black ${
                          selectedItem === "80%" ? "active" : ""
                        }`}
                      >
                        <span className="mb-2">
                          {checkRequiredSkillsMatch(row?.skills)}%{" "}
                        </span>
                        <div
                          className="progress"
                          style={{ height: "15px", borderRadius: "14px" }}
                        >
                          <div
                            className="progress-bar bg-dark-blue"
                            style={{
                              width: `${checkRequiredSkillsMatch(
                                row?.skills
                              )}%`,
                              height: "15px",
                            }}
                          ></div>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    <div>No Need</div>
                  )}
                </td>
                {/* <td>
                  <Link
                    onMouseEnter={(e) =>
                      handleItemMouseEnter(
                        "education",
                        e,
                        row?.education_qualifications
                      )
                    }
                    onClick={(event) => handleItemClick("education")}
                    className={`gray ${
                      selectedItem === "education" ? "active" : ""
                    }`}
                  >
                    Education
                  </Link>
                </td> */}
                <td>{getPresentCompany(row?.work_experiences)}</td>

                <td>
                  {+getTotalExperience(row?.work_experiences)}{" "}
                  {+getTotalExperience(row?.work_experiences) > 1
                    ? "years"
                    : "year"}{" "}
                </td>
                <td>
                  {row?.state?.name} ( {row?.country?.name} )
                </td>
                <td>
                  <select
                    className="form-control"
                    onChange={(e) => handleChangeStatus(e, row?.pivot)}
                    value={row?.pivot?.status}
                  >
                    <option value="Unrated">Un-rated</option>
                    <option value="Good Fit">Good fit</option>
                    <option value="May Be">May be</option>
                    <option value="Not a Fit">Not fit</option>
                  </select>
                </td>
                <td>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle dark-gray-bg rounded-50 p-2 d-flex justify-content-center align-items-center"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <BsThreeDots className="black fa-26" />
                    </button>

                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div className="drop-scroll">
                        <Link
                          to={`/view-applicant/${job?.id}/${row?.id}`}
                          className="dropdown-item fa-14 light-black fw-400"
                        >
                          View Profile{" "}
                        </Link>
                        {row?.resume && (
                          <Link
                            to={`${IMAGEURL}${row?.resume}`}
                            target="_blank"
                            className="dropdown-item fa-14 light-black fw-400"
                          >
                            Download Resume{" "}
                          </Link>
                        )}
                        {/* {row?.phone && (
                        <Link className="dropdown-item fa-14 light-black fw-400">
                          {row?.phone ?? "Not provided"}{" "}
                        </Link>
                      )}
                      {row?.email && (
                        <Link
                          to={`mailto:${row?.email}`}
                          className="dropdown-item fa-14 light-black fw-400"
                        >
                          {row?.email ?? "Not provided"}
                        </Link>
                      )} */}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination-row float-right">
        <Pagination page={page} setPage={setPage} allPages={allPages} />
      </div>

      {/* <div className="position-relative">
        {showBox && (
          <div
            className={`box ${showBox ? "slide-down" : "slide-up"}`}
            style={{
              top: boxPosition.top,
              left: boxPosition.left,
            }}
          >
            {selectedItem === "Condidate" && (
              <div className="box-info">
                <p className="fa-14 gray fw-400 line-height-24">
                  As a Marketing Professional my accentuation is on
                  conversations, for which it is fundamental that we know our
                  get-together of individuals well or potentially have the
                  preparation to get some answers concerning them Pieces of
                  information aren’t actually what examination are uncovering to
                  us how ....
                </p>

                <div className="d-sm-flex d-block justify-content-between">
                  <Link
                    to="/profile"
                    className="btn blue-btn mt-3 px-3 w-sm-100"
                  >
                    View Profile
                  </Link>

                  <Link to="#" className="btn blue-btn mt-3 px-3 w-sm-100">
                    Download Resume
                  </Link>
                </div>
              </div>
            )}

            {selectedItem === "80%" && (
              <div className="skill-content">
                <ul className="skill-content-list">
                  <li>
                    <span>NODE JS</span> <span>90%</span>
                  </li>
                  <li>
                    <span>ANGULAR</span> <span>90%</span>
                  </li>
                  <li>
                    <span>ORACLE</span> <span>90%</span>
                  </li>
                  <li>
                    <span>PYTHON</span> <span>80%</span>
                  </li>
                </ul>
              </div>
            )}

            {selectedItem === "senior" && (
              <div className="work-experience">
                <p className="fa-16 gray mb-1"> CTC - Rs. 4,00,000 </p>
                <h4 className="card-title fa-18 mb-3"> Other Skills </h4>
                <WorkSkills />
              </div>
            )}

            {selectedItem === "education" && (
              <div className="work-experience">
                <Education currentEdu={currentEdu} />
              </div>
            )}

            <button className="box-close" onClick={handleBoxClose}>
              X
            </button>
          </div>
        )}
      </div> */}
    </>
  );
};

export default ManageJobList;
