import React from 'react';

const PageNotFound = () => {

  const goBack = () => {
    window.history.go(-1);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>404 - Page Not Found</h1>
      <p style={styles.message}>Sorry, the page you are looking for might be in another castle.</p>
      <button style={styles.button} onClick={goBack}>Go Back</button>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    padding: '50px',
    margin: 'auto',
    marginTop: '100px',
    maxWidth: '600px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    background: '#fff',
  },
  title: {
    fontSize: '2em',
    marginBottom: '20px',
    color: '#333',
  },
  message: {
    fontSize: '1.2em',
    color: '#666',
    marginBottom: '30px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '1em',
    background: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default PageNotFound;
