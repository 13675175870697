import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Logo from "../../../assets/jobsight-logo.png";
import user from "../../../assets/dummyProfile.webp";
import "./NavComponent.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../Redux/authSlice";
import { IMAGEURL } from "../../../constant";
import authApi from "../../../utils/authApi";
import { toast } from "react-toastify";

function NavComponent() {
  const [isJobOpen, setJobOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isFindJobOpen, setIsFindJobOpen] = useState(false);
  const [allJobTypes, setAllJobTypes] = useState([]);

  const profile = useSelector((state) => state?.user?.profile);
  const isAuth = useSelector((state) => state.auth.isLoggedIn); //constant for checking user is authenticated or not
  const loginAs = useSelector((state) => state.auth.loginAs);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const JobdMouseEnter = () => {
    setJobOpen(true);
  };

  const JobMouseLeave = () => {
    setJobOpen(false);
  };

  const FindJobEnter = () => {
    // console.log("yes");
    setIsFindJobOpen(true);
  };
  const FindJobLeave = () => {
    // console.log("no");
    setIsFindJobOpen(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky]);

  // for scroll
  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    setIsSticky(scrollTop > 250);
  };

  // Close the navbar when the user navigates to another page
  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  //function to logout
  const handleLogOutClick = () => {
    dispatch(logout());
  };

  const getAllJobTypes = async () => {
    try {
      const res = await authApi.get(`get-job-type`);
      setAllJobTypes(res?.data?.jobType);
    } catch (error) {}
  };
  useEffect(() => {
    getAllJobTypes();
  }, []);

  const handleGo = (value) => {
    navigate("/job-search");
    localStorage.removeItem("query");
    localStorage?.setItem("query", JSON.stringify(value));
    setIsFindJobOpen(false);

    //to={`/job-search?job_type=${row?.type}`}
  };
  return (
    <>
      <header className={isSticky ? "sticky" : ""}>
        <div className="navigation-wrap theme-bg">
          <div className="container-fluid side-space">
            <nav className="navbar navbar-expand-lg align-items-start align-items-lg-center pl-0 pr-0 d-flex">
              <div className="logo">
                <Link className="navbar-brand" to="/">
                  <img src={Logo} alt="in Job sight Logo" />
                </Link>
              </div>
              <div className="menu-container text-right w-md-50">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={handleMenuToggle}
                >
                  <div className="d-flex align-items-center">
                    <span className="navbar-toggler-icon"></span>
                    <div className="profile-icon ml-2">
                      {loginAs === "Recruiter" && (
                        <img
                          src={
                            profile?.logo ? `${IMAGEURL}${profile?.logo}` : user
                          }
                          alt="user profile"
                        />
                      )}

                      {loginAs === "Candidate" && (
                        <img
                          src={
                            profile?.avatar
                              ? profile?.avatar.includes("http")
                                ? profile?.avatar
                                : `${IMAGEURL}${profile?.avatar}`
                              : user
                          }
                          alt="user profile"
                        />
                      )}
                    </div>
                  </div>
                </button>
                <div
                  className={`collapse navbar-collapse justify-content-lg-between justify-content-end align-items-center bg-white-collapse text-left text-lg-right p-2 p-lg-0 ${
                    isMenuOpen ? "show" : ""
                  }`}
                >
                  {/* Close button */}
                  {isMenuOpen && (
                    <button className="btn closebtn" onClick={handleMenuToggle}>
                      &times;
                    </button>
                  )}

                  <div className="left-header-menu">
                    <div className="login-details pl-3 d-desk-none">
                      <div className="d-flex align-items-center">
                        <div className="profile-icon">
                          {loginAs === "Recruiter" && (
                            <img
                              src={
                                profile?.logo
                                  ? `${IMAGEURL}${profile?.logo}`
                                  : user
                              }
                              alt="user profile"
                            />
                          )}
                          {loginAs === "Candidate" && (
                            <img
                              src={
                                profile?.avatar
                                  ? profile?.avatar.includes("http")
                                    ? profile?.avatar
                                    : `${IMAGEURL}${profile?.avatar}`
                                  : user
                              }
                              alt="user profile"
                            />
                          )}
                        </div>
                        {loginAs === "Recruiter" && (
                          <p className="mb-0 text-white ml-3">
                            {profile?.name}{" "}
                          </p>
                        )}
                        {loginAs === "Candidate" && (
                          <p className="mb-0 text-white ml-3">
                            {profile?.first_name ?? "Unknown"}
                            {profile?.last_name
                              ? ` ${profile?.last_name}`
                              : ""}{" "}
                          </p>
                        )}
                      </div>
                    </div>

                    <ul className="navbar-nav mt-4 mt-lg-0">
                      {loginAs === "Candidate" && (
                        <li
                          className="nav-item show d-flex align-items-center justify-content-between "
                          onMouseEnter={FindJobEnter}
                          onMouseLeave={FindJobLeave}
                        >
                          <Link className="nav-link" to="/job-search">
                            Find Jobs
                          </Link>
                          {isFindJobOpen && (
                            <div
                              className="dropdown-menu d-flex"
                              onMouseEnter={FindJobEnter}
                              onMouseLeave={FindJobLeave}
                            >
                              {/* <div className="mega-box p-4">
                              <div className="mega-menu text-start">
                                <span className="font-weight-bold fa-18 d-block">
                                  Popular categories
                                </span>
                                <ul className="inner-menu">
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      IT jobs
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Sales jobs
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Marketing jobs
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Data Science jobs
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      HR jobs
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Engineering jobs
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                              <div className="mega-box p-4">
                                <div className="mega-menu text-start">
                                  <span className="font-weight-bold fa-18 d-block">
                                    Jobs in demand
                                  </span>
                                  <ul className="inner-menu">
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to="/job-search?exp=0"
                                      >
                                        Fresher jobs
                                      </Link>
                                    </li>
                                    {/* <li>
                                    <Link className="dropdown-item" to="#">
                                      MNC jobs
                                    </Link>
                                  </li> */}
                                    {allJobTypes?.map((row, index) => (
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => handleGo(row)}
                                        >
                                          {row?.type}
                                        </a>
                                      </li>
                                    ))}

                                    {/* <li>
                                    <Link className="dropdown-item" to="#">
                                      Work from home jobs
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Walk-in jobs
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Part-time jobs
                                    </Link>
                                  </li> */}
                                  </ul>
                                </div>
                              </div>

                              {/* <div className="mega-box p-4">
                              <div className="mega-menu text-start">
                                <span className="font-weight-bold fa-18 d-block">
                                  Jobs by Location
                                </span>
                                <ul className="inner-menu">
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Jobs in Delhi
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Jobs in Mumbai
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Jobs in Bangalore
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Jobs in Hyderabad
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Jobs in Chennai
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Jobs in Pune
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                              {/* <div className="mega-box p-4">
                              <div className="mega-menu text-start">
                                <span className="font-weight-bold fa-18 d-block">
                                  Explore more jobs
                                </span>
                                <ul className="inner-menu">
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Jobs by category
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Jobs by location
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Jobs by skills
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                            </div>
                          )}
                        </li>
                      )}

                      {isAuth && loginAs === "Recruiter" && (
                        <li
                          className="nav-item show d-flex align-items-center justify-content-between "
                          onMouseEnter={JobdMouseEnter}
                          onMouseLeave={JobMouseLeave}
                        >
                          <Link
                            className="nav-link"
                            to="#"
                            aria-haspopup="true"
                            aria-expanded={isJobOpen}
                          >
                            For Company
                          </Link>
                          <span className="d-lg-none ml-2">
                            {isJobOpen ? (
                              <FaChevronUp className="white" />
                            ) : (
                              <FaChevronDown className="white" />
                            )}
                          </span>

                          {isJobOpen && (
                            <div
                              className="dropdown-menu"
                              onMouseEnter={JobdMouseEnter}
                              onMouseLeave={JobMouseLeave}
                            >
                              <div className="mega-box">
                                <div className="mega-menu">
                                  <ul className="inner-menu">
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to="/about-us"
                                      >
                                        Why In Job Sight
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to="/help-center"
                                      >
                                        Knowledge base
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </li>
                      )}
                      {isAuth && loginAs === "Candidate" && (
                        <li className="nav-item">
                          <Link to="/profile" className="nav-link">
                            Profile
                          </Link>
                        </li>
                      )}

                      {isAuth&& loginAs === "Candidate"  && (
                        <li className="nav-item">
                          <Link to="/dashboard" className="nav-link">
                            Dashboard
                          </Link>
                        </li>
                      )}
                         {isAuth&& loginAs === "Recruiter"  && (
                        <li className="nav-item">
                          <Link to="/company" className="nav-link">
                            Company
                          </Link>
                        </li>
                      )}

                      {isAuth && (
                        <li className="nav-item">
                          <Link to="/notification" className="nav-link">
                            Notifications
                          </Link>
                        </li>
                      )}
                      {/* {isAuth && (
                        <li className="nav-item">
                          <Link to="/message" className="nav-link">
                            Messages{" "}
                          </Link>
                        </li>
                      )} */}

                      {isAuth && loginAs === "Recruiter" && (
                        <li className="nav-item d-desk-none">
                          <Link
                           
                            to="/job-post"
                            className="nav-link"
                          >
                            Post Your Job
                          </Link>
                        </li>
                      )}
                      <li className="nav-item">
                        <Link to="/about-us" className="nav-link">
                          About us
                        </Link>
                      </li>
                      <li className="nav-item d-desk-none">
                      {isAuth ? (
                        <Link
                          onClick={() => handleLogOutClick()}
                          className="nav-link"
                        >
                          Log out
                        </Link>
                      ) : (
                        <Link to="/login" className="nav-link">
                          Login
                        </Link>
                      )}
                      </li>
                    </ul>
                  </div>

                  <div className="right-header-menu text-left text-lg-right w-md-100 p-top d-mob-none">
                    <div className="login-conatiner">
                      {isAuth && loginAs === "Recruiter" && (
                        <>
                          <Link
                            to= "/job-post"
                           
                          >
                            Post Your Job
                          </Link>
                          <span className="vertical-divider d-mob-none"></span>
                        </>
                      )}

                      {isAuth ? (
                        <Link
                          onClick={() => handleLogOutClick()}
                          className=""
                        >
                          Log out
                        </Link>
                      ) : (
                        <Link to="/login" className="d-mob-none">
                          Login
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default NavComponent;
