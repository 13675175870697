import React, { useEffect, useState } from "react";
import "./Cards.css";
import { useNavigate } from "react-router-dom";
import { IoMdMail } from "react-icons/io";
const Cards = ({ bg, color, title,Icon,value }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < value) {
        setCount((prevCount) => prevCount + 1);
      } else {
        clearInterval(interval);
      }
    }, 100); // Adjust the interval to control the speed of the animation

    return () => clearInterval(interval);
  }, [count,value]);
  // if(title==="Pending Orders"){
  //   localStorage.setItem("orderType",JSON.stringify("Pending"));
  // }

  // const navigate = useNavigate();
  // const handleGoToLink = () => {
  //   if (link) {
  //     navigate(link);
  //   }
  // };

  return (
    <>
      <div
        className="card-component "
        // style={{ cursor: link && "pointer" }}
        // onClick={() => handleGoToLink()}
      >
        <div className="card-component-inner" style={{ backgroundColor: bg,width:"100%" }}>
          <div className="d-flex justify-content-between align-items-center w-100" >
            <div class="border border-white p-2" style={{borderRadius:"12px"}}>
              {Icon}
            </div> 
            <div className="card-details">
              <h3 style={{ color: color }}>{title}</h3> 

              <h2 style={{ color: color,fontSize:"24px" }} className="text-right count" >{count}</h2>
            </div>
            <div
            // className={`card-icon ${className}`}
            // style={{ color: iconColor }}
            >
              {/* <Icon /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
