import React from "react";
import { Link, useParams } from "react-router-dom";
import { FaShareSquare } from "react-icons/fa";
import MessageModal from "../Message/MessageModal";
import { toast } from "react-toastify";
import authApi from "../../../../utils/authApi";

const ApplicantCard = ({ profile ,job,getAllJobPosts}) => {
  // console.log("profile is",job);
  const search = useParams();

  //payload {'status':''}   //'Unrated','Good Fit','May Be','Not a Fit','Interviewing','Negotiating','Accepted'
  const handleChangeStatus=async(e)=>{
    try{
await authApi.put(`recruiter/update-status/user/${profile?.id}/job/${job?.id??search?.jobId}`,{status:e.target.value});
getAllJobPosts();
toast.success("Status updated successfully.")
    }catch(err){
      toast.error(err?.response?.data?.message)
    }
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div className="applicant-card">
              <div className="applicant-info view-job-post-card-content">
                <h4>
                  {`${profile?.first_name} ${profile?.last_name}`}'s application
                </h4>
                <p>{profile?.about_me}</p>
                {(profile?.city || profile?.state || profile?.country) && (
                  <p>
                    {profile?.city ? profile?.city:""} {profile?.state ? `, ${profile?.state}`: ""}{" "}
                    {profile?.country ?`, ${profile?.country}`: ""}
                  </p>
                )}
                {/* <p className="gray fa-14">Applied 5 days ago</p> */}
              </div>
              <div className="applicant-info-more d-flex gap-30 mt-4">
                <div className="applicant-info-fit">
                  <select className="form-control border-0" onChange={(e)=>handleChangeStatus(e)} value={job?.pivot?.status}>
                  <option value="Unrated">Un-rated</option>
                    <option value="Good Fit">Good fit</option>
                    <option value="May Be">May be</option>
                    <option value="Not a Fit">Not fit</option>
                  </select>
                </div>
                {/* <div className="vertical-line"></div> */}
                {/* <div className="applicant-info-fit">
                  <MessageModal />
                  <Link t0="/message" className="btn btn-blue-outline ">
                    More..
                  </Link>
                </div> */}
              </div>
            </div>

            {/* <div className="share-icon">
              <FaShareSquare className="fa-20 gray" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicantCard;
