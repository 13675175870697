import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddMediaDropdown from "./AddMediaDropdown";
import InputTag from "../../../../CommonComponents/InputTag";
import "./Modal.css";
import authApi from "../../../../../utils/authApi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateUser } from "../../../../../Redux/userSlice";

//initial Data
const initialData = {
  user_id: "",
  institution: "",
  degree: "",
  department: "",
  percentage: "",
  grade: "",
  start_month: "",
  start_year: "",
  end_month: "",
  end_year: "",
  activities: "",
  description: "",
  status: true,
  skills: [],
  media: "",
};
const AddNewEducation = ({
  linkText,
  editIt,
  thisIs,
  updateFlag,
  setUpdateFlag,
  icon,
  className,
}) => {
  const [showNewEducation, setShowNewEducation] = useState(false); //for popup Modal
  const authId = useSelector((state) => state?.auth?.user?.info?.id); //user id
  const [data, setData] = useState(initialData); //state
  const [saving, setSaving] = useState(false);
  const [savedSkills, setSavedSkills] = useState([]); //state for selected skills
  const [media, setMedia] = useState("");
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  // console.log("editIt", editIt);
  useEffect(() => {
    if (editIt?.id) {
      setData({
        user_id: editIt?.user_id ?? "",
        institution: editIt?.institution ?? "",
        degree: editIt?.degree ?? "",
        department: editIt?.department ?? "",
        percentage: editIt?.percentage ?? "",
        grade: editIt?.grade ?? "",
        start_month: editIt?.start_month ?? "",
        start_year: editIt?.start_year ?? "",
        end_month: editIt?.end_month ?? "",
        end_year: editIt?.end_year,
        activities: editIt?.activities ?? "",
        description: editIt?.description ?? "",
        skills: [],
        media: editIt?.media ?? "",
      });
      // if(editIt?.skills){
      //   setSavedSkills(JSON.parse(editIt?.skills).split(","))
      // }
      // if(editIt?.corporate_title){
      //   setTitleQuery(editIt?.corporate_title?.title);
      // }
    }
  }, [editIt]);
  // function for  education
  const handleOpenNewEducation = () => {
    setShowNewEducation(true);
  };

  const handleCloseNewEducation = () => {
    setShowNewEducation(false);
  };
  //function to add education
  const handleAddEducation = async (e) => {
    setData({ ...data, user_id: authId });
    setSaving(true);
    e.preventDefault();
    const formData = new FormData();
    if (thisIs === "Edu") {
      formData.append("degree", data?.degree ?? "");
      formData.append("department", data?.department ?? "");
      formData.append("media", media ?? "");
    } else {
      formData.append("course", data?.degree ?? "");
      formData.append("study_field", data?.department ?? "");
      formData.append("medias", media ?? "");
    }
    formData.append("user_id", data?.user_id ?? "");
    formData.append("institution", data?.institution ?? "");

    formData.append("grade", data?.grade ?? "");
    formData.append("start_month", data?.start_month ?? "");
    formData.append("start_year", data?.start_year ?? "");
    formData.append("end_month", data?.end_month ?? "");
    formData.append("end_year", data?.end_year ?? "");
    formData.append("activities", data?.activities ?? "");
    formData.append("description", data?.description ?? "");
    formData.append("skills", savedSkills ?? "");

    formData.append("percentage", data?.percentage ?? "");

    try {
      if(editIt?.id){
        await authApi.post(`users/${authId}/education-qualifications/${editIt?.id}?_method=PUT`, formData);
        setUpdateFlag(!updateFlag);
        dispatch(updateUser());
        setShowNewEducation(false);
   
        setSaving(false);
      }else{
        await authApi.post(`users/${authId}/education-qualifications`, formData);
        setUpdateFlag(!updateFlag);
        dispatch(updateUser());
        setShowNewEducation(false);
        setData(initialData);
        setSaving(false);
      }
     
    } catch (error) {
      setSaving(false);
    }
  };
  //function to delete
  const handleDelete = async (e) => {
    e.preventDefault();
    setDeleting(true);
    try {
      // https://jobapi.quicsy.com/api/users/{user}/education-qualifications/{educationQualification}
      await authApi.delete(`users/education-qualifications/${editIt?.id}`);
      dispatch(updateUser());
      setDeleting(false);
      setShowNewEducation(false);
    } catch (err) {
      setDeleting(false);
      setShowNewEducation(false);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <>
      <Link
        to=""
        className={`add-new-label ${className}`}
        onClick={handleOpenNewEducation}
      >
        {icon} {linkText}
      </Link>

      {showNewEducation && (
        <div className="modal " style={{ display: "block" }}>
          <div className="modal-dialog modal-lg opne">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {editIt?.id ? "Edit" : "Add"}{" "}
                  {thisIs === "Edu" ? "Education" : "Certification"}
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleCloseNewEducation}
                >
                  &times;
                </button>
              </div>
              <form action="">
                <div className="form-container">
                  <div className="modal-body custom-scrollbar">
                    <div className="form-group">
                      <label htmlFor="school ">
                        School <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex: Boston University"
                        id="school"
                        required
                        value={data?.institution}
                        onChange={(e) =>
                          setData({ ...data, institution: e.target.value })
                        }
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="degree ">
                        Degree <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex: Bachelor’s"
                        id="degree"
                        value={data?.degree}
                        onChange={(e) =>
                          setData({ ...data, degree: e.target.value })
                        }
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="study "> Field of study </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex: Business"
                        id="study"
                        value={data?.department}
                        onChange={(e) =>
                          setData({ ...data, department: e.target.value })
                        }
                      />
                    </div>

                    <div className="d-sm-flex">
                      <div class="form-group flex-fill mr-0 mr-sm-3 ">
                        <label for="start_date">
                          Start <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control w-100"
                          value={data?.start_month}
                          onChange={(e) =>
                            setData({ ...data, start_month: e.target.value })
                          }
                          id="start_date"
                        >
                          <option>Please Select</option>
                          <option>January</option>
                          <option>February</option>
                          <option>March</option>
                          <option>April</option>
                          <option>May</option>
                          <option>June</option>
                          <option>July</option>
                          <option>August</option>
                          <option>September</option>
                          <option>October</option>
                          <option>November</option>
                          <option>December</option>
                        </select>
                      </div>

                      <div class="form-group flex-fill ml-0 ml-sm-3">
                        <label for="location">&nbsp;</label>
                        <select
                          class="form-control w-100"
                          value={data?.start_year}
                          onChange={(e) =>
                            setData({ ...data, start_year: e.target.value })
                          }
                          id="location"
                        >
                          <option>Please Select </option>
                          <option>2023</option>
                          <option>2022</option>
                          <option>2021</option>
                        </select>
                      </div>
                    </div>

                    <div className="d-sm-flex">
                      <div class="form-group flex-fill mr-0 mr-sm-3 ">
                        <label for="start_date">End (or expected)</label>
                        <select
                          className="form-control w-100"
                          value={data?.end_month}
                          onChange={(e) =>
                            setData({ ...data, end_month: e.target.value })
                          }
                          id="start_date"
                        >
                          <option>Please Select</option>
                          <option>January</option>
                          <option>February</option>
                          <option>March</option>
                          <option>April</option>
                          <option>May</option>
                          <option>June</option>
                          <option>July</option>
                          <option>August</option>
                          <option>September</option>
                          <option>October</option>
                          <option>November</option>
                          <option>December</option>
                        </select>
                      </div>

                      <div class="form-group flex-fill ml-0 ml-sm-3">
                        <label for="location" className="hide">
                          &nbsp;
                        </label>
                        <select
                          class="form-control w-100"
                          value={data?.end_year}
                          onChange={(e) =>
                            setData({ ...data, end_year: e.target.value })
                          }
                          id="location"
                        >
                          <option>Please Select </option>
                          <option>2023</option>
                          <option>2022</option>
                          <option>2021</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="grade">Grade </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Grade"
                        id="grade"
                        value={data?.grade}
                        onChange={(e) =>
                          setData({ ...data, grade: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="percentage">Percentage </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Grade"
                        id="grade"
                        value={data?.percentage}
                        onChange={(e) =>
                          setData({ ...data, percentage: e.target.value })
                        }
                      />
                    </div>

                    <div class="form-group">
                      <label htmlFor="activities_and_societies">
                        Activities and societies{" "}
                      </label>
                      <textarea
                        className="form-control"
                        rows="5"
                        id="activities_and_societies"
                        placeholder="Ex: Alpha Phi Omega, Marching Band, Volleyball"
                        value={data?.activities}
                        onChange={(e) =>
                          setData({ ...data, activities: e.target.value })
                        }
                      />
                    </div>

                    <div class="form-group">
                      <label htmlFor="description">Description </label>
                      <textarea
                        className="form-control"
                        rows="5"
                        id="description"
                        placeholder="Description"
                        value={data?.description}
                        onChange={(e) =>
                          setData({ ...data, description: e.target.value })
                        }
                      />
                    </div>

                    <h4 className="form-title pt-2 pb-2">Skill </h4>
                    <p className="small-text">
                      We recommend adding your top 5 used in this role. They’ll
                      also appear in your Skills section.
                    </p>

                    {/* InputTag component */}

                    <InputTag
                      title="Add Skill"
                      savedSkills={savedSkills}
                      setSavedSkills={setSavedSkills}
                    />

                    {/* end InputTag component */}

                    <h4 className="form-title pt-4 pb-2">Media </h4>
                    <p className="small-text">
                      Add media like images, documents, sites or presentations.
                      Learn more about{" "}
                      <Link to="#" className="fa-14 link-text">
                        media file types supported
                      </Link>
                    </p>

                    {/* Media dropdown component*/}

                    <AddMediaDropdown media={media} setMedia={setMedia} />

                    {/* end Media dropdown component*/}
                  </div>
                  <div className="modal-footer  d-flex justify-content-between text-right">
                    {editIt?.id ? (
                      <button
                        className="btn blue-btn"
                        onClick={(e) => handleDelete(e)}
                        disabled={deleting}
                      >
                        {" "}
                        {deleting ? "Deleting" : "Delete"}
                      </button>
                    ) : (
                      <b></b>
                    )}
                    <button
                      type="submit"
                      disabled={
                        !data.institution ||
                        !data.degree ||
                        !data.start_month ||
                        !data.start_year ||
                        saving
                      }
                      
                      className="btn blue-btn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddNewEducation;
