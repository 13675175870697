import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Modal.css";
import { useDispatch, useSelector } from "react-redux";
import authApi from "../../../../../utils/authApi";
import { toast } from "react-toastify";
import { updateUser } from "../../../../../Redux/userSlice";
const initialData = { title: "", from_year: "", to_year: "" };

const AwardsRecognition = ({
  linkText,
  setUpdateFlag,
  updateFlag,
  icon,
  className,
  editIt,
}) => {
  const [data, setData] = useState(initialData); //state
  const authId = useSelector((state) => state?.auth?.user?.info?.id); //user id
  const [saving, setSaving] = useState(false); //saving or not
  const [deleting, setDeleting] = useState(false); //deleting or not
  const dispatch=useDispatch()
  const [showAwardsRecognition, setShowAwardsRecognition] = useState(false);

  const handleAwardsRecognitionsOpen = () => {
    setShowAwardsRecognition(true);
  };

  const handleAwardsRecognitionsClose = () => {
    setShowAwardsRecognition(false);
  };

  //useEffect for pre filling
  useEffect(() => {
    if (editIt?.id) {
      setData({
        title: editIt?.title,
        from_year: editIt?.from_year,
        to_year: editIt?.to_year,
      });
    }
  }, [editIt]);

  //function to add achievement
  const handleAchievementAdd = async (e) => {
    setSaving(true);
    e.preventDefault();
    try {
      if (editIt?.id) {
        await authApi.put(`users/${authId}/achievements`, data);
      } else {
        await authApi.post(`users/${authId}/achievements`, data);
      }
      setUpdateFlag(!updateFlag);
      dispatch(updateUser());
      toast.success("Award or Achievement added successfully.", {
        delay: 1000,
      });
      setData(initialData);
      setSaving(false);
      setShowAwardsRecognition(false);
    } catch (err) {
      setSaving(false);
    }
  };

  const handleDelete = async (e) => {
    setDeleting(true);
    e.preventDefault();
    try {
      await authApi.delete(`users/achievements/${editIt?.id}`);
      setUpdateFlag(!updateFlag);
      toast.success("Award or Achievement deleted successfully.", {
        delay: 1000,
      });
      dispatch(updateUser());
      setData(initialData);
      setDeleting(false);
      setShowAwardsRecognition(false);
    } catch (err) {
      setDeleting(false);
    }
  };

  return (
    <>
      <Link
        to=""
        className={`add-new-label ${className}`}
        onClick={handleAwardsRecognitionsOpen}
      >
        {icon} {linkText}
      </Link>

      {showAwardsRecognition && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {editIt?.id ? "Edit" : "Add"} Awards & Recognition
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleAwardsRecognitionsClose}
                >
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body custom-scrollbar">
                  <div className="form-container border-bottom-2 mb-3">
                    <div className="form-group">
                      <label htmlFor={`project_title`}>
                        {" "}
                        Title <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Title"
                        id={`project_title`}
                        value={data?.title}
                        onChange={(e) =>
                          setData({ ...data, title: e.target.value })
                        }
                      />
                    </div>

                    <div className="d-sm-flex">
                      <div class="form-group flex-fill mr-0 mr-sm-3">
                        <label for="start_date">From (Year )</label>
                        <select
                          className="form-control w-100"
                          id="start_date"
                          value={data?.from_year}
                          onChange={(e) =>
                            setData({ ...data, from_year: e.target.value })
                          }
                        >
                          <option>Please Select</option>
                          <option>2023</option>
                          <option>2022</option>
                          <option>2021</option>
                          <option>2020</option>
                          <option>2019</option>
                          <option>2018</option>
                        </select>
                      </div>

                      <div class="form-group flex-fill ml-0 ml-sm-3">
                        <label for="location">To (Year)</label>
                        <select
                          class="form-control w-100"
                          id="location"
                          value={data?.to_year}
                          onChange={(e) =>
                            setData({ ...data, to_year: e.target.value })
                          }
                        >
                          <option>Please Select </option>
                          <option>2023</option>
                          <option>2022</option>
                          <option>2021</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-flex justify-content-between text-right">
                  <b></b>

                  {editIt?.id ? (
                    <button
                      className="btn blue-btn"
                      onClick={(e) => handleDelete(e)}
                      disabled={deleting}
                    >
                      {" "}
                      {deleting ? "Deleting" : "Delete"}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      onClick={(e) => handleAchievementAdd(e)}
                      className="btn blue-btn"
                      disabled={
                        saving ||
                        data.title === "" ||
                        data.from_year === "" ||
                        data.to_year === ""
                      }
                    >
                      {saving ? "Submitting" : "Submit"}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AwardsRecognition;
