import React, { useState } from "react";
import { CiSliderVertical } from "react-icons/ci";
import { HiOutlineBarsArrowDown } from "react-icons/hi2";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import Pagination from "../../../CommonComponents/Pagination";
import WorkSkills from "../../../CommonComponents/WorkSkills";
import Education from "../../../CommonComponents/Education";
import { DotSpinner } from "@uiball/loaders";
import { IMAGEURL } from "../../../../constant";
import { toast } from "react-toastify";
import authApi from "../../../../utils/authApi";
import Loader from "../../../CommonComponents/Loader/Loader";

const SavedResumeTable = ({
  allSavedCand,
  isLoading,
  page,
  setPage,
  allPages,
  getAllSavedCandidates,
}) => {
  const [showBox, setShowBox] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [boxPosition, setBoxPosition] = useState({});

  const handleItemMouseEnter = (item, event) => {
    setSelectedItem(item);
    const target = event.currentTarget;
    const rect = target.getBoundingClientRect();
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    const position = {
      top: rect.top + rect.height + scrollTop + 10, // Adjust this value
      left: rect.left + scrollLeft,
    };

    // Calculate horizontal center of the clicked element
    const centerX = rect.left + rect.width / 2;
    const boxWidth = "1000";
    const boxLeft = centerX - boxWidth / 2;

    setBoxPosition({
      ...position,
      left: boxLeft,
    });

    setShowBox(true);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowBox(true);
  };

  const handleBoxClose = () => {
    setShowBox(false);
    setSelectedItem(null);
  };
  //get total experience
  function getSumOfDateDifferences(dateObjects) {
    let totalYears = 0;
    let totalMonths = 0;

    dateObjects?.forEach((dateObj) => {
      const startDate = new Date(dateObj.start_date);
      const endDate = new Date(dateObj.end_date);

      const yearDiff = endDate.getFullYear() - startDate.getFullYear();
      const monthDiff = endDate.getMonth() - startDate.getMonth();

      // Adjust for negative month difference
      if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
      }

      totalYears += yearDiff;
      totalMonths += monthDiff;
    });

    // Convert months to years if there are more than 11 months
    if (totalMonths >= 12) {
      const extraYears = Math.floor(totalMonths / 12);
      totalYears += extraYears;
      totalMonths -= extraYears * 12;
    }

    const yearText =
      totalYears > 0 ? `${totalYears} year${totalYears > 1 ? "s" : ""}` : "";
    const monthText =
      totalMonths > 0
        ? `${totalMonths} month${totalMonths > 1 ? "s" : ""}`
        : "";

    // Combine the year and month texts
    const result = [yearText, monthText].filter(Boolean).join(" ");

    return result;
  }
  //get Present Company
  const getPresentCompany = (data) => {
    const a = data?.filter((elem) => elem?.present_job);
    if (a?.length > 0) {
      return a[0]?.company_name;
    } else {
      return "-";
    }
  };
  // Function to calculate the difference in months between two dates
  function calculateMonthDifference(startDate, endDate) {
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();

    let monthDifference = (endYear - startYear) * 12 + (endMonth - startMonth);

    return monthDifference;
  }
  //get total Experience
  const getTotalExperience = (data) => {
    const sumOfMonths = data?.reduce((totalMonths, obj) => {
      const startDate = new Date(obj.start_date);
      let endDate;
      if (obj?.present_job) {
        endDate = new Date(); // Use current date if present_job is true
      } else {
        endDate = new Date(obj.end_date);
      }
      const monthsDifference = calculateMonthDifference(startDate, endDate);
      totalMonths += monthsDifference;
      return totalMonths;
    }, 0);
    const years = sumOfMonths / 12; // Total months converted to years
    if (years > 0) {
      return years.toFixed(1);
    } else {
      return 0;
    }
  };
  const deleteResume = async (id) => {
    try {
      const res = await authApi.delete(`recruiter/company-user-bookmark/${id}`);
      getAllSavedCandidates();
      toast.success("Resume deleted successfully.");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="job-tracker-container table-responsive">
        {isLoading && (
          <div className="d-flex justify-content-center mt-5">
            {" "}
            <Loader />
          </div>
        )}
        {!isLoading && (
          <table className="table  w-100">
            <thead>
              <tr>
                <th>
                  Name
                  {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
                </th>
                {/* <th>
                  Res. match{" "}
                  <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
                </th> */}
                <th>
                  Profile{" "}
                  {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
                </th>
                {/* <th>
                  Education{" "}
                  <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
                </th> */}
                <th>
                  Current Job{" "}
                  {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
                </th>
                <th>
                  Exp.
                  {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
                </th>
                {/* <th>
                  CTC <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
                </th> */}
                <th>
                  Location{" "}
                  {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
                </th>

                <th>
                  Email
                  {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
                </th>

                <th className="text-right">
                  {/* <CiSliderVertical className="fa-20 black fw-700" /> */}
                </th>
              </tr>
            </thead>
            <tbody>
              {allSavedCand?.length > 0 &&
                allSavedCand?.map((row, index) => (
                  <tr className="j-gray-bg align-items-center border-18">
                    <td style={{ width: "150px" }}>
                      <Link
                        // onMouseEnter={(e) => handleItemMouseEnter("Acme Corp", e)}
                        // onClick={() => handleItemClick("Acme Corp")}
                        className={selectedItem === "Acme Corp" ? "active" : ""}
                      >
                        <p className="fa-14 fw-600 light-black mb-1">
                          {row?.user?.first_name
                            ? `${row?.user?.first_name} `
                            : ""}
                          {row?.user?.last_name
                            ? `${row?.user?.last_name}`
                            : ""}
                        </p>
                        <p className="mb-0 small-text"> {row?.user?.phone}</p>
                        <p className="mb-0 small-text">{row?.user?.email} </p>
                      </Link>
                    </td>
                    {/* <td>
                      <span className="mb-2">70%</span>
                      <div
                        className="progress"
                        style={{ height: "15px", borderRadius: "14px" }}
                      >
                        <div
                          className="progress-bar bg-dark-blue"
                          style={{ width: "70%", height: "15px" }}
                        ></div>
                      </div>
                    </td> */}
                    <td>
                      <Link
                        // onMouseEnter={(e) => handleItemMouseEnter("80%", e)}
                        // onClick={(event) => handleItemClick("80%")}
                        className={`light-black ${
                          selectedItem === "80%" ? "active" : ""
                        }`}
                        to={`/all-candidates/view-profile/${row?.user?.id}`}
                      >
                        <span className="mb-2">View</span>
                        {/* <div
                          className="progress"
                          style={{ height: "15px", borderRadius: "14px" }}
                        >
                          <div
                            className="progress-bar bg-dark-blue"
                            style={{ width: "80%", height: "15px" }}
                          ></div>
                        </div> */}
                      </Link>
                    </td>

                    {/* <td>
                    <Link
                      // onMouseEnter={(e) => handleItemMouseEnter("education", e)}
                      // onClick={(event) => handleItemClick("education")}
                      className={`gray ${
                        selectedItem === "education" ? "active" : ""
                      }`}
                    >
                      Education
                    </Link>
                  </td> */}

                    <td>
                      <Link
                        // onMouseEnter={(e) => handleItemMouseEnter("senior", e)}
                        // onClick={(event) => handleItemClick("senior")}
                        className={`gray active`}
                      >
                        <td>
                          {getPresentCompany(row?.user?.work_experiences)}
                        </td>
                      </Link>
                    </td>

                    <td>
                      {+getTotalExperience(row?.user?.work_experiences)}{" "}
                      {+getTotalExperience(row?.user?.work_experiences) > 1
                        ? "years"
                        : "year"}{" "}
                    </td>

                    {/* <td>$0.00</td> */}
                    <td>
                      {row?.user?.state?.name} ( {row?.user?.country?.name} )
                    </td>

                    <td>{row?.user?.email ?? "Not provided"}</td>
                    <td>
                      <div className="dropdown dropright float-right p-3">
                        <button
                          type="button"
                          className="btn dropdown-toggle dark-gray-bg rounded-50 p-2 d-flex justify-content-center align-items-center"
                          data-toggle="dropdown"
                        >
                          <BsThreeDots className="black fa-26" />
                        </button>
                        <div>
                          <div className="dropdown-menu transition-0 j-gray-bg">
                            <Link
                              className="dropdown-item fa-14 light-black fw-400"
                              to={`/all-candidates/view-profile/${row?.user?.id}`}
                            >
                              View Profile{" "}
                            </Link>
                            
                            {row?.user?.resume && (
                              <Link
                                target="_blank"
                                to={`${IMAGEURL}${row?.user?.resume}`}
                                className="dropdown-item fa-14 light-black fw-400"
                              >
                                Download Resume{" "}
                              </Link>
                            )}

                            <Link
                              className="dropdown-item fa-14 light-black fw-400"
                              onClick={() => deleteResume(row?.id)}
                            >
                              Don't Show
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
      {allSavedCand?.length === 0 && (
        <div className="d-flex justify-content-center p-5 fa-20">
          No data available !
        </div>
      )}

      {/* Pagination  */}
      {allSavedCand?.length > 0 && (
        <div className="pagination-row float-right mt-2">
          <Pagination page={page} setPage={setPage} allPages={allPages} />
        </div>
      )}
      {/* Pagination  */}

      {/* <div className="position-relative ">
        {showBox && (
          <div
            className={`box ${showBox ? "slide-down" : "slide-up"}`}
            style={{
              top: boxPosition.top,
              left: boxPosition.left,
            }}
          >
            {selectedItem === "Acme Corp" && (
              <div className="box-info">
                <p className="fa-14 gray fw-400 line-height-24">
                  As a Marketing Professional my accentuation is on
                  conversations, for which it is fundamental that we know our
                  get-together of individuals well or potentially have the
                  preparation to get some answers concerning them Pieces of
                  information aren’t actually what examination are uncovering to
                  us how ....
                </p>

                <div className="d-block  d-md-flex justify-content-between gap-5">
                  <Link to="/profile" className="btn blue-btn mt-3 px-3 w-100">
                    View Profile
                  </Link>

                  <Link to="#" className="btn blue-btn mt-3 px-3 w-100">
                    Download Resume
                  </Link>
                </div>
              </div>
            )}

            {selectedItem === "80%" && (
              <div className="skill-content">
                <ul className="skill-content-list">
                  <li>
                    <span>NODE JS</span> <span>90%</span>
                  </li>
                  <li>
                    <span>ANGULAR</span> <span>90%</span>
                  </li>
                  <li>
                    <span>ORACLE</span> <span>90%</span>
                  </li>
                  <li>
                    <span>PYTHON</span> <span>80%</span>
                  </li>
                </ul>
              </div>
            )}

            {selectedItem === "senior" && (
              <div className="work-experience">
                <p className="fa-16 gray mb-1"> CTC - Rs. 4,00,000 </p>
                <h4 className="card-title fa-18 mb-3"> Other Skills </h4>
                <WorkSkills />
              </div>
            )}

            {selectedItem === "education" && (
              <div className="work-experience">
                <Education />
              </div>
            )}

            <button className="box-close" onClick={handleBoxClose}>
              X
            </button>
          </div>
        )}
      </div> */}
    </>
  );
};

export default SavedResumeTable;
