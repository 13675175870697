import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//import { AiOutlineClockCircle } from "react-icons/ai";
import Icon from "../../../../assets/icon.png";
import JobApply from "./JobApply";
import { toast } from "react-toastify";
import authApi from "../../../../utils/authApi";
import { useSelector } from "react-redux";

const JobBasicDetail = ({ item, appliedJobs, getAppliedJobs }) => {
  const isAuth = useSelector((state) => state.auth.isLoggedIn);
  const authId = useSelector((state) => state?.auth?.user?.info?.id);
  const loginAs = useSelector((state) => state.auth.loginAs);

  const [saving, setSaving] = useState(false);
  const navigte = useNavigate();

  function getTimeDifferenceString(timeString) {
    const currentTime = new Date();
    const pastTime = new Date(timeString);
    const timeDifference = currentTime - pastTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 30) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    }
  }

  //save to jobs
  const handleSaveJob = async () => {
    if (isAuth) {
      setSaving(true);
      try {
        const payload = { user_id: authId, job_id: item?.id };
        await authApi.post(`save-jobs`, payload);
        setSaving(false);
        toast.success("Job saved successfully.");
      } catch (error) {
        setSaving(false);
        toast.error(error?.response?.data?.message);
      }
    } else {
      toast.warn("Please login first !", { position: "top-center" });
      navigte("/login");
    }
  };

  return (
    <>
      <div className="job-card p-0 border-0">
        <div className="job-card-icon">
          <img
            src={Icon}
            className="img-fluid cover-full"
            alt="job icon"
            style={{ width: "55px" }}
          />
        </div>
        <div className="job-card-content">
          <h4 className="light-black fa-20 fa-mdd-16 sa-smm-14 ">
            {item?.title}{" "}
            {item?.job_type?.type ? " - " + item?.job_type?.type : ""}{" "}
            {item?.employment_type?.title
              ? " - " + item?.employment_type?.title
              : ""}
          </h4>
          <p className="light-black fa-18 fa-mdd-16 fa-smm-14">
            {" "}
            {item?.company?.name}
            {item?.company?.state?.name
              ? ", " + item?.company?.state?.name
              : ""}
            {item?.company?.country?.name
              ? ", " + item?.company?.country?.name
              : ""}
          </p>
          {item?.salary &&<p>{item?.salary??"-"}</p>}
          <div className="job-content-inner d-flex align-items-center mt-2 justify-content-between">
            <p className="fa-14">
              {getTimeDifferenceString(item?.created_at)}

              {/* <AiOutlineClockCircle className="black fa-16" /> Be
              among the first 25 applicants */}
            </p>
          </div>
        </div>
      </div>

      <div className="apply-btn paddingleft-6 mt-4">
        {/* apply now btn*/}
        {loginAs === "Candidate" && (
          <JobApply
            getAppliedJobs={getAppliedJobs}
            appliedJobs={appliedJobs}
            item={item}
          />
        )}
        {/* apply now btn*/}
        {loginAs === "Candidate" && !appliedJobs?.includes(item?.id) && (
          <button
            className="btn btn-gary fw-500 fa-16 py-2 mb-2"
            onClick={() => handleSaveJob()}
            disabled={saving}
          >
            {saving ? "Saving" : "Save"}
          </button>
        )}
      </div>
    </>
  );
};

export default JobBasicDetail;
