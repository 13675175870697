import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Modal.css";
import authApi from "../../../../../utils/authApi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IoMdCloseCircle } from "react-icons/io";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { updateUser } from "../../../../../Redux/userSlice";
import InputTag from "../../../../CommonComponents/InputTag";
// function that will return all years till now
function generateYearRange() {
  const currentYear = new Date().getFullYear();
  const startYear = 1975;
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }

  return years;
}

const initialData = {
  title: "",
  start_month: "",
  start_year: "",
  end_month: "",
  end_year: "",
  skills: [],
  description: "",
};

const AddProjects = ({
  linkText,
  updateFlag,
  setUpdateFlag,
  icon,
  className,
  editIt,
}) => {
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState(initialData); //state
  const authId = useSelector((state) => state?.auth?.user?.info?.id); //user id
  const [showProjects, setShowProjects] = useState(false);

  const [years, setYears] = useState([]);
  const [showSkillInput, setShowSkillInput] = useState(false);
  const [searchedSkills, setSearchedSkills] = useState([]);
 
  const [savedSkills, setSavedSkills] = useState([]);
  const [deleting, setDeleting] = useState(false); //deleting or not
  const dispatch=useDispatch();
  //useEffect for pre filling
  useEffect(() => {
    if (editIt?.id) {
      setData({
        title: editIt?.title,
        start_month: editIt?.start_month,
        start_year: editIt?.start_year,
        end_month: editIt?.end_month,
        end_year: editIt?.end_year,
        description: editIt?.description,
      });
      setSavedSkills(JSON.parse(editIt?.skills));
    }
  }, [editIt]);

  useEffect(() => {
    // Call the function to generate the year range when the component mounts
    const yearRange = generateYearRange();
    setYears(yearRange);
  }, []);
  const handleProjectsOpen = () => {
    setShowProjects(true);
  };

  const handleProjectsClose = () => {
    setShowProjects(false);
  };


  //function to add projects
  const handleAddProject = async (e) => {
    data.skills=savedSkills//adding skills array
    setSaving(true);
    e.preventDefault();
    try {
      if (editIt?.id) {
        await authApi.put(`users/${authId}/projects/${editIt?.id}`, data);
      } else {
        await authApi.post(`users/${authId}/projects`, data);
      }
      // await authApi.post(`users/${authId}/projects`, data);
      setUpdateFlag(!updateFlag);
      setShowProjects(false);
      dispatch(updateUser());
      toast.success("Project added successfully.")

      setData(initialData);
      setSaving(false);
    } catch (err) {
      setSaving(false);
      setShowProjects(false);
      toast.error(err?.response?.data?.message)
    }
  };

  //function to add skill
  const handleAddToSkills = (elem) => {
    const check = savedSkills?.filter((ele) => ele === elem?.title);
    if (check.length > 0) {
      toast.warn("Skill already exist!", { position: "top-center" });
    } else {
      savedSkills?.push(elem?.title);
    }

    setShowSkillInput(false);
    setSearchedSkills([]);
  };

  //function to remove saved skill
  const handleRemoveSkill = (elem) => {
    const filter = savedSkills?.filter((ele) => ele !== elem);
    setSavedSkills(filter);
  };
  //function to fetch all searched skills
  const getAllSearchedSkills = async (query) => {
    try {
      const res = await authApi.get(`search-skills/${query}`);
      setSearchedSkills(res?.data?.results);
    } catch (error) {
      setSearchedSkills([]);
    }
  };

  //debounce function
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleSkillInputSearch = (event) => {
    const valu = event.target.value;
    if (valu.trim() !== "" && valu?.length >= 1) {
      setSearchedSkills([]);
      getAllSearchedSkills(valu);
    } else {
      setSearchedSkills([]);
    }
  };

  const handleSkillSearch = debounce(handleSkillInputSearch, 500);

  //function to delete project
  const handleDelete = async (e) => {
    setDeleting(true);
    e.preventDefault();
    try {
      await authApi.delete(`users/projects/${editIt?.id}`);
      setUpdateFlag(!updateFlag);
      toast.success("Project deleted successfully.", {
        delay: 1000,
      });
      dispatch(updateUser());
      setData(initialData);
      setDeleting(false);
      setShowProjects(false);
    } catch (err) {
      setDeleting(false);
    }
  };
  
  return (
    <>
      <Link
        to=""
        className={`add-new-label ${className}`}
        onClick={handleProjectsOpen}
      >
        {icon} {linkText}
      </Link>

      {showProjects && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">{editIt?.id?"Edit":"Add"} Project</h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleProjectsClose}
                >
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body custom-scrollbar">
             
                    <div
                      className="form-container border-bottom-2 mb-3"
                 
                    >
                      <div className="form-group">
                        <label htmlFor={`project_title`}>
                          Project title <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Project title"
                          id={`project_title_`}
                          value={data?.title}
                          onChange={(e) =>
                            setData({ ...data, title: e.target.value })
                          }
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor={`tagline`}>
                          Tag this project with your employment/education
                        </label>
                        <select
                          className="form-control"
                          id={`tagline`}
                        >
                          <option>Web Designer - service base</option>
                          <option>UG - Electronics/Telecommunication</option>
                          <option>Class XII</option>
                        </select>
                      </div>

                      <div className="d-sm-flex">
                        <div className="form-group flex-fill mr-0 mr-sm-3">
                          <label htmlFor={`start_date`}>Start</label>
                          <select
                            className="form-control w-100"
                            id={`start_date`}
                            value={data.start_month}
                            onChange={(e) =>
                              setData({ ...data, start_month: e.target.value })
                            }
                          >
                            <option value="">Select Month</option>

                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </select>
                        </div>

                        <div className="form-group flex-fill ml-0 ml-sm-0">
                          <label htmlFor={`location`} className="hide">
                            &nbsp;
                          </label>
                          <select
                            className="form-control w-100"
                            id={`location`}
                            value={data.start_year}
                            onChange={(e) =>
                              setData({ ...data, start_year: e.target.value })
                            }
                          >
                            <option value="">Select Year</option>
                            {years?.map((row, index) => (
                              <option key={index} value={row}>
                                {row}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="d-sm-flex">
                        <div className="form-group flex-fill mr-0 mr-sm-3">
                          <label htmlFor={`start_date`}>End</label>
                          <select
                            className="form-control w-100"
                            id={`start_date`}
                            value={data.end_month}
                            onChange={(e) =>
                              setData({ ...data, end_month: e.target.value })
                            }
                          >
                            <option value="">Select Month</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </select>
                        </div>

                        <div className="form-group flex-fill ml-0 ml-sm-3">
                          <label htmlFor={`location`} className="hide">
                            &nbsp;
                          </label>
                          <select
                            className="form-control w-100"
                            id={`location`}
                            value={data.end_year}
                            onChange={(e) =>
                              setData({ ...data, end_year: e.target.value })
                            }
                          >
                            <option value="">Select Year</option>
                            {years?.map((row, index) => (
                              <option key={index} value={row}>
                                {row}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor={`project_skill`}>
                          {" "}
                          Skill <span className="text-danger"></span>
                        </label>
                        <InputTag
                          title="Add Skill"
                          savedSkills={savedSkills}
                          setSavedSkills={setSavedSkills}
                        />
                     
                      </div>

                      <div className="form-group">
                        <label htmlFor={`description`}>
                          Details of project
                        </label>
                        <textarea
                          className="form-control"
                          rows="5"
                          id={`description`}
                          placeholder="Type here..."
                          value={data.description}
                          onChange={(e) =>
                            setData({ ...data, description: e.target.value })
                          }
                        ></textarea>
                      </div>
                    </div>
                

                  {/* <div className="add-more-group my-3">
                    <button
                      type="button"
                      className="btn btn-blue-outline "
                      onClick={handleAddMoreProjects}
                    >
                      Add More Projects
                    </button>
                  </div> */}
                </div>
                <div className="modal-footer d-flex justify-content-between text-right">
                  {editIt?.id ? (
                    <button
                      className="btn blue-btn"
                      onClick={(e) => handleDelete(e)}
                      disabled={deleting}
                    >
                      {" "}
                      {deleting ? "Deleting" : "Delete"}
                    </button>
                  ) : (
                    <b></b>
                  )}
                  <button
                    type="submit"
                    onClick={(e) => handleAddProject(e)}
                    className="btn blue-btn"
                    disabled={saving}
                  >
                    {saving ? "Submitting" : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddProjects;
