import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import Comapnybanner from "../../../../assets/comapnybanner.jpg";
import RecentJobsPost from "../../../CommonComponents/RecentJobsPostData";
import officeimg from "../../../../assets/office-building.png";
import { LuExternalLink } from "react-icons/lu";
import { FaDownload } from "react-icons/fa";
import { AiOutlineEdit } from "react-icons/ai";
import { useSelector } from "react-redux";
import authApi from "../../../../utils/authApi";
import { IMAGEURL } from "../../../../constant";
import { DotSpinner } from "@uiball/loaders";
import { toast } from "react-toastify";
import Loader from "../../../CommonComponents/Loader/Loader";
import { MdOutlineModeEditOutline } from "react-icons/md";
const getCompanyProfile = async (userId, setProfile, setIsLoading, company) => {
  try {
    const res = await authApi.get(`recruiter/companies/${company?.id}`);
    setIsLoading(false);
    setProfile(res?.data?.data);
  } catch (error) {
    setIsLoading(false);
  }
};
const ComapnyProfileView = () => {
  const authId = useSelector((state) => state?.auth?.user?.info?.id); //user id
  const company = useParams();
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const navigate=useNavigate();
  //hook to call getProfile
  useEffect(() => {
    getCompanyProfile(authId, setProfile, setIsLoading, company);
  }, [authId, company]);

  //function to delete
  const handleDeleteCompany = async () => {
    setDeleting(true);
    try {
      await authApi.delete(`companies/${company?.id}`);
      setDeleting(false);
      toast.success("Company Page deleted successfully.")
      navigate('/company')
    } catch (error) {
      setDeleting(false);
      toast.error(error?.response?.data?.message)
      navigate('/company')
    }
  };

  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="grid-container">
            <div className="left-side grid-left">
              <LeftSidebar />
            </div>

            
            {isLoading && (
              <div className="d-flex justify-content-center mt-5">
                {" "}
                <Loader />
              </div>
            )}

            {!isLoading && (
              <div className="company-content grid-middle mt-smm-0 mt-3">
                <div className="card border-0">
                  {/*profile  banner */}
                  <div className="profile-hero-banner position-relative">
                    <div className="profile-hero-banner-image profile-background-image--default">
                      <img
                        src={
                          profile?.banner
                            ? `${IMAGEURL}${profile?.banner}`
                            : Comapnybanner
                        }
                        className="img-fluid full-height full-width"
                        alt="Profile banner"
                      />
                    </div>
                  </div>

                  {/*end profile  banner */}

                  {/* profile  info */}
                  <div className="profile-card-photo-deatils position-relative">
                    <div className="profile-card-photo-wrapper">
                      <div className="profile-card-photo-edit">
                        <img
                          src={
                            profile?.logo
                              ? `${IMAGEURL}${profile?.logo}`
                              : officeimg
                          }
                          className="img-fluid"
                          alt="profile"
                        />
                      </div>
                    </div>

                    <div className="ptofile-card-photo-content mt-3">
                      <div className="ptofile-card-photo-content-wrapper d-xl-flex d-block justify-content-between">
                        <div className="profile-card-info">
                          <h4>
                            {profile?.name ?? ""}{" "}
                            <Link to={`/company-edit/${company?.id}`}>
                              <MdOutlineModeEditOutline className="ml-2 fa-20 gray" />
                            </Link>
                          </h4>
                          <p>
                            <small className="profile-role-text">
                              {profile?.tagline ?? ""}
                            </small>
                          </p>
                          <p>
                            {profile?.state?.name ?? ""},{" "}
                            {profile?.country?.name ?? ""}{" "}
                          </p>
                        </div>

                        <div className="visit-website mt-2 mt-xl-0">
                          <Link
                            to={`${
                              profile?.website?.includes("http")
                                ? profile?.website
                                : "https://" + profile?.website
                            }`}
                            target="_blank"
                            className="btn blue-btn py-2"
                          >
                            Vist Website <LuExternalLink />
                          </Link>
                        </div>
                      </div>
                      <hr />

                      <div className="overview"> 
                        <div className="overview-card">
                          <div class="profile-summary-heading">
                            <h2 class="mb-0">Overview</h2>
                          </div>

                          <div className="profile-summary-body mt-2">
                            <p>{profile?.description ?? ""} </p>
                          </div>
                        </div>

                        <div className="overview-card mt-3">
                          <div class="profile-summary-heading">
                            <h2 class="mb-0 fa-16 fw-600">website</h2>
                          </div>
                          <div className="profile-summary-body mt-1">
                            <Link
                              to={`${
                                profile?.website?.includes("http")
                                  ? profile?.website
                                  : "https://" + profile?.website
                              }`}
                              target="_blank"
                              className="link-text fa-16"
                            >
                              {profile?.website ?? ""}
                            </Link>
                          </div>
                        </div>

                        {profile?.phone && (
                          <div className="overview-card mt-3">
                            <div class="profile-summary-heading">
                              <h2 class="mb-0 fa-16 fw-600">Phone</h2>
                            </div>
                            <div className="profile-summary-body mt-1">
                              <Link
                                to={`tel:${profile?.phone}`}
                                target="_blank"
                                className="link-text fa-16"
                              >
                                {profile?.phone}
                              </Link>
                            </div>
                          </div>
                        )}

                        <div className="overview-card mt-3">
                          <div class="profile-summary-heading">
                            <h2 class="mb-0 fa-16 fw-600">Industry</h2>
                          </div>
                          <div className="profile-summary-body mt-1">
                            <p>{profile?.industry?.label}</p>
                          </div>
                        </div>

                        <div className="overview-card mt-3">
                          <div class="profile-summary-heading">
                            <h2 class="mb-0 fa-16 fw-600">Company size</h2>
                          </div>

                          <div className="profile-summary-body mt-1">
                            <p>{profile?.company_size?.size ?? ""}</p>
                          </div>
                        </div>

                        <div className="overview-card mt-3">
                          <div class="profile-summary-heading">
                            <h2 class="mb-0 fa-16 fw-600">Company Type</h2>
                          </div>

                          <div className="profile-summary-body mt-1">
                            <p>{profile?.company_type?.type ?? ""}</p>
                          </div>
                        </div>
                        <div className="overview-card mt-3">
                          <div class="profile-summary-heading">
                            <h2 class="mb-0 fa-16 fw-600">Headquarters</h2>
                          </div>
                          <div className="profile-summary-body mt-1">
                            <p>
                              {profile?.state?.name ?? ""},{" "}
                              {profile?.country?.name ?? ""}
                            </p>
                          </div>
                        </div>

                        <div className="overview-card mt-3">
                          <div class="profile-summary-heading">
                            <h2 class="mb-0 fa-16 fw-600">Founded</h2>
                          </div>
                          <div className="profile-summary-body mt-1">
                            <p>{profile?.year_founded}</p>
                          </div>
                        </div>

                        {profile?.document && (
                          <div className="overview-card mt-3">
                            <div class="profile-summary-heading">
                              <h2 class="mb-0 fa-16 fw-600">Doccument</h2>
                            </div>
                            <div className="profile-summary-body mt-1 ">
                              <p>
                                {" "}
                                <Link
                                  to={`${IMAGEURL}${profile?.document}`}
                                  className="link-text  p-1"
                                >
                                  <span className="gray"> Open Document </span>{" "}
                                  <FaDownload className="fa-20" />{" "}
                                </Link>
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="text-right w-5 mt-4">
                          <button
                            type="submit"
                            onClick={(e) => handleDeleteCompany(e)}
                            className="btn blue-btn"
                            disabled={deleting}
                          >
                            {deleting ? "Deleting" : "Delete"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="right-side grid-right">
              <RecentJobsPost title="Recent Post Job" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComapnyProfileView;
