import React from 'react'
import UploadResume from '../../../CommonComponents/UploadResume'
import CareerInsightData from './CareerInsightData'
import HighlightsData from '../../../CommonComponents/HighlightsData'
import TopSkills from '../../../CommonComponents/TopSkills'
import LeftSidebar from "../LeftSideBar/LeftSidebar";

const EditPageWrapper = ({element}) => {
  return (
    <>
    <section>
      {/* page-wrapper */}
      <div className="container-fluid side-space">
        <div className="grid-container pt-4 pb-4">
          <div className="left-side grid-left">
            <LeftSidebar />
          </div>
          <div className="main-content grid-middle mt-smm-0 mt-3">
           {element}
          </div>
          <div className="right-side grid-right ">
            <UploadResume title="Upload Resume" />
            <CareerInsightData />
            <HighlightsData />
            <TopSkills />
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default EditPageWrapper