import React, { useEffect, useState } from "react";
import JobPostAsideCard from "./JobPostAsideCard";
import "./viewjobpost.css";
import ViewJobPostCard from "./ViewJobPostCard";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import ViewJobPostDescription from "./ViewJobPostDescription";
import loader from "../../../../assets/loader.gif";
import BillingCard from "./BillingCard";
import HiringRoleCard from "./HiringRoleCard";
import { useParams } from "react-router-dom";
import authApi from "../../../../utils/authApi";
import { DotSpinner } from "@uiball/loaders";
import { useSelector } from "react-redux";
import Loader from "../../../CommonComponents/Loader/Loader";

const ViewJobPost = () => {
  const param = useParams();
  const [currJob, setCurrJob] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const loginAs = useSelector((state) => state.auth.loginAs);
  const authId = useSelector((state) => state?.auth?.user?.info?.id);

  const [appliedJobs, setAppliedJobs] = useState([]);

  // get job types
  const getAppliedJobs = async () => {
    try {
      const res = await authApi.get(`users/${authId}/job-ids`);
      // setJobTypes(res?.data?.jobType);
      // setJobTypesLoading(false);
      setAppliedJobs(res?.data?.job_ids);
    } catch (error) {
      // setJobTypesLoading(false);
    }
  };
  useEffect(() => {
    getAppliedJobs();
  }, []);

  useEffect(() => {
    const getSingleJobPost = async () => {
      try {
        if (loginAs === "Recruiter") {
          const res = await authApi.get(`recruiter/jobs/${param.id}`);
          setCurrJob(res?.data);
        } else {
          const res = await authApi.get(`jobs/${param.JobId}`);
          setCurrJob(res?.data?.data);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    getSingleJobPost();
  }, []);
  // console.log(param);
  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center mt-5">
          <Loader loading={true} />
        </div>
      )}
      {!isLoading && (
        <section className="pb-4 pt-4">
          <div className="fluid-container side-space">
            <div className="job-post-view grid-3-1-container">
              <div className="left-side grid-left noPhone">
                <LeftSidebar />
              </div>

              {
                <div className="job-content grid-middle">
                  <div className="job-post-card mb-1">
                    <ViewJobPostCard
                      getAppliedJobs={getAppliedJobs}
                      appliedJobs={appliedJobs}
                      job={currJob}
                    />
                  </div>

                  <div className="job-post-deatils">
                    <ViewJobPostDescription job={currJob} />
                  </div>
                </div>
              }

              <div className="job-info-card grid-right none-mobile">
                {/* <JobPostAsideCard /> */}
                <BillingCard />
                {loginAs === "Recruiter" && <HiringRoleCard />}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ViewJobPost;
