import React, { useState } from "react";
import { Link } from "react-router-dom";
import EditBasicIntro from "./EditBasicIntro";
import "./Modal.css";
import { useSelector } from "react-redux";

const ContactInfo = () => {
  const [showViewContactModal, setViewContactShowModal] = useState(false);
  const profile = useSelector((state) => state?.user?.profile);

  const handleViewContactOpenModal = () => {
    setViewContactShowModal(true);
  };

  const handleViewContactCloseModal = () => {
    setViewContactShowModal(false);
  };

  return (
    <>
      <Link
        to=""
        className="ml-3 link-text"
        onClick={handleViewContactOpenModal}
      >
        Contact info
      </Link>

      {showViewContactModal && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <div>
               { profile?.first_name &&  <h4 className="modal-title">
                    {profile?.first_name + " "??""+ profile?.last_name??""}
                  </h4>}
                </div>
                <span className="edit ml-2 mt-3">
                  {" "}{!profile?.first_name && "Update"}
                  <EditBasicIntro className="ml-0" />
                </span>

                <button
                  type="button"
                  className="close"
                  onClick={handleViewContactCloseModal}
                >
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="form-container">
                  <div className="contact-group">
                    <div className="mb-3">
                      <h4 className="fa-16 light-black fw-500 mb-1">
                        Basic Details
                      </h4>

                      {profile?.first_name && (
                        <p className="mb-0 light-black fa-14 fw-500">
                          Name :
                          <span className="gray fa-14 fw-400">
                            {profile?.first_name + " " ??
                              ""  + profile?.last_name ??
                              ""}
                          </span>
                        </p>
                      )}
                      {profile?.email && (
                        <p className="mb-0 light-black fa-14 fw-500">
                          Email :
                          <Link
                            to={profile?.email}
                            className="gray ml-1 fw-400"
                          >
                            {profile?.email}
                          </Link>
                        </p>
                      )}

                      {profile?.phone && (
                        <p className="mb-0 light-black fa-14 fw-500">
                          Phone :
                          <span className="gray fa-14 fw-400">
                            {profile?.phone}
                          </span>
                        </p>
                      )}
                    </div>
                    {(profile?.address ||
                      profile?.city ||
                      profile?.state ||
                      profile?.country) && (
                      <div className="mb-3">
                        <h4 className="fa-16 light-black fw-500 mb-1">
                          Address
                        </h4>
                        <p className="mb-0 light-black fa-14 fw-400">
                          {profile?.address} {profile?.city} {profile?.state}{" "}
                          {profile?.country}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactInfo;
