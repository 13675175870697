import React, { useState } from "react";
import Icon from "../../../../assets/icon.png";
import "./JobCard.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { DotSpinner } from "@uiball/loaders";
import Loader from "../../../CommonComponents/Loader/Loader";
import ManageCandidateList from "./ManageCandidateList";
const JobCard = ({
  jobs,
  className,
  classNameCard,
  Classtitle,
  boxspace,
  iocn,
  headingstyle,
  isLoading,
}) => {
  const [allApplicants, setAllApplicants] = useState([]);
  const [job, setJob] = useState({});
  const loginAs = useSelector((state) => state.auth.loginAs);
  function getTimeDifferenceString(timeString) {
    const currentTime = new Date();
    const pastTime = new Date(timeString);
    const timeDifference = currentTime - pastTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 30) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    }
  }

  const convertObjToArr=(data)=>{
    const arr=[];
    for(let key in data){
      arr.push(data[key])
    }
    return arr;
  }

  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center mt-5 mb-5">
          {" "}
          <Loader />
        </div>
      )}
      {!isLoading && (
        <div className={`${boxspace}`}>
          {jobs.map((job, index) => (
            <div key={index}>
              <div className="heading">
                <h3 className={`job-card-title ${Classtitle}`}>{job.title}</h3>
              </div>
              <div className={`${className}`}>
                {job?.items?.length > 0 &&
                  job.items.map((item, itemIndex) => (
                    <Link
                      className={`job-card ${classNameCard}`}
                      to={"#"}
                      onClick={() => {
                        setJob(item?.job);
                        setAllApplicants(convertObjToArr(item?.candidates));
                      }}
                      key={itemIndex}
                    >
                      <div className="job-card-icon">
                        <img src={Icon} className="img-fluid" alt="job icon" />
                      </div>
                      <div className="job-card-content">
                        <div className="d-block d-md-flex justify-content-between">
                          <h4 className={`mb-1 ${headingstyle}`}>
                            {item?.job?.title}
                          </h4>
                          {item?.candidates && (
                            <h4 className={`mb-0 ${headingstyle}`}>
                              {convertObjToArr(item?.candidates)?.length ?? 0}{" "}
                              {convertObjToArr(item?.candidates)?.length > 1
                                ? "Candidates"
                                : "Candidate"}
                            </h4>
                          )}
                        </div>

                        <p>
                          {item?.job?.job_type?.type}

                          {item?.job?.employment_type?.title
                            ? ", " + item?.job?.employment_type?.title
                            : ""}
                        </p>
                        <div className="job-content-inner d-sm-flex d-block align-items-center mt-2 justify-content-between">
                          {/* <div className="job-content-inner-icon d-flex  align-items-center">
                        <BiNotepad />
                      </div> */}
                          <div className="job-content-inner-content d-flex justify-content-start justify-content-sm-end mt-sm-0 mt-2">
                            <p>
                              {getTimeDifferenceString(item?.job?.created_at)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          ))}
        </div>
      )}

      {job?.id && (
        <>
          {" "}
          <hr /> {job?.title} ( {allApplicants?.length ?? 0}{" "}
          {allApplicants?.length > 1 ? "Candidates" : "Candidate"})
          <hr />
        </>
      )}
      <div className="mt-2">
        {allApplicants?.length > 0 && (
          <ManageCandidateList allApplicants={allApplicants} job={job} />
        )}
      </div>
    </>
  );
};

export default JobCard;
