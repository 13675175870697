import React from 'react';
import './WorkSkills.css';

const WorkSkills = ({selectedSkills}) => {

  return (
    <>
      <div className="d-flex flex-wrap gap-5">
        {selectedSkills?.map((skill, index) => (
          <span key={index} className="skill-style">
            {skill}
          </span>
        ))}
      </div>
    </>
  );
};

export default WorkSkills;
