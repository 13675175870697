import React, { useEffect, useState } from "react";

import { AiOutlineEdit, AiOutlinePlusCircle } from "react-icons/ai";
import "./ManageMentSkills.css";
import { useSelector } from "react-redux";
import { MdOutlineModeEditOutline } from "react-icons/md";
import AddManageMentSkills from "../AddManagementSkills";

const ManageMentSkills = () => {
  const [listItems, setListItems] = useState([]);
  const profile = useSelector((state) => state.user.profile); //profile of user from redux
  //hook to call getAllSkills
  useEffect(() => {
    setListItems(profile?.skills);
  }, [profile]);

  return (
    <>
      <div className="profile-summary-container">
        <div className="profile-summary-header line-height-0 d-flex">
          <div className="profile-summary-heading">
            <h2>Management skills</h2>
          </div>

          <div className="profile-summary-icon">
            <AddManageMentSkills
              icon={<MdOutlineModeEditOutline className="gray fa-28" />}
              className="icon-deafult"
              editThis={profile}
            />
          </div>
        </div>
        <div className="profile-summary-body mt-3">
          <ul className="profile-summary-list">
            <li>
              <div className="profile-summary-inner-container d-flex justify-content-between mb-1">
                <div className="profile-view-skill">
                  <p>Management Experience </p>
                </div>
                <div
                  className="profile-view-skill-years d-flex g-2"
                  style={{ gap: "15px" }}
                >
                  <p>
                    {+profile?.years_as_manager_of_individual_contributor ?? 0}{" "}
                    {+profile?.years_as_manager_of_individual_contributor > 1
                      ? "years"
                      : "year"}
                  </p>
                  {/* <AddManageMentSkills
                    editIt={row}
                      icon={
                        <MdOutlineModeEditOutline className="gary-text ml-1 ml-sm-3 fa-28 fa-mdd-24" />
                      }
                      className="icon-deafult"
                    /> */}
                </div>
              </div>
            </li>
            <li>
              <div className="profile-summary-inner-container d-flex justify-content-between mb-2">
                <div className="profile-view-skill">
                  <p>Top Management Experience </p>
                </div>
                <div
                  className="profile-view-skill-years d-flex g-2"
                  style={{ gap: "15px" }}
                >
                  <p>
                    {+profile?.years_as_manager_of_other_managers ?? 0}{" "}
                    {+profile?.years_as_manager_of_other_managers > 1
                      ? "years"
                      : "year"}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ManageMentSkills;
