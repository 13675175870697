import React, { useEffect, useState } from "react";
import "../../../CommonComponents/InputTag.css";
import { AiOutlineEdit, AiOutlinePlusCircle } from "react-icons/ai";
//import Dummy from '../../../../assets/dummy.jpg';
import AddProjects from "./ProfileModal/AddProjects";
import { useSelector } from "react-redux";
import { MdOutlineModeEditOutline } from "react-icons/md";
const ProfileProjects = () => {
  const [projects, setProjects] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false); //flag to track that new achievement added
  const profile = useSelector((state) => state.user.profile); //profile of user from redux
  //hook to call getAllProjects
  // useEffect(() => {
  //   getAllProjects(authId, setProjects, setIsLoading);
  // }, [updateFlag, authId]);
useEffect(()=>{
setProjects(profile?.projects)
},[profile])
  return (
    <div className="profile-summary-container">
      <div className="profile-summary-header line-height-0 d-flex mb-2">
        <div className="profile-summary-heading">
          <h2>Projects</h2>
        </div>
        <div className="profile-summary-icon">
          <AddProjects
          setUpdateFlag={setUpdateFlag}
          updateFlag={updateFlag}
            icon={<AiOutlinePlusCircle className="gray fa-28 fa-mdd-24" />}
            className="icon-deafult"
          />
        </div>
      </div>
   
      {projects  &&
        projects?.map((row, index) => (
          <div
            className="profile-summary-body d-flex justify-content-between mt-1"
            key={index}
          >
            <div className="profile-summary-inner-container d-flex">
              <div className="profile-view-content">
                <h4>{row?.title}</h4>
                <p key={index}>{row?.description}</p>
                <p key={index}>
                  {row?.start_year}-{row?.end_year}
                </p>
              </div>
            </div>
            <div>
              <AddProjects
                setUpdateFlag={setUpdateFlag}
                updateFlag={updateFlag}
                icon={
                  <MdOutlineModeEditOutline className="gray ml-1 ml-sm-3 fa-28 fa-mdd-24" />
                }
                className="icon-deafult"
                editIt={row}
              />
            </div>
          </div>
        ))}
            {projects?.length === 0 && (
        <div className="d-flex justify-content-center w-100 mb-2">
          No Project, Add Now!
        </div>
      )}
    </div>
  );
};

export default ProfileProjects;
