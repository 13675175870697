import React, { useEffect, useRef, useState } from "react";
//import ProfileImg from "../../../../assets/nilotapl.jpg";
import { Link } from "react-router-dom";
import DefaultImage from "../.../../../../../assets/default_Image.png";
import dummyProfile from "../.../../../../../assets/dummyProfile.webp";
import authApi from "../../../../utils/authApi";
import { useDispatch, useSelector } from "react-redux";
import { IMAGEURL } from "../../../../constant";
import { updateUser } from "../../../../Redux/userSlice";
import { toast } from "react-toastify";

const AddProfilePhoto = () => {
  const [showAddProfilePhoto, setShowAddProfilePhoto] = useState(false);
  const [saving,setSaving]=useState(false)

  const authId = useSelector((state) => state?.auth?.user?.info?.id); //user id
  const profile=useSelector((state)=>state.user.profile);//profile of user from redux 

  const [pic, setPic] = useState("");
  const dispatch=useDispatch()
  const handleShowAddProfilePhotoOpen = () => {
    setShowAddProfilePhoto(true);
  };

  const handleShowAddProfilePhotoClose = () => {
    setShowAddProfilePhoto(false);
  };

  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadButtonText, setUploadButtonText] = useState("Upload profile");

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setPic(file);
    setSelectedImage(URL.createObjectURL(file));
    // setUploadButtonText("Change Profile");
  };

  useEffect(()=>{
    if(selectedImage){
      setUploadButtonText("Cancel")
    }else{
      setUploadButtonText("Upload new picture")
    }
  },[selectedImage])

  //function to save uploaded photo
  const uploadProfilePhoto = async (e) => {
    setSaving(true)
    e.preventDefault();
    const myFormData = new FormData();
    myFormData.append("avatar", pic);
    try {
      await authApi.post(`users/${authId}?_method=PUT`,myFormData);
      setShowAddProfilePhoto(false);
      setPic("")
      setSelectedImage(null)
      dispatch(updateUser())
      setSaving(false);
      toast.success('Profile updated successfully.')
    } catch (error) {
      setSaving(false)
    }
  };

  return (
    <>
      <Link onClick={handleShowAddProfilePhotoOpen}>
        <img src={profile?.avatar?`${IMAGEURL}${profile.avatar}`:profile?.logo?`${IMAGEURL}${profile.logo}`:dummyProfile} className="img-fluid" alt="profile" />
      </Link>

      {showAddProfilePhoto && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Profile photo</h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleShowAddProfilePhotoClose}
                >
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body">
                  <div className="form-container">
                    {selectedImage && (
                      <div className="selected-image text-center">
                     
                        <img
                          src={selectedImage}
                          alt="Preview"
                          className="img-fluid h-70 w-70"
                        />
                      </div>
                    )}

                    {!selectedImage && (
                      <div className="banner-box text-center">
                        <img
                          src={profile?.avatar?`${IMAGEURL}${profile.avatar}`:profile?.logo?`${IMAGEURL}${profile.logo}`:dummyProfile}
                          className="img-fluid h-70 w-70"
                          alt="banner"
                        />
                      </div>
                    )}

                    <input
                      type="file"
                      ref={fileInputRef}
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <div className="modal-footer  text-right">
                
                  <Link
                    className="btn btn-blue-outline ml-2"
                    onClick={()=>{
                      if(selectedImage){
                        setShowAddProfilePhoto(false);
                        setPic("")
                        setSelectedImage(null)
                      }else{
                        handleButtonClick()
                      }
                     
                    }}
                  >
                    {uploadButtonText}
                  </Link>
                  {selectedImage && (
                    <button
                      type="submit"
                      className="btn blue-btn ml-2 py-2"
                      onClick={(e) => uploadProfilePhoto(e)}
                      disabled={saving}
                    >
                      {saving?"Saving":"Save"}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddProfilePhoto;
