import React, { useEffect, useRef, useState } from "react";
import "./HeroContent.css";
import "./JobSearch.css";
import { useNavigate } from "react-router-dom";
import authApi from "../../../../utils/authApi";
import axios from "axios";

const HeroContent = ({ setAllSearchedJobs }) => {
  //state
  const [title, setTitle] = useState("");
  const [exp, setExp] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [searchState, setSearchState] = useState("");
  const [searchedState, setSearchedState] = useState([]);
  const [stateSearching, setStateSearching] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchIt, setSearchIt] = useState("");

  const [searchExp, setSearchExp] = useState("");
  const [allCorporateTitles, setAllCorporateTitles] = useState([]);
  const [searchedTitle, setSearchedTitle] = useState([]); //state for searched titles;
  const [searchedExp, setSearchedExp] = useState([]);
  const dropdownRef = useRef(null); //dropdown for job title
  const dropdownExpRef = useRef(null); //dropdown for job experience
  const dropdownLocRef = useRef(null); //dropdown for job Location
  //react-router-dom
  const navigate = useNavigate();
  //experience array
  const Experience = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];
  //get All Corporat titles
  useEffect(() => {
    const getAllCorporateTitles = async () => {
      try {
        const res = await authApi.get(`get-active-corporate-title`);
        // setAllCorporateTitles(res?.data?.data);
      } catch (error) {}
    };
    getAllCorporateTitles();
  }, []);

  let cancelToken1;
  const getSearchedTitles = async () => {
    if (typeof cancelToken1 != typeof undefined) {
      cancelToken1.cancel("Cancelled");
    }
    cancelToken1 = axios.CancelToken.source();
    try {
      const res = await authApi.get(`search-corporate/${searchIt}`, {
        cancelToken: cancelToken1.token,
      });
      setSearchedTitle(res?.data);
    } catch (error) {}
  };
  useEffect(() => {
    if (searchIt) {
      getSearchedTitles();
    } else {
      setSearchedTitle([]);
    }
    return () => {
      if (cancelToken1) {
        cancelToken1.cancel("Cancelled");
      }
    };
  }, [searchIt]);

  let cancelToken;
  const getLocation = async () => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Cancelled");
    }
    cancelToken = axios.CancelToken.source();

    try {
      if (searchState!="") {
        setStateSearching(true);
        const res = await authApi.get(`search-state/${searchState}`, {
          cancelToken: cancelToken.token,
        });
        setSearchedState(res?.data);
        setStateSearching(false);
      }
    } catch (error) {
      setStateSearching(false);
    }
  };

  useEffect(() => {
    if (searchState) {
      getLocation();
    } else {
      setSearchState([]);
    }

    return () => {
      if (cancelToken) {
        cancelToken.cancel("Cancelled");
      }
    };
  }, [searchState]);
  // const handleSearchClick = async (e) => {
  //   setSearching(true);
  //   e.preventDefault();
  //   try {
  //     const res = await authApi.post(`search-job?page=${page}`, {
  //       title: title,
  //       required_experience: exp,
  //     });
  //     setAllSearchedJobs(res?.data?.data);
  //     setSearching(false);
  //   } catch (error) {
  //     setSearching(false);
  //   }
  // };

  //hide dropdown when click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      //  console.log("event-target",event.target,"dropdown",dropdownRef.current)
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSearchedTitle([]); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //hide dropdown when click outside of exp
  useEffect(() => {
    const handleClickOutside = (event) => {
      //  console.log("event-target",event.target,"dropdown",dropdownRef.current)
      if (
        dropdownLocRef.current &&
        !dropdownLocRef.current.contains(event.target)
      ) {
        setSearchedState([]); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //hide dropdown when click outside of exp
  useEffect(() => {
    const handleClickOutside = (event) => {
      //  console.log("event-target",event.target,"dropdown",dropdownRef.current)
      if (
        dropdownExpRef.current &&
        !dropdownExpRef.current.contains(event.target)
      ) {
        setSearchedExp([]); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //title change
  const handleTitleSearch = (e) => {
    const val = e.target.value;
    // setSearchIt(val);
    setSearchTitle(val);
    const filter = allCorporateTitles?.filter((ele) =>
      ele?.title?.toLowerCase()?.includes(val.toLowerCase())
    );
    setSearchedTitle(filter);
  };
  //experience change
  const handleExpSearch = (e) => {
    const val = e.target.value;
    setSearchExp(val);
    const filter = Experience?.filter((ele) =>
      ele?.toString()?.includes(val.toString())
    );
    setSearchedExp(filter);
  };
  const handleLocSearch = (e) => {
    const val = e.target.value;
    setSearchState(val);
    setSelectedLocation(val);
    // setSearchedState([]);

    // const filter = se?.filter((ele) =>
    //   ele?.toString()?.includes(val.toString())
    // );
    // setSearchedExp(filter);
  };
  //function executed when search clicked
  const handleSearchClick = (e) => {
    e.preventDefault();
    localStorage.setItem('point',JSON.stringify('Homepage'))
    navigate(
      `/job-search?title=${title ? title : ""}&exp=${exp ? exp : ""}&location=${
        jobLocation ? jobLocation : ""
      }`
    );
  };
  return (
    <>
      <div className="container-fluid side-space">
        <div className="pt-5 pb-5">
          <div className="hero-heading">
            <h1>
              {" "}
              <span className="yellow-text">Automatic </span> Applying to jobs
              is stressful effortlessly
            </h1>
            <p>
              Our passion is to research and select talents and qualified
              personnel for our Clients and to find the job you are passionate
              about.
            </p>
          </div>

          <div className="job-search">
            <div className="search-conatiner">
              <div className="search-inner ">
                <div className="search-left ">
                  <div className="search-text">
                    <p>
                      Find the best <b>career opportunity</b> for you
                    </p>
                  </div>
                </div>

                <div className="search-right">
                  <>
                    <div className="search-form">
                      <form>
                        <div className="row align-items-center">
                          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="what">What?</label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className={`form-control`}
                                  id="what"
                                  placeholder="Profession, Keyword or Company"
                                  value={searchTitle}
                                  onChange={(e) => {
                                    setSearchIt(e.target.value);
                                    handleTitleSearch(e);
                                  }}
                                />
                                <div ref={dropdownRef}>
                                  {searchedTitle.length > 0 && (
                                    <div
                                      className="position-absolute w-100 bg-light col"
                                      style={{
                                        top: "45px",
                                        border: "1px solid lightgray",
                                        borderRadius: "12px",
                                        minHeight: "max-content",
                                        maxHeight: "150px",
                                        overflowY: "scroll",
                                        zIndex: "100",
                                        boxShadow:
                                          " rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                                      }}
                                    >
                                      {searchedTitle?.map((elem, index) => (
                                        <div
                                          className="pt-3 pb-3 pl-3 row"
                                          style={{
                                            cursor: "pointer",
                                            borderTop:
                                              index > 0 &&
                                              "1px solid lightgray",
                                          }}
                                          onClick={() => {
                                            setSearchTitle(elem.title);
                                            setSearchedTitle([]);
                                            setTitle(elem.title);
                                          }}
                                        >
                                          {elem.title}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="experience">Experience</label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className={`form-control`}
                                  id="experience"
                                  placeholder="Yrs. Of Exp."
                                  value={searchExp}
                                  onChange={(e) => handleExpSearch(e)}
                                />
                                <div ref={dropdownExpRef}>
                                  {searchedExp.length > 0 && (
                                    <div
                                      className="position-absolute w-100 bg-light col"
                                      style={{
                                        top: "45px",
                                        border: "1px solid lightgray",
                                        borderRadius: "12px",
                                        minHeight: "max-content",
                                        maxHeight: "150px",
                                        overflowY: "scroll",
                                        zIndex: "100",
                                        boxShadow:
                                          " rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                                      }}
                                    >
                                      {searchedExp?.map((elem, index) => (
                                        <div
                                          className="pt-3 pb-3 pl-3 row"
                                          style={{
                                            cursor: "pointer",
                                            borderTop:
                                              index > 0 &&
                                              "1px solid lightgray",
                                          }}
                                          onClick={() => {
                                            setSearchExp(elem);
                                            setSearchedExp([]);
                                            setExp(elem);
                                          }}
                                        >
                                          {elem}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="city">Where?</label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className={`form-control`}
                                  id="city"
                                  value={selectedLocation}
                                  onChange={(e) => handleLocSearch(e)}
                                  placeholder="City of Region"
                                />
                                <div ref={dropdownLocRef}>
                                  {searchedState.length > 0 && (
                                    <div
                                      className="position-absolute w-100 bg-light col"
                                      style={{
                                        top: "45px",
                                        border: "1px solid lightgray",
                                        borderRadius: "12px",
                                        minHeight: "max-content",
                                        maxHeight: "150px",
                                        overflowY: "scroll",
                                        zIndex: "100",
                                        boxShadow:
                                          " rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                                      }}
                                    >
                                      {stateSearching && (
                                        <div className="d-flex justify-content-center my-2">
                                          <div class="spinner-border text-muted"></div>
                                        </div>
                                      )}
                                      {!stateSearching &&
                                        searchedState?.map((elem, index) => (
                                          <div
                                            className="pt-3 pb-3 pl-3 row"
                                            style={{
                                              cursor: "pointer",
                                              borderTop:
                                                index > 0 &&
                                                "1px solid lightgray",
                                            }}
                                            onClick={() => {
                                              // setSearchState(elem?.name);
                                              setSelectedLocation(
                                                `${elem?.name}, ${elem?.country?.name}`
                                              );
                                              setSearchedState([]);
                                              setJobLocation(elem?.id);
                                            }}
                                          >
                                            {elem?.name}, {elem?.country?.name}
                                          </div>
                                        ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 btn-center">
                            <button
                              type="submit"
                              onClick={(e) => handleSearchClick(e)}
                              className="btn-theme mt-3"
                            >
                              Search Now
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="job-list">
            <ul>
              <li>Remote</li>
              <li>mNC</li>
              <li>
                Information <br />
                technology
              </li>
              <li>KPO</li>
              <li>BPO</li>
              <li>Accounting</li>
            </ul>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default HeroContent;
