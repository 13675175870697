import React from "react";
import Logo from "../../../../../assets/injob-sight-logo.jpg";
import './invoice.css';

const Invoice = () => {
  return (
    <div className="invoice-container">
      <header className="invoice-header">
        <h1 className="invoice-title">Invoice</h1>
        <address className="invoice-address">
          <p className="mb-0">Jonathan Neal</p>
          <p className="mb-0">
            101 E. Chapman Ave
            Orange, CA 92866
          </p>
          <p className="mb-0">(800) 555-1234</p>
        </address>
        <span className="invoice-logo">
          <img alt="" src={Logo} />
        </span>
      </header>

      <div className="invoice-info">
        <div className="invoice-info-to">
          <p className="invoice-info-title">To</p>
          <p className="mb-0">Shakti</p>
          <p className="mb-0">
            101 E. Sejal Apartment
            <br />
            Katra, Pin 211002
          </p>
          <p className="mb-0">(800) 555-12345</p>
        </div>

        <table className="invoice-info-meta">
          <tbody>
            <tr>
              <th>Invoice #</th>
              <td>101138</td>
            </tr>
            <tr>
              <th>Date</th>
              <td>January 1, 2012</td>
            </tr>
            <tr>
              <th>Amount Due</th>
              <td>$600.00</td>
            </tr>
          </tbody>
        </table>
      </div>

      <table className="invoice-items">
        <thead>
          <tr>
            <th>Plan</th>
            <th>Rate</th>
            <th>Year / Month</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Pay Per Job Post</td>
            <td>$150.00</td>
            <td>3 Month </td>
            <td>$450.00</td>
          </tr>
        </tbody>
      </table>

      <table className="invoice-balance">
        <tbody>
          <tr>
            <th>Total</th>
            <td>$450.00</td>
          </tr>
          <tr>
            <th>Amount Paid</th>
            <td>$0.00</td>
          </tr>
          <tr>
            <th>Balance Due</th>
            <td>$450.00</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Invoice;
