import React, {useEffect, useState } from "react";
import UserImg from "../../../assets/user-img.jpg";
import { Link } from "react-router-dom";
import "../UserPanel/Profile/ProfileModal/Modal.css";
import {RiSuitcaseFill} from 'react-icons/ri'
// const initialData={
//   about_company : "",
//     role_responsibility : "",
//     requirements : "",
//     required_skills :[],
//     required_experience : "",
//     salary : "",
//     known_from : "",
// }
const PreviewJobPost = ({questions,customQuestions,jobState,preview}) => {
  
  // const [preview,setPreview]=useState({})
  // useEffect(()=>{
  //   const newArr = questions?.map((elem) => ({
  //     screening_question_id: elem?.id,
  //     min_value: elem?.min_value,
  //     answer: elem?.answer,
  //     recommended: elem?.recommended,
  //   }));
  //   const newArr1 = customQuestions?.map((elem, ind) => ({
  //     ...elem,
  //     tag_name: `custom question ${ind + 1}`,
  //   }));
  //   setPreview({  ...jobState,
  //     screening_question: newArr,
  //     custom_question: newArr1,})
  // },[jobState,questions,customQuestions])


  const [showPreviewJobPost, setShowPreviewJobPost] = useState(false);
  const handleShowPreviewJobPostOpen = () => {
    setShowPreviewJobPost(true);
  };

  const handleShowPreviewJobPostClose = () => {
    setShowPreviewJobPost(false);
  };



  return (
    <>
      <Link to="#" onClick={handleShowPreviewJobPostOpen} className="btn btn-blue-outline py-2 mr-2 w-sm-100">
        Preview
     </Link>

      {showPreviewJobPost && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Preview Job Post</h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleShowPreviewJobPostClose}
                >
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body">
                      <div className="custom-scrollbar">
                         <p className="fa-16 fa-sm-14 gary-text fw-400 text-left">This is a preview of what your job post will look like to job seekers, which includes details about your job such as company size and location, as well as information around when you created your LinkedIn profile. Candidates will answer screening questions when they apply.

</p>
                        <div className="job-description">
                            <h3 className="fw-500 fa-20  fa-sm-18">{preview?.title??"Not provided"}</h3>
                            <p className="fw-400 fa-16 fa-sm-14 gray"> {preview?.about_company??"Not provided"}</p>
                            <p className="fw-400 fa-16 fa-sm-14 gray mt-3"><RiSuitcaseFill className="black-shades-1 fa-24 mr-2"/>{preview?.job_type??"Not provided"} :: {preview?.employment_type??"Not provided"}</p>
                           
                            <div className="apply-btn">
                               <Link className="btn btn-blue-outline mr-2">Easy Apply</Link> <Link className="btn blue-btn py-2">Save</Link>
                            </div>

                            {/* <div className="Posted-by d-flex mt-4 mb-4">
                              <div className="hiring-profile img mr-2">
                                <img src={UserImg} alt="profile "/>
                              </div>
                              <div className="Posted-profile-detail">
                                  <h4 className="fa-18 black-shades-1 fw-500 mb-0">Shakti Yadav</h4>
                                  <p className="fw-400 fa-16 fa-sm-14 gray mb-0">Web Designer at DesignDot Technologies Pvt Ltd</p>
                              </div>
                            </div> */}

                            <p className="fw-400 fa-16 fa-sm-14 gray mt-2">Tips: Provide a summary of the role, what success in the position looks like, and how this role fits into the organization overall.</p>

                            <h3 className="fa-20  fa-sm-18 black-shades-1 fw-500">Responsibilities</h3>
                            <p className="fw-400 fa-16 fa-sm-14 gray">{preview?.role_responsibility??"Not provided"}</p>
                            <h3 className="fa-20  fa-sm-18 black-shades-1 fw-500">Requirements</h3>
                            <p className="fw-400 fa-16 fa-sm-14 gray">{preview?.requirements??"Not provided"}</p>
                            <h3 className="fa-20  fa-sm-18 black-shades-1 fw-500">Required Experience</h3>
                            <p className="fw-400 fa-16 fa-sm-14 gray">{preview?.required_experience??"Not provided"} </p>
                            <h3 className="fa-20  fa-sm-18 black-shades-1 fw-500">Required Skills</h3>
                            <p className="fw-400 fa-16 fa-sm-14 gray">{preview?.required_skills? preview?.required_skills?.map((ele,index)=><span key={index} className="btn blue-btn" >{ele} </span>) :"Not provided"} </p>
                            <p className="fw-400 fa-16 fa-sm-14 gray">{preview?.role_responsibility??"Not provided"}</p>
                            <h3 className="fa-20  fa-sm-18 black-shades-1 fw-500">Salary (USD)</h3>
                            <p className="fw-400 fa-16 fa-sm-14 gray">{preview?.salary??"Not provided"}</p>

                        </div>
                      </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreviewJobPost;
