import React, { useEffect, useState } from "react";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import JobPipeline from "./JobPipeline";
import "./MyJob.css";
import JobTrackerList from "./MyJobTrackerList";
import { useSelector } from "react-redux";
import authApi from "../../../../utils/authApi";
import { DotSpinner } from "@uiball/loaders";
import { Link } from "react-router-dom";
import Loader from "../../../CommonComponents/Loader/Loader";

const JobTracker = () => {
  const [recentJobApplied, setRecentJobApplied] = useState([]);
  const [page, setPage] = useState(1);
  const [allPages, setAllPages] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("BOOKMARKED");

  //react redux
  const authId = useSelector((state) => state?.auth?.user?.info?.id);

  const getAllJobPosts = async () => {
    setIsLoading(true);
    try {
      if (title === "BOOKMARKED") {
        const res = await authApi.get(`get-save-jobs/${authId}`);
        setIsLoading(false);
        setTotalItem();
        setRecentJobApplied(res?.data?.data);
      } else if (title === "APPLIED") {
        const res = await authApi.get(`users/${authId}/jobs`);
        setIsLoading(false);
        setRecentJobApplied(res?.data?.data);
        setAllPages(
          Math.ceil(res?.data?.meta?.total / res?.data?.meta?.per_page)
        );
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllJobPosts();
  }, [authId, title]);
  // JobPipeline

  const jobs = [
    { bgClass: "light-blue-bg", title: "BOOKMARKED", count: "" },
    { bgClass: "light-gray-bg", title: "APPLIED", count: "" },
  ];

  return (
    <>
      <section className="pb-75 pt-4">
        <div className="fluid-container side-space">
          <div className="grid-2-container">
            <div className="job-tacker-left">
              <LeftSidebar />
            </div>
            {isLoading && (
              <div className="d-flex justify-content-center mt-5">
                {" "}
                <Loader />
              </div>
            )}
            {!isLoading && (
              <div className="job-tacker-right flex-fill">
                <div className="job-pipeline-container d-flex flex-wrap gap-30">
                  {jobs.map((job, index) => (
                    <div
                      className={`job-pipline-box flex-fill light-${
                        title === job.title ? "blue" : "gray"
                      }-bg`}
                      style={{ cursor: "pointer", width: "45%" }}
                      key={index}
                      onClick={() => setTitle(job?.title)}
                    >
                      <Link to="#" className="black">
                        {!isLoading && title === job.title && (
                          <span className="fa-24 fa-mdd-20 black fw-600">
                            {/* {allJobs?.length} */}
                          </span>
                        )}
                        <p>{job.title}</p>
                      </Link>
                    </div>
                  ))}
                </div>

                <div className="border-bottom-2 my-4" />

                <JobTrackerList
                  getAllJobPosts={getAllJobPosts}
                  title={title}
                  page={page}
                  setPage={setPage}
                  allPages={allPages}
                  recentJobApplied={recentJobApplied}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default JobTracker;
