import React, { useEffect, useState } from "react";

import "./ViewCandidate.css";

import { useDispatch, useSelector } from "react-redux";

import dummyBanner from "../../../../../assets/dummy.jpg";
import { Link, useParams } from "react-router-dom";
import dummyProfile from "../../../../../assets/dummyProfile.webp";
import Dummy from "../../../../../assets/dummy.jpg";
import { IMAGEURL } from "../../../../../constant";
import CareerInsightData from "../../../UserPanel/Profile/CareerInsightData";
import HighlightsData from "../../../../CommonComponents/HighlightsData";
import TopSkills from "../../../../CommonComponents/TopSkills";
import ProfileMainContent from "../../../UserPanel/Profile/ProfileMainContent";
import authApi from "../../../../../utils/authApi";
import { saveProfile } from "../../../../../Redux/userSlice";
import { DotSpinner } from "@uiball/loaders";
import Loader from "../../../../CommonComponents/Loader/Loader";
const getUserDetails = async (setIsLoading, setUser, userId,loginAs) => {
  try {
    if(loginAs==="Candidate"){
      const res = await authApi.get(`users/${userId}`);
      setUser(res?.data?.data);
    }else{
      const res = await authApi.get(`recruiter/user-details/${userId}`);
      setUser(res?.data?.data);
    }
  
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
  }
};
//https://jobapi.quicsy.com/api/recruiter/user-details/
const ViewCandidate = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const loginAs = useSelector((state) => state.auth.loginAs);
  const [listItems, setListItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});

  useEffect(() => {
    setListItems(user?.skills || []);
  }, [user]);
  //useeffect for getting logged user data
  useEffect(() => {
    if (params?.id) {
      getUserDetails(setIsLoading, setUser, params?.id,loginAs);
    }
  }, [params?.id]);

  //career insight start

  function getSumOfDateDifferences(dateObjects) {
    let totalYears = 0;
    let totalMonths = 0;

    dateObjects?.forEach((dateObj) => {
      const startDate = new Date(dateObj.start_date);
      const endDate = new Date(dateObj.end_date);

      const yearDiff = endDate.getFullYear() - startDate.getFullYear();
      const monthDiff = endDate.getMonth() - startDate.getMonth();

      // Adjust for negative month difference
      if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
      }

      totalYears += yearDiff;
      totalMonths += monthDiff;
    });

    // Convert months to years if there are more than 11 months
    if (totalMonths >= 12) {
      const extraYears = Math.floor(totalMonths / 12);
      totalYears += extraYears;
      totalMonths -= extraYears * 12;
    }

    const yearText =
      totalYears > 0 ? `${totalYears} year${totalYears > 1 ? "s" : ""}` : "";
    const monthText =
      totalMonths > 0
        ? `${totalMonths} month${totalMonths > 1 ? "s" : ""}`
        : "";

    // Combine the year and month texts
    const result = [yearText, monthText].filter(Boolean).join(" ");

    return result;
  }
  //get total roles
  const getTotalRoles = (array) => {
    const newArr = array.map((elem) => elem?.corporate_title_id);
    const uniArr = [...new Set(newArr)];
    return uniArr.length;
  };
  //find average time  per company
  function calculateAverageTime(companies) {
    // Initialize variables to keep track of total years and total months
    let totalYears = 0;
    let totalMonths = 0;

    // Iterate through each company's start and end date
    companies?.forEach((company) => {
      const startDate = new Date(company.start_date);
      const endDate = company.present_job
        ? new Date()
        : new Date(company.end_date);

      // Calculate the time difference in milliseconds
      const timeDifference = endDate - startDate;

      // Convert milliseconds to years and months
      const years = Math.floor(timeDifference / (365 * 24 * 60 * 60 * 1000));
      const months = Math.floor(
        (timeDifference % (365 * 24 * 60 * 60 * 1000)) /
          (30 * 24 * 60 * 60 * 1000)
      );

      // Add to the total years and months
      totalYears += years;
      totalMonths += months;
    });

    // Calculate the average
    const averageYears = Math.floor(totalYears / companies.length);
    const averageMonths = Math.floor(totalMonths / companies.length);

    // Return the average time in years and months
    return {
      years: averageYears,
      months: averageMonths,
    };
  }
  //career insight end
  //Highlight start
  //find longest employer
  function findCompanyWithLongestTimeDifference(companies) {
    let longestTimeDifference = 0; // Initialize the longest time difference to 0
    let companyName = ""; // Initialize the company name to an empty string
    let role = "";
    // Iterate through each company's start and end date
    companies?.forEach((company) => {
      const startDate = new Date(company.start_date);
      const endDate = company.present_job
        ? new Date()
        : new Date(company.end_date);

      // Calculate the time difference in milliseconds
      const timeDifference = endDate - startDate;

      // If the current time difference is longer than the longest one found so far
      if (timeDifference > longestTimeDifference) {
        longestTimeDifference = timeDifference; // Update the longest time difference
        companyName = company.company_name; // Update the company name
        role = company.corporate_title.title;
      }
    });

    // Return the company name with the longest time difference
    return { companyName: companyName, role: role };
  }
  //Highlight end

  // Experience Start
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    setExperiences(user?.workExperiences);
  }, [user]);

  //function to get date
  function formatMonthYear(inputDate) {
    try {
      const dateObj = new Date(inputDate);

      // Array of month names
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      // Get month and year from the date object
      const month = months[dateObj.getMonth()];
      const year = dateObj.getFullYear();

      // Formatted month and year string
      const formattedMonthYear = `${month} ${year}`;

      return formattedMonthYear;
    } catch (error) {
      return "-::-";
    }
  }

  // function to calulate duration
  function calculateDurationBetweenTimes(timeStringA, timeStringB) {
    try {
      const dateA = new Date(timeStringA);
      const dateB = new Date(timeStringB);

      // Calculate the difference in years and months
      const yearDiff = dateB.getFullYear() - dateA.getFullYear();
      const monthDiff = dateB.getMonth() - dateA.getMonth();

      // Calculate the total months, considering years
      const totalMonths = yearDiff * 12 + monthDiff;

      // Convert years and months to a human-readable format
      const years = Math.floor(totalMonths / 12);
      const months = totalMonths % 12;

      // Create the formatted duration string
      let duration = "";
      if (years > 0) {
        duration += years + (years === 1 ? " yr " : " yrs ");
      }
      if (months > 0) {
        duration += months + (months === 1 ? " month" : " months");
      }

      return duration.trim();
    } catch (error) {
      return "-::-";
    }
  }
  //Experience End

  //Education Start
  const [educations, setEducations] = useState([]);

  const profile = useSelector((state) => state.user.profile); //profile of user from redux

  useEffect(() => {
    setEducations(user?.educationQualifications);
  }, [user]);

  //Education End

  //Certification Start
  const [certifications, setCertifications] = useState([]);

  // }, [updateFlag, authId]);
  useEffect(() => {
    setCertifications(user?.certifications);
  }, [user]);
  //Certification End

  //Projects Start
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    setProjects(user?.projects);
  }, [user]);
  //Projects End

  // Achievement Start
  const [achievements, setAchievements] = useState([]);
  useEffect(() => {
    setAchievements(user?.achievements);
  }, [user]);
  // Achievement End
  // Interests Start
  const [interests, setInterests] = useState([]);
  useEffect(() => {
    setInterests(user?.hobbies);
  }, [user]);
  // Interests End
  return (
    <>
        {isLoading && (
        <div className="d-flex justify-content-center mt-5">
          <Loader />
        </div>
      )}
    { !isLoading && <section>
        {/* page-wrapper */}
        <div className="container-fluid side-space">
          <div className="grid-view-container pt-4 pb-4">
            <div className="main-content grid-view-middle mt-smm-0 mt-3">
                <div className="profile-main-wrapper bg-white">
                  <div className="profile-main-container">
                    {/*profile  banner */}
                    <div className="profile-hero-banner position-relative">
                      <div className="profile-hero-banner-image profile-background-image--default">
                        <img
                          src={
                            user?.banner
                              ? `${IMAGEURL}${user.banner}`
                              : dummyBanner
                          }
                          className="img-fluid full-height full-width"
                          alt="Profile banner"
                        />
                      </div>
                      <div className="">{/* <EditBanner /> */}</div>
                    </div>

                    {/*end profile  banner */}

                    {/* profile  info */}
                    <div className="profile-card-photo-deatils position-relative">
                      <div className="profile-card-photo-wrapper">
                        <div className="profile-card-photo-edit">
                          <img
                            src={
                              user?.avatar
                                ? `${IMAGEURL}${user.avatar}`
                                : user?.logo
                                ? `${IMAGEURL}${user.logo}`
                                : dummyProfile
                            }
                            className="img-fluid"
                            alt="profile"
                          />
                        </div>
                      </div>

                      <div className="ptofile-card-photo-content mt-3">
                        <div className="ptofile-card-photo-content-wrapper">
                          <div className="profile-card-info d-lg-flex d-block justify-content-between">
                            <div>
                              <h4>
                                {user?.first_name ?? "Edit Profile"}{" "}
                                {user?.last_name ?? ""}
                              </h4>
                              <p>
                                {" "}
                                <small className="profile-role-text">
                                  {user?.expertise ?? ""}
                                </small>
                              </p>
                              {(user?.city ||
                                user?.state ||
                                user?.country) && (
                                <p>
                                  {user?.city ?? ""} {user?.state ?? ""}{" "}
                                  {user?.country ?? ""}
                                </p>
                              )}
                            </div>

                            <div className="profile-company-info text-lg-right text-left  mt-2 mt-lg-0">
                              <p className="fw-500 text-lg-right text-left">
                                DesignDot Technologies Pvt Ltd
                              </p>
                              <p className="text-lg-right text-left">
                                Since Sep 2022 Exp. 5 Yrs
                              </p>
                            </div>
                          </div>

                          <div className="profile-card-topic mt-3">
                            <div className="profile-summary-header line-height-0 d-flex">
                              <div className="profile-summary-heading">
                                <h2>Expertise</h2>
                              </div>
                              <div className="profile-summary-icon"></div>
                            </div>
                            <p className="mt-1 mb-0">
                              {user?.expertise ?? ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* summary start */}
                    {user?.about_me && (
                      <>
                        {" "}
                        <div className="line mt-2" />
                        <div className="profile-summary-container">
                          <div className="profile-summary-header line-height-0 d-flex">
                            <div className="profile-summary-heading">
                              <h2>Summary</h2>
                            </div>
                            <div className="profile-summary-icon"></div>
                          </div>
                          {user?.about_me && (
                            <div className="profile-summary-body mt-1">
                              <div className="profile-summary-inner-container d-flex">
                                <div className="profile-view-content">
                                  <p>{user?.about_me}</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {/* summary end */}
                    {/* Experience start */}
                    {experiences?.length > 0 && (
                      <div className="profile-summary-container">
                        <div className="profile-summary-header line-height-0 d-flex">
                          <div className="profile-summary-heading">
                            <h2>Experiences</h2>
                          </div>
                          <div className="profile-summary-icon"></div>
                        </div>

                        {experiences &&
                          experiences?.map((row, index) => (
                            <div
                              className="profile-summary-body d-flex justify-content-between mt-1"
                              key={index}
                            >
                              <div className="profile-summary-inner-container d-flex">
                                <div className="profile-view-content">
                                  <h4>{row?.corporate_title?.title}</h4>
                                  <p
                                    className="d-flex"
                                    style={{ gap: "10px" }}
                                  >
                                    <p>{row?.company_name ?? "-"} </p> •
                                    <p>
                                      {row?.employment_type?.title ?? "-"}{" "}
                                    </p>
                                    •
                                    <p>{" " + row?.job_type?.type ?? "-"} </p>{" "}
                                  </p>
                                  <p key={index}>
                                    {formatMonthYear(row?.start_date)}-
                                    {row?.end_year
                                      ? formatMonthYear(row?.start_date)
                                      : "Present"}{" "}
                                    •
                                    {"   " +
                                      calculateDurationBetweenTimes(
                                        row?.start_date,
                                        row?.end_date ?? Date.now()
                                      )}
                                  </p>
                                  <p key={index}>{row?.description}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                    {/* Experience end */}
                    {/* Education start */}
                    {educations?.length > 0 && (
                      <div className="profile-summary-container">
                        <div className="profile-summary-header line-height-0 d-flex">
                          <div className="profile-summary-heading">
                            <h2>Education</h2>
                          </div>
                          <div className="profile-summary-icon"></div>
                        </div>
                        {educations &&
                          educations?.map((row, index) => (
                            <div
                              className="profile-summary-body d-flex justify-content-between mt-1"
                              key={index}
                            >
                              <div className="profile-summary-inner-container d-flex">
                                <div className="profile-view-image mr-sm-4 mr-2">
                                  <img
                                    src={
                                      row?.media
                                        ? `${IMAGEURL}${row?.media}`
                                        : Dummy
                                    }
                                    alt="Title"
                                    className="p-view-image h-100 w-auto"
                                  />
                                </div>

                                <div className="profile-view-content">
                                  <h4>{row?.institution}</h4>
                                  <p>
                                    {row?.degree} {row?.department ?? ""}{" "}
                                  </p>
                                  <p>
                                    {row?.start_year} - {row?.end_year}{" "}
                                  </p>
                                  <p>{row?.description} </p>
                                </div>
                              </div>
                              <div></div>
                            </div>
                          ))}
                      </div>
                    )}
                    {/* Education End */}
                    {/* Certification Start */}
                    {certifications?.length > 0 && (
                      <div className="profile-summary-container">
                        <div className="profile-summary-header line-height-0 d-flex">
                          <div className="profile-summary-heading">
                            <h2>Certification</h2>
                          </div>
                          <div className="profile-summary-icon"></div>
                        </div>{" "}
                        {certifications &&
                          certifications?.map((row, index) => (
                            <div
                              className="profile-summary-body d-flex justify-content-between mt-1"
                              key={index}
                            >
                              <div className="profile-summary-inner-container d-flex">
                                <div className="profile-view-image mr-sm-4 mr-2">
                                  <img
                                    src={
                                      row?.medias
                                        ? `${IMAGEURL}${row?.medias}`
                                        : Dummy
                                    }
                                    alt="Title"
                                    className="p-view-image h-100 w-auto"
                                  />
                                </div>

                                <div className="profile-view-content">
                                  <h4>{row?.institution}</h4>
                                  <p>
                                    {row?.course} {row?.study_field ?? ""}{" "}
                                  </p>
                                  <p>
                                    {row?.start_year} - {row?.end_year}{" "}
                                  </p>
                                  <p>{row?.description} </p>
                                </div>
                              </div>
                              <div></div>
                            </div>
                          ))}
                      </div>
                    )}
                    {/* Certification End */}
                    {/* Projects Start */}
                    {projects?.length > 0 && (
                      <div className="profile-summary-container">
                        <div className="profile-summary-header line-height-0 d-flex mb-2">
                          <div className="profile-summary-heading">
                            <h2>Projects</h2>
                          </div>
                          <div className="profile-summary-icon"></div>
                        </div>

                        {projects &&
                          projects?.map((row, index) => (
                            <div
                              className="profile-summary-body d-flex justify-content-between mt-1"
                              key={index}
                            >
                              <div className="profile-summary-inner-container d-flex">
                                <div className="profile-view-content">
                                  <h4>{row?.title}</h4>
                                  <p key={index}>{row?.description}</p>
                                  <p key={index}>
                                    {row?.start_year}-{row?.end_year}
                                  </p>
                                </div>
                              </div>
                              <div></div>
                            </div>
                          ))}
                      </div>
                    )}
                    {/* Projects End */}
                    {/* Achievement Start */}
                    {projects?.length > 0 && (
                      <div className="profile-summary-container">
                        <div className="profile-summary-header line-height-0 d-flex mb-2">
                          <div className="profile-summary-heading">
                            <h2>Awards & Recognition</h2>
                          </div>
                          <div className="profile-summary-icon"></div>
                        </div>

                        {achievements &&
                          achievements?.map((row, index) => (
                            <div
                              className="profile-summary-body d-flex justify-content-between mt-1"
                              key={index}
                            >
                              <div className="profile-summary-inner-container d-flex">
                                <div className="profile-view-content">
                                  <h4>{row?.title}</h4>
                                  <p>
                                    {row?.from_year}-{row?.to_year}
                                  </p>
                                </div>
                              </div>
                              <div></div>
                            </div>
                          ))}
                      </div>
                    )}
                    {/* Achievement End */}
                    {/* Interests Start */}
                    {interests?.length > 0 && (
                      <div className="profile-summary-container">
                        <div className="profile-summary-header line-height-0 d-flex">
                          <div className="profile-summary-heading">
                            <h2>Interests</h2>
                          </div>
                          <div className="profile-summary-icon"></div>
                        </div>

                        {interests &&
                          interests?.map((row, index) => (
                            <div
                              className="profile-summary-body d-flex justify-content-between mt-1"
                              key={index}
                            >
                              <div className="profile-summary-inner-container d-flex">
                                <div className="profile-view-content">
                                  <h4>{row?.name}</h4>
                                </div>
                              </div>
                              <div></div>
                            </div>
                          ))}
                      </div>
                    )}
                    {/* Interest End */}
                  </div>
                </div>
            </div>
            <div className="right-side grid-view-right ">
              {user?.workExperiences?.length > 0 && (
                <div className="career-insight-container mt-3">
                  <div className="common-heading mt-4">
                    <h3>Career Insight</h3>
                  </div>

                  <div className="career-insight mt-3">
                    <div className="career-insight-inner">
                      <h4>You Have</h4>
                      <p className="fa-18 fa-mdd-16 fw-500 black">
                        {getSumOfDateDifferences(user?.workExperiences)}
                      </p>
                      <p>of work experience</p>
                    </div>
                    <div className="career-insight-inner">
                      <h4>You’ve had</h4>
                      <p className="fa-18 fa-mdd-16 fw-500 black">
                        {getTotalRoles(user?.workExperiences)}{" "}
                      </p>
                      <p>
                        {getTotalRoles(user?.workExperiences) > 1
                          ? "Roles"
                          : "Role"}{" "}
                        in your career
                      </p>
                    </div>

                    <div className="career-insight-inner">
                      <h4>You’ve averaged</h4>
                      <p className="fa-18 fa-mdd-16 fw-500 black">
                        {calculateAverageTime(user?.workExperiences).years > 1
                          ? `${
                              calculateAverageTime(user?.workExperiences).years
                            } years`
                          : `${
                              calculateAverageTime(user?.workExperiences).years
                            } year`}{" "}
                        {calculateAverageTime(user?.workExperiences).months > 1
                          ? `${
                              calculateAverageTime(user?.workExperiences).months
                            } months`
                          : `${
                              calculateAverageTime(user?.workExperiences).months
                            } month`}{" "}
                      </p>
                      <p>per company</p>
                    </div>
                  </div>
                </div>
              )}

              <div>
                {user?.workExperiences?.length > 0 && (
                  <div className="high-lights-container mt-4">
                    <div className="common-heading">
                      <h3>Highlights</h3>
                    </div>

                    <div className="High-lights mt-3">
                      <h4>Longest employer</h4>
                      <p>
                        {
                          findCompanyWithLongestTimeDifference(
                            user?.workExperiences
                          )?.companyName
                        }
                      </p>
                    </div>
                    <div className="High-lights mt-3">
                      <h4>Longest role</h4>
                      <p>
                        {
                          findCompanyWithLongestTimeDifference(
                            user?.workExperiences
                          )?.role
                        }
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {listItems.length > 0 && (
                <>
                  <div className="profile-summary-container">
                    <div className="profile-summary-header line-height-0 d-flex">
                      <div className="profile-summary-heading">
                        <h2>Top skills</h2>
                      </div>
                    </div>
                    <div className="profile-summary-body mt-3">
                      <ul className="profile-summary-list">
                        {listItems?.length > 0 &&
                          listItems?.map((row, index) => (
                            <li key={index}>
                              <div className="profile-summary-inner-container d-flex justify-content-between">
                                <div className="profile-view-skill">
                                  <p>{row?.title} </p>
                                </div>
                                <div
                                  className="profile-view-skill-years d-flex g-2"
                                  style={{ gap: "15px" }}
                                >
                                <p>
                        {" "}
                        {row?.year_of_experience ?? "0"}{" "}
                        {row?.month_or_year == "Year"
                          ? row?.year_of_experience > 1
                            ? "years"
                            : "year"
                          : row?.year_of_experience > 1
                          ? "months"
                          : "month"}{" "}
                      </p>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>}
    </>
  );
};

export default ViewCandidate;
