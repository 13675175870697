import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Modal.css";
import authApi from "../../../../../utils/authApi";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../../Redux/userSlice";
import { toast } from "react-toastify";
const initialState = {
  years_as_manager_of_individual_contributor: 0,
  years_as_manager_of_other_managers: 0,
};
const AddManageMentSkills = ({ linkText, icon, className, editThis }) => {
  const [showItSkills, setShowItSkills] = useState(false);
  const [skillCount, setItSkillsCount] = useState(1);
  const [state, setState] = useState(initialState);
  const [saving, setSaving] = useState(false);
  const authId = useSelector((state) => state?.auth?.user?.info?.id); //fetch id from redux state

  const dispatch = useDispatch();

  const handleItSkillssOpen = () => {
    setShowItSkills(true);
  };

  const handleItSkillssClose = () => {
    setShowItSkills(false);
  };

  useEffect(() => {
    if (editThis?.id) {
      setState({
        years_as_manager_of_individual_contributor:
          +editThis?.years_as_manager_of_individual_contributor ?? 0,
        years_as_manager_of_other_managers:
          +editThis?.years_as_manager_of_other_managers ?? 0,
      });
    }
  }, [editThis]);

  const handleSubmit = async (e) => {
    setSaving(true);
    e.preventDefault();
    try {
      await authApi.put(`users/${authId}`, state);
      dispatch(updateUser());
      toast.success("Profile updated successfully.", {
        delay: 1000,
      });
      setSaving(false);
      setShowItSkills(false);
    } catch (err) {
      setSaving(false);
    }
  };

  return (
    <>
      <Link
        to=""
        className={`add-new-label ${className}`}
        onClick={handleItSkillssOpen}
      >
        {icon} {linkText}
      </Link>

      {showItSkills && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Management Skills</h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleItSkillssClose}
                >
                  &times;
                </button>
              </div>

              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="modal-body custom-scrollbar">
                  <h5>Management Skill</h5>
                  <div className="form-container  mb-3">
                    <div className="form-group">
                      <label htmlFor={`description`}>
                        Experience (In Year)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Eg : 2"
                        value={
                          state?.years_as_manager_of_individual_contributor
                        }
                        onChange={(e) =>
                          setState({
                            ...state,
                            years_as_manager_of_individual_contributor:
                              e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <hr />
                  <h5>Top Management Skill</h5>
                  <div className="form-container  mb-3">
                    <div className="form-group">
                      <label htmlFor={`description`}>
                        Experience (In Year)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Eg : 2"
                        value={state?.years_as_manager_of_other_managers}
                        onChange={(e) =>
                          setState({
                            ...state,
                            years_as_manager_of_other_managers: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-footer text-right">
                  <button
                    type="submit"
                    disabled={saving}
                    className="btn blue-btn"
                  >
                    {saving ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddManageMentSkills;
