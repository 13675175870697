import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const BeInTheBestCard = (props) => {
  const { title, linkUrl } = props;
  const handleCopy = () => {
    const link = "https://job.quicsy.com";
    navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard.");
  };
  return (
    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div className="in-the-best-card mt-3">
        <Link
          onClick={() => {
            if (title === "Refer a friend") {
              handleCopy();
            }
          }}
          to={linkUrl}
          className="btn light-blue-btn"
        >
          {title}
        </Link>
      </div>
    </div>
  );
};

export default BeInTheBestCard;
