import React, { useState } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { register, saveLoginAs } from "../../../Redux/authSlice";
import SocialButton from "./SocialButton";
import { BsCheckCircleFill } from "react-icons/bs";
import Recruiter from "../../../assets/RecruiterPng.png";
import Candidate from "../../../assets/Candidate.png";
const displayNone = {
  display: "none",
};

const displayInherit = {
  display: "inherit",
};

const SignUpForm = ({ formChangeHandler }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); //state to manage response time so that user won't click twice
  const [showPassword, setShowPassword] = useState("password");
  const loginAsValue = useSelector((state) => state.auth.loginAs);
  const [loginAs, setLoginAs] = useState(loginAsValue);
  const [eyeIconStyle, setEyeIconStyle] = useState([
    displayInherit,
    displayNone,
  ]);

  const showPasswordHandler = (action) => {
    if (action === "show") {
      setShowPassword("text");
      setEyeIconStyle([displayNone, displayInherit]);
    }

    if (action === "hide") {
      setShowPassword("password");
      setEyeIconStyle([displayInherit, displayNone]);
    }
  };

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");

  //function to register new user
  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    dispatch(register({ email, password, loginAs, name,fName,lName }))
      .then((response) => {
        setIsLoading(false);
        if (response.payload?.access_token) {
          navigate("/dashboard"); // redirecting to dashboar afetr signing up
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  //login as
  const handleLoginAs = (type) => {
    setLoginAs(type);
    dispatch(saveLoginAs(type));
  };
  return (
    <div className="login">
      <div className="login-heading">
        <h3>Create an account</h3>
      </div>
      <SocialButton />
      <hr className="m-0 mb-2" />
      <div className="d-flex justify-content-between w-90 m-auto">
        <div
          className="w-45 position-relative "
          style={{
            height: "100px",
            border:
              loginAs === "Recruiter"
                ? "3px solid #007BFF"
                : "1px solid lightgray",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => handleLoginAs("Recruiter")}
        >
          <div className="d-flex justify-content-center flex-column align-items-center">
            <div>
              {" "}
              <img src={Recruiter} style={{ height: "70px", width: "70px" }} />
            </div>
            <span className="fa-12 fw-bold">
              <b>Join as a Recruiter</b>{" "}
            </span>
          </div>

          {loginAs === "Recruiter" && (
            <BsCheckCircleFill
              style={{
                fontSize: "26px",
                color: "#007BFF",
                backgroundColor: "white",
                borderRadius: "50%",
                position: "absolute",
                right: "-10px",
                bottom: "-10px",
              }}
            />
          )}
        </div>
        <div
          className="w-45 position-relative "
          style={{
            height: "100px",
            border:
              loginAs === "Candidate"
                ? "3px solid #007BFF"
                : "1px solid lightgray",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => handleLoginAs("Candidate")}
        >
          <div className="d-flex justify-content-center flex-column align-items-center">
            <div>
              {" "}
              <img src={Candidate} style={{ height: "70px", width: "70px" }} />
            </div>
            <span className="fa-12 ">
              <b>Join as a Candidate</b>{" "}
            </span>
          </div>
          {loginAs === "Candidate" && (
            <BsCheckCircleFill
              style={{
                fontSize: "26px",
                color: "#007BFF",
                backgroundColor: "white",
                borderRadius: "50%",
                position: "absolute",
                right: "-10px",
                bottom: "-10px",
              }}
            />
          )}
        </div>
      </div>
      {/* form starts here */}
      <form onSubmit={handleSubmit}>
        <div className="row form-style p-4">
          {loginAs === "Candidate" && (
            <div className="col-xl-12 col-md-12">
              <div className="form-group">
                <label htmlFor="email">
                  First Name <span className="text-danger">*</span>{" "}
                </label>
                <input
                  className="form-control text-dark"
                  placeholder="Enter first name"
                  id="name"
                  type="text"
                  name="fname"
                  value={fName}
                  onChange={(e) => setFName(e.target.value)}
                  required
                />
              </div>
            </div>
          )}
          {loginAs === "Candidate" && (
            <div className="col-xl-12 col-md-12">
              <div className="form-group">
                <label htmlFor="email">
                  Last Name <span className="text-danger">*</span>{" "}
                </label>
                <input
                  className="form-control text-dark"
                  placeholder="Enter last name"
                  id="name"
                  type="text"
                  name="lname"
                  value={lName}
                  onChange={(e) => setLName(e.target.value)}
                  required
                />
              </div>
            </div>
          )}
          {loginAs === "Recruiter" && (
            <div className="col-xl-12 col-md-12">
              <div className="form-group">
                <label htmlFor="email">
                  Name <span className="text-danger">*</span>{" "}
                </label>
                <input
                  className="form-control text-dark"
                  placeholder="Enter full name"
                  id="name"
                  type="text"
                  name="email"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            </div>
          )}

          <div className="col-xl-12 col-md-12">
            <div className="form-group">
              <label htmlFor="email">
                Email <span className="text-danger">*</span>
              </label>
              <input
                className="form-control text-dark"
                placeholder="Enter email"
                id="email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="col-xl-12 col-md-12">
            <div className="form-group">
              <label htmlFor="pwd">
                Password <span className="text-danger">*</span>
              </label>
              <div class="input-group mb-3">
                <input
                  type={showPassword}
                  className="form-control border-right-0 text-dark"
                  placeholder="Enter password"
                  id="pwd"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div class="input-group-prepend">
                  <span class="input-group-text border-left-0">
                    <GoEye
                      style={eyeIconStyle[0]}
                      onClick={(e) => showPasswordHandler("show")}
                    />
                    <GoEyeClosed
                      style={eyeIconStyle[1]}
                      onClick={(e) => showPasswordHandler("hide")}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-12 col-md-12 text-center">
            <button
              type="submit"
              disabled={isLoading || !loginAs || !password || !email}
              className="btn login-btn mt-3"
            >
              {isLoading ? "Creating Account" : "Create Account"}
            </button>
          </div>
        </div>
      </form>

      {/* form ends here */}
      <div>
        <p className="label-text text-center mb-0">
          Have an Account?
          <Link
            to="#"
            class="label-text  text-decoration-underline"
            onClick={(e) => formChangeHandler("login")}
          >
            Log In
          </Link>
        </p>
      </div>
      <hr />

      <div className="term-condition text-center">
        <small>By creating an account, you are agreeing to our</small>
        <p>
          <Link to="#" className="text-decoration-underline">
            Terms & Conditions
          </Link>
          and
          <Link to="#" className="text-decoration-underline">
            Privacy Policy
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignUpForm;
