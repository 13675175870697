import React from 'react';
import {BiPlusCircle} from 'react-icons/bi';
import {Link} from 'react-router-dom';
import MyJobTable from './MyJobTable';
import BookMarkedJobs from './BookMarked';



const MyJobTrackerList = ({recentJobApplied,allPages,page,setPage,title,getAllJobPosts}) => {
  return (
    <>

    <div className='d-flex justify-content-between'>
        <div>
            <h4 className='fa-16 black fw-400'>You have <b>{recentJobApplied?.length} Records</b></h4>
        </div>
        <div>
           <Link to="/job-search" className="btn blue-btn fw-400 fa-16 rounded-lg" style={{width:"max-content"}}> <BiPlusCircle className='fa-24 mr-1'/> Search Job</Link> 
        </div>
    </div>

    <div className='tracker-list mt-4'>
      {title==="BOOKMARKED" ? <BookMarkedJobs getAllJobPosts={getAllJobPosts}  page={page} allPages={allPages} setPage={setPage} recentJobApplied={recentJobApplied} />  :<MyJobTable page={page} getAllJobPosts={getAllJobPosts} allPages={allPages} setPage={setPage} recentJobApplied={recentJobApplied} />}
     
    </div>
    </>
  )
}

export default MyJobTrackerList

