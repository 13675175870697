import React, { useEffect, useState } from "react";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import JobDescriptionList from "./JobDescriptionList";
import "./ManageJob.css";
import Candidatefillter from "./Candidatefillter";
import ManageJobList from "./ManageJobList";
import { useSelector } from "react-redux";
import authApi from "../../../../utils/authApi";
import { useParams } from "react-router-dom";
import { DotSpinner } from "@uiball/loaders";
import DropdownFilter from "../../../CommonComponents/DropdownFilter";
import axios from "axios";
import Loader from "../../../CommonComponents/Loader/Loader";

const Education = [
  { label: "B.Tech", value: "B.Tech" },
  { label: "M.Tech", value: "M.Tech" },
  { label: "B.A", value: "B.A" },
  { label: "B.Sc", value: "B.Sc" },
  { label: "B.Com", value: "B.Com" },
  { label: "B.S", value: "B.S" },
  { label: "B.E", value: "B.E" },
  { label: "MBA", value: "MBA" },
  { label: "M.A", value: "M.A" },
  { label: "M.Sc", value: "M.Sc" },
  { label: "M.Com", value: "M.Com" },
  { label: "M.S", value: "M.S" },
  { label: "M.E", value: "M.E" },
  { label: "Ph.D", value: "Ph.D" },
  { label: "High School Diploma", value: "High School Diploma" },
  { label: "GED", value: "GED" },
  { label: "Associate's Degree", value: "Associate's Degree" },
  { label: "Certificate Program", value: "Certificate Program" },
  { label: "Diploma", value: "Diploma" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Bachelor of Business Administration (BBA)", value: "BBA" },
  { label: "Other", value: "Other" },
];
const Status = [
  { label: "Good Fit", value: "Good Fit" },
  { label: "Not a Fit", value: "Not a Fit" },
  { label: "Unrated", value: "Unrated" },
  { label: "May Be", value: "May Be" },
];
const SortBy = [
  { label: "Good Fit", value: "Good Fit" },
  { label: "Not a Fit", value: "Not a Fit" },
  { label: "Unrated", value: "Unrated" },
  { label: "May Be", value: "May Be" },
];
const Experience = [
  { label: "All", value: "All" },
  { label: "1 Year", value: "1" },
  { label: "2 Year", value: "2" },
  { label: "3 Year", value: "3" },
  { label: "4 Year", value: "4" },
  { label: "5 Year", value: "5" },
  { label: "6 Year", value: "6" },
  { label: "7 Year", value: "7" },
  { label: "8 Year", value: "8" },
  { label: "9 Year", value: "9" },
  { label: "10 Year", value: "10" },
];

const Candidate = () => {
  const [education, setEducation] = useState([]);
  const [experience, setExperience] = useState([]);
  const [status, setStatus] = useState([]);
  const [sortBy, setSortBy] = useState("years_of_experience");
  const [sortDir, setSortDir] = useState("desc");

  const [locations, setLocations] = useState([]);
  const [locationsLoading, setLocationLoading] = useState(false);
  const [location, setLocation] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [searchSkill, setSearchSkill] = useState("");
  const [skillsLoading, setSkillsLoading] = useState(false);

  const [allPages, setAllPages] = useState(1);
  const [page, setPage] = useState(1);

  const handleFilterChange = (id, selectedOptions) => {};
  const [isLoading, setIsLoading] = useState(true);
  const [allApplicants, setAllApplicants] = useState([]);
  const [job, setJob] = useState({});
  const search = useParams();
  //react redux
  const authId = useSelector((state) => state?.auth?.user?.info?.id);

  let cancelToken2;
  const getAllJobPosts = async () => {
    if (typeof cancelToken2 != typeof undefined) {
      cancelToken2.cancel("Cancelled");
    }
    cancelToken2 = axios.CancelToken.source();
    try {
      let payload = {
        location: [location],
        skill: selectedSkills,
        education: education[0],
        years_of_experience: experience[0],
        sort_by: sortBy,
        sort_direction: sortDir,
      };
      if (status.length > 0) {
        payload.status = status[0];
      } else {
        delete payload.status;
      }
      const res = await authApi.get(
        `recruiter/jobs/${search?.id}/users`,
        {
          params: payload,
          cancelToken: cancelToken2.token,
        },
        // { cancelToken: cancelToken2.token }
      );
      setIsLoading(false);

      setAllApplicants(res?.data?.data);
      setAllPages(Math.ceil(res?.data?.total / res?.data?.per_page));
    } catch (err) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllJobPosts();
    return () => {
      if (cancelToken2) {
        cancelToken2.cancel("Cancelled");
      }
    };
  }, [
    authId,
    search,
    location,
    selectedSkills,
    education,
    experience,
    status,
    sortBy,
    sortDir,
  ]);

  const getJobDetail = async () => {
    try {
      const res = await authApi.get(`recruiter/jobs/${search?.id}`);
      setJob(res?.data);
    } catch (err) {}
  };
  useEffect(() => {
    getJobDetail();
  }, [authId, search]);

  let cancelToken1;
  const getLocation = async () => {
    try {
      if (typeof cancelToken1 != typeof undefined) {
        cancelToken1.cancel("Cancelled");
      }
      cancelToken1 = axios.CancelToken.source();

      if (searchQuery) {
        setLocationLoading(true);
        const res = await authApi.get(`search-state/${searchQuery}`, {
          cancelToken: cancelToken1.token,
        });
        setLocations(res?.data);
        setLocationLoading(false);
      }
    } catch (error) {
      setLocationLoading(false);
    }
  };
  useEffect(() => {
    getLocation();
    return () => {
      if (cancelToken1) {
        cancelToken1.cancel("Cancelled");
      }
    };
  }, [searchQuery]);

  let cancelToken;
  const getSkills = async () => {
    try {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Cancelled");
      }
      cancelToken = axios.CancelToken.source();
      if (searchSkill) {
        setSkillsLoading(true);
        const res = await authApi.get(
          `recruiter/search-skills/${searchSkill}`,
          { cancelToken: cancelToken.token }
        );
        setSkills(res?.data?.results);
        setSkillsLoading(false);
      }
    } catch (error) {
      setSkillsLoading(false);
    }
  };
  useEffect(() => {
    getSkills();
    return () => {
      if (cancelToken) {
        cancelToken.cancel("Cancelled");
      }
    };
  }, [searchSkill]);

  const Location = locations?.map((ele) => ({
    label: `${ele?.name}, ${ele?.country?.name}`,
    value: ele?.id,
  }));
  const Skills = skills?.map((ele) => ({ label: ele?.title, value: ele?.id }));

  console.log(isLoading);
  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="grid-3-container">
            <div className="left-side grid-left">
              <LeftSidebar />
            </div>
            {isLoading && (
              <div className="d-flex justify-content-center mt-5">
                {" "}
                <Loader />
              </div>
            )}
            
            {!isLoading && (
              <>
                <div className="grid-middle mt-smm-0 mt-3">
                  <JobDescriptionList getJobDetail={getJobDetail} job={job} />
                </div>

                <div className="grid-right">
                  <>
                    <div className="mt-lg-0 mt-3">
                      <h3 className="card-title fw-600 mb-2">
                        {allApplicants?.length}{" "}
                        {allApplicants?.length > 1 ? "Candidates" : "Candidate"}
                      </h3>
                    </div>

                    <div className="candidate-fillter">
                      <div className="">
                        <DropdownFilter
                          marginclass="mt-4"
                          title="Education"
                          className="min-width-150 bg-light-gray-1"
                          options={Education}
                          onChange={(selectedOptions) => {
                            handleFilterChange("Relevance", selectedOptions);
                            setEducation(selectedOptions);
                          }}
                          name="All"
                          label={education?.length}
                        />
                      </div>

                      <div>
                        <DropdownFilter
                          marginclass="mt-4"
                          title="Location"
                          className="min-width-150 bg-light-gray-1"
                          options={Location}
                          onChange={(selectedOptions) => {
                            handleFilterChange("Location", selectedOptions);
                            setLocation(selectedOptions);
                          }}
                          name="All"
                          searchable
                          setQuery={setSearchQuery}
                          query={searchQuery}
                          loading={locationsLoading}
                          label={location?.length}
                        />
                      </div>

                      <div className="">
                        <DropdownFilter
                          marginclass="mt-4"
                          title="Yrs of Experience"
                          className="min-width-150 bg-light-gray-1"
                          options={Experience}
                          onChange={(selectedOptions) => {
                            handleFilterChange("Experience", selectedOptions);
                            setExperience(selectedOptions);
                          }}
                          name="All"
                          label={experience?.length}
                        />
                      </div>

                      <div>
                        <DropdownFilter
                          marginclass="mt-4"
                          title="Skill"
                          className="min-width-150 bg-light-gray-1"
                          options={Skills}
                          onChange={(selectedOptions) => {
                            handleFilterChange("SkilledIn", selectedOptions);
                            setSelectedSkills(selectedOptions);
                          }}
                          name="All"
                          multiple={true}
                          searchable
                          setQuery={setSearchSkill}
                          query={searchSkill}
                          loading={skillsLoading}
                          label={selectedSkills.length}
                        />
                      </div>
                      <div>
                        <DropdownFilter
                          marginclass="mt-4"
                          title="Status"
                          className="min-width-150 bg-light-gray-1"
                          options={Status}
                          onChange={(selectedOptions) => {
                            handleFilterChange("Relevance", selectedOptions);
                            setStatus(selectedOptions);
                          }}
                          name="All"
                          label={status?.length}
                        />
                        {/* <label>Status</label>
                        <select
                          className="form-control bg-light-gray-1"
                          value={status}
                          style={{ height: "36px",borderRadius:"30px" }}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="All">All</option>
                          <option value="Unrated">Un-rated</option>
                          <option value="Good Fit">Good fit</option>
                          <option value="May Be">May be</option>
                          <option value="Not a Fit">Not fit</option>
                        </select> */}
                      </div>
                    </div>
                  </>
                  <hr />
                  {allApplicants?.length === 0 && (
                    <div className="d-flex justify-content-center p-5 fa-20">
                      No data available !
                    </div>
                  )}
                  {allApplicants?.length > 0 && (
                    <ManageJobList
                      job={job}
                      page={page}
                      setPage={setPage}
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                      sortDir={sortDir}
                      setSortDir={setSortDir}
                      allPages={allPages}
                      getAllJobPosts={getAllJobPosts}
                      allApplicants={allApplicants}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Candidate;
