import React, { useEffect, useState } from "react";

import { AiOutlineEdit, AiOutlinePlusCircle } from "react-icons/ai";
import AddSkills from "../CommonComponents/Modal/AddSkills";
import "./TopSkills.css";
import { useSelector } from "react-redux";
import { MdOutlineModeEditOutline } from "react-icons/md";

const TopSkills = () => {
  const [listItems, setListItems] = useState([]);
  const profile = useSelector((state) => state.user.profile); //profile of user from redux
  //hook to call getAllSkills
  useEffect(() => {
    setListItems(profile?.skills);
  }, [profile]);

  return (
    <>
      <div className="profile-summary-container">
        <div className="profile-summary-header line-height-0 d-flex">
          <div className="profile-summary-heading">
            <h2>Top skills</h2>
          </div>

          <div className="profile-summary-icon">
            <AddSkills
              icon={<AiOutlinePlusCircle className="gray fa-28" />}
              className="icon-deafult"
            />
          </div>
        </div>
        <div className="profile-summary-body mt-3">
          <ul className="profile-summary-list">
            {listItems?.length === 0 && (
              <li className="text-center">No Skill , Add Now!</li>
            )}
            {listItems?.length > 0 &&
              listItems?.map((row, index) => (
                <li key={index}>
                  <div className="profile-summary-inner-container d-flex justify-content-between">
                    <div className="profile-view-skill">
                      <p>{row?.title} </p>
                    </div>
                    <div
                      className="profile-view-skill-years d-flex g-2"
                      style={{ gap: "15px" }}
                    >
                      <p>
                        {" "}
                        {row?.year_of_experience ?? "0"}{" "}
                        {row?.month_or_year == "Year"
                          ? row?.year_of_experience > 1
                            ? "years"
                            : "year"
                          : row?.year_of_experience > 1
                          ? "months"
                          : "month"}{" "}
                      </p>
                      <AddSkills
                        editIt={row}
                        icon={
                          <MdOutlineModeEditOutline className="gary-text ml-1 ml-sm-3 fa-28 fa-mdd-24" />
                        }
                        className="icon-deafult"
                      />
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TopSkills;
