import React, { useEffect, useState } from "react";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import MembershipDetails from "./MembershipDetails";
import PackageCard from "./PackageCard";
import { AiOutlineCheck } from "react-icons/ai";
import "./Packages.css";
import { useSelector } from "react-redux";
import authApi from "../../../../utils/authApi";
import { DotSpinner } from "@uiball/loaders";
import Loader from "../../../CommonComponents/Loader/Loader";

const Packages = () => {
  const loginAs = useSelector((state) => state.auth.loginAs);
  const [allRecPackages, setAllRecPackages] = useState([]); //recruiter package
  const [allCanPackages, setAllCanPackages] = useState([]); //candidate package
  const [isLoading, setIsLoading] = useState(true);
  const [activePackage, setActivePackage] = useState([]);
  //get all packages
  const getAllRecPackages = async () => {
    try {
      const res = await authApi.get(`recruiter/get-all-package`);
      setAllRecPackages(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getActivePackage = async () => {
    try {
      if (loginAs === "Candidate") {
        const res = await authApi.get(`get-candidate-subscriptions?status=1 `);
        setActivePackage(res?.data?.packages);
      } else {
        const res = await authApi.get(
          `recruiter/get-recruiter-subscriptions?status=1 `
        );
        setActivePackage(res?.data?.packages);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getActivePackage();
  }, []);
  //get all packages
  const getAllCanPackages = async () => {
    try {
      const res = await authApi.get(`get-all-package`);
      setAllCanPackages(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (loginAs === "Recruiter") {
      getAllRecPackages();
    } else {
      getAllCanPackages();
    }
  }, []);

  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="job-tracker-section grid-2-container">
            <div className="left-side grid-left">
              <LeftSidebar />
            </div>
            {isLoading && (
              <div className="d-flex justify-content-center mt-5">
                {" "}
                <Loader />
              </div>
            )}
            {!isLoading && (
              <div className="main-content grid-middle">
                <div className="card border-0 p-0">
                  <div className="card-header bg-transparent border-0  px-0">
                    <h3 className="card-title">Card Title</h3>
                    <p className="fa-16 light-black fw-400 mb-0">
                      All paid plans allow for the use of assets with time
                      limit. Each account is for one user only. If you are a
                      team, please contact us.
                    </p>
                  </div>
                  <div className="card-body mt-3 mt-xl-4 px-0">
                    <div className="packages-grid w-100">
                      <div>
                        <MembershipDetails />
                      </div>
                      <div className="package-listed">
                        {loginAs === "Candidate" && (
                          <div>
                            <div className="package-heading mb-4">
                              <h3 className="fa-24 fw-600 black py-1">
                                Candidate
                              </h3>
                              <div className="blue-line pl-4"></div>
                            </div>

                            <div className="display-grid">
                              {allCanPackages?.map((row, index) => (
                                <PackageCard
                                  data={row}
                                  loginAs={loginAs}
                                  getActivePackage={getActivePackage}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                        {loginAs === "Recruiter" && (
                          <div className="mt-5">
                            <div className="package-heading mb-4">
                              <h3 className="fa-24 fw-600 black border-bottom-blue py-1">
                                Recruiter / Company
                              </h3>
                              <div className="blue-line w-70 ml-2"></div>
                            </div>
                            <div className="display-grid ">
                              {allRecPackages?.map((row, index) => (
                                <PackageCard
                                  data={row}
                                  loginAs={loginAs}
                                  getActivePackage={getActivePackage}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                        <div className="mt-5">
                          <div className="package-heading mb-4">
                            <h3 className="fa-24 fw-600 black border-bottom-blue py-1">
                              Active Package
                            </h3>
                            <div className="blue-line w-70 ml-2"></div>
                          </div>
                          <div className="display-grid ">
                            {activePackage?.length === 0 && (
                              <div className="card border-radius-14 d-flex justify-content-center text-center align-items-center fa-18">
                                No active package !
                              </div>
                            )}
                            {activePackage?.length > 0 &&
                              activePackage?.map((row, index) => (
                                <PackageCard
                                  data={row}
                                  loginAs={loginAs}
                                  active={true}
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Packages;
