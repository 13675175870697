import React  from 'react';
import MenuList from './MenuList';
import { useSelector } from 'react-redux';

const MenuListData = () => {

  const loginAs=useSelector((state)=>state.auth.loginAs);
 
  const menuData = {
    menuItems: [

      loginAs==="Candidate" &&  {
        title: 'Profile', 
        link: '/profile',
        items: [ 
          
        ],
      },
      {
        title: 'Dashboard', 
        link: '/dashboard',
       
        items: [
        ],
      },
     loginAs==="Candidate" && {
        title: 'Candidate', 
        items: [
          { label: 'My Job', link:  '/my-job' },
          { label: 'Recommended Job', link: '/my-recommendedJob-jobs' },
          { label: 'Resume Builder', modalContent: 'ResumeBuilder' },
          { label: 'Jobs Alert', modalContent: 'JobAlert' },
        
        
        ],
      },
      loginAs==="Recruiter" && {
        title: 'Employer',
        items: [
          // { label: 'Company Page', link: '/company' },
          { label: 'Post Job', link: '/job-post' },
          { label: 'Manage Job Post', link: '/manage-job-post' },
          { label: 'Recommended Candidates', link: '/recommended-candidates' },
          { label: 'Search Candidate', link: '/search-candidate' },
          { label: 'Saved Resume', link: '/save-resume' },
        ],
      },
      {
        title: 'Package',
        items: [
          { label: 'Purchase History', link: '/purchase-history' },
          { label: 'Packages', link: '/packages' },
        ],
      },
      // {
      //   title: 'Purchase History',
      //   items: [],
      //   link: '/purchase-history',
      // },
      // {
      //   title: 'Packages',
      //   items: [],
      //   link: '/packages',
        
      // }, 
      {
        title: 'Miscellaneous',
        items: [
          // { label: 'Messages', link: '/message' },
          { label: 'Notifcation', link: '/notification' },
        ],
      },
      {
        title: 'Settings',
        items: [
          { label: 'Refer a friend', link: '#'  },
          { label: 'Help', link: '/help-center' },
          { label: 'Log Out', link: '/login' },
         
        ],
      },
    ],
  };

  const customClasses = {
    Dashboard: 'hide-text-for-desc  sub-text-hide', 
    Profile: 'hide-text-for-desc sub-text-hide',   
  };

    const isMobile = window.innerWidth <= 768; 
   

  return (
    <div>
      {/* Other components */}
      <MenuList
        menuData={{
          ...menuData,
          menuItems: menuData.menuItems.filter(
            item => (isMobile || (item.title !== 'Profile' && item.title !== 'Dashboard'))  ),
        }}
        customClasses={customClasses}
      />
      {/* Other components */}
    </div>
  );
};

export default MenuListData;
