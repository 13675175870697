import React, { useState } from "react";
import "./Login.css";
import LoginImg from "../../../assets/login-bg.jpg";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";
import ForgetPassword from "./ForgetPassword";
import BeInTheBest from "../Homepage/BeInTheBest/BeInTheBest";

const Login= () => {

  const [loginPageStyle, setLoginPageStyle] = useState([true, false, false]);

  const formChangeHandler = (formType) => {
    if (formType === "login") {
      setLoginPageStyle([true, false, false]);
    }

    if (formType === "signUp") {
      setLoginPageStyle([false, true, false]);
    }

    if (formType === "forgetPassword") {
      setLoginPageStyle([false, false, true]);
    }
  };

  return (
    <>
      <section>
        <div className="container-fluid p-0">
          <div className="login-container position-relative">
            <div className="login-bg-image">
              <img src={LoginImg} alt="login" className="login-bg" />
            </div>

            <div className="mx-2">
            <div className="login-form py-4">
              {/* Login-form is here */}
              {loginPageStyle[0] && (
                <>
                  <LoginForm formChangeHandler={formChangeHandler} />
                </>
              )}

              {/* Sign Up Form is here form is here */}
              {loginPageStyle[1] && (
                <>
                  <SignUpForm formChangeHandler={formChangeHandler} />
                </>
              )}

              {/* ForgetPassword Form is here */}
              {loginPageStyle[2] && (
                <>
                  <ForgetPassword formChangeHandler={formChangeHandler} />
                </>
              )}
            </div>
            </div>
           
            </div>
          </div>
        
      </section>

      {/* beInTheBest  */}
      <section className="ptb-75">
        <BeInTheBest/>
      </section>
      {/* beInTheBest  */}
    </>
  );
}

export default Login;
