import React, { useEffect, useState } from "react";
import "../../../CommonComponents/InputTag.css";
import { AiOutlineEdit, AiOutlinePlusCircle } from "react-icons/ai";
import Dummy from "../../../../assets/dummy.jpg";
import AddNewEducation from "./ProfileModal/AddNewEducation";
import { useSelector } from "react-redux";


import { IMAGEURL } from "../../../../constant";
import AddCertification from "./ProfileModal/AddCertifications";
import { MdOutlineModeEditOutline } from "react-icons/md";


const ProfileCertification = () => {
  const [listItems, setListItems] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false); //flag to track that new achievement added

  const profile = useSelector((state) => state.user.profile); //profile of user from redux
  //hook to call getCert
  // useEffect(() => {
  //   getAllCertification(authId, setListItems, setIsLoading);
  // }, [updateFlag, authId]);
  useEffect(() => {
    setListItems(profile?.certifications);
  }, [profile]);

  return (
    <div className="profile-summary-container">
      <div className="profile-summary-header line-height-0 d-flex">
        <div className="profile-summary-heading">
          <h2>Certification</h2>
        </div>
        <div className="profile-summary-icon">
          <AddCertification
           updateFlag={updateFlag}
           setUpdateFlag={setUpdateFlag}
           thisIs={"Cer"}
            icon={<AiOutlinePlusCircle className="gray fa-28 fa-mdd-24" />}
            className="icon-deafult"
          />
        </div>
      </div>{" "}

      {listItems &&
        listItems?.map((row, index) => (
          <div
            className="profile-summary-body d-flex justify-content-between mt-1"
            key={index}
          >
            <div className="profile-summary-inner-container d-flex">
          
                <div className="profile-view-image mr-sm-4 mr-2">
                  <img
                    src={row?.medias ? `${IMAGEURL}${row?.medias}` : Dummy}
                    alt="Title"
                    className="p-view-image h-100 w-auto"
                  />
                </div>
            
              <div className="profile-view-content">
                <h4>{row?.institution}</h4>
                <p>
                  {row?.course} {row?.study_field ?? ""}{" "}
                </p>
                <p>
                  {row?.start_year} - {row?.end_year}{" "}
                </p>
                <p>{row?.description} </p>
              </div>
            </div>
            <div>
              <AddCertification
                 thisIs={"Cer"}
               updateFlag={updateFlag}
               setUpdateFlag={setUpdateFlag}
               editIt={row}
                icon={
                  <MdOutlineModeEditOutline className="gray ml-1 ml-sm-3 fa-28 fa-mdd-24" />
                }
                className="icon-deafult"
              />
            </div>
          </div>
        ))}
               {listItems?.length === 0 && (
        <div className="d-flex justify-content-center w-100 mb-2">
          No Certificate, Add Now!
        </div>
      )}
    </div>
  );
};

export default ProfileCertification;
