import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Modal.css";
import { useDispatch, useSelector } from "react-redux";
import authApi from "../../../../../utils/authApi";
import { toast } from "react-toastify";
import { saveProfile } from "../../../../../Redux/userSlice";
// import authApi from "../../../../../utils/authApi";

//function to get All skills
// const getAllSkills = async () => {
//   try {
//     const res = await authApi.get("skills");

//   } catch (error) {}
// };

const ProfileTopic = ({ icon, className }) => {
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const [showProfileTopic, setProfileTopic] = useState(false);
  const user = useSelector((state) => state.user.profile);
  const authId = useSelector((state) => state?.auth?.user?.info?.id);
  const [expertise,setExpertise]=useState(user?.expertise??"")
  const handleProfileTopicOpen = () => {
    setProfileTopic(true);
  };

  const handleProfileTopicClose = () => {
    setProfileTopic(false);
  };
  const handleAddExpertise = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
     const res= await authApi.put(`users/${authId}`, { expertise: expertise });
      toast.success("Expertise added successfully.");
      dispatch(saveProfile(res?.data?.data));
      setProfileTopic(false);
      setSaving(false);
    } catch (err) {
      setSaving(false);
      setProfileTopic(false);
    }
  };
  return (
    <>
      <Link
        className={`gary-text ml-3 fa-28 ${className}`}
        onClick={handleProfileTopicOpen}
      >
        {icon}
      </Link>

      {showProfileTopic && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h4 className="modal-title">Add Profile Hash Topics</h4>
                </div>

                <button
                  type="button"
                  className="close"
                  onClick={handleProfileTopicClose}
                >
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="heading">
                  <p className="fa-14 black mt-1">
                    Your topics will be displayed at the top of your profile to
                    show your audience what you talk about most.
                  </p>
                </div>

                <input
                  autoFocus
                  placeholder="Enter what you talk about most"
                  className="form-control"
                  value={expertise}
                  onChange={(e)=>setExpertise(e.target.value)}
                />
                 <div className="modal-footer  text-right">
                  <button
                    type="submit"
                    onClick={(e) => handleAddExpertise(e)}
                    className="btn blue-btn"
                    disabled={saving}
                  >
                  {saving?"Submitting":"Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileTopic;
