import React, { useEffect, useState } from "react";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import PurchaseFilter from "./PurchaseFilter";
import PurchaseTable from "./PurchaseTable";
import authApi from "../../../../utils/authApi";
import { APIBASE } from "../../../../constant";
import { useSelector } from "react-redux";
import { DotSpinner } from "@uiball/loaders";
import Loader from "../../../CommonComponents/Loader/Loader";


const getAllHistory = async (setIsLoading, setHistory, loginAs, filterBy) => {
  
  try {
    if (loginAs === "Candidate") {
      const res = await authApi.get(
        `get-payment-histories?date_range=${filterBy}`
      );
      setHistory(res?.data?.transactions);
    } else {
      const res = await authApi.get(
        `recruiter/get-payment-histories?date_range=${filterBy}`
      );
      setHistory(res?.data?.transactions);
    }
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
  }
};

const PurchaseHistory = () => {

  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterBy, setFilterBy] = useState("");
  const loginAs = useSelector((state) => state.auth.loginAs);

  useEffect(() => {
    getAllHistory(setIsLoading, setHistory, loginAs, filterBy);
  }, [filterBy]);

  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="job-tracker-section grid-2-container">
            <div className="left-side grid-left">
              <LeftSidebar />
            </div>
            {isLoading && (
              <div className="d-flex justify-content-center">
                <Loader />{" "}
              </div>
            )}
            {!isLoading && (
              <div className="candidate-explor  grid-middle">
                <div className="border-1 border-radius-14 p-3 p-sm-4 mt-3 mt-smm-0">
                  <form>
                    <div className="row align-items-start">
                      <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12">
                        <h3 className="job-card-title fa-20">
                          Purchase History
                        </h3>
                      </div>
                      <div className="col-xl-5 col-lg-6  col-md-7 col-sm-12 mt-sm-0 mt-2">
                        <div className="">
                          <select
                            value={filterBy}
                            onChange={(e) => setFilterBy(e.target.value)}
                            className="form-control"
                            id="skill"
                          >
                            <option value="">
                              {filterBy ? "All" : "Select duration"}
                            </option>
                            <option value="today">Today</option>
                            <option value="last_week">Last 1 Week</option>
                            <option value="last_month">Last 1 Month</option>
                            <option value="last_six_month">
                              Last 6 Months
                            </option>
                            <option value="last_one_year">
                              Last 12 Months
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <hr />

                <div className="mt-3">
                  <PurchaseTable history={history} />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default PurchaseHistory;
