import './UploadResume.css';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { BsUpload } from 'react-icons/bs';
import authApi from '../../utils/authApi';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../Redux/userSlice';
import { toast } from 'react-toastify';

const UploadResume = ({title, className}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const authId = useSelector((state) => state?.auth?.user?.info?.id);
  const [uploading,setUploading]=useState(false);
  const dispatch=useDispatch()
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/docx/pdf*', // Specify the file types to accept
    onDrop: (acceptedFiles) => {
      setSelectedFiles(acceptedFiles);
    },
  });

  const uploadResume = async () => {
    setUploading(true);
    // e.preventDefault();
    const myFormData = new FormData();
    myFormData.append("resume", selectedFiles[0]);
    try {
       await authApi.post(`users/${authId}?_method=PUT`, myFormData);
      dispatch(updateUser());
      setSelectedFiles([])
      toast.success("Resume Uploaded successfully.")
      setUploading(false);
    } catch (error) {
      setUploading(false);
    }
  };



  const renderHeader = () => {

    if (selectedFiles.length > 0) {
      return (
        <p>
          {selectedFiles.map((file) => (
            <span key={file.name}>{file.name}</span>
          ))}
        </p>
      );
    } else {
      return <p className='drop-drag-text'>Drag & Drop your resume here or click to select a file (.doc ot pdf)</p>;
    }
  };

  return (
    <div className='dropzone-conatiner'>
      <div className={`common-heading ${className}`}>
         <h3>{title}</h3>
      </div>
     
    <div {...getRootProps()} className={`drag-area mt-3 ${isDragActive ? 'active' : ''}`}>
      <div className="icon">
        <BsUpload />
      </div>
      {renderHeader()}
      <input {...getInputProps()} />
      </div>
      <div className='p-2 text-center'>
    { selectedFiles.length>0 && <button onClick={()=>uploadResume()} type="button" disabled={uploading} className='btn btn-success'>{uploading?"Uploading":"Upload"}</button>}
      </div>
     
    </div>
  );
};

export default UploadResume;
