import React, { useEffect, useMemo, useState } from "react";
import "./JobSearchResult.css";
import Icon from "../../../../assets/icon.png";
import JobSearch from "../../../CommonComponents/SearchForm";
import ToggleSwitch from "../../../CommonComponents/ToggleSwitch";
import DropdownSearchFilterData from "./SearchFilterData";
import JobBasicDetail from "./JobBasicDetail";
import { BsThreeDots } from "react-icons/bs";
import JobDescription from "./JobDescription";
import MobileSearchFilter from "./MobileSearchFilter";
//import { BiNotepad } from "react-icons/bi";
import { DotSpinner } from "@uiball/loaders";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import authApi from "../../../../utils/authApi";
import Pagination from "../../../CommonComponents/Pagination";
import { toast } from "react-toastify";
import DropdownFilter from "../../../CommonComponents/DropdownFilter";
import { useSelector } from "react-redux";
import axios from "axios";
import Loader from "../../../CommonComponents/Loader/Loader";

const JobSearchResult = () => {
  const [allSearchedJobs, setAllSearchedJobs] = useState([]);
  const [showThis, setShowThis] = useState(0);
  const [title, setTitle] = useState("");
  const [exp, setExp] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [jobTypes, setJobTypes] = useState([]);
  const [titleId, setTitleId] = useState("");
  const [allJobalerts, setAllJobAlerts] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [searching, setSearching] = useState(false);
  const authId = useSelector((state) => state?.auth?.user?.info?.id);
  const loginAs = useSelector((state) => state.auth.loginAs);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [showData, setShowData] = useState(false);

    const toggleData = () => {
      setShowData(!showData);
    };
  const [showItsInfo, setShowItsInfo] = useState({});
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [page, setPage] = useState(1);
  const [allPages, setAllPages] = useState(1);
  const [searchParam, setSearchParam] = useSearchParams();
  const [total,setTotal]=useState(0)

  // filter States
  const [datePosted, setDatePosted] = useState([]);

  const [company, setCompany] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [companiesLoading, setCompaniesLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [jobType, setJobType] = useState([]);
  const [jobTypesLoading, setJobTypesLoading] = useState(true);
  // filter States

  // console.log("dp",datePosted);

  const paramsObject = useMemo(() => {
    const obj = {};
    for (const [key, value] of searchParams) {
      obj[key] = decodeURIComponent(value);
    }
    return obj;
  }, [searchParams]);

  useEffect(() => {
    setShowItsInfo(allSearchedJobs[showThis]);
  }, [showThis, allSearchedJobs]);

  //to find time gap
  function getTimeDifferenceString(timeString) {
    const currentTime = new Date();
    const pastTime = new Date(timeString);
    const timeDifference = currentTime - pastTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 30) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    }
  }
  //set value of title (pre-fill)
  useEffect(() => {
    if (
      paramsObject.title ||
      paramsObject.exp ||
      paramsObject.location ||
      paramsObject?.date_posted ||
      paramsObject?.job_type
    ) {
      setTitle(paramsObject?.title);
      setExp(paramsObject?.exp);
      setJobLocation(paramsObject?.location);
    }
  }, [paramsObject]);

  useEffect(() => {
    const query = JSON.parse(localStorage.getItem("query"));
    // console.log("query", query);
    if (query) {
      setJobType([query?.id]);
      localStorage.removeItem("query");
    }
  }, []);

  useEffect(() => {
    const obj = {};
    if (exp) {
      obj.exp = exp;
    } else {
      delete obj.exp;
    }
    if (jobLocation) {
      obj.location = jobLocation;
    } else {
      delete obj.location;
    }
    if (title) {
      obj.title = title;
    } else {
      delete obj.title;
    }
    if (company?.length > 0) {
      obj.company = allCompanies?.filter(
        (ele) => ele?.id === company[0]
      )[0]?.name;
    } else {
      delete obj.company;
    }
    if (jobType?.length > 0 && jobTypes?.length > 0) {
      obj["job_type"] = jobTypes?.filter(
        (ele) => ele?.id === jobType[0]
      )[0]?.type;
    } else {
      delete obj["job_type"];
    }
    if (datePosted?.length > 0) {
      obj["date_posted"] = datePosted[0];
    } else {
      delete obj.date_posted;
    }
    setSearchParam(obj);
  }, [exp, title, company, jobType, datePosted, jobLocation, jobTypes]);
  // useEffect(() => {
  //   if (
  //     paramsObject.title ||
  //     paramsObject?.exp ||
  //     paramsObject?.location ||
  //     paramsObject?.company ||
  //     paramsObject["job_type"] ||
  //     paramsObject?.date_posted
  //   ) {

  let cancelToken2
  const getData = async () => {
    if(typeof cancelToken2!=typeof undefined){
      cancelToken2.cancel('Cancelled')
    }
    cancelToken2=axios.CancelToken.source();
    setSearching(true);
    try {
      const res = await authApi.post(`search-job?page=${page}`, {
        title: paramsObject.title??'',
        location: paramsObject.location??'',
        required_experience: paramsObject.exp??'',
        job_type: jobType[0]??'',
        company: company[0]??'',
        date_posted: datePosted[0]??'',
      },{cancelToken:cancelToken2?.token});
      setAllSearchedJobs(res?.data?.data);
      getAllJobAlerts()
      setTotal(res?.data?.meta?.total )
      setAllPages(
        Math.ceil(res?.data?.meta?.total / res?.data?.meta?.per_page)
      );
      // setSearchParam({});
      setSearching(false);
      // clearQueryParameters();
    } catch (error) {
      setSearching(false);
    }
  };
useEffect(()=>{
  let dat=JSON.parse(localStorage.getItem('point'));
  
  if(dat==='Homepage'){
    getData();
    localStorage.removeItem('point')
  }
},[paramsObject,setSearchParam, page])

  // useEffect(() => {
  //   if (
  //     paramsObject?.title ||
  //     paramsObject?.exp ||
  //     paramsObject?.location ||
  //     paramsObject?.company ||
  //     paramsObject["job_type"] ||
  //     paramsObject?.date_posted || page
  //   ) {
  

  //   }

  //   return ()=>{
  //     if(cancelToken2){
  //       cancelToken2.cancel('Cancelled')
  //     }
  //   }
  // }, [paramsObject, setSearchParam, page]);

  const saveJobAlert = async (value) => {
    setIsAlert(value);
    changeAlert();
  };

  const changeAlert = async () => {
    // console.log("alert", isAlert, titleId);
    if ( title) {
      try {
        if (isAlert) {
          await authApi.post(`/set-job-alert/corporate-title/${title}`, {
            action: "remove",
          });
        } else {
          await authApi.post(`/set-job-alert/corporate-title/${title}`, {
            action: "Add",
          });
        }
        toast.success(`Changes saved successfully.`);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  // get job types
  const getAllJobTypes = async () => {
    try {
      const res = await authApi.get(`get-job-type`);
      setJobTypes(res?.data?.jobType);
      setJobTypesLoading(false);
    } catch (error) {
      setJobTypesLoading(false);
    }
  };
  useEffect(() => {
    getAllJobTypes();
  }, []);
  // get job types
  const getAppliedJobs = async () => {
    try {
      const res = await authApi.get(`users/${authId}/job-ids`);
      // setJobTypes(res?.data?.jobType);
      // setJobTypesLoading(false);
      setAppliedJobs(res?.data?.job_ids);
    } catch (error) {
      // setJobTypesLoading(false);
    }
  };
  useEffect(() => {
    getAppliedJobs();
  }, []);
  // /users/{user}/jobs
  // get job types
  const getAllJobAlerts = async () => {
    try {
      const res = await authApi.get(`get-job-alerts`);
      setAllJobAlerts(res?.data);
    } catch (error) {}
  };
  useEffect(() => {
    getAllJobAlerts();
  }, []);

  useEffect(() => {
    const check = allJobalerts?.filter((elem) => elem?.title == title);
    if (check?.length > 0) {
      setIsAlert(true);
    } else {
      setIsAlert(false);
    }
  }, [allJobalerts]);
  //get company
  let cancelToken;
  const getSearchedCompany = async () => {
    setCompaniesLoading(true);

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Cancelled");
    }
    cancelToken = axios.CancelToken.source();
    try {
      if (searchQuery) {
        const res = await authApi.get(`get-companies?search=${searchQuery}`, {
          cancelToken: cancelToken.token,
        });
        setAllCompanies(res?.data?.company);
      }

      setCompaniesLoading(false);
    } catch (error) {
      setCompaniesLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      getSearchedCompany();
    }
    return () => {
      if (cancelToken) {
        cancelToken.cancel("Cancelled");
      }
    };
  }, [searchQuery]);

  // filter

  const Anytime = [
    { label: "Last 24 hours", value: "last 24 hours" },
    { label: "Last 3 days", value: "last 3 days" },
    { label: "Last 1 Week", value: "last 1 week" },
    { label: "Last 1 month", value: "last 1 month" },
  ];

  const Company =
    allCompanies?.map((ele) => ({ label: ele?.name, value: ele?.id })) || [];

  // const Salary = [
  //   { label: "1LPA-2.3LPA", value: "1LPA-2.3LPA" },
  //   { label: "3LPA-5.3LPA", value: "3LPA-5.3LPA" },
  //   { label: "7LPA-12LPA", value: "7LPA-12LPA" },
  // ];

  const Style =
    jobTypes?.map((ele) => ({ label: ele?.type, value: ele?.id })) || [];

  //[
  //   { label: "Remote", value: "Remote" },
  //   { label: "On-site", value: "On-site" },
  //   { label: "Hybrid", value: "hybrid" },

  // ];
  const handleFilterChange = (id, selectedOptions) => {};
  return (
    <>
      <section className="pt-4 pb-3">
        <div className="container-fluid side-space">
          {/* search form */}
          <div className="search-result">
            <div className="row">
              <div className="col-xl-10 col-xl-10 col-md-12 col-sm-12 m-auto">
                <JobSearch
                  setAllSearchedJobs={setAllSearchedJobs}
                  title={title}
                  setTitleId={setTitleId}
                  searching={searching}
                  setSearching={setSearching}
                  setTitle={setTitle}
                  exp={exp}
                  page={page}
                  jobType={jobType}
                  company={company}
                  datePosted={datePosted}
                  getData={getData}
                  jobLocation={jobLocation}
                  setJobLocation={setJobLocation}
                  searchParam={searchParam}
                  setExp={setExp}
                  paramsObject={paramsObject}
                  className="fw-600 black"
                  inputclass="input-bg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end search form */}

      {/* search result */}
      <section className="py-4 search-filter-container">
        <div className="container-fluid side-space">
          <div className="search-filter-grid">
            <div className="saerc-filter-text w-100">
              {<h4 className="fa-16 blue fw-600 fa-sm-14 mb-0">{title}</h4>}
            </div>
            <div className="search-filter text-right">
              <div className="d-flex gap-30 justify-content-lg-end none-mobile">
                <>
                  <DropdownFilter
                    value={datePosted}
                    className="min-width-185"
                    options={Anytime}
                    onChange={(selectedOptions) => {
                      {
                        handleFilterChange("anytime", selectedOptions);
                        setDatePosted(selectedOptions);
                      }
                    }}
                    label={datePosted?.length}
                    name="Date posted"
                  />
                  <DropdownFilter
                    className="min-width-185"
                    options={Company}
                    onChange={(selectedOptions) => {
                      handleFilterChange("company", selectedOptions);
                      setCompany(selectedOptions);
                    }}
                    name="Company"
                    searchable
                    label={company?.length}
                    setQuery={setSearchQuery}
                    query={searchQuery}
                    loading={companiesLoading}
                  />

                  {/* <DropdownFilter
                    className="min-width-185"
                    options={Salary}
                    onChange={(selectedOptions) =>
                      handleFilterChange("company", selectedOptions)
                    }
                    name="Salary"
                  /> */}

                  <DropdownFilter
                    className="min-width-185"
                    options={Style}
                    value={jobType}
                    onChange={(selectedOptions) => {
                      handleFilterChange("company", selectedOptions);
                      setJobType(selectedOptions);
                    }}
                    name="Job type"
                    label={jobType?.length}
                    loading={jobTypesLoading}
                  />
                </>
              </div>
              <div className="d-block d-sm-flex flex-wrap w-100 none-desktop justify-content-end">

              <div>
        <button className='btn btn-gary' onClick={toggleData}>All Fillter</button>
        {showData && <div className='no-of-data'> <>
                  <DropdownFilter
                    value={datePosted}
                    className="min-width-185"
                    options={Anytime}
                    onChange={(selectedOptions) => {
                      {
                        handleFilterChange("anytime", selectedOptions);
                        setDatePosted(selectedOptions);
                      }
                    }}
                    label={datePosted?.length}
                    name="Date posted"
                  />
                  <DropdownFilter
                    className="min-width-185"
                    options={Company}
                    onChange={(selectedOptions) => {
                      handleFilterChange("company", selectedOptions);
                      setCompany(selectedOptions);
                    }}
                    name="Company"
                    searchable
                    label={company?.length}
                    setQuery={setSearchQuery}
                    query={searchQuery}
                    loading={companiesLoading}
                  />

                  {/* <DropdownFilter
                    className="min-width-185"
                    options={Salary}
                    onChange={(selectedOptions) =>
                      handleFilterChange("company", selectedOptions)
                    }
                    name="Salary"
                  /> */}

                  <DropdownFilter
                    className="min-width-185"
                    options={Style}
                    value={jobType}
                    onChange={(selectedOptions) => {
                      handleFilterChange("company", selectedOptions);
                      setJobType(selectedOptions);
                    }}
                    name="Job type"
                    label={jobType?.length}
                    loading={jobTypesLoading}
                  />
                </>  </div>}
      </div>
             
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*start  search result */}
      <section className="py-4">
        <div className="container-fluid side-space">
          <div className="grid-2-1-container">
            <div className="search-filter-left grid-left">
              <div className="search-filter-result d-flex justify-content-between">
                <div className="search-counting">
                  <h4 className="fa-14 black fw-600 mb-0">Jobs in India </h4>
                </div>
                {title && loginAs === "Candidate" && (
                  <div className="d-flex pr-3">
                    <span className="mr-3 mt-1">Set Job Alert</span>
                    <ToggleSwitch onChange={saveJobAlert} checked={isAlert} />
                  </div>
                )}
              </div>
              {/* Searched Jobs */}
              <div className="searched-job">
                <div className="mb-4">
                  <div>
                    <div className="heading">
                      <h3 className={`job-card-title`}>
                        Searched Results {title ? `for ${title}` : ""}
                      </h3>
                      <p className="fa-14 black mt-1">
                        {total} Results
                      </p>
                    </div>
                    <div>
                      {allSearchedJobs?.map((item, itemIndex) => (
                       
                        <div
                          className={`job-card text-dark`}
                          key={itemIndex}
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowThis(itemIndex)}
                        >
                          <div className="job-card-icon">
                            <img
                              src={Icon}
                              className="img-fluid"
                              alt="job icon"
                            />
                          </div>
                          <div className="job-card-content">
                            <h4>
                              {item?.title}{" "}
                              {item?.job_type?.type
                                ? " - " + item?.job_type?.type
                                : ""}{" "}
                              {item?.employment_type?.title
                                ? " - " + item?.employment_type?.title
                                : ""}{" "}
                            </h4>
                            <p>
                              {item?.company?.name}
                              {item?.company?.state?.name
                                ? ", " + item?.company?.state?.name
                                : ""}
                              {item?.company?.country?.name
                                ? ", " + item?.company?.country?.name
                                : ""}
                            </p>
                           {item?.salary && <p>
                              {item?.salary??"-"}
                            </p>}
                            <div className="job-content-inner d-sm-flex d-block align-items-center mt-2 justify-content-between">
                              {/* <div className="job-content-inner-icon d-flex  align-items-center">
                                {<BiNotepad />}
                                <p className="pl-1">{item.status}</p>
                              </div> */}
                              <div className="job-content-inner-content d-flex justify-content-start justify-content-sm-end mt-sm-0 mt-2">
                                <p>
                                  {getTimeDifferenceString(item?.created_at)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                 
                      ))}
                    </div>
                  </div>
                </div>
                {allSearchedJobs.length > 0 && (
                  <div className="pagination-row float-right mt-3">
                    <Pagination
                      page={page}
                      setPage={setPage}
                      allPages={allPages}
                    />
                  </div>
                )}
              </div>
            </div>
            {/*end  search result */}
            {/* {searching && (
              <div className="d-flex justify-content-center mt-5">
                {" "}
                <Loader />
              </div>
            )} */}
            {allSearchedJobs?.length > 0 && !searching && (
              <div className="search-filter-right grid-middle mt-2">
                <div className="card search-filter-container border-0">
                  <div className="card-body">
                    <div className="job-basic-info d-flex justify-content-between">
                      <div>
                        <JobBasicDetail
                          item={showItsInfo}
                          appliedJobs={appliedJobs}
                          getAppliedJobs={getAppliedJobs}
                          classNameCard="border-0"
                        />
                      </div>
                      <div className="float-right">
                        <div className="three-dot dropdown dropleft ">
                          <BsThreeDots
                            className="gray fa-24 "
                            data-toggle="dropdown"
                          />
                          <div className="dropdown-menu dropleft">
                            <Link
                              className="dropdown-item"
                              to={`/view-job/${showItsInfo?.id}`}
                            >
                              View
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="job-description">
                  <div className="card border-0  pt-4">
                    <div className="card-body pl-0">
                      <JobDescription item={showItsInfo} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default JobSearchResult;
