import React from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineGoogle, AiFillLinkedin } from "react-icons/ai";

const SocialButton = () => {
    const handleSocialLogin = (provider) => {
        // Redirect to the OAuth provider's login page
        window.location.href = process.env.REACT_APP_API_URL+`api/login/${provider}`;
    };
  return (
    <div className="third-party-join-container d-flex justify-content-between p-4">
        <Link to="#" onClick={() => handleSocialLogin('google')} className="google-btn">
          Google
          <AiOutlineGoogle className="fa-24 ml-1" />
        </Link>
        <Link to="#" onClick={() => handleSocialLogin('linkedin')} className="google-btn">
          Linkedln
          <AiFillLinkedin className="fa-24 ml-1" />
        </Link>
      </div>
  )
}

export default SocialButton