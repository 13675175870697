import React, { useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import "./Modal.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import authApi from "../../../../../utils/authApi";
import { saveNewUserInfo } from "../../../../../Redux/authSlice";
import { updateUser } from "../../../../../Redux/userSlice";
import { MdOutlineModeEditOutline } from "react-icons/md";

// initial state for updating contact info
const initialInfo = {
  first_name: "",
  last_name: "",
  email: "",
  dob: "",
  phone: "",
  address: "",
  current_position: "",
  gender: "",
  state_id: "",
  country_id: "",
  city_id: "",
  headline: "",
};
//function to get all states based on country
const getAllStates = async (countryId, setActiveStates) => {
  if (countryId) {
    try {
      const res = await authApi.get(`countries/${countryId}/states`);
      setActiveStates(res?.data?.data);
    } catch (error) {}
  }
};
//function to get all cities based on state
const getAllCities = async (stateId, setActiveCities) => {
  if (stateId) {
    try {
      const res = await authApi.get(`states/${stateId}/cities`);
      setActiveCities(res?.data?.data);
    } catch (error) {}
  }
};
const EditBasicIntro = ({ className }) => {
  const dispatch = useDispatch();
  const authId = useSelector((state) => state?.auth?.user?.info?.id); //fetch id from redux state
  const auth = useSelector((state) => state?.auth?.user?.info);

  const [statex, setStatex] = useState(initialInfo); //state for state management
  const [activeCountries, setActiveCountries] = useState([]); //state for saving countries
  const [activeStates, setActiveStates] = useState([]); //state for saving states
  const [activeCities, setActiveCities] = useState([]); //state for saving cities
  const [saving, setSaving] = useState(false);
  const [showBasicIntro, setShowBasicIntro] = useState(false);

  const handleBasicIntroOpen = () => {
    setShowBasicIntro(true);
  };

  const handleBasicIntroClose = () => {
    setShowBasicIntro(false);
  };

  //checking if user has some data already then data should be prefilled
  useEffect(() => {
    if (auth.first_name) {
      setStatex((statex) => ({
        ...statex,
        first_name: auth?.first_name,
        last_name: auth?.last_name,
        country_id: auth?.country_id,
        city_id: auth?.city_id,
        state_id: auth?.state_id,
        address: auth?.address,
        dob: auth?.dob,
        gender: auth?.gender,
        phone: auth?.phone,
        email: auth?.email,
        headline: auth?.headline,
      }));
    }
  }, [auth]);

  //function to get all active countries

  const getAllCountries = async () => {
    try {
      const res = await authApi.get(`countries`);
      setActiveCountries(res?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    getAllCountries();
  }, []);

  //useEffect to get all active states
  useEffect(() => {
    getAllStates(statex.country_id, setActiveStates);
  }, [statex.country_id]);

  //useEffect to get all active states
  useEffect(() => {
    getAllCities(statex.state_id, setActiveCities);
  }, [statex.state_id]);

  //function to save entered data in form
  const handleContactInfoSubmit = async (e) => {
    setSaving(true);
    e.preventDefault();
    try {
      await authApi.put(`users/${authId}`, statex);
      setSaving(false);
      dispatch(saveNewUserInfo({ ...auth, ...statex }));
      // window.location.reload();
      dispatch(updateUser());
      toast.success("Contact information updated.");
      setStatex(initialInfo);
      handleBasicIntroClose();
    } catch (error) {
      setSaving(false);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <>
        <Link
          data-toggle="modal"
          data-target="#editintro"
          onClick={handleBasicIntroOpen}
        >
          <MdOutlineModeEditOutline className={`gary-text ml-3 fa-24 ${className}`} />
        </Link>

        {showBasicIntro && (
          <div className="modal" style={{ display: "block" }}>
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Edit intro</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={handleBasicIntroClose}
                  >
                    &times;
                  </button>
                </div>
                <form  onSubmit={(e) => handleContactInfoSubmit(e)}>
                  <div className="modal-body custom-scrollbar">
                    <div className="form-container">
                      <div className="form-group ">
                        <label htmlFor="first_name">
                          First name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control"
                          placeholder="First Name"
                          id="first_name"
                          value={statex.first_name}
                          onChange={(e) =>
                            setStatex({
                              ...statex,
                              first_name: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="last_name">
                          Last name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control"
                          placeholder="Last Name"
                          id="last_name"
                          value={statex.last_name}
                          onChange={(e) =>
                            setStatex({
                              ...statex,
                              last_name: e.target.value,
                            })
                          }
                        />
                      </div>
                      <p class="mb-2 small-text">
                        <BsInfoCircle /> This can only be added using our mobile
                        app
                      </p>

                      <div class="form-group">
                        <label for="sel1">Gender</label>
                        <select
                          class="form-control"
                          id="sel1"
                          value={statex.gender}
                          onChange={(e) =>
                            setStatex({ ...statex, gender: e.target.value })
                          }
                        >
                          <option value="">Select Gender </option>
                          <option value="female">She / Her </option>
                          <option value="male">He / Him</option>
                          <option value="many">They / Them</option>
                          <option value="custom">Custom</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="headline">Headline</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Headline"
                          value={statex.headline}
                          onChange={(e) =>
                            setStatex({ ...statex, headline: e.target.value })
                          }
                        />
                      </div>

                      {/* <div className="form-group">
                        <label htmlFor="current_position">
                          Current position
                        </label>
                        <select class="form-control" id="sel1">
                          <option>Please select </option>
                          <option>
                            Web Designer at Designdot Technologies Pvt Ltd
                          </option>
                        </select>
                      </div> */}

                      <div className="d-sm-flex">
                        <div className="form-group flex-fill mr-3">
                          <label htmlFor="phonenumber">
                            Phone number <span className="text-danger">*</span>
                          </label>
                          <input
                            type="tel"
                            required
                            className="form-control"
                            placeholder="Ex. xxx xxx xxxx"
                            id="phonenumber"
                            value={statex.phone}
                            onChange={(e) =>
                              setStatex({ ...statex, phone: e.target.value })
                            }
                          />
                        </div>

                        <div className="form-group flex-fill">
                          <label htmlFor="email">
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            required
                            className="form-control"
                            placeholder="Ex. example@gmail.com"
                            id="email"
                            value={statex.email}
                            onChange={(e) =>
                              setStatex({ ...statex, email: e.target.value })
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <textarea
                          className="form-control"
                          id="address"
                          value={statex.address}
                          onChange={(e) =>
                            setStatex({ ...statex, address: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="city">
                          City <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-control"
                          id="sel1"
                          required={activeCities?.length!=0}
                          disabled={!statex.state_id}
                          value={statex.city_id}
                          onChange={(e) =>
                            setStatex({ ...statex, city_id: e.target.value })
                          }
                        >
                          <option value="">
                            {statex.state_id
                              ? "Select City"
                              : "Select State First."}
                          </option>
                          {activeCities?.map((row, index) => (
                            <option key={index} value={row.id}>
                              {row?.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="state">
                          State <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-control"
                          id="sel1"
                          required
                          disabled={!statex.country_id}
                          value={statex.state_id}
                          onChange={(e) =>
                            setStatex({ ...statex, state_id: e.target.value })
                          }
                        >
                          <option value="">
                            {statex.country_id
                              ? "Select State"
                              : "Select Country First."}
                          </option>
                          {activeStates?.map((row, index) => (
                            <option key={index} value={row.id}>
                              {row?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="country">
                          Country <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-control"
                          id="sel1"
                          required
                          value={statex.country_id}
                          onChange={(e) =>
                            setStatex({
                              ...statex,
                              country_id: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Country</option>
                          {activeCountries?.map((row, index) => (
                            <option key={index} value={row.id}>
                              {row?.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group flex-fill">
                        <label htmlFor="birthday">Birthday </label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Ex. 29-02-1996"
                          id="birthday"
                          value={statex.dob}
                          onChange={(e) =>
                            setStatex({ ...statex, dob: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer  text-right">
                    <button
                      type="submit"
                     
                      className="btn blue-btn"
                      disabled={
                        saving
                      }
                    >
                      {saving ? "Submitting" : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default EditBasicIntro;
