import React from "react";
import "./CareerInsight.css";
import { useSelector } from "react-redux";

const CareerInsight = () => {
  const profile = useSelector((state) => state?.user?.profile);

  // console.log(profile?.workExperiences);

  function getSumOfDateDifferences(dateObjects) {
    let totalYears = 0;
    let totalMonths = 0;
    dateObjects?.forEach((dateObj) => {
      let startDate = new Date(dateObj?.start_date);
      let endDate = dateObj?.present_job
        ? new Date()
        : new Date(dateObj?.end_date);
  
      let yearDiff = endDate?.getFullYear() - startDate?.getFullYear();
      let monthDiff = endDate?.getMonth() - startDate?.getMonth();
  
      // Adjust for negative month difference
      if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
      }
  
      totalYears += yearDiff;
      totalMonths += monthDiff;
    });
  
    // Convert months to years if there are more than 11 months
    if (totalMonths >= 12) {
      let extraYears = Math.floor(totalMonths / 12);
      totalYears += extraYears;
      totalMonths -= extraYears * 12;
    }
  
    let yearText =
      totalYears > 0 ? `${totalYears} year${totalYears > 1 ? "s" : ""}` : "";
    let monthText =
      totalMonths > 0
        ? `${totalMonths} month${totalMonths > 1 ? "s" : ""}`
        : "";
  
    let result = [yearText, monthText].filter(Boolean).join(" ");
  
    return result;
  }
  
  //get total roles
  const getTotalRoles = (array) => {
    const newArr = array.map((elem) => elem?.corporate_title_id);
    const uniArr = [...new Set(newArr)];
    return uniArr.length;
  };
  function calculateAverageTime(companies) {
    // Initialize variables to keep track of total years and total months
    let totalYears = 0;
    let totalMonths = 0;
  
    // Iterate through each company's start and end date
    companies?.forEach((company) => {
      const startDate = new Date(company.start_date);
      const endDate = company?.present_job
        ? new Date()
        : new Date(company?.end_date);
      // Calculate the time difference in milliseconds
      const timeDifference = endDate - startDate;
  
      // Convert milliseconds to years and months
      const years = Math.floor(timeDifference / (365 * 24 * 60 * 60 * 1000));
      const months = Math.floor(
        (timeDifference % (365 * 24 * 60 * 60 * 1000)) /
          (30 * 24 * 60 * 60 * 1000)
      );
  
      // Add to the total years and months
      totalYears += years;
      totalMonths += months;
    });
  
    // Calculate the average
    const averageYears = Math.round(totalYears / companies.length);
    const averageMonths = Math.round(totalMonths / companies.length);
    // Return the average time in years and months
    return {
      years: averageYears,
      months: averageMonths,
    };
  }
  
  return (
    <>
    {profile?.workExperiences?.length>0 &&  <div className="career-insight-container mt-3">
        <div className="common-heading mt-4">
          <h3>Career Insight</h3>
        </div>

        <div className="career-insight mt-3">
          
          <div className="career-insight-inner">
            <h4>You Have</h4>
            <p className="fa-18 fa-mdd-16 fw-500 black">
              {getSumOfDateDifferences(profile?.workExperiences)}
            </p>
            <p>of work experience</p>
          </div>

          <div className="career-insight-inner">
            <h4>You’ve had</h4>
            <p className="fa-18 fa-mdd-16 fw-500 black">
              {getTotalRoles(profile?.workExperiences)}{" "}
            </p>
            <p>
              {getTotalRoles(profile?.workExperiences) > 1 ? "Roles" : "Role"}{" "}
              in your career
            </p>
          </div>

          <div className="career-insight-inner">
            <h4>You’ve averaged</h4>
            <p className="fa-18 fa-mdd-16 fw-500 black">
              {calculateAverageTime(profile?.workExperiences).years > 1
                ? `${
                    calculateAverageTime(profile?.workExperiences).years
                  } years`
                : `${
                    calculateAverageTime(profile?.workExperiences).years
                  } year`}{" "}
              {calculateAverageTime(profile?.workExperiences).months > 1
                ? `${
                    calculateAverageTime(profile?.workExperiences).months
                  } months`
                : `${
                    calculateAverageTime(profile?.workExperiences).months
                  } month`}{" "}
            </p>
            <p>per company</p>
          </div>

        </div>
      </div>}
    </>
  );
};

export default CareerInsight;
