import React, { useEffect, useState } from "react";
import { BiX } from "react-icons/bi";

const AddScreeningQuestion = ({
  row,
  index,
  questions,
  setQuestions,
  selectedTags,
  setSelectedTags,
  handleRemoveCard,
}) => {
  const [data, setData] = useState(row);

  useEffect(() => {
    if (questions?.length > 0) {
      questions[index] = data;
    }
  }, [data, questions, index]);

  useEffect(() => {
    setQuestions(questions);
  }, [questions,setQuestions]);

  useEffect(() => {
    setData(row);
  }, [row]);

  return (
    <div className="card  mb-3">
      <div className="card-header d-flex justify-content-between align-items-sm-center align-items-start">
        <h3 className="color-292D32 fw-500 fa-16 lineheight-28 w-90">
          {/* {cardData[tag].title} */}
          {data?.question}
          <br className="br-for-sm" />
        </h3>
        <button
          className="remove-btn btn btn-outline-danger p-small-0"
          onClick={() => handleRemoveCard(row?.tag_name)}
        >
          <BiX className="fa-20" />
        </button>
      </div>

      <div className="card-body">
        <div className="screening-details">
          <div className="d-flex flex-wrap align-items-center justify-content-between gap-30">
            {data?.input_type === "text" && (
              <div className="flex-fill">
                <div className="form-group">
                  <label>Ideal answer (minimum)</label>
                  <input
                    type="number"
                    placeholder="type your answer..."
                    className="form-control"
                    value={data?.answer}
                    onChange={(e) =>
                      setData({ ...data, answer: e.target.value })
                    }
                  />
                </div>
              </div>
            )}
            {data?.input_type === "select" && (
              <div className="flex-fill">
                <div className="form-group">
                  <label>Ideal answer</label>
                  <select
                    className="form-control"
                    value={data?.answer}
                    onChange={(e) =>
                      setData({ ...data, answer: e.target.value })
                    }
                    id="sel1"
                  >
                    <option value="">Select Option</option>
                    {data?.input_options?.map((option, index) => (
                      <option key={index} value={option}>
                        {option}{" "}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            {data?.input_type === "checkbox" && (
              <div className="flex-fill">
                <div className="form-group">
                  {data?.input_options?.map((option, index) => (
                    <div class="form-check-inline" key={index}>
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          value=""
                        />
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="">
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input mr-2"
                    id={`${index}customCheck`}
                    name="qualification--op"
                    checked={data.recommended}
                    onChange={(e) =>
                      setData({
                        ...data,
                        recommended: e.target.checked ? 1 : 0,
                      })
                    }
                  />
                  <label
                    className="custom-control-label pt-1 pl-2"
                    htmlFor={`${index}customCheck`}
                  >
                    Must–have qualification
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddScreeningQuestion;
