import React from "react";
import { HiOutlineBarsArrowDown } from "react-icons/hi2";
import { Link } from "react-router-dom";
import Pagination from "../../../CommonComponents/Pagination";
import { IMAGEURL } from "../../../../constant";

const PurchaseTable = ({ history }) => {
  function formatDate(dateString) {
    if(dateString){
      const inputDate = new Date(dateString);

      const options = { day: "numeric", month: "short", year: "numeric" };
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        inputDate
      );
  if(formattedDate){
    return formattedDate
  }else{
    return "-"
  }
    }
   return "-"
  }
  return (
    <>
      <div className="job-tracker-container table-responsive">
        <table className="table  w-100">
          <thead>
            <tr>
              <th>
                Date 
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Invoice 
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Type 
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Package 
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Description{" "}
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Total 
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              {/* <th>
                Download  */}
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              {/* </th> */}
            </tr>
          </thead>
          <tbody>
            {history?.length > 0 &&
              history?.map((row, index) => (
                <tr
                  key={index}
                  className="j-gray-bg align-items-center border-18"
                >
                  <td>
                    <p className="fa-14 fw-600 light-black mb-0">
                      {formatDate(row?.created_at)}
                    </p>
                  </td>
                  <td>
                    <Link to={row?.invoice_pdf?`${IMAGEURL}${row?.invoice_pdf}`:''} target={row?.invoice_pdf&&"_blank"} className="light-black">
                     {row?.invoice_number??"-"}
                    </Link>
                  </td>
                  <td>{row?.model ?? row?.type??""}</td>
                  <td>{row?.package?.title ?? "-"}</td>

                  <td>Job Search & Apply Job</td>
                  <td>${row?.grand_total ?? row?.amount??""}</td>
                  {/* <td>
                    <Link
                      to="/invoice"
                      className="bg-success px-3 py-1 border-radius-8 text-white"
                    >
                      Invoice
                    </Link>
                  </td> */}
                </tr>
              ))}
          </tbody>
        </table>
        {history?.length === 0 && (
          <div className="d-flex justify-content-center fa-18 my-4">
            No history found !
          </div>
        )}
      </div>

      {false && history?.length > 0 && (
        <div className="pagination-row float-right mt-3">
          <Pagination />
        </div>
      )}
    </>
  );
};

export default PurchaseTable;
