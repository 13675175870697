import React, { useEffect, useState } from "react";
import { CiSliderVertical } from "react-icons/ci";
import { HiOutlineBarsArrowDown } from "react-icons/hi2";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import Pagination from "../../../CommonComponents/Pagination";
import WorkSkills from "../../../CommonComponents/WorkSkills";
import Education from "../../../CommonComponents/Education";
import { toast } from "react-toastify";
import authApi from "../../../../utils/authApi";
import { useSelector } from "react-redux";
import { IMAGEURL } from "../../../../constant";

const CandidateList = ({ allCandidates, allPages, setPage, page }) => {
  const [showBox, setShowBox] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [boxPosition, setBoxPosition] = useState({});
  const authId = useSelector((state) => state?.auth?.user?.info?.id);
  const [savingCand, setSavingCand] = useState("");
  const [allSavedCand, setAllSavedCand] = useState([]);

  const getAllSavedCandidates = async () => {
    try {
      const res = await authApi.get(`recruiter/companies/${authId}/saved-user`);
      setAllSavedCand(res?.data);
    } catch (error) {}
  };
  useEffect(() => {
    getAllSavedCandidates();
  }, []);

  //get all candidates who applied to job;

  const handleItemMouseEnter = (item, event) => {
    setSelectedItem(item);
    const target = event.currentTarget;
    const rect = target.getBoundingClientRect();
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    const position = {
      top: rect.top + rect.height + scrollTop + 10, // Adjust this value
      left: rect.left + scrollLeft,
    };

    // Calculate horizontal center of the clicked element
    const centerX = rect.left + rect.width / 2;
    const boxWidth = "1000";
    const boxLeft = centerX - boxWidth / 2;

    setBoxPosition({
      ...position,
      left: boxLeft,
    });

    setShowBox(true);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowBox(true);
  };

  const handleBoxClose = () => {
    setShowBox(false);
    setSelectedItem(null);
  };

  //find current Job
  const findCurrentJob = (data) => {
    const res = data?.filter((ele) => ele.present_job === true);
    if (res.length > 0) {
      return res[0]?.company_name;
    }
    return "";
  };

  // Function to calculate the difference in months between two dates
  function calculateMonthDifference(startDate, endDate) {
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();

    let monthDifference = (endYear - startYear) * 12 + (endMonth - startMonth);

    return monthDifference;
  }
  //find total Experience
  const getTotalExperience = (data) => {
    const sumOfMonths = data?.reduce((totalMonths, obj) => {
      const startDate = new Date(obj.start_date);
      let endDate;
      if (obj?.present_job) {
        endDate = new Date(); // Use current date if present_job is true
      } else {
        endDate = new Date(obj.end_date);
      }
      const monthsDifference = calculateMonthDifference(startDate, endDate);
      totalMonths += monthsDifference;
      return totalMonths;
    }, 0);
    const years = sumOfMonths / 12; // Total months converted to years
    if (years > 0) {
      return years.toFixed(1);
    } else {
      return 0;
    }
  };
  //save candidate
  const handleSaveCandidate = async (row) => {
    setSavingCand(row?.id);
    try {
      const payload = { user_id: row?.id, company_id: authId };
      await authApi.post(`recruiter/company-user-bookmark`, payload);
      setSavingCand("");
      getAllSavedCandidates()
      toast.success("Candidate saved successfully.");
    } catch (error) {
      setSavingCand("");
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <hr />
      <div className="job-tracker-container table-responsive">
        <table className="table  w-100">
          <thead>
            <tr>
              <th>
                Name 
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              {/* <th>
                Res. match{" "}
                <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th>
              <th>
                Skill match{" "}
                <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th> */}
              {/* <th>
                Education{" "}
                <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th> */}
              <th>
                Current Job{" "}
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Exp. 
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              {/* <th>
                CTC <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th> */}
              <th>
                Location 
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>

              <th>
                Status 
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>

              <th className="text-right">
                <CiSliderVertical className="fa-20 black fw-700" />
              </th>
            </tr>
          </thead>
          <tbody>
            {allCandidates?.length > 0 &&
              allCandidates?.map((row, index) => (
                <tr className="j-gray-bg align-items-center border-18">
                  <td style={{ width: "150px" }}>
                    <Link
                      // onMouseEnter={(e) => handleItemMouseEnter("Acme Corp", e)}
                      // onClick={() => handleItemClick("Acme Corp")}
                      className={selectedItem === "Acme Corp" ? "active" : ""}
                    >
                      <p className="fa-14 fw-600 light-black mb-0">
                        {`${row.first_name ?? ""} ${row.last_name ?? ""}`}
                      </p>
                    </Link>
                  </td>
                  {/* <td>
                  <span className="mb-2">70%</span>
                  <div
                    className="progress"
                    style={{ height: "15px", borderRadius: "14px" }}
                  >
                    <div
                      className="progress-bar bg-dark-blue"
                      style={{ width: "70%", height: "15px" }}
                    ></div>
                  </div>
                </td>
                <td>
                  <Link
                    // onMouseEnter={(e) => handleItemMouseEnter("80%", e)}
                    // onClick={(event) => handleItemClick("80%")}
                    className={`light-black ${
                      selectedItem === "80%" ? "active" : ""
                    }`}
                  >
                    <span className="mb-2">80%</span>
                    <div
                      className="progress"
                      style={{ height: "15px", borderRadius: "14px" }}
                    >
                      <div
                        className="progress-bar bg-dark-blue"
                        style={{ width: "80%", height: "15px" }}
                      ></div>
                    </div>
                  </Link>
                </td> */}
                  {/* 
                <td>
                  <Link
                    onMouseEnter={(e) => handleItemMouseEnter("education", e)}
                    onClick={(event) => handleItemClick("education")}
                    className={`gray ${
                      selectedItem === "education" ? "active" : ""
                    }`}
                  >
                    Education
                  </Link>
                </td> */}

                  <td>
                    <Link
                      // onMouseEnter={(e) => handleItemMouseEnter("senior", e)}
                      // onClick={(event) => handleItemClick("senior")}
                      className={`gray ${
                        selectedItem === "senior" ? "active" : ""
                      }`}
                    >
                      {findCurrentJob(row?.work_experiences)}
                    </Link>
                  </td>

                  <td>
                    {getTotalExperience(row?.work_experiences)}{" "}
                    {getTotalExperience(row?.work_experiences) > 1
                      ? "years"
                      : "year"}
                  </td>

                  {/* <td>$0.00</td> */}
                  <td>
                    {row?.state?.name}{" "}
                    {row?.country?.name ? `, ${row?.country?.name}` : ""}
                  </td>

                  <td>
                    {allSavedCand?.includes(row?.id) ? (
                      <button
                        className="btn btn-secondary"
                        
                        disabled
                      >
                      Saved
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleSaveCandidate(row)}
                        disabled={savingCand == row?.id}
                      >
                        {savingCand == row?.id ? "Saving" : "Save"}
                      </button>
                    )}
                  </td>
                  <td>
                    <div className="dropdown dropright float-right">
                      <button
                        type="button"
                        className="btn dropdown-toggle dark-gray-bg rounded-50 p-2 d-flex justify-content-center align-items-center"
                        data-toggle="dropdown"
                      >
                        <BsThreeDots className="black fa-26" />
                      </button>
                      <div className="dropdown-menu  j-gray-bg">
                        <Link
                          className="dropdown-item fa-14 light-black fw-400"
                          to={`/all-candidates/view-profile/${row?.id}`}
                        >
                          View Profile
                        </Link>
                        {row?.resume && (
                          <Link
                            target="_blank"
                            to={`${IMAGEURL}${row?.resume}`}
                            className="dropdown-item fa-14 light-black fw-400"
                          >
                            Download Resume{" "}
                          </Link>
                        )}
                        {row?.phone && (
                          <a
                            target="_blank"
                            href={`https://wa.me/${row?.phone}`}
                            className="dropdown-item fa-14 light-black fw-400"
                          >
                            {row?.phone}
                          </a>
                        )}
                        {row?.email && (
                          <a
                            target="_blank"
                            href={`mailto:${row?.email}`}
                            className="dropdown-item fa-14 light-black fw-400"
                          >
                            {row?.email}
                          </a>
                        )}
                    
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {allCandidates?.length === 0 && (
        <div className="d-flex justify-content-center p-5 fa-20">
          No data available !
        </div>
      )}

      {/* Pagination  */}
      {allCandidates?.length > 0 && (
        <div className="pagination-row float-right mt-2">
          <Pagination page={page} setPage={setPage} allPages={allPages} />
        </div>
      )}
      {/* Pagination  */}

      <div className="position-relative ">
        {showBox && (
          <div
            className={`box ${showBox ? "slide-down" : "slide-up"}`}
            style={{
              top: boxPosition.top,
              left: boxPosition.left,
            }}
          >
            {selectedItem === "Acme Corp" && (
              <div className="box-info">
                <p className="fa-14 gray fw-400 line-height-24">
                  As a Marketing Professional my accentuation is on
                  conversations, for which it is fundamental that we know our
                  get-together of individuals well or potentially have the
                  preparation to get some answers concerning them Pieces of
                  information aren’t actually what examination are uncovering to
                  us how ....
                </p>

                <div className="d-block  d-md-flex justify-content-between gap-5">
                  <Link to="/profile" className="btn blue-btn mt-3 px-3 w-100">
                    View Profile
                  </Link>

                  <Link to="#" className="btn blue-btn mt-3 px-3 w-100">
                    Download Resume
                  </Link>
                </div>
              </div>
            )}

            {selectedItem === "80%" && (
              <div className="skill-content">
                <ul className="skill-content-list">
                  <li>
                    <span>NODE JS</span> <span>90%</span>
                  </li>
                  <li>
                    <span>ANGULAR</span> <span>90%</span>
                  </li>
                  <li>
                    <span>ORACLE</span> <span>90%</span>
                  </li>
                  <li>
                    <span>PYTHON</span> <span>80%</span>
                  </li>
                </ul>
              </div>
            )}

            {selectedItem === "senior" && (
              <div className="work-experience">
                <p className="fa-16 gray mb-1"> CTC - Rs. 4,00,000 </p>
                <h4 className="card-title fa-18 mb-3"> Other Skills </h4>
                <WorkSkills />
              </div>
            )}

            {selectedItem === "education" && (
              <div className="work-experience">
                <Education />
              </div>
            )}

            <button className="box-close" onClick={handleBoxClose}>
              X
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default CandidateList;
