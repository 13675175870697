import React, { useState } from "react";
import "./GettingStarted.css";
import { Link, useNavigate } from "react-router-dom";
import EditTargetGoal from "./EditTargetGoal";
import ResumeBuilder from "../ResumeBuilder/ResumeBuilder";
import { toast } from "react-toastify";
import UploadResume from "../../../CommonComponents/UploadResume";
import EditBasicIntro from "../Profile/ProfileModal/EditBasicIntro";
import { useSelector } from "react-redux";

const GettingStarted = () => {
  const progressPercentage = 17;
  const totalTasks = 1;
  // const navigate = useNavigate();
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const auth = useSelector((state) => state?.auth?.user?.info);
  const profile = useSelector((state) => state?.user?.profile);

  console.log("auth is", auth, profile);

  const getTask1Percentage = () => {
    let int = 0;
    if (profile?.first_name && profile?.last_name) {
      int += 1;
    }
    if (profile?.phone) {
      int += 1;
    }
    if (profile?.email) {
      int += 1;
    }
    if (profile?.city && profile?.state && profile?.country) {
      int += 1;
    }
    const percentage = (int / 4) * 100;
    return percentage.toFixed(0);
  };
  const getTask2Percentage = () => {
    if (profile?.resume) {
      return 100;
    } else {
      return 0;
    }
  };
  const getTask3Percentage = () => {
    if (profile?.educationQualifications?.length > 0) {
      return 100;
    } else {
      return 0;
    }
  };
  const getTask4Percentage = () => {
    if (profile?.skills?.length > 0) {
      return 100;
    } else {
      return 0;
    }
  };
  const getTask5Percentage = () => {
    if (profile?.target_job?.target_job) {
      return 100;
    } else {
      return 0;
    }
  };

  const getOverAllPercentage = () => {
    let task = 0;

    const task1 = getTask1Percentage();
    const task2 = getTask2Percentage();
    const task3 = getTask3Percentage();
    const task4 = getTask4Percentage();
    const task5 = getTask5Percentage();

    if (task1 > 0) {
      task += 1;
    }
    if (task2 > 0) {
      task += 1;
    }
    if (task3 > 0) {
      task += 1;
    }
    if (task4 > 0) {
      task += 1;
    }
    if (task5 > 0) {
      task += 1;
    }

    const percentage = ((+task1 + +task2 + +task3 + +task4 + +task5) / 500) * 100;

    return { percent: percentage, task: task };
  };
  const tasks = [
    {
      title: "Personal Information",
      percentage: getTask1Percentage(),
      description:
        "Help stay focused on track during your job search by setting and editing your career goals directly in your scandity",
      btnText: (
        <div className="text-left">
          Edit{" "}
          <EditBasicIntro
            className="text-white fa-sm-14"
            linkText="Edit Goal"
          />
        </div>
      ),
    },
    {
      title: "Upload Resume",
      percentage: getTask2Percentage(),

      description: "Upload your updated resume and help recruiters find you.",
      link: "#",
      btnText: "Upload Resume",
    },
    {
      title: "Add Education",
      percentage: getTask3Percentage(),
      description:
        "Add your education to be on the top of recruiters searchlist.",
      link: "/profile",
      btnText: "Add Education",
    },
    {
      title: "Add Skills",
      percentage: getTask4Percentage(),
      description:
        "Add your all skills to get maximum skills matching percentage.",
      link: "/profile",
      btnText: "Add Skill",
    },
    {
      title: "Add Target Title",
      percentage: getTask5Percentage(),
      description: "Add your target title to get notified.",
      link: "",
      btnText: (
        <EditTargetGoal className="text-white fa-sm-14" linkText="Add" />
      ),
    },
  ];

  return (
    <>
      <div className="summary-module-wrapper">
        <div className="card">
          <div className="card-header bg-transparent">
            <h3 className="card-title">Getting Started</h3>
          </div>
          <div className="card-body">
            <div className="task-progress">
              <div className="progress-lable d-flex justify-content-between">
                <h3>{getOverAllPercentage()?.percent??'0'}% Complete</h3>
                <h3>
                  {getOverAllPercentage()?.task??0}/{tasks.length} Task
                </h3>
              </div>
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  style={{ width: `${getOverAllPercentage()?.percent??0}%`, height: "10px" }}
                ></div>
              </div>
            </div>

            <div className="line mt-3"></div>

            {tasks.map((task, index) => (
              <div className="task-item" key={index}>
                <div className="task-card">
                  <div className="tast-content">
                    <h3>
                      {task.title} ({task?.percentage}%){" "}
                    </h3>
                    <p>{task.description}</p>
                  </div>

                  <div className="task-action">
                    <div className="text-left text-lg-right mt-md-0 mt-mdd-2">
                      {task.link ? (
                        <Link
                          to={task.link}
                          data-toggle={
                            task?.title == "Upload Resume" && "modal"
                          }
                          data-target={
                            task?.title == "Upload Resume" && "#myModal"
                          }
                          className="btn blue-btn padding_ fa-mdd-14"
                        >
                          {task.btnText}
                        </Link>
                      ) : (
                        <button
                          className="btn blue-btn padding_ fa-mdd-14"
                          onClick={task.onClick}
                        >
                          {task.btnText}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* {isModalOpen && <EditBasicIntro />} */}
      <div class="modal fade" id="myModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <UploadResume title="Upload Resume" />
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GettingStarted;
