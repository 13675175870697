import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserImage from "../../../assets/user-img.jpg";
import AddScreeningQuestions from "./AddScreeningQuestions";
import PreviewJobPost from "./PreviewJobPost";
import { useDispatch, useSelector } from "react-redux";
import { saveProfile, setJobState } from "../../../Redux/userSlice";
import { toast } from "react-toastify";
import authApi from "../../../utils/authApi";
import PageNotFound from "../Pages/PageNotFound/PageNotFound";
const initialData = {
  send_application_by: "",
  send_email_to: "",
  status: true,
};
const ReceiveQualifiedApplicants = () => {
  const [saving, setSaving] = useState(false);
  const [screeningQuestions, setScreeningQuestions] = useState([]);
  const [customQuestions, setCustomQuestions] = useState([]); //custom screening question would be saved in this state
  const [questions, setQuestions] = useState([]); //screening question would be saved in this state

  //react redux
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jobState = useSelector((state) => state.user.jobState);
  const preview = useSelector((state) => state.user.preview);
  const profile = useSelector((state) => state?.user?.profile);

  const authId = useSelector((state) => state?.auth?.user?.info?.id);
  const [data, setData] = useState({ ...initialData, ...jobState });
  
  useEffect(() => {
    document.body.classList.add("job-post-bg"); // Add the class to the body element
    return () => {
      document.body.classList.remove("job-post-bg"); // Remove the class when the component is unmounted
    };
  }, []);

  useEffect(() => {
    dispatch(setJobState({ ...data }));
  }, [data, dispatch]);

  //get all screening questions
  useEffect(() => {
    const getAllQuestions = async () => {
      try {
        const res = await authApi.get(
          `recruiter/corporate-titles/${jobState?.title_id}/screenings`
        );
        setScreeningQuestions(res?.data?.data);
      } catch (error) {}
    };
    getAllQuestions();
  }, [jobState?.title_id]);
  useEffect(() => {
    if (profile?.email) {
      setData({ ...data, send_email_to: profile?.email });
    }
  }, [profile]);
  useEffect(() => {
    dispatch(setJobState({ ...data }));
  }, [questions, customQuestions, data, dispatch]);

  //post job
  const handlePostJob = async (e) => {
    e.preventDefault();
    setSaving(true);
    const newArr = questions?.map((elem) => ({
      screening_question_id: elem?.id,
      min_value: elem?.min_value,
      answer: elem?.answer,
      recommended: elem?.recommended,
    }));
    const newArr1 = customQuestions?.map((elem, ind) => ({
      ...elem,
      tag_name: `custom question ${ind + 1}`,
    }));
    const payload = {
      ...jobState,
      screening_question: newArr,
      custom_question: newArr1,
      status: true,
    };
    try {
      await authApi.post(`recruiter/jobs`, payload);
      setSaving(false);
      dispatch(setJobState({}));
      updateCompanyProfile();
      toast.success("Job posted successfully.");
      navigate("/company");
    } catch (error) {
      setSaving(false);
      toast.error(error?.response?.data?.error);
    }
  };

  const updateCompanyProfile = async () => {
    try {
      const res = await authApi.get(`recruiter/companies/${authId}`);
      dispatch(saveProfile(res?.data?.data));
    } catch (error) {}
  };

  return (
    <>
 

     
        <section className="pt-4 pb-75">
          <div className="container-fluid side-space">
            <div className="job-post-summary col-xl-8 col-lg-8 col-md-12 col-sm-12 m-auto p-0">
              <div className="card">
                <div className="card-header bg-transparent">
                  <h3 className="card-title">Receive qualified applicants</h3>
                </div>
                <div className="card-body">
                  <div className="job-role-form form-container">
                    <form  onSubmit={(e) => handlePostJob(e)}>
                    <div>
                      <h3 className="form-title mb-3 ">
                        Applicant collection{" "}
                      </h3>
                      <div className="d-flex flex-md-nowrap flex-wrap justify-content-between">
                        <div className="w-100 mr-sm-2">
                          <div className="form-group">
                            <label htmlFor="description">
                              Receive applicants
                              <span className="text-danger">*</span>
                            </label>
                            <select
                            required
                              className="form-control"
                              value={data?.send_application_by}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  send_application_by: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Method</option>
                              <option value="By email">By email</option>
                              <option value="At an external website">
                                At an external website
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="w-100 ml-sm-2">
                          <div className="form-group">
                            <label htmlFor="email">
                              Email address
                              <span className="text-danger">*</span>
                            </label>

                            <input
                              type="text"
                              required
                              className="form-control"
                              placeholder="example@gmail.com"
                              id="email"
                              value={data?.send_email_to}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  send_email_to: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {/* Conditional rendering based on the selected option */}

                      {data?.send_application_by === "By email" ? (
                        <div className="mt-3">
                          <h3 className="form-title m2-3 ">
                            Screening questions
                          </h3>
                          <p className="para-text">
                            We recommend adding 3 or more questions. Applicants
                            must answer each question.
                          </p>

                          <p className="para-text mt-4">
                            Add screening questions:
                          </p>

                          {/* Add Screening Questions */}

                          <AddScreeningQuestions
                            screeningQuestions={screeningQuestions}
                            customQuestions={customQuestions}
                            setCustomQuestions={setCustomQuestions}
                            questions={questions}
                            setQuestions={setQuestions}
                          />

                          {/* Add Screening Questions */}
                        </div>
                      ) : (
                        <div className="mt-3">
                          <p className="para-text">
                            <b>
                              Receive applicants by email to use screening
                              questions.
                            </b>
                            Screening questions can’t be collected from
                            applicants when they apply on an external site. If
                            you would like to collect answers to screening
                            questions, please choose to receive applicants by
                            email.
                          </p>
                        </div>
                      )}

                      <hr />
                      <p className="para-text mt-4">
                        By continuing, you agree to In job sight
                        <Link to="" className="link-text">
                          Jobs Terms and Conditions
                        </Link>
                        including our
                        <Link to="" className="link-text">
                          policies prohibiting discriminatory job posts
                        </Link>
                        .
                      </p>

                      <hr />

                      <div className="d-flex d-for-mob-block justify-content-between ">
                        <div className="text-left mb-2 mb-sm-0">
                          <PreviewJobPost
                            preview={preview}
                            jobState={jobState}
                            questions={questions}
                            customQuestions={customQuestions}
                          />
                        </div>

                        <div className="text-right">
                          <Link
                            to="/job-post-summary"
                            className="btn btn-outline-secondary border-radius-50 w-sm-100 px-4 py-2 border-width fw-500 mr-2 mb-2 mb-sm-0"
                          >
                            Back
                          </Link>
                          <button
                          type="submit"
                           
                            className="btn blue-btn ml-sm-2 ml-0 fw-500 w-sm-100 mb-2 mb-sm-0"
                            disabled={saving}
                          >
                            {saving ? "Posting" : "Post"}
                          </button>
                        </div>


                      </div>
                    </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      
    </>
  );
};

export default ReceiveQualifiedApplicants;
