import React, { useEffect, useState } from "react";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import ApplicantCard from "./ApplicantCard";
import InsightFromProfile from "./InsightFromProfile";
import "./viewapplicant.css";
import authApi from "../../../../utils/authApi";
import { useParams } from "react-router-dom";
import { DotSpinner } from "@uiball/loaders";
import Loader from "../../../CommonComponents/Loader/Loader";
const getApplicant = async (id, setProfile, setIsLoading) => {
  try {
    const res = await authApi.get(`recruiter/user-details/${id}`);
    setProfile(res?.data?.data);
    setIsLoading(false);
  } catch (err) {
    setIsLoading(false);
  }
};
const ViewApplicant = () => {
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [job, setJob] = useState({});
  const [screeningQsns,setScreeningQsns]=useState([]);
  const [customQsns,setCustomQsns]=useState([])
  const search = useParams();

    const getAllJobPosts = async () => {
      try {
        const res = await authApi.get(
          `recruiter/user-details/${search?.id}/${search?.jobId}`
        );
        setJob(res?.data);
      } catch (err) {}
    };
    useEffect(() => {
    getAllJobPosts();
  }, [search]);

  useEffect(() => {
    getApplicant(search.id, setProfile, setIsLoading);
  }, [search]);



//set screening questions
useEffect(()=>{
  if(job?.pivot?.screening_questions){
    setScreeningQsns(JSON.parse(job?.pivot?.screening_questions))
  }
  if(job?.pivot?.custom_questions){
    setCustomQsns(JSON.parse(job?.pivot?.custom_questions))
  }

},[job])


  return (
    <>
      <section className="pb-75 pt-4">
        <div className="fluid-container side-space">
          <div className="grid-2-container">
            <div className="left-side">
              <LeftSidebar />
            </div>

            <div className="job-content manage-job-main">
              {isLoading && (
                <div className="d-flex justify-content-center mt-5">
                  {" "}
                  <Loader />
                </div>
              )}
              {!isLoading && (
                <div className="job-post-card mb-1">
                  <ApplicantCard getAllJobPosts={getAllJobPosts} profile={profile} job={job} />

                  <InsightFromProfile allQsns={[...screeningQsns,...customQsns]} profile={profile} />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewApplicant;
