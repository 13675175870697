import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "api/",
  headers: {
    Accept: "application/json",
    // Add any other common headers here
  },
});

export default api;
