import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PreviewJobPost from "./PreviewJobPost";
import { FaRegMinusSquare } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setJobState, setPreviewState } from "../../../Redux/userSlice";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import InputTag from "../../CommonComponents/InputTag";
import PageNotFound from "../Pages/PageNotFound/PageNotFound";
const initialData = {
  about_company: "",
  role_responsibility: "",
  requirements: "",
  required_skills: [],
  required_experience: "",
  salary: "",
  known_from: "",
  skill_wise_experience: [],
  management_skill: false,
  top_management_skill: false,
  years_as_manager_of_individual_contributor: 0,
  years_as_manager_of_other_managers: 0,
};
// {"skill":"react","experioence":"6", "month_or_year":"year"}
const JobPostSummary = () => {
  const profile = useSelector((state) => state?.auth?.user?.info); //user id;
  //state
  const [data, setData] = useState(initialData);
  const [savedSkills, setSavedSkills] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [expSkills, setExpSkills] = useState([
    { skill: "", experience: "", type: "" },
  ]);
  const [skill, setSkill] = useState("");
  const [minSal, setMinSal] = useState(""); //minimum salary
  const [maxSal, setMaxSal] = useState(""); //maximum salary
  const [exper, setExper] = useState(""); //experience
  const [flag, setFlag] = useState(false);

  //react redux
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jobState = useSelector((state) => state?.user?.jobState);
  const preview = useSelector((state) => state?.user?.preview);

  useEffect(() => {
    document.body.classList.add("job-post-bg"); // Add the class to the body element
    return () => {
      document.body.classList.remove("job-post-bg"); // Remove the class when the component is unmounted
    };
  });

  //handle continue click
  const handleContinueClick = (e) => {
    e.preventDefault();
    data.required_skills = savedSkills;
    data.salary = `$${minSal}-$${maxSal}`;
    data.required_experience = `${exper}`;

    dispatch(setJobState({ ...jobState, ...data }));
    dispatch(setPreviewState({ ...preview, ...data }));
    navigate(`/qualified-applicants`);
  };

  useEffect(() => {
    setData((data) => ({
      ...data,
      salary: `$${minSal}-$${maxSal}`,
      required_experience: `${exper}`,
    }));
  }, [minSal, maxSal, exper]);

  //skill add and remove
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const check = savedSkills?.filter(
        (elem) => elem.toLowerCase() === skill.toLowerCase()
      );
      if (check?.length === 0) {
        setSavedSkills([...savedSkills, skill]);
      } else {
        toast.warn("Skill already exists.", { position: "top-center" });
      }
      setShowInput(false);
      setSkill("");
    }
  };

  //removev skill
  const handleRemoveOption = (elem) => {
    const check = savedSkills?.filter(
      (ele) => ele.toLowerCase() !== elem.toLowerCase()
    );
    setSavedSkills(check);
  };

  const handleAddMore = () => {
    setExpSkills([...expSkills, { skill: "", experience: "", type: "" }]);
  };

  const handleRemoveRow = (e, ind) => {
    e.preventDefault();
    const filter = expSkills?.filter((ele, index) => index !== ind);
    setExpSkills(filter);
    setFlag(!flag);
  };

  useEffect(() => {
    if (expSkills?.length > 0) {
      const filter = expSkills?.filter(
        (ele) => ele?.skill && ele?.experience && ele?.type
      );
      if (filter?.length > 0) {
        const arr = filter?.map((ele) => ({
          skill: ele?.skill,
          experience: ele?.experience,
          month_or_year: ele?.type,
        }));
        setData({ ...data, skill_wise_experience: arr });
      }
    }
  }, [expSkills]);

  return (
    <>
      <section className="pt-4 pb-75">
        <div className="container-fluid side-space">
          <div className="job-post-summary col-xl-8 col-lg-10 col-md-12 col-sm-12 m-auto p-0">
            <div className="card">
              <div className="card-header bg-transparent">
                <h3 className="card-title">Tell us about the role</h3>
              </div>
              <div className="card-body">
                <div className="job-role-form form-container">
                  <form onSubmit={(e) => handleContinueClick(e)}>
                    <div>
                      <div className="form-group">
                        <label htmlFor="description">About the company </label>
                        <textarea
                          className="form-control"
                          // required
                          value={data.about_company}
                          onChange={(e) =>
                            setData({ ...data, about_company: e.target.value })
                          }
                        ></textarea>
                      </div>

                      <div className="form-group">
                        <label htmlFor="description">
                          Role and responsibility{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <textarea
                          required
                          className="form-control"
                          value={data.role_responsibility}
                          onChange={(e) =>
                            setData({
                              ...data,
                              role_responsibility: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>

                      <div className="form-group">
                        <label htmlFor="description">
                          Requirement <span className="text-danger">*</span>
                        </label>
                        <textarea
                          required
                          className="form-control"
                          value={data.requirements}
                          onChange={(e) =>
                            setData({ ...data, requirements: e.target.value })
                          }
                        ></textarea>
                      </div>

                      <div className="form-group">
                        <label htmlFor="description">Skills</label>
                        <p className="para-text">
                          Add skill keywords to make your job more visible to
                          the right candidates.
                        </p>
                        <InputTag
                          title="Add Skill"
                          savedSkills={savedSkills}
                          setSavedSkills={setSavedSkills}
                        />
                      </div>

                      <div className="form-group">
                        <div className="d-flex justify-content-between align-items-center ">
                          <label htmlFor="experiance">
                            Skill with experience
                          </label>
                          <button
                            className="btn btn-primary mb-1"
                            onClick={() => handleAddMore()}
                          >
                            Add More
                          </button>
                        </div>
                        {expSkills?.map((row, index) => (
                          <SingleRow
                            row={row}
                            index={index}
                            handleRemoveRow={handleRemoveRow}
                            expSkills={expSkills}
                            setExpSkills={setExpSkills}
                            flag={flag}
                          />
                        ))}
                      </div>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <label htmlFor="experiance">Management Skills</label>
                          <div className="d-flex">
                            <input
                              className="form-control mr-2"
                              placeholder="Experiance in years"
                              id="experiance"
                              type="number"
                              value={
                                data?.years_as_manager_of_individual_contributor
                              }
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  years_as_manager_of_individual_contributor:
                                    e.target.value,
                                  management_skill:
                                    e.target.value > 0 ? true : false,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <label htmlFor="experiance">
                            Top Management Skills
                          </label>
                          <div className="d-flex">
                            <input
                              className="form-control mr-2"
                              placeholder="Experiance in year"
                              id="experiance"
                              type="number"
                              value={data?.years_as_manager_of_other_managers}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  years_as_manager_of_other_managers:
                                    e.target.value,
                                  top_management_skill:
                                    e.target.value > 0 ? true : false,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="experiance">
                          Experiance <span className="text-danger">*</span>
                        </label>
                        <div className="d-flex">
                          <input
                            required
                            className="form-control mr-2"
                            placeholder="Experiance in years"
                            id="experiance"
                            type="number"
                            value={exper}
                            onChange={(e) => setExper(e.target.value)}
                          />
                          {/* <select
                          className="form-control"
                          placeholder="Experiance"
                          id="experiance"
                          value={expType}
                          onChange={(e) => setExpType(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="year">Year</option>
                          <option value="month">Month</option>
                        </select> */}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="salary">Salary (In USD)</label>
                        <div className="d-flex">
                          <input
                            className="form-control mr-2"
                            placeholder="Min Salary"
                            id="salary"
                            type="number"
                            value={minSal}
                            onChange={(e) => setMinSal(e.target.value)}
                          />
                          <input
                            className="form-control"
                            placeholder="Max Salary"
                            id="salary"
                            type="number"
                            value={maxSal}
                            onChange={(e) => setMaxSal(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="description">
                          How did you hear about LinkedIn jobs?{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          required
                          className="form-control"
                          value={data?.known_from}
                          onChange={(e) =>
                            setData({ ...data, known_from: e.target.value })
                          }
                        >
                          <option value="">Select Method</option>
                          <option value="in_the_mail">In the mail</option>
                          <option value="podcast">Podcast</option>
                          <option value="ad_on_linked_in">
                            Ad on LinkedIn.com
                          </option>
                          <option value="online_ad/search-engine">
                            Online ad / Search engine
                          </option>
                          <option value="streaming_audio">
                            Streaming Audio (Spotify/Pandora/iHeart/Deezer)
                          </option>
                          <option value="other">Other</option>
                        </select>
                      </div>

                      <hr />

                      <div className="d-sm-flex d-block justify-content-between ">
                        <div className="text-left mb-2 mb-sm-0">
                          {/* <PreviewJobPost /> */}
                        </div>

                        <div className="text-right">
                          <Link
                            to="/job-post"
                            className="btn btn-outline-secondary border-radius-50 px-4 w-sm-100 py-2 border-width fw-500 mr-2 mb-2 mb-sm-0"
                          >
                            Back
                          </Link>
                          <button
                            type="submit"
                            className="btn blue-btn ml-sm-2 ml-0 fw-500 w-sm-100"
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const SingleRow = ({
  row,
  index,
  handleRemoveRow,
  expSkills,
  setExpSkills,
  flag,
}) => {
  const [state, setState] = useState({});
  const [expSavedSkills, setExpSavedSkills] = useState([]);

  useEffect(() => {
    setState(row);
    if (row?.skill) {
      setExpSavedSkills([row?.skill]);
    }
  }, [flag]);

  useEffect(() => {
    if (expSavedSkills.length > 0) {
      setState({ ...state, skill: expSavedSkills[0] });
    }
  }, [expSavedSkills]);

  useEffect(() => {
    const updatedVariations = expSkills.map((v, i) =>
      i === index ? state : v
    );
    setExpSkills(updatedVariations);
  }, [state, index, setExpSkills]);

  return (
    <>
      <div className="d-flex mb-2 justify-content-around align-items-center">
        <div>
          <InputTag
            marginTop="mt-0"
            paddingTop="pt-0"
            marginRight="mr-2"
            single={true}
            title="Add Skill"
            width="120px"
            savedSkills={expSavedSkills}
            setSavedSkills={setExpSavedSkills}
          />
        </div>

        {/* <input
        className="form-control mr-2"
        placeholder={`skill ${index + 1}`}
        id="experiance"
        type="text"
        // value={exper}
        // onChange={(e) => setExper(e.target.value)}
      /> */}
        <input
          className="form-control mr-2"
          placeholder="experience"
          id="experiance"
          type="number"
          value={state?.experience}
          onChange={(e) => setState({ ...state, experience: e.target.value })}
        />
        <select
          className="form-control"
          placeholder="Experiance"
          id="experiance"
          value={state?.type}
          onChange={(e) => setState({ ...state, type: e.target.value })}
        >
          <option value="">Select duration</option>
          <option value="year">Year</option>
          <option value="month">Month</option>
        </select>
        {expSkills?.length > 1 && (
          <button
            className="btn btn-danger ml-1"
            onClick={(e) => handleRemoveRow(e, index)}
          >
            -
          </button>
        )}
      </div>
    </>
  );
};

export default JobPostSummary;
