import React, { useRef, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import "./Modal.css";
import bannerImage from "../.../../../../../../assets/bannerbg.webp";
import authApi from "../../../../../utils/authApi";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../../Redux/userSlice";
import { toast } from "react-toastify";
import { IMAGEURL } from "../../../../../constant";
import { MdOutlineModeEditOutline } from "react-icons/md";

const EditBanner = () => {
  const [showEditBanner, setShowEditBanner] = useState(false);
  const [saving, setSaving] = useState(false);

  const authId = useSelector((state) => state?.auth?.user?.info?.id); //user id
  const profile = useSelector((state) => state.user.profile); //profile of user from redux
  const [pic, setPic] = useState("");
  const handleShowEditBannerOpen = () => {
    setShowEditBanner(true);
  };
  const dispatch = useDispatch();
  const handleShowEditBannerClose = () => {
    setShowEditBanner(false);
  };

  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setPic(file);
    setSelectedImage(URL.createObjectURL(file));
  };

  //function to save uploaded photo
  const uploadProfilePhoto = async (e) => {
    setSaving(true);
    e.preventDefault();
    const myFormData = new FormData();
    myFormData.append("banner", pic);
    try {
      await authApi.post(`users/${authId}?_method=PUT`, myFormData);
      setShowEditBanner(false);
      setPic("");
      setSelectedImage(null);
      dispatch(updateUser());
      setSaving(false);
      toast.success("Profile updated successfully.");
    } catch (error) {
      setSaving(false);
    }
  };
  return (
    <>
      <Link to="#" onClick={handleShowEditBannerOpen}>
      <MdOutlineModeEditOutline />
      </Link>

      {showEditBanner && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add background photo</h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleShowEditBannerClose}
                >
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body">
                  <div className="form-container ">
                    {selectedImage && (
                      <div className="banner-img">
                        <img
                          src={selectedImage}
                          alt="Preview"
                          className="img-fluid"
                          style={{ maxHeight: "100%" }}
                        />
                      </div>
                    )}

                    {!selectedImage && (
                      <div className="banner-box">
                        <img
                          src={profile?.banner?`${IMAGEURL}${profile.banner}`:profile?.logo?`${IMAGEURL}${profile.logo}`:bannerImage}
                          className="img-fluid"
                          alt="banner"
                          style={{borderRadius:"4px"}}
                        />
                     
                      </div>
                    )}

                    <input
                      type="file"
                      ref={fileInputRef}
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <div className="modal-footer  text-right">
                  <Link
                    className="btn  btn-blue-outline"
                    onClick={() => {
                      if(selectedImage){
                        setShowEditBanner(false);
                        setPic("");
                        setSelectedImage(null);
                      }else{
                        handleButtonClick();

                      }
                    }}
                  >
                    {selectedImage?"Cancel":"Upload image"}
                  </Link>

                  {selectedImage && (
                    <button
                      type="submit"
                      className="btn blue-btn mr-2 py-2"
                      onClick={(e) => uploadProfilePhoto(e)}
                      disabled={saving}
                    >
                      {saving ? "Saving" : "Save"}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditBanner;
