// import React, { useEffect, useState } from "react";
// import { BiPlus, BiCheck } from "react-icons/bi";
// import CustomQuestions from "./CustomQuestions";
// import AddScreeningQuestion from "./AddScreeningQuestion";

// const getQuestion = (screeningQuestions, tag) => {
//   const res = screeningQuestions?.filter((elem) => elem?.tag_name === tag)[0];
//   res.answer = "";
//   res.min_value = "";
//   res.recommended = 1;
//   return res;
// };

// const ScreeningTags = ({
//   screeningQuestions,
//   customQuestions,
//   setCustomQuestions,
//   questions,
//   setQuestions,
// }) => {
//   const [selectedTags, setSelectedTags] = useState([]);

//   const handleTagClick = (tag) => {
//     if (selectedTags.includes(tag)) {
//       setSelectedTags(selectedTags.filter((t) => t !== tag));
//     } else {
//       setSelectedTags([...selectedTags, tag]);
//     }
//   };

//   const handleRemoveCard = (tag) => {
//     setSelectedTags(selectedTags.filter((t) => t !== tag));
//   };

//   //function to get question based on tag

//   //custom qsn click
//   const handleCustomClick = () => {
//     if (customQuestions?.length > 0) {
//       setCustomQuestions([
//         ...customQuestions,
//         {
//           question: "",
//           min_value: "",
//           answer: "",
//           recommended: 1,
//         },
//       ]);
//     } else {
//       setCustomQuestions([
//         {
//           question: "",
//           min_value: "",
//           answer: "",
//           recommended: 1,
//         },
//       ]);
//     }
//   };

//   const go = () => {


//     if (selectedTags?.length > 0) {

//       selectedTags?.forEach((ele, index) => {
//         const filter = questions?.filter((el) => el.tag_name === ele);
//         if (filter?.length === 0) {
//           if (questions?.length > 0) {
//             setQuestions([...questions, getQuestion(screeningQuestions, ele)]);
//           } else {
//             setQuestions([getQuestion(screeningQuestions, ele)]);
//           }
//         }
//       }); 


//     } else {


//       setQuestions([]);


//     }  
//   };
//   useEffect(() => {
//     go();
//   }, [selectedTags]);  

//   // useEffect(() => {
//   //   const go = () => {
//   //     if (selectedTags?.length > 0) {
//   //       selectedTags?.forEach((ele, index) => {
//   //         const filter = questions?.filter((el) => el.tag_name === ele);
//   //         if (filter?.length === 0) {
//   //           setQuestions((prevQuestions) => [
//   //             ...prevQuestions,
//   //             getQuestion(screeningQuestions, ele)
//   //           ]);
//   //         }
//   //       }); 
//   //     } else {
//   //       setQuestions([]);
//   //     }
//   //   };
//   //   go();
//   // }, [selectedTags]);

//   // const [a,setA]=useState(1)
//   // useEffect(()=>{
//   //   setA(a+1)
//   // },[a])
  
//  console.log("Infinity going---------------**")
//   return (
//     <div className="screening-block">
//       <div className="screening-list d-flex flex-wrap justify-content-start align-items-center mb-3">
//         {screeningQuestions?.map((tag, index) => (
//           <div className="screening-tag" key={index}>
//             <button
//               className={`btn ${
//                 selectedTags.includes(tag.tag_name) ? "disabled" : ""
//               }`}
//               onClick={() => handleTagClick(tag.tag_name)}
//               disabled={selectedTags.includes(tag.tag_name)}
//             >
//               {selectedTags.includes(tag.tag_name) ? (
//                 <>
//                   <BiCheck className="mr-1 fa-20" />
//                   <span>{tag.tag_name}</span>
//                 </>
//               ) : (
//                 <>
//                   <BiPlus className="mr-1 fa-20" />
//                   <span>{tag.tag_name}</span>
//                 </>
//               )}
//             </button>
//           </div>
//         ))}

//         <div className="screening-tag">
//           <button className={`btn`} onClick={() => handleCustomClick()}>
//             <>
//               <BiPlus className="mr-1 fa-20" />
//               <span>Custom Question</span>
//             </>
//           </button>
//         </div>
//       </div>

//       {customQuestions?.length > 0 &&
//         customQuestions?.map((row, index) => (
//           <CustomQuestions
//             row={row}
//             customQuestions={customQuestions}
//             setCustomQuestions={setCustomQuestions}
//             index={index}
//           />
//         ))}
//       {questions?.length > 0 &&
//         questions?.map((row, index) => (
//           <AddScreeningQuestion
//             row={row}
//             questions={questions}
//             setQuestions={setQuestions}
//             index={index}
//             handleRemoveCard={handleRemoveCard}
//           />
//         ))}
//     </div>
//   );
// };

// export default ScreeningTags;

// import React, { useEffect, useState } from "react";
// import { BiPlus, BiCheck } from "react-icons/bi";
// import CustomQuestions from "./CustomQuestions";
// import AddScreeningQuestion from "./AddScreeningQuestion";

// const getQuestion = (screeningQuestions, tag) => {
//   const res = screeningQuestions.find((elem) => elem.tag_name === tag);
//   return {
//     ...res,
//     answer: "",
//     min_value: "",
//     recommended: 1,
//   };
// };

// const ScreeningTags = ({
//   screeningQuestions,
//   customQuestions,
//   setCustomQuestions,
//   questions,
//   setQuestions,
// }) => {
//   const [selectedTags, setSelectedTags] = useState([]);

//   const handleTagClick = (tag) => {
//     if (selectedTags.includes(tag)) {
//       setSelectedTags(selectedTags.filter((t) => t !== tag));
//     } else {
//       setSelectedTags([...selectedTags, tag]);
//     }
//   };

//   const handleRemoveCard = (tag) => {
//     setSelectedTags(selectedTags.filter((t) => t !== tag));
//   };

//   const handleCustomClick = () => {
//     setCustomQuestions((prevCustomQuestions) => [
//       ...prevCustomQuestions,
//       {
//         question: "",
//         min_value: "",
//         answer: "",
//         recommended: 1,
//       },
//     ]);
//   };

//   useEffect(() => {
//     if (selectedTags?.length > 0) {
//     selectedTags.forEach((ele) => {
//       if (!questions.some((el) => el.tag_name === ele)) {
//         setQuestions((prevQuestions) => [
//           ...prevQuestions,
//           getQuestion(screeningQuestions, ele),
//         ]);
//       }
//     });}else{
//       setQuestions([])
//     }
//   }, [selectedTags, questions, screeningQuestions, setQuestions]);



//   return (
//     <div className="screening-block">
//       <div className="screening-list d-flex flex-wrap justify-content-start align-items-center mb-3">
//         {screeningQuestions.map((tag, index) => (
//           <div className="screening-tag" key={index}>
//             <button
//               className={`btn ${selectedTags.includes(tag.tag_name) ? "disabled" : ""}`}
//               onClick={() => handleTagClick(tag.tag_name)}
//               disabled={selectedTags.includes(tag.tag_name)}
//             >
//               {selectedTags.includes(tag.tag_name) ? (
//                 <>
//                   <BiCheck className="mr-1 fa-20" />
//                   <span>{tag.tag_name}</span>
//                 </>
//               ) : (
//                 <>
//                   <BiPlus className="mr-1 fa-20" />
//                   <span>{tag.tag_name}</span>
//                 </>
//               )}
//             </button>
//           </div>
//         ))}

//         <div className="screening-tag">
//           <button className="btn" onClick={handleCustomClick}>
//             <>
//               <BiPlus className="mr-1 fa-20" />
//               <span>Custom Question</span>
//             </>
//           </button>
//         </div>
//       </div>

//       {customQuestions.length > 0 &&
//         customQuestions.map((row, index) => (
//           <CustomQuestions
//             key={index}
//             row={row}
//             customQuestions={customQuestions}
//             setCustomQuestions={setCustomQuestions}
//             index={index}
//           />
//         ))}

//       {questions.length > 0 &&
//         questions.map((row, index) => (
//           <AddScreeningQuestion
//             key={index}
//             row={row}
//             questions={questions}
//             setQuestions={setQuestions}
//             index={index}
//             handleRemoveCard={handleRemoveCard}
//           />
//         ))}
//     </div>
//   );
// };

// export default ScreeningTags;

import React, { useEffect, useState } from "react";
import { BiPlus, BiCheck } from "react-icons/bi";
import CustomQuestions from "./CustomQuestions";
import AddScreeningQuestion from "./AddScreeningQuestion";

const getQuestion = (screeningQuestions, tag) => {
  const question = screeningQuestions.find((elem) => elem.tag_name === tag);
  return {
    ...question,
    answer: "",
    min_value: "",
    recommended: 1,
  };
};

const ScreeningTags = ({
  screeningQuestions,
  customQuestions,
  setCustomQuestions,
  questions,
  setQuestions,
}) => {
  const [selectedTags, setSelectedTags] = useState([]);

  const toggleTag = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const addCustomQuestion = () => {
    const newCustomQuestion = {
      question: "",
      min_value: "",
      field_type:'',
      answer: "",
      recommended: 1,
    };
    setCustomQuestions([...customQuestions, newCustomQuestion]);
  };

useEffect(()=>{
  if(selectedTags.length===0){
    setQuestions([])
  }
},[setQuestions,selectedTags])



  useEffect(() => {

    selectedTags.forEach((tag) => {
      if (!questions.some((question) => question.tag_name === tag)) {
        const newQuestion = getQuestion(screeningQuestions, tag);
        setQuestions([...questions, newQuestion]);
      }
    });


  }, [selectedTags, questions, screeningQuestions, setQuestions]);



  return (
    <div className="screening-block">
      <div className="screening-list d-flex flex-wrap justify-content-start align-items-center mb-3">
        {screeningQuestions.map((tag, index) => (
          <div className="screening-tag" key={index}>
            <button
              className={`btn ${selectedTags.includes(tag.tag_name) ? "disabled" : ""}`}
              onClick={() => toggleTag(tag.tag_name)}
              disabled={selectedTags.includes(tag.tag_name)}
            >
              {selectedTags.includes(tag.tag_name) ? (
                <>
                  <BiCheck className="mr-1 fa-20" />
                  <span>{tag.tag_name}</span>
                </>
              ) : (
                <>
                  <BiPlus className="mr-1 fa-20" />
                  <span>{tag.tag_name}</span>
                </>
              )}
            </button>
          </div>
        ))}

        <div className="screening-tag">
          <button className="btn" onClick={addCustomQuestion}>
            <>
              <BiPlus className="mr-1 fa-20" />
              <span>Custom Question</span>
            </>
          </button>
        </div>
      </div>

      {customQuestions.map((row, index) => (
        <CustomQuestions
          key={index}
          row={row}
          customQuestions={customQuestions}
          setCustomQuestions={setCustomQuestions}
          index={index}
        />
      ))}

      {questions.map((row, index) => (
        <AddScreeningQuestion
          key={index}
          row={row}
          questions={questions}
          setQuestions={setQuestions}
          index={index}
          handleRemoveCard={() => setSelectedTags(selectedTags.filter((tag) => tag !== row.tag_name))}
        />
      ))}
    </div>
  );
};

export default ScreeningTags