import React from "react";
//import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IMAGEURL } from "../../../../constant";
import { useSelector } from "react-redux";
import JobApply from "../JobSearchResult/JobApply";
//import Review from "../../../CommonComponents/review/Review";
import ReviewNew from "../../../CommonComponents/review/RatingModal";

const ViewJobPostCard = ({ job, appliedJobs,getAppliedJobs }) => {

  const loginAs = useSelector((state) => state.auth.loginAs);

  //to find time gap
  function getTimeDifferenceString(timeString) {
    const currentTime = new Date();
    const pastTime = new Date(timeString);
    const timeDifference = currentTime - pastTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 30) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    }
  }
  return (
    <>
      <div className="card profile-bg border-0">
        <div className="card-body view-job-post-card">
          <div class="d-flex view-card-img">
            <div class="job-view-image mr-4">
              <img
                src={
                  job?.company?.logo
                    ? `${IMAGEURL}${job?.company?.logo}`
                    : "/static/media/dummy.7c118d410059de3c522d.jpg"
                }
                alt="Title"
                class="j-view-image"
              />
            </div>

            <div class="view-job-post-card-content">
              <h4>{job?.title ?? "-"}</h4>
              <p>{job?.company?.name ?? "-"}</p>
              <p>
                {job?.job_type?.type ?? "-"} •{" "}
                {job?.employment_type?.title ?? "-"}
              </p>
              <p>Created {getTimeDifferenceString(job.created_at)}</p>
            </div>
          </div>

          <div className="float-right d-flex view-card-btn">
            <div className="job-btn">
              {loginAs === "Recruiter" && (
                <Link
                  to={`/job-view/${job?.id}`}
                  className="btn btn-blue-outline fa-mmd-14 mr-2"
                >
                  View applicants
                </Link>
              )}
              {loginAs === "Candidate" &&  (
                <JobApply getAppliedJobs={getAppliedJobs} appliedJobs={appliedJobs??[]} item={job} />
              )}

              {/* {appliedJobs?.includes(job?.id) && loginAs === "Candidate" && (
                <JobApply appliedJobs={appliedJobs} item={job} />
              )} */}

              {/* <Link className="">
                <ReviewNew productId={job?.id}   />
              </Link> */}
            </div>
            {/* <div className="three-dot three-ml">
              <Link to="#">
                {" "}
                <BsThreeDots className="gray fa-24 fa-mdd-20" />
              </Link>
            </div> */}

          </div>
        </div>
      </div>
    </>
  );
};

export default ViewJobPostCard;
