import React from "react";
import JobpostInfo from "./JobpostInfo";
import JobPostSettings from "./JobPostSettings";
import ViewScreeningquestion from "./ViewScreeningquestion";
import { useSelector } from "react-redux";

const ViewJobPostDescription = ({ job }) => {
  const loginAs = useSelector((state) => state.auth.loginAs);

  return (
    <>
      <div className="job-info-tab">
        <ul className="nav nav-pills">
          <li className="job-info-custme-item">
            <a
              className="job-info-custme-link active"
              data-toggle="pill"
              href="#jobinfo"
            >
              Job Info
            </a>
          </li>

          {loginAs === "Recruiter" && false && (
            <li className="job-info-custme-item">
              <a
                className="job-info-custme-link"
                data-toggle="pill"
                href="#setting"
              >
                Settings
              </a>
            </li>
          )}
        </ul>
      </div>

      <div class="tab-content mt-3">
        <div class="tab-pane active" id="jobinfo">
          <JobpostInfo job={job} />
          <ViewScreeningquestion job={job} />
        </div>

        {loginAs === "Recruiter" && (
          <div class="tab-pane" id="setting">
            <JobPostSettings />
          </div>
        )}
      </div>
    </>
  );
};

export default ViewJobPostDescription;
