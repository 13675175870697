import React from 'react';
import {BiPlusCircle} from 'react-icons/bi';
import {Link} from 'react-router-dom';
import JobPostTable from './JobPostTable';



const JobPostList = ({allJobs,page,setPage,allPages,totalItem,getAllJobPosts}) => {
  return (
    <>

    <div className='d-sm-flex d-block  justify-content-between'>
        <div>
            <h4 className='fa-18 fa-mdd-14 black fw-400'>You have <b>{totalItem} Records</b></h4>
        </div>
        <div className='mt-sm-0 mt-2'>
           <Link to="/job-post" className="btn blue-btn fw-400 fa-16 fa-mdd-14 rounded-lg"><BiPlusCircle className='fa-24 mr-1'/> Post Your  Job</Link> 
        </div>
    </div>

    <div className='tracker-list mt-4'>
       <JobPostTable allJobs={allJobs} getAllJobPosts={getAllJobPosts} page={page} setPage={setPage} allPages={allPages} />
    </div>

      
    </>
  )
}

export default JobPostList

