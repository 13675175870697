import React, { useEffect, useState } from "react";
import BrowserByCard from "./BrowserByCardSlide";
import "./help.css";
import HelpQuestionCard from "./HelpQuestionCard";
import HelpSearch from "./HelpSearch";
import authApi from "../../../../utils/authApi";
import { DotSpinner } from "@uiball/loaders";
import Loader from "../../../CommonComponents/Loader/Loader";

const Help = () => {
  const [allFaqs, setAllFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAllFaqs = async () => {
    try {
      const res = await authApi.get(`get-all-faq`);
      setAllFaqs(res?.data?.faq);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllFaqs();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center mt-5">
          {" "}
          <Loader />
        </div>
      )}
      {!isLoading && (
        <section>
          <div className="fluid-container">
            <div>
              <div className="">
                <div className="card-body p-0">
                  <div className="help-center">
                    <div className="help-search">
                      <h3 className="pb-2 fa-24 fa-sm-20 light-black fw-500 text-center">
                        Hi, how can we help you?
                      </h3>
                      <HelpSearch allFaqs={allFaqs} />
                    </div>
                  </div>

                  <div className="help-question-conatiner">
                    <div className="help-question text-center py-3">
                      <h3 className="fa-24 fa-sm-20 fw-500">QUICK SOLUTION</h3>
                      <p className="fa-16 mb-0 fw-400">
                        as per the recent search trend
                      </p>

                      <div className="mt-3">
                        <HelpQuestionCard allFaqs={allFaqs} />
                      </div>
                    </div>
                  </div>

                  <div className="brwoser-by-container py-4 side-space">
                    <h3 className="fa-24 fa-sm-20 fw-500 text-center mb-0">
                      BROWSE BY TOPIC
                    </h3>

                    <BrowserByCard allFaqs={allFaqs} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Help;
