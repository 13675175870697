import React, { useState, useEffect, useRef } from "react";
import "./DropdownFilter.css";
import { AiFillCaretDown } from "react-icons/ai";

const DropdownFilter = ({
  title,
  name,
  options,
  onChange,
  className,
  marginclass,
  multiple,
  label,
  loading,
  searchable,
  query,
  setQuery,
  value
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(()=>{
    if(value?.length>0){
      setSelectedOptions(value)
    }
  },[value])
  
  const handleOptionToggle = (option) => {
    let updatedOptions;
    let updateValues;
    if (selectedOptions.includes(option?.value)) {
      updatedOptions = selectedOptions.filter((ele) => ele !== option?.value);
      updateValues = selectedValues?.filter(
        (ele) => ele?.value !== option?.value
      );
    } else {
      if (multiple) {
        updatedOptions = [...selectedOptions, option?.value];
        updateValues = [...selectedValues, option];
      } else {
        updatedOptions = [option?.value];
      }
    }
    setSelectedValues(updateValues);
    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleResetValue = () => {
    setSelectedValues([]);
    setSelectedOptions([]);
    onChange([]);
  };

  useEffect(() => {
    if (isDropdownOpen && searchable) {
      setQuery("");
    }
  }, [isDropdownOpen]);



  return (
    <>
      <div ref={dropdownRef} className="dropdown ">
        <p className="dropdown-title mb-1 pl-2">{title} </p>
        <button
          type="button"
          className={`btn btn-mute gray fw-400 rounded-30 dropdown-toggle ${className}`}
          onClick={handleDropdownToggle}
          style={{ backgroundColor: label > 0 && "#CBE5FF" }}
        >
          {multiple
            ? name
            : label > 0
            ? options?.filter((ele) => ele?.value === selectedOptions[0])[0]
                ?.label
            : name}
          {label > 0 && multiple && <div className="label"> {label}</div>}
          <AiFillCaretDown className="ml-2" />
        </button>
        {isDropdownOpen && (
          <>
            <div className={`dropdown-custome show mt-2 ${marginclass}`}>
              {searchable && (
                <div className="dropdown-custome-scrollDiv">
                  <label className="dropdown-custome-item mb-2">
                    <input
                      type="text"
                      value={query}
                      autoFocus
                      onChange={(e) => setQuery(e.target.value)}
                      className="form-control"
                      placeholder="search..."
                    />
                  </label>
                </div>
              )}
              {query === "" && multiple && (
                <div className="dropdown-custome-scrollDiv">
                  {selectedValues.map((option, index) => (
                    <label
                      className="dropdown-custome-item text-justify"
                      key={index}
                    >
                      <input
                        type="checkbox"
                        className={"checkbox-input"}
                        checked={selectedOptions.includes(option.value)}
                        onChange={() => handleOptionToggle(option)}
                      />
                      <span className={"custom-checkbox-style"}>
                        {selectedOptions.includes(option.value) &&
                          (multiple ? (
                            <span className="checkmark-style"> &#10003; </span>
                          ) : (
                            <span className="custom-radio"></span>
                          ))}
                      </span>
                      {option.label}
                    </label>
                  ))}
                </div>
              )}

              {!loading && (searchable?query:true)  && (
                <div className="dropdown-custome-scrollDiv">
                  {options.map((option) => (
                    <label
                      className="dropdown-custome-item text-justify"
                      key={option.value}
                    >
                      <input
                        type="checkbox"
                        className={"checkbox-input"}
                        checked={selectedOptions.includes(option.value)}
                        onChange={() => handleOptionToggle(option)}
                      />
                      <span
                        className={
                          multiple
                            ? "custom-checkbox-style"
                            : "custom-radio-style"
                        }
                      >
                        {selectedOptions.includes(option.value) &&
                          (multiple ? (
                            <span className="checkmark-style"> &#10003; </span>
                          ) : (
                            <span className="custom-radio"></span>
                          ))}
                      </span>
                      {option.label}
                    </label>
                  ))}
                </div>
              )}

              {loading && (
                <div className="d-flex justify-content-center">
                  <div class="spinner-border text-muted "></div>
                </div>
              )}
              {label > 0 && (
                <div>
                  <hr />
                  <div>
                    <button
                      className="reset-btn"
                      onClick={() => handleResetValue()}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DropdownFilter;
