import React, { useEffect } from "react";
import DropdownFilter from "../../../CommonComponents/DropdownFilter";
import "./search-condidate.css";

const Education = [
  { label: "B.Tech", value: "B.Tech" },
  { label: "M.Tech", value: "M.Tech" },
  { label: "B.A", value: "B.A" },
  { label: "B.Sc", value: "B.Sc" },
  { label: "B.Com", value: "B.Com" },
  { label: "B.S", value: "B.S" },
  { label: "B.E", value: "B.E" },
  { label: "MBA", value: "MBA" },
  { label: "M.A", value: "M.A" },
  { label: "M.Sc", value: "M.Sc" },
  { label: "M.Com", value: "M.Com" },
  { label: "M.S", value: "M.S" },
  { label: "M.E", value: "M.E" },
  { label: "Ph.D", value: "Ph.D" },
  { label: "High School Diploma", value: "High School Diploma" },
  { label: "GED", value: "GED" },
  { label: "Associate's Degree", value: "Associate's Degree" },
  { label: "Certificate Program", value: "Certificate Program" },
  { label: "Diploma", value: "Diploma" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Bachelor of Business Administration (BBA)", value: "BBA" },
  { label: "Other", value: "Other" },
];

const Experience = [
  { label: "All", value: "All" },
  { label: "1 Year", value: "1 year" },
  { label: "2 Years", value: "2 year" },
  { label: "3 Years", value: "3 year" },
  { label: "4 Years", value: "4 year" },
  { label: "5 Years", value: "5 year" },
  { label: "6 Years", value: "6 year" },
  { label: "7 Years", value: "7 year" },
  { label: "8 Years", value: "8 year" },
  { label: "9 Years", value: "9 year" },
  { label: "10 Years", value: "10 year" },
];

const SkilledIn = [
  { label: "All", value: "All" },
  { label: "Remote", value: "Remote" },
  { label: "On-site", value: "On-site" },
  { label: "Hybrid", value: "hybrid" },
];


const Candidatesearchfillter = ({
  fetchCandidates,
  allCandidates,
  searching,
  setSkills,
  setLocations,
  setCtc,
  setEdu,
  setExp,
  edu,
  exp,
  ctc,
  skills,
  locations,
  searchQuery,
  setSearchQuery,
  locationsLoading,
  setLocation,
  location,
  selectedSkills,
  setSelectedSkills,
  skillsLoading,
  searchSkill,
  setSearchSkill,
}) => {
  const handleFilterChange = (id, selectedOptions) => {
    if (id === "Relevance") {
      setEdu(selectedOptions);
    }
    if (id === "Experience") {
      setExp(selectedOptions);
    }
    if (id === "SkilledIn") {
      setSelectedSkills(selectedOptions);
    }
    if (id === "Location") {
      setLocation(selectedOptions);
    }
  };

  const Location=locations?.map((ele)=>({label:`${ele?.name}, ${ele?.country?.name}`,value:ele?.id}));
  const Skills=skills?.map((ele)=>({label:ele?.title,value:ele?.id}));
// useEffect(()=>{
//   if(searchSkill===''){
//     setSkills([])
//   }
// },[searchSkill])
  const handleSearch = (e) => {   
    e.preventDefault();
    fetchCandidates();
  };

  return (
    <>
      <div className="border-1 border-radius-14 p-4">
        <h3 className="job-card-title fa-20 mb-3">Search Candidate</h3>
        <div className="">
          <form>
            <div className="search-filter">
              <div className="search-item">
                <DropdownFilter
                  marginclass="mt-4"
                  title="Education"
                  className="min-width-150 bg-light-gray-1"
                  options={Education}
                  multiple={false}
                  onChange={(selectedOptions) =>
                    handleFilterChange("Relevance", selectedOptions)
                  }
                  name="All"
                  label={edu?.length}
                />
              </div>

              <div className="search-item">
                <DropdownFilter
                  marginclass="mt-4"
                  title="Yrs of Experience"
                  className="min-width-150 bg-light-gray-1"
                  options={Experience}
                  multiple={false}
                  onChange={(selectedOptions) =>
                    handleFilterChange("Experience", selectedOptions)
                  }
                  name="All"
                  label={exp?.length}
                />
              </div>

              <div className="search-item">
                <DropdownFilter
                  marginclass="mt-4"
                  title="Skill"
                  className="min-width-150 bg-light-gray-1"
                  options={Skills}
                  onChange={(selectedOptions) =>
                    handleFilterChange("SkilledIn", selectedOptions)
                  }
                  name="All"
                  multiple={true}
                  searchable
                  setQuery={setSearchSkill}
                  query={searchSkill}
                  loading={skillsLoading}
                  label={selectedSkills.length}
                />
              </div>

              <div className="search-item">
                <DropdownFilter
                  marginclass="mt-4"
                  title="Location"
                  className="min-width-150 bg-light-gray-1"
                  options={Location}
                  onChange={(selectedOptions) =>
                    handleFilterChange("Location", selectedOptions)
                  }
                  name="All"
                  searchable
                  setQuery={setSearchQuery}
                  query={searchQuery}
                  loading={locationsLoading}
                  label={location?.length}
                />
              </div>

            
            </div>

            <div className="search-item text-sm-left text-center mt-3">
              <button
                onClick={(e) => handleSearch(e)}
                className="btn btn-gary fw-500 fa-16 py-2 gray"
                style={{ width: "fit-content" }}
                disabled={searching}
              >
                {searching ? "Searching" : "Search"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="mt-3  d-flex justify-content-between fillter-desk">
        <h3 className="card-title">
        
        </h3>
        <p className="fa-16 fw-00 light-black mb-0">
          Search result <b> {allCandidates?.length} records</b>
        </p>
      </div>
    </>
  );
};

export default Candidatesearchfillter;
