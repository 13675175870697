import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/Style.css";
import "./assets/ColorPalette.css";

import Web from "./components/Layout/Web";
import Home from "./components/Webpage/Homepage/Home";
import Profile from "./components/Webpage/UserPanel/Profile/Profile";
import Login from "./components/Webpage/Login/Login";
import Dashboard from "./components/Webpage/UserPanel/Dashboard/Dashboard";
import PostYourJob from "./components/Webpage/PostYourJob/JobPost";
import JobPostSummary from "./components/Webpage/PostYourJob/JobPostSummary";
import RecommendedJob from "./components/Webpage/UserPanel/RecommendedJob/MyRecommendedJob";
import JobSearchResult from "./components/Webpage/UserPanel/JobSearchResult/JobSearchResult";
import MyJob from "./components/Webpage/UserPanel/MyJob/MyJob";
import AnalyticsProfileTips from "./components/Webpage/UserPanel/Analytics&ProfileTips/Analytics&ProfileTips";
import CompanyEdit from "./components/Webpage/UserPanel/Company/CompanyEdit";
import ManageJobPost from "./components/Webpage/UserPanel/MangeJobPost/ManageJobPost";
import ManageJobView from "./components/Webpage/UserPanel/MangeJobPost/ManageJobView";
import ViewJobPost from "./components/Webpage/UserPanel/MangeJobPost/ViewJobPost";
import ReceiveQualifiedApplicants from "./components/Webpage/PostYourJob/ReceiveQualifiedApplicants";
import SearchCandidate from "./components/Webpage/UserPanel/Candidate/SearchCandidate";
import SavedResume from "./components/Webpage/UserPanel/SavedResume/SavedResume";
import PurchaseHistory from "./components/Webpage/UserPanel/PurchaseHistory/PurchaseHistory";
import Packages from "./components/Webpage/UserPanel/Packages/Packages";
import ComapnyProfileView from "./components/Webpage/UserPanel/Company/ComapnyProfile";
import Notification from "./components/Webpage/UserPanel/Notification/Notofication";
import ViewApplicant from "./components/Webpage/UserPanel/MangeJobPost/ViewApplicant";
import Message from "./components/Webpage/UserPanel/Message/Message";
import PrivacyPolicy from "./components/Webpage/Pages/PrivacyPolicy";
import Help from "./components/Webpage/UserPanel/Settings/Help";
import HelpQATabData from "./components/Webpage/UserPanel/Settings/HelpQATabData";
import Invoice from "./components/Webpage/UserPanel/PurchaseHistory/Invoice/Invoice";
import Callback from "./components/Webpage/Login/Callback";
import { useDispatch, useSelector } from "react-redux";
import EditSkills from "./components/Webpage/UserPanel/Profile/EditPages/EditSkills";
import EditExperience from "./components/Webpage/UserPanel/Profile/EditPages/EditExperience";
import EditProjects from "./components/Webpage/UserPanel/Profile/EditPages/EditProjects";
import EditAchievements from "./components/Webpage/UserPanel/Profile/EditPages/EditAchievements";
import EditCertificates from "./components/Webpage/UserPanel/Profile/EditPages/EditCertificates";
import EditEducation from "./components/Webpage/UserPanel/Profile/EditPages/EditEducation";
import Company from "./components/Webpage/UserPanel/Company/Company";
import { useEffect } from "react";
import { saveProfile } from "./Redux/userSlice";
import SearchedJobs from "./components/Webpage/Homepage/SearchedJobs/SearchedJobs";
import PageNotFound from "./components/Webpage/Pages/PageNotFound/PageNotFound";
import AboutUs from "./components/Webpage/UserPanel/Settings/AboutUs";
import TermsAndConditions from "./components/Webpage/UserPanel/Settings/PolicyPages/TermsAndConditions";
import AllJobs from "./components/Webpage/UserPanel/AllJobs/AllJobs";
import ViewCandidate from "./components/Webpage/Homepage/AllCandidates/ViewCandidate/ViewCandidate";
import AllCandidates from "./components/Webpage/Homepage/AllCandidates/AllCandidates";
import MyCandidates from "./components/Webpage/UserPanel/RecommendedCandidates/MyRecommendedJob";

const App = () => {
  const isAuth = useSelector((state) => state.auth.isLoggedIn); //constant for checking user is authenticated or not
  const loginAs = useSelector((state) => state.auth.loginAs);
  
  const dispatch = useDispatch();
  // Define a custom route guard function to protect routes
  const ProtectedRoute = ({ element, path }) => {
    if (isAuth) {
      return element;
    } else {
      // If not authenticated, redirect to the login page
      return <Navigate to="/login" />;
    }
  };

  useEffect(() => {
    if (!isAuth) {
      dispatch(saveProfile({}));
    }
  }, [isAuth, dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Web />}>
          <Route index element={<Home />} />
          {/* <Route path="/profile" element={<Profile />} /> */}
          <Route
            path="/profile"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Candidate" ? <Profile /> : <PageNotFound />
                }
              />
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/auth/:provider/callback" element={<Callback />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute element={<Dashboard />} />}
          />
          <Route path="/search" element={<SearchedJobs />} />
          <Route path="/all-jobs/:query" element={<AllJobs />} />
          <Route path="/all-candidates/:query" element={<AllCandidates />} />
          <Route
            path="/all-candidates/view-profile/:id"
            element={<ViewCandidate />}
          />
          <Route
            path="/job-post"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Recruiter" ? <PostYourJob /> : <PageNotFound />
                }
              />
            }
          />
          <Route
            path="/profile/skills"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Candidate" ? <EditSkills /> : <PageNotFound />
                }
              />
            }
          />
          <Route
            path="/profile/experience"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Candidate" ? (
                    <EditExperience />
                  ) : (
                    <PageNotFound />
                  )
                }
              />
            }
          />
          <Route
            path="/profile/projects"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Candidate" ? <EditProjects /> : <PageNotFound />
                }
              />
            }
          />
          <Route
            path="/profile/achievements"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Candidate" ? (
                    <EditAchievements />
                  ) : (
                    <PageNotFound />
                  )
                }
              />
            }
          />
          <Route
            path="/profile/certificates"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Candidate" ? (
                    <EditCertificates />
                  ) : (
                    <PageNotFound />
                  )
                }
              />
            }
          />
          <Route
            path="/profile/education"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Candidate" ? <EditEducation /> : <PageNotFound />
                }
              />
            }
          />
          <Route
            path="/job-post-summary"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Recruiter" ? (
                    <JobPostSummary />
                  ) : (
                    <PageNotFound />
                  )
                }
              />
            }
          />
          <Route
            path="/qualified-applicants"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Recruiter" ? (
                    <ReceiveQualifiedApplicants />
                  ) : (
                    <PageNotFound />
                  )
                }
              />
            }
          />
          <Route
            path="/my-recommendedJob-jobs"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Candidate" ? (
                    <RecommendedJob />
                  ) : (
                    <PageNotFound />
                  )
                }
              />
            }
          />
          <Route path="/job-search" element={<JobSearchResult />} />
          {/* <Route path="/view-job/:jobId" element={<JobSearchResult />} /> */}

          <Route
            path="/my-job"
            element={
              <ProtectedRoute
                element={loginAs === "Candidate" ? <MyJob /> : <PageNotFound />}
              />
            }
          />
          <Route path="/analytics" element={<AnalyticsProfileTips />} />
          <Route
            path="/company-edit"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Recruiter" ? <CompanyEdit /> : <PageNotFound />
                }
              />
            }
          />
          <Route
            path="/company-edit/:id"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Recruiter" ? <CompanyEdit /> : <PageNotFound />
                }
              />
            }
          />
          <Route
            path="/company"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Recruiter" ? <Company /> : <PageNotFound />
                }
              />
            }
          />
          <Route
            path="/company/:id"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Recruiter" ? (
                    <ComapnyProfileView />
                  ) : (
                    <PageNotFound />
                  )
                }
              />
            }
          />
          <Route
            path="/manage-job-post"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Recruiter" ? <ManageJobPost /> : <PageNotFound />
                }
              />
            }
          />
               <Route
            path="/recommended-candidates"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Recruiter" ? <MyCandidates /> : <PageNotFound />
                }
              />
            }
          />
        
          <Route
            path="/job-view/:id"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Recruiter" ? <ManageJobView /> : <PageNotFound />
                }
              />
            }
          />
          <Route
            path="/view-applicant/:jobId/:id"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Recruiter" ? <ViewApplicant /> : <PageNotFound />
                }
              />
            }
          />
          <Route
            path="/view-job-post/:id"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Recruiter" ? <ViewJobPost /> : <PageNotFound />
                }
              />
            }
          />
          <Route
            path="/view-job/:JobId"
            element={
              <ProtectedRoute
                element={
                  loginAs === "Candidate" ? <ViewJobPost /> : <PageNotFound />
                }
              />
            }
          />
          <Route
            path="/search-candidate"
            element={
              loginAs === "Recruiter" ? <SearchCandidate /> : <PageNotFound />
            }
          />
          <Route
            path="/save-resume"
            element={
              loginAs === "Recruiter" ? <SavedResume /> : <PageNotFound />
            }
          />
          <Route
            path="/purchase-history"
            element={<ProtectedRoute element={<PurchaseHistory />} />}
          />
          <Route
            path="/packages"
            element={<ProtectedRoute element={<Packages />} />}
          />
          <Route
            path="/notification"
            element={<ProtectedRoute element={<Notification />} />}
          />
          <Route
            path="/message"
            element={<ProtectedRoute element={<Message />} />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms-&-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/help-center" element={<Help />} />
          <Route
            path="/job-seeker-search-question/:id"
            element={<HelpQATabData />}
          />

          <Route
            path="/job-seeker-search-question"
            element={<HelpQATabData />}
          />
          <Route
            path="/invoice"
            element={<ProtectedRoute element={<Invoice />} />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
