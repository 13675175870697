import React, { useEffect, useState } from "react";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import JobPipeline from "../MyJob/JobPipeline";
import SavedResumeTable from "./SavedResumeTable";
import ResumeFilter from "./ResumeFilter";
import { useSelector } from "react-redux";
import authApi from "../../../../utils/authApi";
import DropdownFilter from "../../../CommonComponents/DropdownFilter";
import axios from "axios";

const Education = [
  { label: "B.Tech", value: "B.Tech" },
  { label: "M.Tech", value: "M.Tech" },
  { label: "B.A", value: "B.A" },
  { label: "B.Sc", value: "B.Sc" },
  { label: "B.Com", value: "B.Com" },
  { label: "B.S", value: "B.S" },
  { label: "B.E", value: "B.E" },
  { label: "MBA", value: "MBA" },
  { label: "M.A", value: "M.A" },
  { label: "M.Sc", value: "M.Sc" },
  { label: "M.Com", value: "M.Com" },
  { label: "M.S", value: "M.S" },
  { label: "M.E", value: "M.E" },
  { label: "Ph.D", value: "Ph.D" },
  { label: "High School Diploma", value: "High School Diploma" },
  { label: "GED", value: "GED" },
  { label: "Associate's Degree", value: "Associate's Degree" },
  { label: "Certificate Program", value: "Certificate Program" },
  { label: "Diploma", value: "Diploma" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Bachelor of Business Administration (BBA)", value: "BBA" },
  { label: "Other", value: "Other" },
];

const Experience = [
  { label: "All", value: "All" },
  { label: "1 Year", value: "1" },
  { label: "2 Year", value: "2" },
  { label: "3 Year", value: "3" },
  { label: "4 Year", value: "4" },
  { label: "5 Year", value: "5" },
  { label: "6 Year", value: "6" },
  { label: "7 Year", value: "7" },
  { label: "8 Year", value: "8" },
  { label: "9 Year", value: "9" },
  { label: "10 Year", value: "10" },
];

const Location = [
  { label: "All", value: "All" },
  { label: "Bangalore", value: "Bangalore" },
  { label: "Noida", value: "Noida" },
];

const Ratings = [
  { label: "Unrated", value: "Unrated" },
  { label: "Good fit", value: "Good fit" },
  { label: "Maybe", value: "Maybe" },
  { label: "Not a fit", value: "Not a fit" },
];

const SkilledIn = [
  { label: "Marketing", value: "Marketing" },
  { label: "Sales", value: "Sales" },
  { label: "Lead Generation", value: "Lead Generation" },
  { label: "Sales and Marketing", value: "Sales and Marketing" },
  { label: "Business Development", value: "Business Development" },
  { label: "Microsoft Excel", value: "Microsoft Excel" },
];

const SavedResume = () => {
  const authId = useSelector((state) => state?.auth?.user?.info?.id);
  const [allSavedCand, setAllSavedCand] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState("BOOKMARKED");
  const [page, setPage] = useState(1);
  const [allPages, setAllPages] = useState(1);

  const [education, setEducation] = useState([]);
  const [experience, setExperience] = useState([]);

  const [locations, setLocations] = useState([]);
  const [locationsLoading, setLocationLoading] = useState(false);
  const [location, setLocation] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [searchSkill, setSearchSkill] = useState("");
  const [skillsLoading, setSkillsLoading] = useState(false);

  const [searchCand, setSearchCand] = useState("");
let cancelToken1
  const getLocation = async () => {
    if(typeof cancelToken1 !=typeof undefined){
      cancelToken1.cancel('Cancelled')
    }
    cancelToken1=axios.CancelToken.source()
    try {
      if (searchQuery) {
        setLocationLoading(true);
        const res = await authApi.get(`search-state/${searchQuery}`,{cancelToken:cancelToken1.token});
        setLocations(res?.data);
        setLocationLoading(false);
      }
    } catch (error) {
      setLocationLoading(false);
    }
  };
  useEffect(() => {
    getLocation();
    return ()=>{
      if(cancelToken1){
        cancelToken1.cancel('Cancelled')
      }
    }
  }, [searchQuery]);
  let cancelToken;
  const getSkills = async () => {
    if(typeof cancelToken !=typeof undefined){
      cancelToken.cancel('Cancelled')
    }
    cancelToken=axios.CancelToken.source()
    try {
      if (searchSkill) {
        setSkillsLoading(true);
        const res = await authApi.get(`recruiter/search-skills/${searchSkill}`,{cancelToken:cancelToken.token});
        setSkills(res?.data?.results);
        setSkillsLoading(false);
      }
    } catch (error) {
      setSkillsLoading(false);
    }
  };
  useEffect(() => {
    getSkills();
    return ()=>{
      if(cancelToken){
        cancelToken.cancel('Cancelled')
      }
    }
  }, [searchSkill]);

  const Location = locations?.map((ele) => ({
    label: `${ele?.name}, ${ele?.country?.name}`,
    value: ele?.id,
  }));
  const Skills = skills?.map((ele) => ({ label: ele?.title, value: ele?.id }));

  //get all saved candidates
  const getAllSavedCandidates = async () => {
    setIsLoading(true);
    try {
      // if (title === "BOOKMARKED") {
      const res = await authApi.get(
        `recruiter/companies/${authId}/user-bookmark`,
        {
          params: {
            location: location[0],
            skill: selectedSkills,
            education: education[0],
            years_of_experience: experience[0],
            search: searchCand,
          },
        }
      );
      setAllSavedCand(res?.data?.data);
      setAllPages(Math.ceil(res.data?.meta?.total / res.data?.meta?.per_page));
      // } else if (title === "APPLIED") {
      //   const res = await authApi.get(
      //     `recruiter/companies/${authId}/user-bookmark`
      //     // {
      //     //   params: {
      //     //     // location:10,
      //     //     skill: [8],
      //     //     education: "B.sc",
      //     //     years_of_experience: 0,
      //     //   },
      //     // }
      //   );
      //   setAllSavedCand(res?.data?.data);
      //   setAllPages(
      //     Math.ceil(res.data?.meta?.total / res.data?.meta?.per_page)
      //   );
      // } else if (title === "INTERVIEWING") {
      //   const res = await authApi.get(
      //     `recruiter/companies/${authId}/user-bookmark`
      //     // {
      //     //   params: {
      //     //     // location:10,
      //     //     skill: [8],
      //     //     education: "B.sc",
      //     //     years_of_experience: 0,
      //     //   },
      //     // }
      //   );
      //   setAllSavedCand(res?.data?.data);
      //   setAllPages(
      //     Math.ceil(res.data?.meta?.total / res.data?.meta?.per_page)
      //   );
      // } else if (title === "NEGOTIATING") {
      //   const res = await authApi.get(
      //     `recruiter/companies/${authId}/user-bookmark`
      //     // {
      //     //   params: {
      //     //     // location:10,
      //     //     skill: [8],
      //     //     education: "B.sc",
      //     //     years_of_experience: 0,
      //     //   },
      //     // }
      //   );
      //   setAllSavedCand(res?.data?.data);
      //   setAllPages(
      //     Math.ceil(res.data?.meta?.total / res.data?.meta?.per_page)
      //   );
      // } else if (title === "ACCEPTED") {
      //   const res = await authApi.get(
      //     `recruiter/companies/${authId}/user-bookmark`
      //     // {
      //     //   params: {
      //     //     // location:10,
      //     //     skill: [8],
      //     //     education: "B.sc",
      //     //     years_of_experience: 0,
      //     //   },
      //     // }
      //   );
      //   setAllSavedCand(res?.data?.data);
      //   setAllPages(
      //     Math.ceil(res.data?.meta?.total / res.data?.meta?.per_page)
      //   );
      // }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllSavedCandidates();
  }, [title]);
  // JobPipeline
  const jobsPiplineData = [
    {
      bgClass: `light-${title === "BOOKMARKED" ? "blue" : "gray"}-bg`,
      title: "BOOKMARKED",
      count: "1",
    },
    {
      bgClass: `light-${title === "APPLIED" ? "blue" : "gray"}-bg`,
      title: "APPLIED",
      count: "-",
    },
    {
      bgClass: `light-${title === "INTERVIEWING" ? "blue" : "gray"}-bg`,
      title: "INTERVIEWING",
      count: "-",
    },
    {
      bgClass: `light-${title === "NEGOTIATING" ? "blue" : "gray"}-bg`,
      title: "NEGOTIATING",
      count: "-",
    },
    {
      bgClass: `light-${title === "ACCEPTED" ? "blue" : "gray"}-bg`,
      title: "ACCEPTED",
      count: "-",
    },
  ];
  const handleFilterChange = (id, selectedOptions) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    getAllSavedCandidates();
  };
  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="grid-2-container">
            <div className="left-side">
              <LeftSidebar />
            </div>
            <div className="mt-smm-0 mt-3">
              <>
                <div className="border-1 border-radius-14 p-4">
                  <h3 className="job-card-title fa-20 mb-3">Saved Resume</h3>
                  <div className="">
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div className="search-filter">
                        <div className="form-group search-item">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            className="form-control bg-light-gray-1  md-w-fit rounded-30"
                            id="name"
                            value={searchCand}
                            placeholder="Candidate Name"
                            onChange={(e) => setSearchCand(e.target.value)}
                          />
                        </div>

                        <div className="form-group search-item">
                          <DropdownFilter
                            marginclass="mt-4"
                            title="Education"
                            className="min-width-150 bg-light-gray-1"
                            options={Education}
                            onChange={(selectedOptions) => {
                              handleFilterChange("Relevance", selectedOptions);
                              setEducation(selectedOptions);
                            }}
                            name="All"
                            label={education?.length}
                          />
                        </div>

                        <div className="form-group search-item">
                          <DropdownFilter
                            marginclass="mt-4"
                            title="Yrs of Experience"
                            className="min-width-150 bg-light-gray-1"
                            options={Experience}
                            onChange={(selectedOptions) => {
                              handleFilterChange("Experience", selectedOptions);
                              setExperience(selectedOptions);
                            }}
                            name="All"
                            label={experience?.length}
                          />
                        </div>

                        <div className="form-group search-item">
                          <DropdownFilter
                            marginclass="mt-4"
                            title="Skill"
                            className="min-width-150 bg-light-gray-1"
                            options={Skills}
                            onChange={(selectedOptions) => {
                              handleFilterChange("SkilledIn", selectedOptions);
                              setSelectedSkills(selectedOptions);
                            }}
                            name="All"
                            multiple={true}
                            searchable
                            setQuery={setSearchSkill}
                            query={searchSkill}
                            loading={skillsLoading}
                            label={selectedSkills.length}
                          />
                        </div>

                        <div className="form-group search-item">
                          <DropdownFilter
                            marginclass="mt-4"
                            title="Location"
                            className="min-width-150 bg-light-gray-1"
                            options={Location}
                            onChange={(selectedOptions) => {
                              handleFilterChange("Location", selectedOptions);
                              setLocation(selectedOptions);
                            }}
                            name="All"
                            searchable
                            setQuery={setSearchQuery}
                            query={searchQuery}
                            loading={locationsLoading}
                            label={location?.length}
                          />
                        </div>
                      </div>
                      <div className="text-md-left text-center">
                        <button
                          disabled={isLoading}
                          className="btn btn-gary fw-500 fa-16 py-2 white mt-2 px-5"
                        >
                          {isLoading ? "Searching" : "Search"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </>

              <hr />
              {/* <div className="d-flex flex-wrap gap-30">
                <JobPipeline
                  jobs={jobsPiplineData}
                  title={title}
                  setTitle={setTitle}
                />
              </div> */}
              <div className="mt-3">
                <SavedResumeTable
                  allSavedCand={allSavedCand}
                  allPages={allPages}
                  page={page}
                  setPage={setPage}
                  isLoading={isLoading}
                  getAllSavedCandidates={getAllSavedCandidates}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SavedResume;
