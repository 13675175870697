import React, { useState } from "react";
import DummyImage from "../../../../assets/dummy.jpg";
import { Link, useNavigate } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import authApi from "../../../../utils/authApi";

const NotificationCard = ({ row, index, flag, setFlag, role }) => {
  const [deleting, setDeleting] = useState(false);
  const [reading, setReading] = useState(false);
  const navigate = useNavigate();
  const readNotifications = async () => {
    setReading(true);
    try {
      if (role === "Candidate") {
        await authApi.post(`mark-as-read-notification`, {
          id: row?.id,
        });
        setFlag(!flag);
      } else {
        await authApi.post(`recruiter/mark-as-read-notification`, {
          id: row?.id,
        });
        setFlag(!flag);
      }

      setReading(false);
    } catch (error) {
      setReading(false);
    }
  };
  const deleteNotification = async () => {
    setDeleting(true);
    try {
      if (role === "Candidate") {
        await authApi.delete(`delete-notification/${row?.id}`);
        setFlag(!flag);
      } else {
        await authApi.delete(`recruiter/delete-notification/${row?.id}`);
        setFlag(!flag);
      }

      setDeleting(false);
    } catch (error) {
      setDeleting(false);
    }
  };

  const handleNavigateProperly = (row) => {
    if (role === "Candidate" && row?.data?.job_title && row?.data?.job_title) {
      navigate(
        `/job-search?title=${row?.data?.job_title ? row?.data?.job_title : ""}`
      );
    } else if (
      role === "Recruiter" &&
      row?.data?.job_id &&
      row?.data?.user_id
    ) {
      navigate(`/view-applicant/${row?.data?.job_id}/${row?.data?.user_id}`);
    }
  };

  return (
    <>
      <div
        key={index}
        className="notification-card d-flex justify-content-between align-items-center"
      >
        <div
          className="notification-img"
          style={{ filter: !row?.read_at && "blur(6px)" }}
          onClick={() => handleNavigateProperly(row)}
        >
          <img src={DummyImage} alt="notification" className="img-fluid" />
          <p className="mb-0 ml-3">
            <Link>{row?.data?.message}</Link>
          </p>
        </div>

        <div>
          {row?.read_at ? (
            <span className="trashicon">
              <Link>
                {deleting ? (
                  <div class="spinner-border text-muted"></div>
                ) : (
                  <BsTrash onClick={() => deleteNotification()} />
                )}
              </Link>
            </span>
          ) : (
            <span className="text-info fa-30" style={{ cursor: "pointer" }}>
              <Link>
                {reading ? (
                  <div class="spinner-border text-muted"></div>
                ) : (
                  <FaEye onClick={() => readNotifications()} />
                )}
              </Link>
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default NotificationCard;
