// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../utils/api";


// Async thunk for user login
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password,loginAs }, { rejectWithValue }) => {


    try {
  if(loginAs==="Candidate"){
    const response = await api.post("login", {
      email,
      password
    });

    return response?.data;
 
  }else{
    const response = await api.post("recruiter/login", {
      email,
      password
    });

    return response?.data;
  }
  

    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

// Async thunk for user registration
export const register = createAsyncThunk(
  "auth/register",
  async ({ email, password,loginAs,name,fName,lName }, { rejectWithValue }) => {
  

    try {
      if(loginAs==="Candidate"){
        const response = await api.post("register",  {
          email,
          password,
          first_name:fName,
          last_name:lName
        });
        return response?.data;
      }else{
        const response = await api.post("recruiter/register",  {
          email,
          password,
          name
        });
        return response?.data;
      }
 
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
// Create an async thunk for social login
export const socialLogin = createAsyncThunk(
  "auth/socialLogin",
  async (provider) => {
    // const url = window.location.href;
    // const response = await api.get(
    //   `login/${provider}/callback${url.substring(url.indexOf("?"))}`
    // );
    const response = await api.get(
      `login/${provider}/callback${window.location.search}`
    );
    return response.data;
  }
);

// Auth slice
const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    loginAs: "Candidate",
    isLoggedIn: false,
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = {};
      state.isLoggedIn = false;
    },
    setUserToken: (state, action) => {
      state.user._token = action.payload;
    },
    saveLoginAs: (state, action) => {
      state.loginAs = action.payload;
    },
    saveNewUserInfo: (state, action) => {
      state.user.info = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoggedIn = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(socialLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(socialLogin.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoggedIn = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(socialLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.user = action.payload;
        state.error = null;
        toast.success("Registered successful!");
      })

      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        toast.error("Registered failed!");
      });
  },
});

export const { logout, saveNewUserInfo, setUserToken,saveLoginAs } = authSlice.actions;

export default authSlice.reducer;
