import React from 'react';
import {Link} from 'react-router-dom';
import {BiDownload} from 'react-icons/bi';
import Resume  from '../../../../assets/Resume.pdf'
import { IMAGEURL } from '../../../../constant';

const ApplicantResumeView = ({profile}) => {

  
  return (
    <>
       <div className="card mt-3">
        <div className="card-header bg-transparent border-bottom-0 d-flex justify-content-between">
          <h3 className="card-title fw-500 light-black">
           Resume
          </h3>
          <a
          target='_blank'
            href={`${IMAGEURL}${profile?.resume}`} // Replace with the correct URL to the resume
            download={`${profile?.first_name} ${profile?.last_name} Resume.pdf`} // Specify the file name for download
            className="link-text fa-16 fw-500"
          >
            <BiDownload className='fa-20'/> Download
          </a>
          {/* <Link to={`${IMAGEURL}${profile?.resume}`} download={`${profile?.first_name} ${profile?.first_name} Resume`} className="link-text fa-16 fw-500"> <BiDownload className='fa-20'/> Download </Link> */}

        </div>
        <div className="card-body pt-0 pb-0">
          <div className='resume-view'>
             <iframe src={`${IMAGEURL}${profile?.resume}`} width="100%" height="400" title="resume"/>
          </div>
        </div>
      </div>
    </>
  )
}

export default ApplicantResumeView
