import React, { useEffect } from "react";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import "./Profile.css";
import ProfileMainContent from "./ProfileMainContent";
import UploadResume from "../../../CommonComponents/UploadResume";
import HighlightsData from "../../../CommonComponents/HighlightsData";
import CareerInsightData from "./CareerInsightData";
import TopSkills from "../../../CommonComponents/TopSkills";
import authApi from "../../../../utils/authApi";
import { useDispatch, useSelector } from "react-redux";
import { saveProfile } from "../../../../Redux/userSlice";
import { Link } from "react-router-dom";
import { IMAGEURL } from "../../../../constant";
import ManageMentSkills from "./ProfileModal/ManagementSkills/ManageMentSkills";

const Profile = () => {
  const dispatch = useDispatch();

  const authId = useSelector((state) => state?.auth?.user?.info?.id);
  const profile = useSelector((state) => state?.user?.profile);
  //useeffect for getting logged user data
  useEffect(() => {
    const getProfile = async () => {
      try {
        const res = await authApi.get(`users/${authId}`);
        // dispatch(saveProfile(res?.data?.data));
      } catch (err) {}
    };
    getProfile();
  }, [authId, dispatch]);

  return (
    <>
      <section>
        {/* page-wrapper */}
        <div className="container-fluid side-space">
          <div className="grid-container pt-4 pb-4">
            <div className="left-side grid-left">
              <LeftSidebar />
            </div>
            <div className="main-content grid-middle mt-smm-0 mt-3">
              <ProfileMainContent />
            </div>
            <div className="right-side grid-right ">
              <UploadResume title="Upload Resume" />
              {profile?.resume && (
                <div className="card text-center py-2">
                  <Link
                    to={`${IMAGEURL}${profile?.resume}`}
                    target="_blank"
                    className="link-text"
                  >
                    View My Resume
                  </Link>
                </div>
              )}

              <CareerInsightData />

              <HighlightsData />
              <ManageMentSkills />
              <TopSkills />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
