import React from "react";
//import Profilebanner from "../../../../assets/profilebanner.png";
import ProfileSummary from "./ProfileSummary";
import EditBasicIntro from "./ProfileModal/EditBasicIntro";
import ViewContactDeatils from "./ProfileModal/ContactInfo";
import EditBanner from "./ProfileModal/EditBanner";
import dummyBanner from "../../../../assets/dummy.jpg";
import AddProfile from "./AddProfilePhoto";
import ProfileHashTopic from "./ProfileModal/ProfileHashTopic";
import { AiOutlineEdit } from "react-icons/ai";
import { useSelector } from "react-redux";
import { IMAGEURL } from "../../../../constant";
import { MdOutlineModeEditOutline } from "react-icons/md";

const ProfileMainContent = () => {
  const profile = useSelector((state) => state.user.profile); //profile of user from redux;
  //get Present Company

  const getPresentCompany = (data) => {
    const a = data?.filter((elem) => elem.present_job);
    if (a?.length > 0) {
      return a[0]?.company_name;
    } else {
      return "-";
    }
  };
  const findTimeInt = (data) => {
    const a = data?.filter((elem) => elem.present_job);
    if (a?.length > 0) {
      return calculateDurationBetweenTimes(
        a[0]?.start_date,
        a[0]?.end_date,
        true
      );
    } else {
      return "-";
    }
  };
  // function to calulate duration
  function calculateDurationBetweenTimes(
    timeStringA,
    timeStringB,
    present_job
  ) {
    try {
      const dateA = new Date(timeStringA);
      const dateB = present_job ? new Date() : new Date(timeStringB);

      // Calculate the difference in years and months
      const yearDiff = dateB.getFullYear() - dateA.getFullYear();
      const monthDiff = dateB.getMonth() - dateA.getMonth();

      // Calculate the total months, considering years
      const totalMonths = yearDiff * 12 + monthDiff;

      // Convert years and months to a human-readable format
      const years = Math.floor(totalMonths / 12);
      const months = totalMonths % 12;

      // Create the formatted duration string
      let duration = "";
      if (years > 0) {
        duration += years + (years === 1 ? " yr " : " yrs ");
      }
      if (months > 0) {
        duration += months + (months === 1 ? " month" : " months");
      }

      return duration.trim();
    } catch (error) {
      return "-::-";
    }
  }
  return (
    <>
      <main>
        <div className="profile-main-wrapper bg-white">
          <div className="profile-main-container">
            {/*profile  banner */}
            <div className="profile-hero-banner position-relative">
              <div className="profile-hero-banner-image profile-background-image--default">
                <img
                  src={
                    profile?.banner
                      ? `${IMAGEURL}${profile.banner}`
                      : dummyBanner
                  }
                  className="img-fluid full-height full-width"
                  alt="Profile banner"
                />
              </div>
              <div className="profile-hero-banner-edit-icon">
                <EditBanner />
              </div>
            </div>

            {/*end profile  banner */}

            {/* profile  info */}
            <div className="profile-card-photo-deatils position-relative">
              <div className="profile-card-photo-wrapper">
                <div className="profile-card-photo-edit">
                  <AddProfile />
                </div>
              </div>

              <div className="ptofile-card-photo-content mt-3">
                <div className="ptofile-card-photo-content-wrapper">
                  <div className="profile-card-info d-lg-flex d-block justify-content-between">
                    <div>
                      <h4>
                        {profile?.first_name ?? "Edit Profile"}{" "}
                        {profile?.last_name ?? ""} <EditBasicIntro />{" "}
                      </h4>
                      <p>
                        {" "}
                        <small className="profile-role-text">
                          {profile?.expertise ?? ""}
                        </small>
                      </p>
                      {(profile?.city ||
                        profile?.state ||
                        profile?.country) && (
                        <p>
                          {profile?.city ?? ""} {profile?.state ?? ""}{" "}
                          {profile?.country ?? ""} <ViewContactDeatils />
                        </p>
                      )}
                    </div>

                    {profile?.workExperiences?.filter(
                      (elem) => elem?.present_job
                    )?.length > 0 && (
                      <div className="profile-company-info text-lg-right text-left  mt-2 mt-lg-0">
                        <p className="fw-500 text-lg-right text-left">
                          {getPresentCompany(profile?.workExperiences)}
                        </p>
                        <p className="text-lg-right text-left">
                          {findTimeInt(profile?.workExperiences)}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="profile-card-topic mt-3">
                    <div className="profile-summary-header line-height-0 d-flex">
                      <div className="profile-summary-heading">
                        <h2>Expertise</h2>
                      </div>
                      <div className="profile-summary-icon">
                        <ProfileHashTopic icon={<MdOutlineModeEditOutline />} />
                      </div>
                    </div>
                    {/* <h4 className="fa-20 fa-mdd-16">
                      Expertise <ProfileHashTopic  icon={<AiOutlineEdit />} />
                    </h4> */}
                    <p className="mt-1 mb-0">{profile?.expertise ?? ""}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* end profile  info */}

            <div className="line mt-2" />

            {/*  profile  details */}
            <ProfileSummary />
            {/* end  profile  details */}
          </div>
        </div>
      </main>
    </>
  );
};

export default ProfileMainContent;
