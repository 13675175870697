import React, { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { toast } from "react-toastify";
import authApi from "../../../../utils/authApi";
import { useSelector } from "react-redux";
import PaymentComponent from "./Razorpay";
const initialState = {
  first_name: "",
  last_name: "",
  card_number: "",
  expiry_date: "",
};
const PackageCard = ({ icon, originalPrice, data, loginAs,getActivePackage,active }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [state, setState] = useState(initialState);
  const [expDate, setExpDate] = useState("");
  const authId = useSelector((state) => state?.auth?.user?.info?.id);
  const handleInputChange = (e) => {
    const input = e.target.value;
    const isValidInput = /^\d{0,2}\/?\d{0,2}$/.test(input);
    if (isValidInput || input === "") {
      // Remove non-numeric characters and format MM/YY
      const formattedInput = input.replace(/\D/g, "");
      const formattedExpDate =
        formattedInput.length > 2
          ? `${formattedInput.slice(0, 2)}/${formattedInput.slice(2)}`
          : formattedInput;

      setExpDate(formattedExpDate);
    }
  };

  const handlePurchaseClick = async (data) => {
    // console.log(data);
    try {
      if (loginAs === "Candidate") {
        await authApi.post(`subscribe/${authId}/${data?.id}`, {
          month: data?.duration === "Free" ? 0 : +data?.duration?.split(" ")[0],
        });
        toast.success("Payment successful.");
      } else {
        await authApi.post(`recruiter/subscribe/${data?.id}`, {
          month: data?.duration === "Free" ? 0 : +data?.duration?.split(" ")[0],
        });
        toast.success("Payment successful.");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div
        className="card border-radius-14 "

        // onMouseEnter={() => setIsHovered(true)}
        // onMouseLeave={() => setIsHovered(false)}
      >
        <div className="card-body p-0 bg-light-gray-shades-3 border-radius-14 ">
          <div className="position-relative" >
            <div className="p-2 p-xl-3">
              <h3 className="card-title blue fw-600">{data?.title}</h3>
              <div 
              >
                <div className="packagelist mt-2"
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                ></div>
                {/* <ul>
                  {features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul> */}
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-between align-items-center bg-blue px-2 px-xl-3  rounded-8 package-bottom"
            style={{ cursor: "pointer" }}
            // data-toggle="modal"
            // data-target={`#modal-${data?.id}`}
          >
            <div className="m-3">
              <div className="d-flex align-items-center">
                {data?.amount ? (
                  <p className="fa-18 white fw-400 mb-0">
                    <span className="fa-20  white fw-400 ml-2">
                      ${data.grand_total}
                    </span>
                    <span className="fa-14  white fw-400 line-through ml-2">
                      ${data.amount}
                    </span>
                  </p>
                ) : (
                  <p className="fa-12 white fw-400 mb-0 py-2">
                    first month free
                  </p>
                )}
                <span className="fa-16  white fw-400 line-through ml-2">
                  {originalPrice}
                </span>
              </div>
            </div>

            <div className="">
              <p className="fa-14 white fw-400 mb-0 fa-mdd-12">
                {/* <AiOutlineCheck className="mt-1 fa-18 mr-1" /> */}
          {!active &&    <PaymentComponent p_id={data?.id} data={data} getActivePackage={getActivePackage} />}
              </p>
            </div>

            {/* {isHovered && <button className="buy-now-button">Buy Now</button>} */}
          </div>
        </div>
      </div>
      {/*  */}
      <div class="modal" id={`modal-${data?.id}`}>
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            {/* <!-- Modal Header --> */}
            <div class="modal-header">
              <h4 class="modal-title">Payment</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            {/* <!-- Modal body --> */}
            <div class="modal-body">
              <div class="row mb-2">
                <div class="col-sm-6">
                  {" "}
                  <label htmlFor="">First Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    value={state.first_name}
                    onChange={(e) => {
                      if (
                        /^[a-zA-Z]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setState({ ...state, first_name: e.target.value });
                      }
                    }}
                  />
                </div>
                <div class="col-sm-6">
                  {" "}
                  <label htmlFor="">Last Name *</label>
                  <input
                    type="text"
                    value={state.last_name}
                    onChange={(e) => {
                      if (
                        /^[a-zA-Z]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setState({ ...state, last_name: e.target.value });
                      }
                    }}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="mb-2">
                <label htmlFor="">Credit Card or Debit Card number *</label>
                <input
                  type="number"
                  maxLength={16}
                  value={state?.card_number}
                  onChange={(e) => {
                    {
                      if (e.target.value.toString().length <= 16) {
                        setState({ ...state, card_number: e.target.value });
                      }
                    }
                  }}
                  className="form-control"
                />
              </div>
              <div class="row mb-2">
                <div class="col-sm-6">
                  <label htmlFor="">Expiration Date *</label>

                  <input
                    type="text"
                    className="form-control"
                    id="expirationDate"
                    placeholder="MM/YY"
                    value={expDate}
                    onChange={(e) => handleInputChange(e)}
                    maxLength={5} // Limit the input to 5 characters (MM/YY)
                  />
                </div>
                <div class="col-sm-6">
                  {" "}
                  <label htmlFor="">CVV *</label>
                  <input
                    className="form-control"
                    type="password"
                    maxLength={3}
                    onChange={(e) => {
                      setState({ ...state, cvv: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <!-- Modal footer --> */}
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">
                Close
              </button>
              <button
                type="button"
                class="btn btn-success"
                data-dismiss="modal"
                onClick={() => handlePurchaseClick(data)}
              >
                Purchase
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageCard;
