import React from 'react';
import Highlights from './Highlights';
import { useSelector } from 'react-redux';

const HighlightsData = () => {
  const profile = useSelector((state) => state?.user?.profile);

//find longest employer
function findCompanyWithLongestTimeDifference(companies) {
  let longestTimeDifference = 0; // Initialize the longest time difference to 0
  let companyName = ''; // Initialize the company name to an empty string
  let role=''
  // Iterate through each company's start and end date
  companies?.forEach((company) => {
    const startDate = new Date(company.start_date);
    const endDate = company.present_job ? new Date() : new Date(company.end_date);

    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;

    // If the current time difference is longer than the longest one found so far
    if (timeDifference > longestTimeDifference) {
      longestTimeDifference = timeDifference; // Update the longest time difference
      companyName = company.company_name; // Update the company name
      role=company.corporate_title.title
    }
  });

  // Return the company name with the longest time difference
  return {companyName:companyName,role:role};
}
  return (
    <div>
     {profile?.workExperiences?.length>0 && <div className='high-lights-container mt-4'>
      <div className='common-heading'>
        <h3>Highlights</h3>
      </div>
    
        <div className='High-lights mt-3' >
          <h4>Longest employer</h4>
          <p>{findCompanyWithLongestTimeDifference(profile?.workExperiences)?.companyName}</p>
        </div>
        <div className='High-lights mt-3' >
          <h4>Longest role</h4>
          <p>{findCompanyWithLongestTimeDifference(profile?.workExperiences)?.role}</p>
        </div>
        {/* <div className='High-lights mt-3' >
          <h4>Longest employer</h4>
          <p>{findCompanyWithLongestTimeDifference(profile?.workExperiences)}</p>
        </div> */}
    
    </div>}
    </div>
  );
};

export default HighlightsData;
