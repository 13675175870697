import React, { useState } from "react";
import { CiSliderVertical } from "react-icons/ci";
import { HiOutlineBarsArrowDown } from "react-icons/hi2";
import { BsThreeDots } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../CommonComponents/Pagination";
import WorkSkills from "../../../CommonComponents/WorkSkills";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import authApi from "../../../../utils/authApi";

const BookMarkedJobs = ({
  recentJobApplied,
  page,
  setPage,
  allPages,
  getAllJobPosts,
}) => {
  //states
  const [showBox, setShowBox] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [boxPosition, setBoxPosition] = useState({});
  const [selectedSkills, setSelectedSkills] = useState([]);
  //react-redux
  const navigate = useNavigate();

  const profile = useSelector((state) => state.user.profile);

  const handleItemMouseEnter = (item, event, data) => {
    setSelectedItem(item);
    if (item === "skill") {
      setSelectedSkills(data);
    }
    const target = event.currentTarget;
    const rect = target.getBoundingClientRect();
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const position = {
      top: rect.top + rect.height + scrollTop + 30,
      left: rect.left + scrollLeft,
    };
    setBoxPosition(position);
    setShowBox(true);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowBox(true);
  };

  const handleBoxClose = () => {
    setShowBox(false);
    setSelectedItem(null);
  };

  const workplace = ["Remote", "On-site", "Hybrid"];
  const jobtype = ["Full Time", "Part Time", "Contarct", "Temporary", "Other"];

  //check skill matching
  const checkSkillsMatch = (job) => {
    let candidateSkills = profile?.skills;
    // Extract the 'key' values from array2
    const keysArray2 = candidateSkills?.map((item) => {
      if (item?.alternate_names) {
        return [
          ...JSON.parse(item?.alternate_names),
          item?.title?.toLowerCase(),
        ];
      } else {
        return [item?.title?.toLowerCase()];
      }
    });
    // Concatenate arrays using reduce
    const resultArray = keysArray2.reduce(function (accumulator, currentArray) {
      return accumulator.concat(currentArray);
    }, []);
    let finalArray = resultArray?.filter((ele) => ele != undefined);
    const keysArray1 = job?.required_skills?.map((elem) => elem.toLowerCase());

    // Count the number of keys from array2 that are present in array1
    const countMatchingKeys = finalArray?.filter((key) =>
      keysArray1?.includes(key)
    )?.length;
    // Calculate the percentage
    const percentage = (countMatchingKeys / job?.required_skills?.length) * 100;
    return percentage.toFixed(2) + "%";
  };

  //check Required skill match
  const checkRequiredSkillsMatch = (job) => {
    let candidateSkills = profile?.skills;
console.log(candidateSkills);
    const reqSkillsWithExp = JSON.parse(job?.skill_wise_experience ?? "[]");

    // Extract the 'key' values from array2
    const keysArray2 = candidateSkills?.map((item) => {
      if (item?.alternate_names) {
        const arr = JSON.parse(item?.alternate_names) || [];
        if (arr.length > 0) {
          const newArr = arr?.map(
            (ele) =>
              `${ele?.toLowerCase()}-${
                item?.month_or_year?.toLowerCase() == "year"
                  ? 12 * +item?.year_of_experience
                  : item?.year_of_experience
              }`
          );
          return [
            ...newArr,
            `${item?.title?.toLowerCase()}-${
              item?.month_or_year?.toLowerCase() == "year"
                ? 12 * +item?.year_of_experience
                : item?.year_of_experience
            }`,
          ];
        }
      } else {
        return [
          `${item?.title?.toLowerCase()}-${
            item?.month_or_year?.toLowerCase() == "year"
              ? 12 * +item?.year_of_experience
              : item?.year_of_experience
          }`,
        ];
      }
    });
    console.log(keysArray2);
    // Concatenate arrays using reduce
    const resultArray = keysArray2.reduce(function (accumulator, currentArray) {
      return accumulator.concat(currentArray);
    }, []);

    let finalArray = resultArray?.filter(
      (ele) => ele?.split("-")[0] != "undefined"
    );

    const keysArray1 =
      reqSkillsWithExp?.length >= 0
        ? reqSkillsWithExp?.map(
            (elem) =>
              `${elem?.skill?.toLowerCase()}-${
                elem?.month_or_year?.toLowerCase() == "year"
                  ? 12 * +elem?.experience
                  : elem?.experience
              }`
          )
        : [];

    // const rett=calculateOverallPercentage(keysArray1,finalArray);
    if (
      keysArray1?.length >= 0 &&
      keysArray1.length != 0 &&
      finalArray?.length >= 0
    ) {
      const rett = calculateOverallPercentage(keysArray1, finalArray);
      return rett;
    } else if (keysArray1.length == 0) {
      return "-";
    } else {
      return 0;
    }
  };

  function calculateOverallPercentage(arr1, arr2) {
    let totalPercentage = 0;
    arr2?.forEach((skillFromArr2) => {
      let [skill, durationFromArr2] = skillFromArr2.split("-");
      let matchingSkills = arr1.filter((skillFromArr1) =>
        skillFromArr1.includes(skill)
      );
      if (matchingSkills.length > 0) {
        let durationFromArr1 = matchingSkills[0].split("-")[1];
        let durationNumberFromArr1 = +durationFromArr1;
        let durationNumberFromArr2 = +durationFromArr2;
        if (durationNumberFromArr2 >= durationNumberFromArr1) {
          let percentage = 100;
          totalPercentage += percentage;
        } else {
          let percentage =
            (+durationNumberFromArr2 / +durationNumberFromArr1) * 100;
          totalPercentage += percentage;
        }
      }
    });
    return (totalPercentage / arr1?.length).toFixed(2);
  }
  //go to job detial
  const viewJobProfile = (row) => {
    navigate(`/view-job/${row?.id}`);

    // navigate(`/job-search?title=${row?.title ? row?.title : ""}`);
  };

  const deleteSavedJob = async (id) => {
    try {
      await authApi.delete(`remove-save-job/${id}`);
      getAllJobPosts();
      toast.success("Job removed successfully.");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="job-tracker-container table-responsive">
        <table className="table  w-100">
          <thead>
            <tr>
              <th>
                Job Position{" "}
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>

              <th>
                Skill match{" "}
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Required Skills Match
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Workplace{" "}
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Job Type 
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Experience{" "}
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Location 
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              <th>
                Salary 
                {/* <HiOutlineBarsArrowDown className="ml-1 fa-20 black" /> */}
              </th>
              {/* <th>
                Applied <HiOutlineBarsArrowDown className="ml-1 fa-20 black" />
              </th> */}

              <th className="text-right">
                <CiSliderVertical className="fa-20 black fw-700" />
              </th>
            </tr>
          </thead>
          <tbody>
            {recentJobApplied?.map((row, index) => (
              <tr
                key={index}
                className="j-gray-bg align-items-center border-18"
              >
                <td style={{ width: "200px" }}>
                  <Link
                    // onMouseEnter={(e) =>
                    //   handleItemMouseEnter("Comapny Name", e)
                    // }
                    // onClick={() => handleItemClick("Comapny Name")}
                    className={selectedItem === "Comapny Name" ? "active" : ""}
                  >
                    <p className="fa-14 fw-600 light-black mb-0">
                      {row?.job?.title}
                    </p>
                    <p className="fa-12 fw-400 gray">
                      {row?.job?.company?.name}
                    </p>
                  </Link>
                </td>
                {/* <td>
                  <span className="mb-2">70%</span>
                  <div
                    className="progress"
                    style={{ height: "15px", borderRadius: "14px" }}
                  >
                    <div
                      className="progress-bar bg-dark-blue"
                      style={{ width: "70%", height: "15px" }}
                    ></div>
                  </div>
                </td> */}
                <td >
                  <div style={{width:"100px"}}>
                  <Link
                    //onMouseEnter={(e) => handleItemMouseEnter("80%", e)}
                    // onClick={(event) => handleItemClick("80%")}
                    className={`light-black ${
                      selectedItem === "80%" ? "active" : ""
                    }`}
                  >
                    <span className="mb-2">{checkSkillsMatch(row?.job)} </span>
                    <div
                      className="progress"
                      style={{ height: "15px", borderRadius: "14px" }}
                    >
                      <div
                        className="progress-bar bg-dark-blue"
                        style={{
                          width: `${checkSkillsMatch(row?.job)}`,
                          height: "15px",
                        }}
                      ></div>
                    </div>
                  </Link>
                  </div>
                 
                </td>
                <td >
                {JSON.parse(row?.job?.skill_wise_experience)?.length > 0 ? (
                  <div style={{width:"100px"}}>

                  <Link
                    //onMouseEnter={(e) => handleItemMouseEnter("80%", e)}
                    // onClick={(event) => handleItemClick("80%")}
                    className={`light-black ${
                      selectedItem === "80%" ? "active" : ""
                    }`}
                  >
                    <span className="mb-2">
                      {!row?.job?.skill_wise_experience
                        ? "-"
                        : checkRequiredSkillsMatch(row?.job) + "%"}
                    </span>
                    {row?.job?.skill_wise_experience && (
                      <div
                        className="progress"
                        style={{ height: "15px", borderRadius: "14px" }}
                      >
                        <div
                          className="progress-bar bg-dark-blue"
                          style={{
                            width: `${checkRequiredSkillsMatch(row?.job)}%`,
                            height: "15px",
                          }}
                        ></div>
                      </div>
                    )}
                  </Link>
                  </div>
                  ) : ( 
                    <div>No Need</div>
                  )}
                </td>
                {/* <td>
                  <Link
                    onMouseEnter={(e) =>
                      handleItemMouseEnter("skill", e, row?.required_skills)
                    }
                    onClick={(event) => handleItemClick("skill")}
                    className={`gray ${
                      selectedItem === "skill" ? "active" : ""
                    }`}
                  >
                    Skill
                  </Link>
                </td> */}
                <td className="remotedata">
                  {row?.job?.employment_type?.title}
                  {/* <select className="form-control fa-14">
                    {workplace.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select> */}
                </td>

                <td className="remotedata">
                  {row?.job?.job_type?.type}
                  {/* <select className="form-control fa-14">
                    {jobtype.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select> */}
                </td>

                <td>{row?.job?.required_experience}  </td>
                <td>{row?.job?.job_type?.type}</td>
                <td>{row?.job?.salary} </td>
                {/* <td>
                  <select className="form-control fa-14">
                    {applied.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </td> */}
                <td>
                  <div className="dropdown dropright float-right">
                    <button
                      type="button"
                      className="btn dropdown-toggle dark-gray-bg rounded-50 p-2 d-flex justify-content-center align-items-center"
                      data-toggle="dropdown"
                    >
                      <BsThreeDots className="black fa-26" />
                    </button>
                    <div className="dropdown-menu transition-0">
                      <button
                        onClick={() => viewJobProfile(row?.job)}
                        className="dropdown-item fa-14 light-black fw-400"
                      >
                        Job Details
                      </button>
                      <Link
                        className="dropdown-item fa-14 light-black fw-400"
                        onClick={() => deleteSavedJob(row?.id)}
                      >
                        Delete
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="position-relative ">
        {showBox && (
          <div
            className={`box ${showBox ? "slide-down" : "slide-up"}`}
            style={{
              top: boxPosition.top,
              left: boxPosition.left,
            }}
          >
            {selectedItem === "Comapny Name" && (
              <div className="box-info">
                <p className="fa-14 gray fw-400 line-height-24">
                  As a Marketing Professional my accentuation is on
                  conversations, for which it is fundamental that we know our
                  get-together of individuals well or potentially have the
                  preparation to get some answers concerning them Pieces of
                  information aren’t actually what examination are uncovering to
                  us how ....
                </p>

                <Link to="/company" className="btn blue-btn mt-3">
                  View Profile
                </Link>
              </div>
            )}

            {selectedItem === "80%" && (
              <div className="skill-content">
                <ul className="skill-content-list">
                  <li>
                    <span>NODE JS</span> <span>90%</span>
                  </li>
                  <li>
                    <span>ANGULAR</span> <span>90%</span>
                  </li>
                  <li>
                    <span>ORACLE</span> <span>90%</span>
                  </li>
                  <li>
                    <span>PYTHON</span> <span>80%</span>
                  </li>
                </ul>
              </div>
            )}

            {selectedItem === "skill" && (
              <div className="work-experience">
                <WorkSkills selectedSkills={selectedSkills} />
              </div>
            )}

            <button className="box-close" onClick={handleBoxClose}>
              X
            </button>
          </div>
        )}
      </div>

      {recentJobApplied?.length > 0 && (
        <div className="pagination-row float-right">
          <Pagination page={page} allPages={allPages} setPage={setPage} />
        </div>
      )}
    </>
  );
};

export default BookMarkedJobs;
