import React, { useEffect, useState } from "react";
import "./privacy.css";
import authApi from "../../../utils/authApi";
import { DotSpinner } from "@uiball/loaders";
import Loader from "../../CommonComponents/Loader/Loader";

const PrivacyPolicy = () => {
  const [policy, setPolicy] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const getPrivacyPolicy = async () => {
    try {
      const res = await authApi.get(`get-privacy-policy`);
      setPolicy(res?.data?.policy?.description);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getPrivacyPolicy();
  }, []);
  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      )}
      {!isLoading && (
       
   <section>
          <div className="fluid-container side-space">
          <div className="header-contact-body ptb-5">
            <div className="header-contact-form-new">
              <div className="heading-policy">
              <div
                  className="privecy-setting"
                  dangerouslySetInnerHTML={{
                    __html: policy ?? "<p>No Content</p>",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
             
      )}
    </>
  );
};

export default PrivacyPolicy;
