import React, { useEffect, useState } from "react";
import { BiX } from "react-icons/bi";

const CustomQuestions = ({
  row,
  index,
  customQuestions,
  setCustomQuestions,
}) => {
  const [state, setState] = useState(row);
  const [ansType, setAnsType] = useState("numeric");
  const handleRemoveCard = () => {
    const updatedElements = [...customQuestions];
    updatedElements.splice(index, 1); // Remove the element at the given index
    setCustomQuestions(updatedElements); // Update the state with the new list
  };

  useEffect(() => {
    if (customQuestions?.length > 0) {
      customQuestions[index] = state;
    }
  }, [state, customQuestions, index]);

  useEffect(() => {
    setCustomQuestions(customQuestions);
  }, [customQuestions, setCustomQuestions]);

  useEffect(() => {
    setState(row);
  }, [row]);

  useEffect(() => {
    if (ansType === "numeric") {
      setState((s) => ({ ...s, min_value: "",field_type:"input" }));
    } else {
      setState((s) => ({ ...s, answer: "" ,field_type:"select" }));
    }
    setState((s) => ({ ...s, field_type: ansType }));
  }, [ansType]);
 

  return (
    <div key={index} className="card  mb-3">
      <div className="card-header d-flex justify-content-between align-items-sm-center align-items-start">
        <h3 className="color-292D32 fw-500 fa-16 lineheight-28 w-90">
          Write a custom screening question.
          <br className="br-for-sm" />
        </h3>
        <button
          className="remove-btn btn btn-outline-danger p-small-0"
          onClick={() => handleRemoveCard()}
        >
          <BiX className="fa-20" />
        </button>
      </div>
      <div className="card-body">
        <div className="screening-details">
          <div className="flex-fill">
            <div className="form-group">
              <textarea
                placeholder="type your Question"
                className="form-control"
                value={state?.question}
                onChange={(e) =>
                  setState({ ...state, question: e.target.value })
                }
              ></textarea>
            </div>
          </div>
          <div className="d-flex flex-wrap align-items-center justify-content-between gap-30">
            <div className="flex-fill">
              <div className="form-group d-flex">
                <div className="w-50">
                  <label>Ideal Answer {ansType === "numeric"?`(Minimum)`:""}</label>
                  {ansType === "numeric" ? (
                    <input
                      type="number"
                      placeholder="type your answer..."
                      className="form-control w-80"
                      value={state?.min_value}
                      onChange={(e) =>
                        setState({ ...state, min_value: e.target.value })
                      }
                    />
                  ) : (
                    <select
                      className="form-control w-80"
                      id="sel1"
                      onChange={(e) =>
                        setState({ ...state, answer: e.target.value })
                      }
                    >
                      <option value="">Select Ideal Answer</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  )}
                </div>
                <div className="form-group w-30">
                  <label>Answer Type</label>
                  <select
                    className="form-control"
                    value={ansType}
                    onChange={(e) => setAnsType(e.target.value)}
                    id="sel1"
                  >
                    <option value="select">Yes/No</option>
                    <option value="numeric">Numeric</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="">
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input mr-2"
                    id={`customCheck${index}`}
                    name="qualification"
                    checked={state.recommended}
                    onChange={(e) =>
                      setState({
                        ...state,
                        recommended: e.target.checked ? 1 : 0,
                      })
                    }
                  />
                  <label
                    className="custom-control-label pt-1 pl-2"
                    htmlFor={`customCheck${index}`}
                  >
                    Must–have qualification
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomQuestions;
