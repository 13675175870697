import React, { useEffect, useState } from "react";
import dummyImage from "../../../../assets/dummy.jpg";
import { Link } from "react-router-dom";
import ApplicantResumeView from "./ApplicantResumeView";
import ApplicantScreeningQuestionRespons from "./ApplicantScreeningQuestionRespons";

const InsightFromProfile = ({ profile, allQsns }) => {
  const [edu, setEdu] = useState([]);
  const [exp, setExp] = useState([]);
  const [hobby, setHobby] = useState([]);
  const [projects, setProjects] = useState([]);
  const [skills, setSkills] = useState([]);
  const [achive, setAchieve] = useState([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (profile?.educationQualifications.length > 0) {
      setEdu(profile?.educationQualifications);
    }
    if (profile?.workExperiences.length > 0) {
      setExp(profile?.workExperiences);
    }
    if (profile?.skills.length > 0) {
      setSkills(profile?.skills);
    }
    if (profile?.projects.length > 0) {
      setProjects(profile?.projects);
    }
    if (profile?.hobbies.length > 0) {
      setHobby(profile?.hobbies);
    }
    if (profile?.achievements.length > 0) {
      setAchieve(profile?.achievements);
    }
  }, [profile]);

  //get year
  function extractYearFromDate(dateString) {
    const date = new Date(dateString);
    return date.getFullYear();
  }
  return (
    <>
      <div className="card mt-3">
        <div className="card-header bg-transparent border-bottom-0">
          <h3 className="card-title fw-500 light-black">
            Insights from profile
          </h3>
        </div>
        <div className="card-body pt-0 pb-0">
          {profile?.expertise && (
            <div className="post-job-info mb-3">
              <h4>Expertise </h4>

              <div className="mt-3">
                <div className="applicant-icon d-flex">
                  <div>
                    <p>• {profile.expertise ?? "*"}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {exp?.length > 0 && (
            <div className="post-job-info mb-3">
              <h4>Experience </h4>
              {exp?.map((row, index) => (
                <div className="mt-3">
                  <div className="applicant-icon d-flex">
                    <img src={dummyImage} alt="applicant" className="mr-3" />
                    <div>
                      <p>{row?.profile_headline}</p>
                      <p className="fa-14 gray">{row?.company_name}</p>
                      <p className="fa-14 gray">
                        {" "}
                        {extractYearFromDate(row?.start_date)}-
                        {row?.present_job
                          ? "Present"
                          : extractYearFromDate(row?.end_date)}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <hr />
          {edu?.length > 0 && (
            <div className="post-job-info mb-3">
              <h4>Education </h4>
              {edu?.map((row, index) => (
                <div className="mt-3">
                  <div className="applicant-icon d-flex">
                    <img src={dummyImage} alt="applicant" className="mr-3" />
                    <div>
                      <p>{row?.institution} </p>
                      <p className="fa-14 gray">
                        {`${row?.degree} ${row?.department}`}
                      </p>
                      <p className="fa-14 gray">
                        {" "}
                        {row?.start_year}-{row?.end_year}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {showMore && (
            <>
              <hr />
              {achive?.length > 0 && (
                <div className="post-job-info mb-3">
                  <h4>Achievements </h4>
                  {achive?.map((row, index) => (
                    <div className="mt-3">
                      <div className="applicant-icon d-flex">
                        <div>
                          <p>• {row?.title} </p>
                          <p className="fa-14 gray">
                            {" "}
                            {row.from_year ?? ""}-{row.to_year ?? ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {projects?.length > 0 && (
                <div className="post-job-info mb-3">
                  <h4>Projects </h4>
                  {projects?.map((row, index) => (
                    <div className="mt-3">
                      <div className="applicant-icon d-flex">
                        <div>
                          <p>• {row?.title} </p>
                          <p className="fa-14 gray"> {row.description}</p>
                          <p className="fa-14 gray">
                            {" "}
                            {row.start_month ?? "*"} {row.start_year ?? "*"}-{" "}
                            {row.end_month ?? "*"} {row.end_year ?? "*"}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {skills?.length > 0 && (
                <div className="post-job-info mb-3">
                  <h4>Skills </h4>
                  {skills?.map((row, index) => (
                    <div className="mt-3">
                      <div className="applicant-icon d-flex">
                        <div>
                          <p>• {row?.title} </p>
                          <p className="fa-14 gray"> {row.description}</p>
                          <p className="fa-14 gray">
                            {" "}
                            Exp: {row.year_of_experience ?? "*"}{" "}
                            {row.month_or_year ?? "*"}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {hobby?.length > 0 && (
                <div className="post-job-info mb-3">
                  <h4>Hobbies </h4>
                  {hobby?.map((row, index) => (
                    <div className="mt-3">
                      <div className="applicant-icon d-flex">
                        <div>
                          <p>
                            {index + 1}. {row.name ?? "*"}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          <div class="card-footer text-center bg-transparent">
            {showMore ? (
              <Link
                onClick={() => setShowMore(false)}
                className="link-text fw-500 fa-18 text-center"
              >
                See less
              </Link>
            ) : (
              <Link
                onClick={() => setShowMore(true)}
                className="link-text fw-500 fa-18 text-center"
              >
                See full profile
              </Link>
            )}
          </div>
        </div>
      </div>
      <ApplicantResumeView profile={profile} />
      <ApplicantScreeningQuestionRespons allQsns={allQsns} />
    </>
  );
};

export default InsightFromProfile;
