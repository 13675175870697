import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import "./HelpSearch.css";
import { RxCrossCircled } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
const HelpSearch = ({ allFaqs }) => {
  const [results, setResults] = useState([]);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchText(query);
    if (query) {
      const filter = allFaqs?.filter((elem) =>
        elem?.question?.toLowerCase().includes(query?.toLowerCase())
      );
      setResults(filter);
    } else {
      setResults([]);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setResults([]);
    setSearchText([]);
  };

  return (
    <>
      <form className="search-form" onSubmit={(e) => handleSearch(e)}>
        <div className="position-relative">
          <input
            type="text"
            placeholder="Search by keyword..."
            value={searchText}
            onChange={(e) => handleSearchChange(e)}
            name="search"
          />
          <button disabled={searchText.length === 0}>
            {searchText?.length === 0 ? (
              <BsSearch className="fa-24" />
            ) : (
              <RxCrossCircled className="fa-28" />
            )}
          </button>
          {results?.length > 0 && (
            <div className="position-absolute dropdown-results" style={{ top: "60px" }}>
              {results?.map((row, index) => (
                <div
                  key={index}
                  onClick={() =>
                    navigate(`/job-seeker-search-question/${row?.id}`)
                  }
                >
                  {row?.question}
                </div>
              ))}
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default HelpSearch;
