import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { socialLogin } from "../../../Redux/authSlice";

const Callback = () => {
  const { provider } = useParams();
  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(socialLogin(provider))
      .unwrap()
      .then(() => {
        // Redirect to the desired page after successful login
        window.location.href = "/dashboard";
      })
      .catch((error) => {
        // Handle errors (e.g., display an error message)
        toast.error(error);
      });
  }, [dispatch, provider]);

  return <div>Logging in...</div>;
};

export default Callback;
