import React, { useEffect, useState } from "react";
import "../../../CommonComponents/InputTag.css";
import { AiOutlineEdit, AiOutlinePlusCircle } from "react-icons/ai";
//import Dummy from "../../../../assets/dummy.jpg";
import AddNewExperience from "./ProfileModal/AddNewExperience";
import { useSelector } from "react-redux";
import { MdOutlineModeEditOutline } from "react-icons/md";

const ProfileExperiences = () => {
  const [listItems, setListItems] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false); //flag to track that new achievement added
 
  const profile = useSelector((state) => state?.user?.profile);
  //hook to call getAllExperience
  // useEffect(() => {
  //   getAllExperiences(authId, setListItems, setIsLoading);
  // }, [updateFlag, authId]);
  useEffect(() => {
    setListItems(profile?.workExperiences);
  }, [profile]);

  //function to get date
  function formatMonthYear(inputDate) {
    try {
      const dateObj = new Date(inputDate);

      // Array of month names
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Get month and year from the date object
      const month = months[dateObj.getMonth()];
      const year = dateObj.getFullYear();

      // Formatted month and year string
      const formattedMonthYear = `${month} ${year}`;

      return formattedMonthYear;
    } catch (error) {
      return "-::-";
    }
  }

// function to calulate duration
function calculateDurationBetweenTimes(timeStringA, timeStringB, present_job) {
  try {
    const dateA = new Date(timeStringA);
    const dateB = present_job ? new Date() : new Date(timeStringB);

    // Calculate the difference in years and months
    const yearDiff = dateB.getFullYear() - dateA.getFullYear();
    const monthDiff = dateB.getMonth() - dateA.getMonth();

    // Calculate the total months, considering years
    const totalMonths = yearDiff * 12 + monthDiff;

    // Convert years and months to a human-readable format
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    // Create the formatted duration string
    let duration = "";
    if (years > 0) {
      duration += years + (years === 1 ? " yr " : " yrs ");
    }
    if (months > 0) {
      duration += months + (months === 1 ? " month" : " months");
    }

    return duration.trim();
  } catch (error) {
    return "-::-";
  }
}

  return (
    <div className="profile-summary-container">
      <div className="profile-summary-header line-height-0 d-flex">
        <div className="profile-summary-heading">
          <h2>Experiences</h2>
        </div>
        <div className="profile-summary-icon">
          <AddNewExperience
            updateFlag={updateFlag}
            setUpdateFlag={setUpdateFlag}
            icon={<AiOutlinePlusCircle className="gray fa-28 fa-mdd-24" />}
            className="icon-deafult"
          />
        </div>
      </div>

      {listItems &&
        listItems?.map((row, index) => (
          <div
            className="profile-summary-body d-flex justify-content-between mt-1"
            key={index}
          >
            <div className="profile-summary-inner-container d-flex">
              <div className="profile-view-content">
                <h4>{row?.corporate_title?.title}</h4>
                <p className="d-flex" style={{ gap: "10px" }}>
                  <p>{row?.company_name ?? "-"} </p> •
                  <p>{row?.employment_type?.title ?? "-"} </p>•
                  <p>{" " + row?.job_type?.type ?? "-"} </p>{" "}
                </p>
                <p key={index}>
                  {formatMonthYear(row?.start_date)}-
                  {row?.end_year ? formatMonthYear(row?.start_date) : "Present"}{" "}
                  •
                  {"   " +
                    calculateDurationBetweenTimes(
                      row?.start_date,
                      row?.end_date ?? Date.now(),
                      row?.present_job
                    )}
                </p>
                <p key={index}>{row?.description}</p>
              </div>
            </div>
            <div>
              <AddNewExperience
                updateFlag={updateFlag}
                setUpdateFlag={setUpdateFlag}
                editIt={row}
                icon={
                  <MdOutlineModeEditOutline className="gary ml-1 ml-sm-3 fa-28 fa-mdd-24" />
                }
                className="icon-deafult"
              />
            </div>
          </div>
        ))}

      {listItems?.length === 0 && (
        <div className="d-flex justify-content-center w-100 mb-2">
          No Experience, Add Now!
        </div>
      )}
    </div>
  );
};

export default ProfileExperiences;
