import React, { useEffect, useState } from "react";

const VerticalTab = ({ allFaqs, currQsn }) => {
  const [allTopicQsn, setAllTopicQsn] = useState([]);
  const [activeTab, setActiveTab] = useState();
  const [currTopic, setCurrTopic] = useState("");

  useEffect(() => {
    if (allFaqs?.length > 0 && currQsn / 1) {
      const qsn = allFaqs?.filter((elem) => elem.id === +currQsn)[0];
      if (qsn?.id) {
        setCurrTopic(qsn?.topic);
      }
    } else {
      setCurrTopic(currQsn);
    }
  }, [allFaqs,currQsn]);

  useEffect(() => {
    if (currTopic) {
      const qsns = allFaqs?.filter((elem) => elem?.topic === currTopic);
      setAllTopicQsn(qsns);
    }
  }, [currTopic,currQsn]);
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  useEffect(() => {
    if (+currQsn / 1) {
      setActiveTab(+currQsn)
    } else {
      if (allTopicQsn?.length > 0) {
        setActiveTab(allTopicQsn[0]?.id);
      } else {
        setActiveTab(0);
      }
    }
  }, [allTopicQsn,currQsn]);
  return (
    <>
      <div className="search-question pb-4">
        <h3>{currTopic}</h3>
      </div>
      <div className="vertical-tab-container">
        <div className="vertical-tab-menu">
          <div className="custom-scrollbar">
            {allTopicQsn?.map((row, index) => (
              <div
                key={index}
                className={`tab-item ${activeTab === row?.id ? "active" : ""}`}
                onClick={() => handleTabClick(row?.id)}
              >
                {row?.question}
              </div>
            ))}
          </div>
        </div>

        <div className="vertical-tab-content">
          {allTopicQsn?.map((row, index) => (
            <div
              key={index}
              className={`tab-content-detail ${
                activeTab === row?.id ? "active" : ""
              }`}
            >
              <div dangerouslySetInnerHTML={{ __html: row?.answer }}></div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default VerticalTab;
