import { Link } from "react-router-dom";
import "./PolicyPages.css";
import { useEffect, useState } from "react";
import authApi from "../../../../../utils/authApi";
import { DotSpinner } from "@uiball/loaders";
import Loader from "../../../../CommonComponents/Loader/Loader";

const TermsAndConditions = () => {
  const [terms, setTerms] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const getTerms = async () => {
    try {
      const res = await authApi.get(`get-terms-conditions`);
      setTerms(res?.data?.terms?.description);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTerms();
  }, []);
  return (
    <>
      <section>
        <div className="fluid-container side-space">
          <div className="header-contact-body ptb-5">
            <div className="header-contact-form-new">
              {isLoading && (
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              )}
              {!isLoading && (
                <div className="heading-policy">
                  <div
                    className="privecy-setting"
                    dangerouslySetInnerHTML={{
                      __html: terms ?? "<p>No Content</p>",
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
