import React, { useEffect, useRef, useState } from "react";
import authApi from "../../utils/authApi";
import axios from "axios";

const SearchForm = ({
  className,
  searching,
  setSearching,
  inputclass,
  title,
  jobLocation,
  setJobLocation,
  setTitle,
  setAllSearchedJobs,
  paramsObject,
  // handleSearchClick,
  exp,
  setTitleId,
  getData,
  jobType,
  company,
  datePosted,
  setExp,
  page,
  searchParam,
}) => {
  const [searchTitle, setSearchTitle] = useState("");
  const [searchState, setSearchState] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchedState, setSearchedState] = useState([]);
  const [searchExp, setSearchExp] = useState("");
  const [allCorporateTitles, setAllCorporateTitles] = useState([]);
  const [searchedTitle, setSearchedTitle] = useState([]); //state for searched titles;
  const [searchedExp, setSearchedExp] = useState([]);
  const dropdownRef = useRef(null); //dropdown for job title
  const dropdownExpRef = useRef(null); //dropdown for job experience
  const dropdownLocRef = useRef(null); //dropdown for job Location
  const [stateSearching, setStateSearching] = useState(false);
  const [searchIt, setSearchIt] = useState("");

  //experience array
  const Experience = [
    0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];
  //get All Corporat titles
  useEffect(() => {
    const getAllCorporateTitles = async () => {
      try {
        const res = await authApi.get(`get-active-corporate-title`);
        // setAllCorporateTitles(res?.data?.data);
      } catch (error) {}
    };
    getAllCorporateTitles();
  }, []);

  useEffect(() => {
    if (title) {
      const filter = allCorporateTitles?.filter((ele) => ele?.title === title);
      if (filter?.length > 0) {
        setTitleId(filter[0]?.id);
      }
    } else {
      setTitleId("");
    }
  }, [title]);

  let cancelToken1;
  const getSearchedTitles = async () => {
    if (typeof cancelToken1 != typeof undefined) {
      cancelToken1.cancel("Cancelled");
    }
    cancelToken1 = axios.CancelToken.source();
    try {
      const res = await authApi.get(`search-corporate/${searchIt}`, {
        cancelToken: cancelToken1.token,
      });
      setSearchedTitle(res?.data);
    } catch (error) {}
  };
  useEffect(() => {
    if (searchIt) {
      getSearchedTitles();
    } else {
      setTitle("");
      setSearchedTitle([]);
      // getData();
    }
    return () => {
      if (cancelToken1) {
        cancelToken1.cancel("Cancelled");
      }
    };
  }, [searchIt]);
  useEffect(() => {
    if (exp) {
      setSearchExp(exp);
    }
  }, [exp]);
  let cancelToken;
  const getLocation = async () => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Cancelled");
    }
    cancelToken = axios.CancelToken.source();

    try {
      if (searchState != "") {
        setStateSearching(true);
        const res = await authApi.get(`search-state/${searchState}`, {
          cancelToken: cancelToken.token,
        });
        setSearchedState(res?.data);
        setStateSearching(false);
      }
    } catch (error) {
      setStateSearching(false);
    }
  };
  useEffect(() => {
    if (searchState) {
      getLocation();
    } else {
      setJobLocation("");
      // getData();
      setSearchState([]);
    }

    return () => {
      if (cancelToken) {
        cancelToken.cancel("Cancelled");
      }
    };
  }, [searchState]);

  const handleSearchClick = async (e) => {
    setSearching(true);
    e.preventDefault();
    try {
      const res = await authApi.post(`search-job?page=${page}`, {
        title: paramsObject.title,
        location: +paramsObject.location,
        required_experience: +paramsObject.exp,
        job_type: +jobType[0],
        company: company[0],
        date_posted: datePosted[0],
      });
      setAllSearchedJobs(res?.data?.data);
      setSearching(false);
    } catch (error) {
      setSearching(false);
    }
  };

  //hide dropdown when click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      //  console.log("event-target",event.target,"dropdown",dropdownRef.current)
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSearchedTitle([]); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setSearchTitle(title);
  }, [title]);
  //hide dropdown when click outside of exp
  useEffect(() => {
    const handleClickOutside = (event) => {
      //  console.log("event-target",event.target,"dropdown",dropdownRef.current)
      if (
        dropdownExpRef.current &&
        !dropdownExpRef.current.contains(event.target)
      ) {
        setSearchedExp([]); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //hide dropdown when click outside of exp
  useEffect(() => {
    const handleClickOutside = (event) => {
      //  console.log("event-target",event.target,"dropdown",dropdownRef.current)
      if (
        dropdownLocRef.current &&
        !dropdownLocRef.current.contains(event.target)
      ) {
        setSearchedState([]); // Close the dropdown
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //title change
  const handleTitleSearch = (e) => {
    const val = e.target.value;
    setSearchTitle(val);
    const filter = allCorporateTitles?.filter((ele) =>
      ele?.title?.toLowerCase()?.includes(val.toLowerCase())
    );
    setSearchedTitle(filter);
  };
  //experience change
  const handleExpSearch = (e) => {
    const val = e.target.value;
    setSearchExp(val);
    const filter = Experience?.filter((ele) =>
      ele?.toString()?.includes(val.toString())
    );
    setSearchedExp(filter);

    if (val == "") {
      setExp("");
      // getData();
    }
  };
  const handleLocSearch = (e) => {
    const val = e.target.value;
    setSearchState(val);
    setSelectedLocation(val);
  };

  const getStateById = async () => {
    try {
      const res = await authApi.get(`states/${jobLocation}`);
      setSelectedLocation(
        `${res?.data?.data?.name}, ${res?.data?.data?.country?.name}`
      );
    } catch (error) {}
  };
  useEffect(() => {
    if (jobLocation) {
      getStateById();
    }
  }, [jobLocation]);

  return (
    <>
      <div className="search-form">
        <form>
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <div className="form-group">
                <label htmlFor="what" className={`${className}`}>
                  What?
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    className={`form-control ${inputclass} `}
                    id="what"
                    placeholder="Profession, Keyword or Company"
                    value={searchTitle}
                    onChange={(e) => {
                      setSearchIt(e.target.value);
                      handleTitleSearch(e);
                      setTitle(e.target.value);
                    }}
                  />
                  <div ref={dropdownRef}>
                    {searchedTitle.length > 0 && (
                      <div
                        className="position-absolute w-100 bg-light col"
                        style={{
                          top: "45px",
                          border: "1px solid lightgray",
                          borderRadius: "12px",
                          minHeight: "max-content",
                          maxHeight: "150px",
                          overflowY: "scroll",
                          zIndex: "100",
                          boxShadow:
                            " rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                        }}
                      >
                        {searchedTitle?.map((elem, index) => (
                          <div
                            className="pt-3 pb-3 pl-3 row"
                            style={{
                              cursor: "pointer",
                              borderTop: index > 0 && "1px solid lightgray",
                            }}
                            onClick={() => {
                              setSearchTitle(elem.title);
                              setSearchedTitle([]);
                              setTitle(elem.title);
                              setTitleId(elem?.id);
                            }}
                          >
                            {elem.title}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <div className="form-group">
                <label htmlFor="experience" className={`${className}`}>
                  Experience
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    className={`form-control ${inputclass}`}
                    id="experience"
                    placeholder="Yrs. Of Exp."
                    value={searchExp}
                    onChange={(e) => handleExpSearch(e)}
                  />
                  <div ref={dropdownExpRef}>
                    {searchedExp.length > 0 && (
                      <div
                        className="position-absolute w-100 bg-light col"
                        style={{
                          top: "45px",
                          border: "1px solid lightgray",
                          borderRadius: "12px",
                          minHeight: "max-content",
                          maxHeight: "150px",
                          overflowY: "scroll",
                          zIndex: "100",
                          boxShadow:
                            " rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                        }}
                      >
                        {searchedExp?.map((elem, index) => (
                          <div
                            className="pt-3 pb-3 pl-3 row"
                            style={{
                              cursor: "pointer",
                              borderTop: index > 0 && "1px solid lightgray",
                            }}
                            onClick={() => {
                              setSearchExp(elem);
                              setSearchedExp([]);
                              setExp(elem);
                            }}
                          >
                            {elem}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <div className="form-group">
                <label htmlFor="city" className={`${className}`}>
                  Where?
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    className={`form-control ${inputclass}`}
                    id="city"
                    value={selectedLocation}
                    onChange={(e) => handleLocSearch(e)}
                    placeholder="City of Region"
                  />
                  <div ref={dropdownLocRef}>
                    {searchedState.length > 0 && (
                      <div
                        className="position-absolute w-100 bg-light col"
                        style={{
                          top: "45px",
                          border: "1px solid lightgray",
                          borderRadius: "12px",
                          minHeight: "max-content",
                          maxHeight: "150px",
                          overflowY: "scroll",
                          zIndex: "100",
                          boxShadow:
                            " rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                        }}
                      >
                        {stateSearching && (
                          <div className="d-flex justify-content-center my-2">
                            <div class="spinner-border text-muted"></div>
                          </div>
                        )}
                        {!stateSearching &&
                          searchedState?.map((elem, index) => (
                            <div
                              className="pt-3 pb-3 pl-3 row"
                              style={{
                                cursor: "pointer",
                                borderTop: index > 0 && "1px solid lightgray",
                              }}
                              onClick={() => {
                                setSelectedLocation(
                                  `${elem?.name}, ${elem?.country?.name}`
                                );

                                setSearchedState([]);
                                setJobLocation(elem?.id);
                              }}
                            >
                              {elem?.name}, {elem?.country?.name}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 btn-center">
              <button
                disabled={searching}
                type="submit"
                onClick={(e) => getData()}
                className="btn-theme mt-3"
              >
                {searching ? "Searching" : "Search Now"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SearchForm;
