import React from 'react';
import {Link} from 'react-router-dom';
import './JobSearchSteps.css';
import {FaAngleRight}  from 'react-icons/fa';
import JobSearchStepsCard from './JobSearchStepsCard';

const JobSearchSteps = () => {
  return (
    <>
      
        <div className="container-fluid side-space">
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
              <div className="personal-recruiter-left">
                <h3>
                Job Search <br className='for-brake'/>Made Easy
                </h3>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 border-left-3 d-flex align-items-center deafult-mob-height">
              <div className="job-search-list w-100">
                <div className="job-search-stpes ml-0 ml-sm-3 ml-xl-5 ">
                    <JobSearchStepsCard title="description " description ="Complete our short questionnaire to help us find jobs that are best suited to your needs."/>
                    <JobSearchStepsCard title="Match" description="Complete our short questionnaire to help us find jobs that are best suited to your needs." />
                    <JobSearchStepsCard title="Auto
Apply" description="Complete our short questionnaire to help us find jobs that are best suited to your needs."/>
                </div>

                <div className=" text-center text-xl-right ">
                   <Link to="" className="btn-theme fa-20 default-padding">Try #inJobsight for Free <FaAngleRight/></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
     
    </>
  )
}

export default JobSearchSteps
