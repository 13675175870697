import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Link, useNavigate } from "react-router-dom";
import "./JobPost.css";
import { FaRegEdit } from "react-icons/fa";
import { BsFillQuestionSquareFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import authApi from "../../../utils/authApi";
import { setJobState, setPreviewState } from "../../../Redux/userSlice";
import Select from "react-select";
import axios from "axios";
import PageNotFound from "../Pages/PageNotFound/PageNotFound";

const initialData = {
  title: "",
  company_id: "",
  job_type_id: "",
  employment_type_id: "",
  title_id: "",
  state_id: "",
  city_id: "",
  country_id: "",
};
//function to get all states based on country
const getAllStates = async (countryId, setActiveStates) => {
  if (countryId) {
    try {
      const res = await authApi.get(`countries/${countryId}/states`);
      setActiveStates(res?.data?.data);
    } catch (error) {}
  }
};
const JobPost = () => {
  const authId = useSelector((state) => state?.auth?.user?.info?.id); //user id;
  const jobState = useSelector((state) => state.user.jobState);
  const profile = useSelector((state) => state?.auth?.user?.info); //user id;

  const preview = useSelector((state) => state?.user?.preview);
  const [data, setData] = useState(initialData);
  const [jobFlag, setJobFlag] = useState(""); //flag to check that job type is remote onsite or anything else
  const [allCorporateTitles, setAllCorporateTitles] = useState([]);
  const [allJobTypes, setAllJobTypes] = useState([]);
  const [allEmpTypes, setAllEmpTypes] = useState([]);
  const [titleQuery, setTitleQuery] = useState(""); //query to search title
  const [searchedTitle, setSearchedTitle] = useState([]); //state for searched titles
  const [activeCountries, setActiveCountries] = useState([]); //state for saving countries
  const [activeStates, setActiveStates] = useState([]); //state for saving states
  const [activeCities, setActiveCities] = useState([]);
  const [country_id, setCountry_id] = useState("");
  const [state_id, setState_id] = useState("");
  const [searchIt, setSearchIt] = useState([]);
  const [searching, setSearching] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  // https://jobapi.quicsy.com/api/recruiter/search-corporate?search=abc
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //function to get all active countries

  const getAllCountries = async () => {
    try {
      const res = await authApi.get(`countries`);
      setActiveCountries(res?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    getAllCountries();
  }, []);

  //function to get all active countries
  const getAllCities = async () => {
    if (data?.state_id) {
      try {
        const res = await authApi.get(`states/${data?.state_id}/cities`);
        setActiveCities(res?.data?.data);
      } catch (error) {}
    }
  };
  useEffect(() => {
    getAllCities();
  }, [data?.state_id]);

  //useEffect to get all active states
  useEffect(() => {
    getAllStates(data.country_id, setActiveStates);
  }, [data.country_id]);

  //get All Corporat titles
  useEffect(() => {
    const getAllCorporateTitles = async () => {
      try {
        const res = await authApi.get(`get-active-corporate-title`);
        setAllCorporateTitles(res?.data?.data);
      } catch (error) {}
    };
    getAllCorporateTitles();
  }, []);
  //get All Job Type
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await authApi.get(`get-active-job-type`);
        setAllJobTypes(res?.data?.data);
      } catch (err) {}
    };
    fetch();
  }, []);
  //set All Emp Types
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await authApi.get(`get-active-employment-type`);
        setAllEmpTypes(res?.data?.data);
      } catch (err) {}
    };
    fetch();
  }, []);

  useEffect(() => {
    document.body.classList.add("job-post-bg"); // Add the class to the body element
    return () => {
      document.body.classList.remove("job-post-bg"); // Remove the class when the component is unmounted
    };
  }, []);

  let cancelToken;
  const searchTitles = async () => {
    try {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("cancelled");
      }
      cancelToken = axios.CancelToken.source();
      // 
      if (searchIt!='') {
        setSearching(true);
        const res = await authApi.get(
          `recruiter/search-corporate?search=${searchIt}`,
          { cancelToken: cancelToken.token }
        );
        const data=res?.data?.results;
        let newData = data.flatMap(({ title, alternate_names,id }) => {
          let names = [];
          try {
              names = JSON.parse(alternate_names);
          } catch (error) {
              console.error("Error parsing alternate names:", error);
          }
          if(names?.length>0){
            return [title, ...names].map(name => ({ title: name,id:id }));
          }else{
            return [title].map(name => ({ title: name, id:id}));
          }
         
      });
      if(newData?.length>0){
        setSearchedTitle(newData);
        setSearching(false);
      }else{
        setSearchedTitle(newData);
        setSearching(false);
      }
        
      }
      
    } catch (error) {
      // setSearching(false);
    }
  };

  useEffect(() => {
    if (searchIt) {
      searchTitles();
    } else {
      setSearching(false);
      setSearchedTitle([]);
    }
    return () => {
      if (cancelToken) {
        cancelToken.cancel("Cancelled");
      }
    };
  }, [searchIt]);


  //title change
  const handleTitleSearch = (e) => {
    setSearchIt(e.target.value);
    // const val = e.target.value;
    // setTitleQuery(val);
    // const filter = allCorporateTitles?.filter((ele) =>
    //   ele?.title?.toLowerCase()?.includes(val.toLowerCase())
    // );
    // setSearchedTitle(filter);
  };

  //get started click
  const handleGetStarted = () => {
    data.company_id = authId;
    dispatch(setJobState({ ...jobState, ...data }));
    navigate("/job-post-summary");
  };

  //funcion will return boolean to go further
  const shouldWeGo = () => {
    if (data.employment_type_id && data.job_type_id && data.title_id) {
      if (jobFlag === "Onsite" || jobFlag === "Hybrid") {
        if (data?.state_id && data?.country_id) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  };
  return (
    <>

        <section className="pt-4 pb-4">
          <div className="container-fluid side-space">
            <div className="m-auto">
              <div className="row justify-content-center ">
                <div className="col-xl-4 col-lg-6 col-md-8 col-sm-12">
                  <div className="job-post  border-radius-8">
                    <div className="job-heading pb-3">
                      <h3>Post a job now</h3>
                      <p>
                        <sub>Rated #1 in increasing quality of hire.</sub>
                      </p>
                    </div>
                    <div className="job-role-form form-container">
                      <form>
                        <div className="form-group">
                          <label htmlFor="title">
                            Job Title
                            <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative">
                            <div className="d-flex">
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Add the title you are hiring for"
                                id="corpTitle"
                                // value={searchIt}
                                onChange={(e) => handleTitleSearch(e)}
                              />
                              {searching && (
                                <div className="spinner-border text-muted ml-2 d-flex-align-items-center"></div>
                              )}
                            </div>

                            {searchedTitle.length > 0 && searchIt && (
                              <div
                                className="position-absolute w-100 bg-light col"
                                style={{
                                  top: "40px",
                                  border: "1px solid lightgray",
                                  borderRadius: "8px",
                                  minHeight: "max-content",
                                  maxHeight: "150px",
                                  overflowY: "scroll",
                                  boxShadow:
                                    " rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                                }}
                              >
                                {searchedTitle?.map((elem, index) => (
                                  <div
                                    className="pt-3 pb-3 pl-2 row"
                                    style={{
                                      cursor: "pointer",
                                      borderTop:
                                        index > 0 && "1px solid lightgray",
                                    }}
                                    onClick={() => {
                                      document.getElementById(
                                        "corpTitle"
                                      ).value = elem.title;
                                      setTitleQuery(elem.title);
                                      dispatch(
                                        setPreviewState({
                                          ...preview,
                                          title: elem?.title,
                                        })
                                      );
                                      setSearchedTitle([]);
                                      setData({
                                        ...data,
                                        title: elem.title,
                                        title_id: elem.id,
                                      });
                                    }}
                                  >
                                    {elem.title}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="workpalce-type">
                            Employment Type
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            // value={data?.employment_type_id}
                            onChange={(e) => {
                              dispatch(
                                setPreviewState({
                                  ...preview,
                                  employment_type:
                                    e.target.value?.split("-")[1],
                                })
                              );
                              setData({
                                ...data,
                                employment_type_id:
                                  e.target.value?.split("-")[0],
                              });
                            }}
                          >
                            <option value="">Select Employment Type</option>
                            {allEmpTypes?.map((elem, index) => (
                              <option
                                value={`${elem?.id}-${elem?.title}`}
                                key={index}
                              >
                                {elem?.title}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="job-type">
                            Job Type
                            <span className="text-danger">*</span>
                          </label>

                          <select
                            className="form-control"
                            value={`${data?.job_type_id}-${jobFlag}`}
                            onChange={(e) => {
                              dispatch(
                                setPreviewState({
                                  ...preview,
                                  job_type: e.target.value?.split("-")[1],
                                })
                              );
                              setData({
                                ...data,
                                job_type_id: e.target.value.split("-")[0],
                              });
                              setJobFlag(e.target.value.split("-")[1]);
                            }}
                          >
                            <option value="">Select Job Type</option>
                            {allJobTypes?.map((elem, index) => (
                              <option
                                value={`${elem?.id}-${elem.type}`}
                                key={index}
                              >
                                {elem?.type}
                              </option>
                            ))}
                          </select>
                        </div>
                        {(jobFlag === "Onsite" || jobFlag === "Hybrid") && (
                          <div className="form-group">
                            <label htmlFor="city">
                              City
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  city_id: e.value?.id,
                                });
                                dispatch(
                                  setPreviewState({
                                    ...preview,
                                    city: e.value?.label,
                                  })
                                );
                              }}
                              options={activeCities?.map((ele) => ({
                                label: ele?.name,
                                value: ele,
                              }))}
                            />
                            {/* <select
                            className="form-control"
                            // value={data.state_id}
                            disabled={!data.state_id}
                            onChange={(e) => {
                              dispatch(
                                setPreviewState({
                                  ...preview,
                                  city: e.target.value?.split("-")[1],
                                })
                              );
                              setData({
                                ...data,
                                city_id: e.target.value?.split("-")[0],
                              });
                            }}
                          >
                            <option value="">
                              {data?.state_id
                                ? "Select City"
                                : "Select State First"}
                            </option>
                            {activeCities?.map((elem, index) => (
                              <option
                                value={`${elem.id}-${elem?.name}`}
                                key={index}
                              >
                                {elem.name}{" "}
                              </option>
                            ))}
                          </select> */}
                          </div>
                        )}
                        {(jobFlag === "Onsite" || jobFlag === "Hybrid") && (
                          <div className="form-group">
                            <label htmlFor="state">
                              State
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  state_id: e.value?.id,
                                });
                                dispatch(
                                  setPreviewState({
                                    ...preview,
                                    state: e.value?.label,
                                  })
                                );
                              }}
                              options={activeStates?.map((ele) => ({
                                label: ele?.name,
                                value: ele,
                              }))}
                            />

                            {/* <select
                            className="form-control"
                            // value={data.state_id}
                            disabled={!data.country_id}
                            onChange={(e) => {
                              dispatch(
                                setPreviewState({
                                  ...preview,
                                  state: e.target.value?.split("-")[1],
                                })
                              );
                              setData({
                                ...data,
                                state_id: e.target.value?.split("-")[0],
                              });
                            }}
                          >
                            <option value="">
                              {data?.country_id
                                ? "Select State"
                                : "Select Country First"}
                            </option>
                            {activeStates?.map((elem, index) => (
                              <option
                                value={`${elem.id}-${elem?.name}`}
                                key={index}
                              >
                                {elem.name}{" "}
                              </option>
                            ))}
                          </select> */}
                          </div>
                        )}
                        {(jobFlag === "Onsite" || jobFlag === "Hybrid") && (
                          <div className="form-group">
                            <label htmlFor="country">
                              Country
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  country_id: e.value?.id,
                                });
                                dispatch(
                                  setPreviewState({
                                    ...preview,
                                    country: e.value?.label,
                                  })
                                );
                              }}
                              options={activeCountries?.map((ele) => ({
                                label: ele?.name,
                                value: ele,
                              }))}
                            />
                            {/* <select
                            className="form-control"
                            // value={data?.country_id}
                            onChange={(e) => {
                              dispatch(
                                setPreviewState({
                                  ...preview,
                                  state: e.target.value?.split("-")[1],
                                })
                              );
                              setData({
                                ...data,
                                country_id: e.target.value?.split("-")[0],
                              });
                            }}
                          >
                            <option value="">Please Select </option>
                            {activeCountries?.map((elem, index) => (
                              <option
                                value={`${elem.id}-${elem?.name}`}
                                key={index}
                              >
                                {elem.name}{" "}
                              </option>
                            ))}
                          </select> */}
                          </div>
                        )}

                        <div className="text-center mt-4">
                          <button
                            // to="/job-post-summary"
                            id="second-app-title"
                            className="btn blue-btn pl-5 pr-5 fa-20 fw-500"
                            onClick={() => handleGetStarted()}
                            disabled={shouldWeGo()}
                          >
                            Get Started
                          </button>
                          {shouldWeGo() && (
                            <ReactTooltip
                              anchorId="second-app-title"
                              place="top"
                              variant="info"
                              content="Please Fill All data to get started ! "
                            />
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                  <div className="job-post  border-radius-8 mt-lg-0 mt-3">
                    <div className="pb-3">
                      <Link
                        to="#"
                        className="btn btn-blue-outline w-100 fw-600 py-2"
                      >
                        Post a free job <FaRegEdit />
                      </Link>
                      <p className="mt-4 para-text mb-2">
                        Save up to 35% by purchasing job posting budget in
                        advance.
                      </p>
                      <Link className="link-text fa-18x ">Get discount</Link>
                    </div>
                  </div>
                  <div className="help-blok mt-3">
                    <p className="para-text">
                      <BsFillQuestionSquareFill /> Questions?{" "}
                      <span className="ml-2">
                        <Link to="#" className="link-text">
                          Contact Support
                        </Link>
                      </span>{" "}
                      <span className="mx-2">|</span>{" "}
                      <span>
                        {" "}
                        <Link to="#" className="link-text">
                          Help Center{" "}
                        </Link>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

    </>
  );
};

export default JobPost;
