import React from "react";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import BeInTheBest from "../../Homepage/BeInTheBest/BeInTheBest";
import AppliedJobsData from "../../../CommonComponents/AppliedJobsData";
import RecommendedJobs from "./RecommendedCandidates";
import SuggestedJobSearches from "./SuggestedJobSearches";
import RecentJobsPostData from "../../../CommonComponents/RecentJobsPostData";
import RecentJobsPost from "../../../CommonComponents/RecentJobsPostData";
import RecommendedCandidates from "./RecommendedCandidates";

const MyCandidates = () => {
  return (
    <>
      <section>
        <div className="fluid-container side-space">
          <div className="grid-2-container pt-4 pb-4">
            <div className="left-side grid-left">
              <LeftSidebar />
            </div>

            <div className="main-content grid-right mt-smm-0 mt-3">
              {/* <SuggestedJobSearches/> */}
              <RecommendedCandidates />
            </div>

           
          </div>
        </div>
      </section>

      {/* BeInTheBest */}

      {/* <section className="pt-4 pb-75">
        <BeInTheBest />
      </section> */}
    </>
  );
};

export default MyCandidates;
