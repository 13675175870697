import React from 'react';

import TargetCross from '../../../../assets/target-cross.png';
import Calendar from '../../../../assets/calendar.png';
import Dollar from '../../../../assets/dollar.png';
import EditTargetGoal from '../Dashboard/EditTargetGoal';
import { AiOutlineEdit } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { MdOutlineModeEditOutline } from 'react-icons/md';




const TargetGoal = () => {
  const target = useSelector((state) => state?.user?.profile?.target_job);



  return (
    <>
      <div className='summary-module-wrapper mb-3'>
        <div className='card'>
          <div className='card-header border-bottom-0 bg-transparent d-flex align-items-lg-start'>
            <h3 className='card-title'>Next Career Goal: Land a New Job</h3>  <EditTargetGoal icon={<MdOutlineModeEditOutline className='gray fa-24 ml-3'/>}/>
          </div>
          <div className='card-body'>
            <div className='row m-0'>
             
                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 p-0'>
                  <div className='goal-item d-flex align-items-center align-custom-items-start'>
                    <div className='goal-item-icon'>
                      <img src={TargetCross} className='img-fluid'  />
                    </div>
                    <div className='goal-item-content ml-2'>
                      <p className='goal-item-label'>Target title</p>
                      <h4 className='goal-item-value'>{target?.target_job??"Not set"}</h4>
                    </div>
                  </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 p-0' >
                  <div className='goal-item d-flex align-items-center align-custom-items-start'>
                    <div className='goal-item-icon'>
                      <img src={Calendar} className='img-fluid' />
                    </div>
                    <div className='goal-item-content ml-2'>
                      <p className='goal-item-label'>Target date</p>
                      <h4 className='goal-item-value'>{target?.target_date??"Not set"}</h4>
                    </div>
                  </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 p-0' >
                  <div className='goal-item d-flex align-items-center align-custom-items-start'>
                    <div className='goal-item-icon'>
                      <img src={Dollar} className='img-fluid'  />
                    </div>
                    <div className='goal-item-content ml-2'>
                      <p className='goal-item-label'>Target Salary Range</p>
                      <h4 className='goal-item-value'>{`$${target?.min_salary??'0'}-$${target?.max_salary??'0'}`}</h4>
                    </div>
                  </div>
                </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TargetGoal;

