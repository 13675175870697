import React, { useEffect, useMemo, useState } from "react";
import "./JobSearchResult.css";
import Icon from "../../../../assets/icon.png";
import ToggleSwitch from "../../../CommonComponents/ToggleSwitch";
import { BsThreeDots } from "react-icons/bs";
import { DotSpinner } from "@uiball/loaders";
import { useLocation, useSearchParams } from "react-router-dom";
import authApi from "../../../../utils/authApi";
import Pagination from "../../../CommonComponents/Pagination";
import JobDescription from "../JobSearchResult/JobDescription";
import JobBasicDetail from "../JobSearchResult/JobBasicDetail";
import { toast } from "react-toastify";
import Loader from "../../../CommonComponents/Loader/Loader";

const AllJobs = () => {
  const [showThis, setShowThis] = useState(0);
  const [allLiveJobs, setAllLiveJobs] = useState([]);
  const [searching, setSearching] = useState(true);
  const [showItsInfo, setShowItsInfo] = useState({});
  const [page, setPage] = useState(1);
  const [jobAlert,setJobAlert]=useState(false)
  const [allPages, setAllPages] = useState(1);

  useEffect(() => {
    setShowItsInfo(allLiveJobs[showThis]);
  }, [showThis, allLiveJobs]);
  //hook to get all live jobs
  useEffect(() => {
    const getAllLiveJobs = async () => {
      try {
        const res = await authApi.get(`get-live-jobs`);
        setAllLiveJobs(res?.data?.jobs?.data);
        setAllPages(Math.ceil(res?.data?.jobs?.total / res?.data?.jobs?.per_page));
        setSearching(false)
      } catch (error) {
        setSearching(false)
      }
    };
    getAllLiveJobs();
  }, []);

  //to find time gap
  function getTimeDifferenceString(timeString) {
    const currentTime = new Date();
    const pastTime = new Date(timeString);
    const timeDifference = currentTime - pastTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 30) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    }
  }

  const saveJobAlert=async()=>{
    try{

    }catch(error){
      toast.error(error?.response?.data?.message)
    }
  }
// console.log(jobAlert);
  return (
    <>
     {searching && (
                    <div className="d-flex justify-content-center mt-5">
                      {" "}
                      <Loader />
                    </div>
                  )}

   { !searching &&  <section className="py-4">
        <div className="container-fluid side-space">
          <div className="grid-2-1-container">
            <div className="search-filter-left grid-left">
              <div className="search-filter-result d-flex justify-content-between">
                <div className="search-counting">
                  <h4 className="fa-14 black fw-600 mb-0">Jobs in India </h4>
                </div>
                <div className="d-flex pr-3">
                  <span className="mr-3 mt-1">Set Job Alert</span>
                  <ToggleSwitch onChange={setJobAlert} />
                </div>
              </div>
              {/* Searched Jobs */}
              <div className="searched-job">
                <div className="mb-4">
                  <div>
                    <div className="heading">
                      {/* <h3 className={`job-card-title`}>
                        Searched Results {title ? `for ${title}` : ""}
                      </h3>
                      <p className="fa-14 black mt-1">
                        {allSearchedJobs?.length} Results
                      </p> */}
                    </div>
                    <div>
                      {allLiveJobs?.map((item, itemIndex) => (
                        <div
                          className={`job-card`}
                          key={itemIndex}
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowThis(itemIndex)}
                        >
                          <div className="job-card-icon">
                            <img
                              src={Icon}
                              className="img-fluid"
                              alt="job icon"
                            />
                          </div>
                          <div className="job-card-content">
                            <h4>
                              {item?.title}{" "}
                              {item?.job_type?.type
                                ? " - " + item?.job_type?.type
                                : ""}{" "}
                              {item?.employment_type?.title
                                ? " - " + item?.employment_type?.title
                                : ""}{" "}
                            </h4>
                            <p>
                              {item?.company?.name}
                              {item?.company?.state?.name
                                ? ", " + item?.company?.state?.name
                                : ""}
                              {item?.company?.country?.name
                                ? ", " + item?.company?.country?.name
                                : ""}
                            </p>
                            <div className="job-content-inner d-sm-flex d-block align-items-center mt-2 justify-content-between">
                              {/* <div className="job-content-inner-icon d-flex  align-items-center">
                                {<BiNotepad />}
                                <p className="pl-1">{item.status}</p>
                              </div> */}
                              <div className="job-content-inner-content d-flex justify-content-start justify-content-sm-end mt-sm-0 mt-2">
                                <p>
                                  {getTimeDifferenceString(item?.created_at)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {allLiveJobs?.length > 0 && (
                  <div className="pagination-row float-right mt-3">
                    <Pagination
                      page={page}
                      setPage={setPage}
                      allPages={allPages}
                    />
                  </div>
                )}
              </div>
            </div>
            {/*end  search result */}
            {searching && (
              <div className="d-flex justify-content-center mt-5">
                {" "}
                <Loader />
              </div>
            )}
            {allLiveJobs?.length > 0 &&(
              <div className="search-filter-right grid-middle">
                <div className="card search-filter-container border-0">
                  <div className="card-body">
                    <div className="job-basic-info d-flex justify-content-between">
                      <div>
                        <JobBasicDetail
                          item={showItsInfo}
                          classNameCard="border-0"
                        />
                      </div>
                      <div className="float-right">
                        <div className="three-dot">
                          <BsThreeDots className="gray fa-24" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="job-description">
                  <div className="card border-0  pt-4">
                    <div className="card-body pl-0">
                      <JobDescription item={showItsInfo} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>}
    </>
  );
};

export default AllJobs;
