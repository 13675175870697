import React, { useState } from "react";
import axios from "axios";
import authApi from "../../../../utils/authApi";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../Redux/userSlice";
// prefill: {
//   name: 'John Doe', // Prefill name
//   email: 'john@example.com', // Prefill email
//   contact: '9876543210', // Prefill contact number
// },
const PaymentComponent = ({ p_id, data, getActivePackage }) => {
  const [paymentData, setPaymentData] = useState({});
  const [loading, setLoading] = useState(false);
  const loginAs = useSelector((state) => state.auth.loginAs);
  const profile = useSelector((state) => state?.user?.profile);
  const RazorPayId = process.env.REACT_APP_RAZOR_PAY_KEY_ID;
  const dispatch = useDispatch();

  const handlePayment = async () => {
    try {
      setLoading(true);

      // Make a request to your Laravel backend to initiate the payment
      if (loginAs === "Candidate") {
        const response = await authApi.post(`payment/${p_id}`, {
          amount: data?.grand_total, // Set the amount as needed
        });

        setPaymentData(response.data);
        // Initiate the Razorpay checkout form
        const options = {
          key: RazorPayId,
          amount: +response.data.data.amount,
          currency: "USD",
          // name: 'Designdot',
          // description: 'Payment for your order',
          order_id: response.data.data.id,
          prefill: {
            name: `${profile?.first_name} ${profile?.last_name}`, // Prefill name
            email: profile?.email, // Prefill email
            contact: profile?.phone, // Prefill contact number
          },
          handler: function (res) {
            // Handle the successful payment callback
            

            // Make a request to your Laravel backend to handle the Razorpay callback
            authApi
              .post("payment/callback", {
                razorpay_payment_id: res.razorpay_payment_id,
                razorpay_order_id: res.razorpay_order_id,
                razorpay_signature: res.razorpay_signature,
              })
              .then((callbackResponse) => {
                getActivePackage();

                console.log("Callback response:", callbackResponse.data);
              })
              .catch((error) => {
                console.error("Error handling callback:", error);
              });
          },
        };

        const razorpay = new window.Razorpay(options);
        razorpay.open();
      } else {
        const response = await authApi.post(`recruiter/payment/${p_id}`, {
          amount: data?.grand_total, // Set the amount as needed
        });

        setPaymentData(response.data);
        // Initiate the Razorpay checkout form
        const options = {
          key: RazorPayId,
          amount: +response.data.data.amount,
          // currency: response.data.data.currency,
          // name: 'Your Company Name',
          // description: 'Payment for your order',
          order_id: response.data.data.id,
          prefill: {
            name: profile?.name, // Prefill name
            email: profile?.email, // Prefill email
            contact: profile?.phone, // Prefill contact number
          },
          handler: function (res) {
            // Handle the successful payment callback

            // Make a request to your Laravel backend to handle the Razorpay callback
            authApi
              .post("recruiter/payment/callback", {
                razorpay_payment_id: res.razorpay_payment_id,
                razorpay_order_id: res.razorpay_order_id,
                razorpay_signature: res.razorpay_signature,
              })
              .then((callbackResponse) => {
                getActivePackage();
                console.log("Callback response:", callbackResponse.data);
              })
              .catch((error) => {
                console.error("Error handling callback:", error);
              });
          },
        };

        const razorpay = new window.Razorpay(options);
        razorpay.open();
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleActivateFree = async () => {
    setLoading(true);
    try {
      if(loginAs==='Candidate'){
        await authApi.post(`apply-free-package/${data?.id}`);
      }else{
        await authApi.post(`recruiter/apply-free-package/${data?.id}`);
      }


      dispatch(updateUser());
      getActivePackage();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div>
      <button
        onClick={() => {
          if (data?.title === "Free") {
            handleActivateFree();
          } else {
            handlePayment();
          }
        }}
        disabled={loading || (data?.title === "Free" && profile?.free_package_taken > 0)}
        className="btn btn-light"
      >
        {loading ? "Processing..." : "Activate"}
      </button>
    </div>
  );
};

export default PaymentComponent;
