import React, { useEffect, useState } from "react";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import DashboardInfo from "./DashboardInfo";
import AppliedJobsData from "../../../CommonComponents/AppliedJobsData";
import RecentJobsPostData from "../../../CommonComponents/RecentJobsPostData";
import { useDispatch, useSelector } from "react-redux";
import authApi from "../../../../utils/authApi";
import { saveProfile } from "../../../../Redux/userSlice";
import Comapnybanner from "../../../../assets/comapnybanner.jpg";
import officeimg from "../../../../assets/office-building.png";
import { FaDownload } from "react-icons/fa";
import { IMAGEURL } from "../../../../constant";
import { Link, useNavigate } from "react-router-dom";
import { LuExternalLink } from "react-icons/lu";
import { DotSpinner } from "@uiball/loaders";
import RecruiterDashboard from "./RecruiterDashboard";
import Loader from "../../../CommonComponents/Loader/Loader";
//get All companies
const getCompanies = async (userId, setProfile, setIsLoading) => {
  try {
    const res = await authApi.get(`recruiter/companies/${userId}`);
    setIsLoading(false);
    setProfile(res?.data?.data);
  } catch (error) {
    setIsLoading(false);
  }
};
const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authId = useSelector((state) => state?.auth?.user?.info?.id);
  const loginAs = useSelector((state) => state.auth.loginAs);
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  //useeffect for getting logged user data
  useEffect(() => {
    const getProfile = async () => {
      try {
        if (loginAs === "Candidate") {
          const res = await authApi.get(`users/${authId}`);
          dispatch(saveProfile(res?.data?.data));
        } else {
          const res = await authApi.get(`recruiter/companies/${authId}`);
          dispatch(saveProfile(res?.data?.data));
        }
      } catch (err) {}
    };
    getProfile();
  }, [authId, dispatch, loginAs]);

  //hook to call getProfile
  useEffect(() => {
    if(loginAs != "Candidate"){
      getCompanies(authId, setProfile, setIsLoading);
    }
  }, [authId]);
  //redirect to add page
  const handleAddPage = () => {
    navigate(`/company-edit`);
  };
  return (
    <>
      <section>
        <div className="container-fluid side-space">
          <div className="grid-container pt-4 pb-4">
            <div className="left-side grid-left">
              <LeftSidebar />
            </div>

            <div className="main-content grid-middle mt-3 mt-smm-0 ">
              {loginAs === "Candidate" && <DashboardInfo />}

              {loginAs === "Recruiter" && (
                <>
                  {isLoading && (
                    <div className="d-flex justify-content-center mt-5">
                      {" "}
                      <Loader />
                    </div>
                  )}

            {    !isLoading &&  <RecruiterDashboard />}
                </>
              )}
            </div>

            <div className="right-side  grid-right">
              {loginAs === "Candidate" && (
                <AppliedJobsData title="Applied Jobs" />
              )}
              {loginAs === "Recruiter" && (
                <RecentJobsPostData title="Recent Job Post" />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
