import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Modal.css";
import { useDispatch, useSelector } from "react-redux";
import authApi from "../../../../../utils/authApi";
import { toast } from "react-toastify";
import { updateUser } from "../../../../../Redux/userSlice";

const AddInterest = ({
  linkText,
  editIt,
  updateFlag,
  setUpdateFlag,
  icon,
  className,
}) => {
  const [showInterest, setShowInterest] = useState(false);
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const authId = useSelector((state) => state?.auth?.user?.info?.id); //user id
  const [saving, setSaving] = useState(false); //saving or not
  const [deleting, setDeleting] = useState(false); //deleting or not
  //useEffect for pre filling
  useEffect(() => {
    if (editIt?.id) {
      setName(editIt?.name);
    }
  }, [editIt]);
  const handleInterestsOpen = () => {
    setShowInterest(true);
  };

  const handleInterestsClose = () => {
    setShowInterest(false);
  };

  //function to add hobby
  const handleInterestAdd = async (e) => {
    setSaving(true);
    e.preventDefault();
    try {
      if (editIt?.id) {
        await authApi.put(`users/${authId}/hobbies`, { name });
      } else {
        await authApi.post(`users/${authId}/hobbies`, { name });
      }
      // setUpdateFlag(!updateFlag);

      dispatch(updateUser());
      toast.success("Interest added successfully.", {
        delay: 1000,
      });
      setName("");
      setSaving(false);
      setShowInterest(false);
    } catch (err) {
      setSaving(false);
    }
  };

  const handleDelete = async (e) => {
    setDeleting(true);
    e.preventDefault();
    try {
      await authApi.delete(`users/hobbies/${editIt?.id}`);
      setUpdateFlag(!updateFlag);
      dispatch(updateUser());
      toast.success("Interest deleted successfully.", {
        delay: 1000,
      });

      setName("");
      setDeleting(false);
      setShowInterest(false);
    } catch (err) {
      setDeleting(false);
    }
  };

  return (
    <>
      <Link
        to=""
        className={`add-new-label ${className}`}
        onClick={handleInterestsOpen}
      >
        {icon} {linkText}
      </Link>

      {showInterest && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {editIt?.id ? "Edit" : "Add"} Interest
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleInterestsClose}
                >
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body custom-scrollbar">
                  <div className="form-container border-bottom-2 mb-3">
                    <div className="form-group">
                      <label htmlFor={`project_title`}>
                        {" "}
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Interest title"
                        id={`project_title`}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-footer d-flex justify-content-between text-right">
                  {editIt?.id ? (
                    <button
                      className="btn blue-btn"
                      onClick={(e) => handleDelete(e)}
                      disabled={deleting}
                    >
                      {" "}
                      {deleting ? "Deleting" : "Delete"}
                    </button>
                  ) : (
                    <b></b>
                  )}
                  <button
                    type="submit"
                    onClick={(e) => handleInterestAdd(e)}
                    className="btn blue-btn"
                    disabled={saving}
                  >
                    {saving ? "Submitting" : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddInterest;
