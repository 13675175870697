import React, { useEffect, useState } from "react";
import EditProfileSummary from "../Profile/ProfileModal/EditProfileSummary";
import "../../../CommonComponents/InputTag.css";
import ProfileExperiences from "./ProfileExperiences";
import ProfileEducation from "./ProfileEducation";
import ProfileCertification from "./ProfileCertification";
import ProfileProjects from "./ProfileProjects";
import ProfileAchievements from "./ProfileAchievements";
import ProfileInterest from "./ProfileInterest";
import { useDispatch, useSelector } from "react-redux";
import { saveProfile } from "../../../../Redux/userSlice";
import authApi from "../../../../utils/authApi";

const ProfileSummary = () => {
  const authId = useSelector((state) => state?.auth?.user?.info?.id);
  const user = useSelector((state) => state.user.profile);
  const [updateFlag, setUpdateFlag] = useState(false);
  const dispatch = useDispatch();

  //useeffect for getting logged user data
  useEffect(() => {
    const getProfile = async () => {
      try {
        const res = await authApi.get(`users/${authId}`);
        dispatch(saveProfile(res?.data?.data));
      } catch (err) {}
    };
    getProfile();
  }, [authId, dispatch, updateFlag]);

  return (
    <>
      <div className="profile-summary-container">
        <div className="profile-summary-header line-height-0 d-flex">
          <div className="profile-summary-heading">
            <h2>Summary</h2>
          </div>
          <div className="profile-summary-icon">
            <EditProfileSummary
              updateFlag={updateFlag}
              setUpdateFlag={setUpdateFlag}
            />
          </div>
        </div>
        {user?.about_me ? (
          <div className="profile-summary-body mt-1">
            <div className="profile-summary-inner-container d-flex">
              <div className="profile-view-content">
                <p>{user?.about_me}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center w-100 mb-2">
            No Summary, Add Now!
          </div>
        )}
      </div>

      <ProfileExperiences />
      <ProfileEducation />
      <ProfileCertification />
      <ProfileProjects />

      <ProfileAchievements />

      <ProfileInterest />
    </>
  );
};

export default ProfileSummary;
