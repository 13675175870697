import React, { useEffect, useMemo, useState } from "react";
import "./AllCandidates.css";
import Icon from "../../../../assets/icon.png";
import ToggleSwitch from "../../../CommonComponents/ToggleSwitch";
import { BsThreeDots } from "react-icons/bs";
import { DotSpinner } from "@uiball/loaders";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import dummyProfile from "../../../../assets/dummyProfile.webp";
import authApi from "../../../../utils/authApi";
import Pagination from "../../../CommonComponents/Pagination";
import JobBasicDetail from "../../UserPanel/JobSearchResult/JobBasicDetail";
import JobDescription from "../../UserPanel/JobSearchResult/JobDescription";
import { IMAGEURL } from "../../../../constant";
import Loader from "../../../CommonComponents/Loader/Loader";

const AllCandidates = () => {
  const [showThis, setShowThis] = useState(0);
  const [allActiveUsers, setAllActiveUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showItsInfo, setShowItsInfo] = useState({});
  const [totalUsers, setTotalUsers] = useState(0);
  const [page, setPage] = useState(1);
  const [allPages, setAllPages] = useState(1);

  useEffect(() => {
    setShowItsInfo(allActiveUsers[showThis]);
  }, [showThis, allActiveUsers]);
  //hook to get all live jobs
  useEffect(() => {
    const getallActiveUsers = async () => {
      try {
        const res = await authApi.get(`get-active-users`);
        setIsLoading(false);
        setAllActiveUsers(res?.data?.candidate?.data);
        setAllPages(
          Math.ceil(
            res?.data?.candidate?.total / res?.data?.candidate?.per_page
          )
        );
        setTotalUsers(res?.data?.candidate?.total);
      } catch (error) {
        setIsLoading(false);
      }
    };
    getallActiveUsers();
  }, []);

  //to find time gap
  function getTimeDifferenceString(timeString) {
    const currentTime = new Date();
    const pastTime = new Date(timeString);
    const timeDifference = currentTime - pastTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 30) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    }
  }

  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center mt-5">
          <Loader />
        </div>
      )}
      {!isLoading && (
        <section className="py-4">
          <div className="container-fluid side-space">
            <div className="grid-1-1-container">
              <div className="search-filter-left grid-left">
                <div className="search-filter-result d-flex justify-content-between">
                  <div className="search-counting">
                    <h3 className="fa-20 black fw-600 mb-0">
                      {totalUsers} Active Users{" "}
                    </h3>
                  </div>
                </div>
                {/* Searched Jobs */}
                <div className="searched-job">
                  <div className="mb-4">
                    <div>
                      <div className="heading"></div>
                      <div className="user-card-container">
                        {allActiveUsers?.map((item, itemIndex) => (
                          <div
                            className={`job-card`}
                            key={itemIndex}
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowThis(itemIndex)}
                          >
                            <div className="job-card-icon">
                              <img
                                src={
                                  item?.avatar
                                    ? item?.avatar.includes("http")
                                      ? item?.avatar
                                      : `${IMAGEURL}${item?.avatar}`
                                    : dummyProfile
                                }
                                className="img-fluid"
                                alt="profile pic"
                                style={{
                                  height: "auto",
                                  width: "48px",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                            <div className="job-card-content ">
                              <h4>
                                {item?.first_name ?? "Unknown"}
                                {item?.last_name ? ` ${item?.last_name}` : ""}
                              </h4>
                              <div className="job-content-inner d-sm-flex d-block align-items-center mt-2 justify-content-between">
                                <div className="job-content-inner-content d-flex justify-content-start justify-content-sm-end mt-sm-0 mt-2">
                                  <p>{item?.about_me ?? ""}</p>
                                </div>
                              </div>
                              <div className="skill-box">
                                {item?.skills?.map((row, index) => (
                                  <span className="skill-btn" key={index}> {row?.title ?? ""} </span>
                                ))}
                              </div>
                              <Link
                                className="btn blue-btn mt-2"
                                to={`/all-candidates/view-profile/${item?.id}`}
                              >
                                View Profile
                              </Link>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {allActiveUsers?.length > 0 && (
                    <div className="pagination-row w-100 text-right mt-3 ">
                      <Pagination
                        page={page}
                        setPage={setPage}
                        allPages={allPages}
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* {allActiveUsers?.length > 0 && (
              <div className="search-filter-right grid-middle">
                <div className="card search-filter-container border-0">
                  <div className="card-body">
                    <div className="job-basic-info d-flex justify-content-between">
                      <div>
                        <JobBasicDetail
                          item={showItsInfo}
                          classNameCard="border-0"
                        />
                      </div>
                      <div className="float-right">
                        <div className="three-dot">
                          <BsThreeDots className="gray fa-24" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="job-description">
                  <div className="card border-0  pt-4">
                    <div className="card-body pl-0">
                      <JobDescription item={showItsInfo} />
                    </div>
                  </div>
                </div>
              </div>
            )} */}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default AllCandidates;
