import React, { useEffect, useState } from "react";
//import HeroContent from "../HeroSection/HeroContent";
import Filter from "./Filter";

const SearchedJobs = ({ allSearchedJobs }) => {
  const [showThis, setShowThis] = useState(0);
  const [data, setData] = useState({});
  useEffect(() => {
    setData(allSearchedJobs[showThis]);
  }, [showThis, allSearchedJobs]);

  return (
    <>
      <section className=" w-100">
        <Filter />
        <section className="d-flex w-90  m-auto">
          <section className="w-50 bg-none pl-5">
            {allSearchedJobs?.map((elem, index) => (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setShowThis(index)}
              >
                <div></div>
                <div>
                  {" "}
                  <h3>{elem?.title} </h3>
                </div>
                <hr />
              </div>
            ))}
          </section>
          <section className="w-50 pl-5">
            <div>
              <h1>{data?.title} </h1>
            </div>
            {data?.job_type?.type && (
              <div>
                <h3>Job Type </h3>
                <p>{data?.job_type?.type} </p>
              </div>
            )}
            {data?.required_experience && (
              <div>
                <h3>Experience Required </h3>
                <p>{data?.required_experience} </p>
              </div>
            )}
            {data?.salary && (
              <div>
                <h3>Salary </h3>
                <p>{data?.salary} </p>
              </div>
            )}
            {data?.role_responsibility && (
              <div>
                <h3>Role and Responsibility </h3>
                <p>{data?.role_responsibility} </p>
              </div>
            )}
            {data?.requirements && (
              <div>
                <h3>Requirements </h3>
                <p>{data?.requirements} </p>
              </div>
            )}
            {data?.required_skills && (
              <div>
                <h3>Skills </h3>
                {data?.required_skills?.map((ele, ind) => (
                  <p key={ind}> {ele} </p>
                ))}
              </div>
            )}
          </section>
        </section>
      </section>
    </>
  );
};

export default SearchedJobs;
