import React from 'react';
import {Link} from 'react-router-dom'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HelpQuestionCard = ({allFaqs}) => {

  const questions = [
    'How can I update/edit my Profile?',
    'How do I reset my password?',
    'What payment methods are accepted?',
    'How can I track my order?',
  ];

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: allFaqs?.length<4?allFaqs?.length:4,
    slidesToScroll: 1,
    responsive: [
     
        {
          breakpoint: 1501,
          settings: {
            slidesToShow: allFaqs?.length<4?allFaqs?.length:4,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: allFaqs?.length<4?allFaqs?.length:4,
          },
        },
  
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: allFaqs?.length<4?allFaqs?.length:3,
          },
        },
  
        {
          breakpoint: 770,
          settings: {
            slidesToShow: allFaqs?.length<4?allFaqs?.length:2,
          },
        },
  
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
  
        {
          breakpoint: 375,
          settings: {
            slidesToShow: 1,
          },
        },
     
    ],
  };

  return (
    <div className="carousel-wrapper">
      <Slider {...settings}>
        {allFaqs.map((row, index) => (
         index<5 && <div className='border-right-1 text-left'>
            <div className="question" key={index}>
               <div className=""><Link to={`/job-seeker-search-question/${row?.id}`} className='light-black'>{`Q. ${row?.question}`}</Link></div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HelpQuestionCard;
