import React, { useEffect, useRef, useState } from "react";
import { BiMap, BiBarChartSquare } from "react-icons/bi";
import { BiEdit } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import ResumeBuilder from "../ResumeBuilder/ResumeBuilder";
import JobAlert from "../JobAlert/JobAlert";
import AddProfile from "../Profile/AddProfilePhoto";
import { HiBars3BottomLeft } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import authApi from "../../../../utils/authApi";
import { toast } from "react-toastify";
import { saveProfile, updateUser } from "../../../../Redux/userSlice";
import { logout } from "../../../../Redux/authSlice";

const MenuList = ({ menuData, customClasses }) => {
  const profile = useSelector((state) => state.user.profile); //profile of user from redux
  const loginAs = useSelector((state) => state.auth.loginAs);
  const authId = useSelector((state) => state?.auth?.user?.info?.id);

  const [showModal, setShowModal] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [isMobileMenuCollapsed, setIsMobileMenuCollapsed] = useState(true);
  const [isOngoingEditable, setIsOngoingEditable] = useState(false);
  const [saving, setSaving] = useState(false);
  const selectRef = useRef();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleOngoingEditClick = () => {
    if (!saving) {
      setIsOngoingEditable(true);
    }
  };

  const handleOngoingSaveClick = () => {
    setIsOngoingEditable(false);
  };

  const handleModalClose = () => {
    setShowModal(null);
  };

  const handleLogOutClick = () => {
    dispatch(logout());
  };

  const handleItemClick = (menuItem) => {
    if (menuItem.modalContent) {
      setShowModal(menuItem.modalContent);
    }
    if (menuItem?.label === "Log Out") {
      handleLogOutClick();
    }
    if (menuItem?.label === "Refer a friend") {
      const link='https://job.quicsy.com';
      navigator.clipboard.writeText(link);
      toast.success('Link copied to clipboard.');
    }
    setActiveItem(menuItem);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuCollapsed(!isMobileMenuCollapsed);
  };

  const handleSaveStatus = async (e) => {
    setSaving(true);
    try {
      handleOngoingSaveClick();
      await authApi.put(`update-user/${profile?.id}`, {
        profile_status: e.target.value,
      });
      setSaving(false);
      dispatch(updateUser());
      toast.success("Profile status updated successfully.");
    } catch (error) {
      setSaving(false);
      toast.error(error?.response?.data?.message);
    }
  };

const ongoingOptions = [
    "Actively looking for job",
    "Not looking for job",
    "Looking for job change",
];

  useEffect(() => {
    const handleDocumentClick = (e) => {
      // Check if the click occurred outside of the select element and not on a link
      const isOutsideSelect =
        selectRef.current && !selectRef.current.contains(e.target);
      const isLinkClick = e.target.tagName === "A";

      if (isOutsideSelect && !isLinkClick) {
        setIsOngoingEditable(false);
      }
    };
    // Add the event listener when the component mounts
    document.addEventListener("click", handleDocumentClick);
    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [selectRef]);

  useEffect(() => {
    const getProfile = async () => {
      try {
        if (loginAs === "Candidate") {
          const res = await authApi.get(`users/${authId}`);
          dispatch(saveProfile(res?.data?.data));
        } else {
          const res = await authApi.get(`recruiter/companies/${authId}`);
          dispatch(saveProfile(res?.data?.data));
        }
      } catch (err) {}
    };
    getProfile();
  }, [authId, dispatch, loginAs]);
  // console.log(menuData?.menuItems);
  return (
    <>
      {/* Profile Card */}
      <div className="profile-card profile-bg">
        <div className="p-24">
          <div className="profile-card-img text-center">
            <AddProfile />
          </div>
          {loginAs === "Candidate" && (
            <div className="profile-card-details pt-2 text-center">
              {isOngoingEditable ? (
                <>
                  <select
                    ref={selectRef}
                    value={profile?.profile_status}
                    onChange={(e) => handleSaveStatus(e)}
                    onBlur={handleOngoingSaveClick}
                    className="form-control"
                    disabled={saving}
                  >
                    <option value="">Change Status</option>
                    {ongoingOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                <Link
                  className="text-dark"
                  onClick={handleOngoingEditClick}
                >
                  {profile?.profile_status ?? "Change Status"} <BiEdit />
                </Link>
              )}
              <h4>
                {profile?.first_name ?? ""} {profile?.last_name ?? ""}
              </h4>
              <p>{profile?.expertise ?? ""}</p>
            </div>
          )}
          {loginAs === "Recruiter" && (
            <div className="profile-card-details text-center">
              <h4>{profile?.name ?? ""}</h4>
              <p>{profile?.tagline ?? ""}</p>
            </div>
          )}
        </div>

        <div
          className="profile-links menu-list text-center profile-card-for-mob"
          style={{ borderBottom: "none" }}
        >
          <ul>
        
        { loginAs === "Candidate" &&   <li
              onClick={() => handleItemClick("Dashboard")}
              className={`py-14px ${
                activeItem === "Dashboard" || location.pathname === "/dashboard"
                  ? "menu-active"
                  : ""
              }`}
            >
              <Link to="/dashboard" className="fw-600">
                Dashboard
              </Link>
            </li>}
            {loginAs === "Candidate" && (
              <li
                onClick={() => handleItemClick("Profile")}
                className={`py-14px  border-top-2 mb-0 ${
                  activeItem === "Profile" || location.pathname === "/profile"
                    ? "menu-active"
                    : ""
                }`}
              >
                <Link to="/profile" className="fw-600">
                  Profile
                </Link>
              </li>
            )}
            { loginAs === "Recruiter" &&   <li
              onClick={() => handleItemClick("CompanyPage")}
              className={`py-14px ${
                activeItem === "CompanyPage" || location.pathname === "/company"
                  ? "menu-active"
                  : ""
              }`}
            >
              <Link to="/company" className="fw-600">
                Company Page
              </Link>
            </li>}
          </ul>
        </div>
      </div>
      {/* End of Profile Card */}

      {/* Profile Summary */}

      <div className="profile-bg mt-3 mb-1 mb-sm-0">
        <div>
          <button className="mobile-collapse-button" onClick={toggleMobileMenu}>
            {isMobileMenuCollapsed ? (
              <>
                Menu <HiBars3BottomLeft className="fa-28 fw-600" />
              </>
            ) : (
              <>
                Menu <HiBars3BottomLeft className="fa-28 fw-600" />
              </>
            )}
          </button>

          <div className="profile-summary">
            {menuData?.menuItems?.map(
              (menuItem, index) =>
                menuItem?.title && (
                  <div
                    className={`left-menu${
                      index === menuData.menuItems.length - 1
                        ? " last-menu"
                        : ""
                    }${isMobileMenuCollapsed ? "" : " menu-expanded"}`}
                    key={index}
                  >
                    <h4
                      className={`sidebar-menu-title ${
                        customClasses[menuItem.title] || ""
                      } ${
                        location.pathname === menuItem.link ? "menu-active" : ""
                      }`}
                    >
                      <Link className="black" to={menuItem.link}>
                        {menuItem.title}
                      </Link>
                    </h4>
                    <div className="menu-list">
                      <ul>
                        {menuItem?.items?.map((item, itemIndex) => (
                          <li
                            key={itemIndex}
                            className={`${customClasses[menuItem.title]} ${
                              activeItem === item ||
                              location.pathname === item.link
                                ? "menu-active"
                                : ""
                            }`}
                            onClick={() => handleItemClick(item)}
                          >
                            {item.modalContent ? (
                              <Link
                                className="modal-link"
                                to={item.link}
                                onClick={() => handleItemClick(item)}
                              >
                                {item.label}
                              </Link>
                            ) : (
                              <Link to={item.link}>{item.label}</Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
      {/* End of Profile Summary */}

      {/* Render modals based on showModal */}
      {showModal === "ResumeBuilder" && (
        <ResumeBuilder handleClose={handleModalClose} />
      )}
      {showModal === "JobAlert" && <JobAlert handleClose={handleModalClose} />}
    </>
  );
};

export default MenuList;
