import React, { useEffect, useState } from "react";
import HelpContactCard from "./HelpContactCard";
import VerticalTab from "./VerticalTab";
import HelpSearch from "./HelpSearch";
import authApi from "../../../../utils/authApi";
import { useParams } from "react-router-dom";
import { DotSpinner } from "@uiball/loaders";
import Loader from "../../../CommonComponents/Loader/Loader";

const HelpQATabData = () => {
  const [allFaqs, setAllFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const search = useParams();
  const getAllFaqs = async () => {
    try {
      const res = await authApi.get(`get-all-faq`);
      setAllFaqs(res?.data?.faq);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllFaqs();
  }, [search]);

  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center mt-5">
          {" "}
          <Loader />
        </div>
      )}
      {!isLoading && (
        <section className="pb-4 pt-3">
          <div className="fluid-container side-space">
            {/* search */}
            <div className="help-center mb-4 border-radius-14">
              <div className="help-search">
                <HelpSearch allFaqs={allFaqs} />
              </div>
            </div>

            {/* Browser card */}

            <div className="help-conatiner">
              <div>
                <div className="">
                  <VerticalTab allFaqs={allFaqs} currQsn={search?.id} />
                </div>
              </div>

              <div>
                <HelpContactCard />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default HelpQATabData;
