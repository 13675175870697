import "./RecruiterDashboard.css";
import React, { useEffect, useState } from "react";
import Cards from "./Cards/Cards";
import { IoMdMail } from "react-icons/io";
import { RiHandbagFill } from "react-icons/ri";
import authApi from "../../../../utils/authApi";
import { useSelector } from "react-redux";

const RecruiterDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const profile = useSelector((state) => state?.user?.profile);
  const [data, setData] = useState({});
  const getDashData = async () => {
    try {
      const res = await authApi.get(`recruiter/dashboard`);
      setData(res?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getDashData();
  }, []);
  return (
    <div>
      <div className="card-container  ">
        <Cards
          bg="#0A66C2"
          color="#fff"
          title="Total Job Posts"
          Icon={<RiHandbagFill className="fa-36 text-white" />}
          value={data?.job_count}
        />
        <Cards
          bg="rgb(90 172 255)"
          color="#fff"
          title="Active Job Posts"
          Icon={<RiHandbagFill className="fa-36 text-white" />}
          value={data?.candidate_count}
        />
        <Cards
          bg="#5ac35a"
          color="#fff"
          title="Notifications"
          Icon={<IoMdMail className="fa-36 text-white" />}
          value="17"
        />
        <Cards
          bg="#00e6ac"
          color="#fff"
          title="Total active package"
          Icon={<IoMdMail className="fa-36 text-white" />}
          value={data?.active_package_count}
        />
      </div>
    </div>
  );
};

export default RecruiterDashboard;
