import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { login, saveLoginAs } from "../../../Redux/authSlice";
import SocialButton from "./SocialButton";
import { toast } from "react-toastify";
import { BsCheckCircleFill } from "react-icons/bs";
import Recruiter from "../../../assets/RecruiterPng.png";
import Candidate from "../../../assets/Candidate.png";
const displayNone = {
  display: "none",
};

const displayInherit = {
  display: "inherit",
};

export default function LoginForm({ formChangeHandler }) {
  const [isLoading, setIsLoading] = useState(false); //state to manage response time
  const [showPassword, setShowPassword] = useState("password");
  const loginAsValue = useSelector((state) => state.auth.loginAs);
  const [loginAs, setLoginAs] = useState(loginAsValue);
  const [eyeIconStyle, setEyeIconStyle] = useState([
    displayInherit,
    displayNone,
  ]);

  const showPasswordHandler = (action) => {
    if (action === "show") {
      setShowPassword("text");
      setEyeIconStyle([displayNone, displayInherit]);
    }

    if (action === "hide") {
      setShowPassword("password");
      setEyeIconStyle([displayInherit, displayNone]);
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const loading = useSelector((state) => state.auth.loading);
  //   const error = useSelector((state) => state.auth.error);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();

    dispatch(login({ email, password, loginAs}))
      .then((res) => {
        setIsLoading(false);
        if (res.payload.access_token) {
          //if token is there then only user can go to dashboard
          if(loginAs==="Candidate"){
            navigate("/dashboard");
          }else{
            navigate("/company");
          }
         
          toast.success("Login Successful.");
        } else {
          setIsLoading(false);
          toast.error(res.payload.error[0]);
        }
      })
      .catch((err) => {
        toast.error("Login Failed!");
        setIsLoading(false);
      });
  };

  //login as
  const handleLoginAs = (type) => {
    setLoginAs(type);
    dispatch(saveLoginAs(type));
  };

  return (
    <div className="login">
      <div className="login-heading">
        <h3>Log In</h3>
      </div>
      <SocialButton />

      <hr className="m-0 mb-2" />
      <div className="d-flex justify-content-between w-90 m-auto">
        <div
          className="w-45 position-relative pt-1"
          style={{
            height: "100px",
            border:
              loginAs === "Recruiter"
                ? "3px solid #007BFF"
                : "1px solid lightgray",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => handleLoginAs("Recruiter")}
        >
          <div className="d-flex justify-content-center flex-column align-items-center">
            <div> 
              {" "}
              <img src={Recruiter} style={{ height: "70px", width: "70px" }} />
            </div>
            <span className="fa-12 fw-bold">
              <b>Join as a Recruiter</b>{" "}
            </span>
          </div>

          {loginAs === "Recruiter" && (
            <BsCheckCircleFill
              style={{
                fontSize: "26px",
                color: "#007BFF",
                backgroundColor: "white",
                borderRadius: "50%",
                position: "absolute",
                right: "-10px",
                bottom: "-10px",
              }}
            />
          )}
        </div>
        <div
          className="w-45 position-relative pt-1"
          style={{
            height: "100px",
            border:
              loginAs === "Candidate"
                ? "3px solid #007BFF"
                : "1px solid lightgray",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => handleLoginAs("Candidate")}
        >
          <div className="d-flex justify-content-center flex-column align-items-center">
            <div>
              {" "}
              <img src={Candidate} style={{ height: "70px", width: "70px" }} />
            </div>
            <span className="fa-12 ">
              <b>Join as a Candidate</b>{" "}
            </span>
          </div>
          {loginAs === "Candidate" && (
            <BsCheckCircleFill
              style={{
                fontSize: "26px",
                color: "#007BFF",
                backgroundColor: "white",
                borderRadius: "50%",
                position: "absolute",
                right: "-10px",
                bottom: "-10px",
              }}
            />
          )}
        </div>
      </div>
      {/* form starts here */}
      <form onSubmit={handleSubmit}>
        <div className="row form-style p-4">
      

          <div className="col-xl-12 col-md-12">
            <div className="form-group">
              <label htmlFor="email">Email <span className="text-danger">*</span></label>
              <input
                className="form-control black"
                placeholder="Enter email"
                id="email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="col-xl-12 col-md-12">
            <div className="form-group">
              <label htmlFor="pwd">Password <span className="text-danger">*</span></label>
              <div class="input-group mb-3">
                <input
                  type={showPassword}
                  className="form-control border-right-0 black"
                  placeholder="Enter password"
                  id="pwd"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div class="input-group-prepend">
                  <span class="input-group-text border-left-0" style={{paddingBottom:"9px",paddingTop:"9px"}}>
                    <GoEye
                      style={eyeIconStyle[0]}
                      onClick={(e) => showPasswordHandler("show")}
                    />
                    <GoEyeClosed
                      style={eyeIconStyle[1]}
                      onClick={(e) => showPasswordHandler("hide")}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-12 col-md-12">
            <div className="remember-me d-sm-flex d-block  justify-content-between my-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck"
                  name=""
                />
                <label
                  class="custom-control-label label-text pt-1 pl-2"
                  htmlFor="customCheck"
                >
                  Remember me
                </label>
              </div>

              <div className="forgot-password mt-sm-0 mt-1">
                <Link
                  className="text-decoration-underline link-text"
                  to="#"
                  onClick={(e) => formChangeHandler("forgetPassword")}
                >
                  Forgot your password?
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-md-12 text-center">
            <button
              type="submit"
              disabled={isLoading || !loginAs || !password || !email}
              className="btn login-btn mt-3"
            >
              {isLoading ? "Logging in" : "Log in"}
            </button>
          </div>
        </div>
      </form>
      {/* form ends here */}

      <div>
        <p className="label-text text-center mb-0">
          Don't have an account?{" "}
          <Link
            to="#"
            class="link-text text-decoration-underline "
            onClick={(e) => formChangeHandler("signUp")}
          >
            Sign Up
          </Link>
        </p>
      </div>
    </div>
  );
}
