import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../Webpage/UserPanel/Profile/ProfileModal/Modal.css";
import { ImArrowUpRight2 } from "react-icons/im";
import "./JobApply.css";
import UploadResume from "./UploadResume";
import { FaFilePdf } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { IMAGEURL, NOIMAGE } from "../../../../constant";
import { toast } from "react-toastify";
import authApi from "../../../../utils/authApi";
import { updateUser } from "../../../../Redux/userSlice";
const initialState = {
  status: 1,
  user_id: "",
  job_id: "",
  screening_questions: [],
  custom_questions: [],
};
const JobApply = ({ className, item, appliedJobs, getAppliedJobs }) => {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess() {
    setNumPages(numPages);
  }
  const isAuth = useSelector((state) => state.auth.isLoggedIn);

  const [showJobApply, setShowJobApply] = useState(false); //for popup Modal
  const [qsnAns, setQsnAns] = useState([]);
  const [state, setState] = useState(initialState);
  const [screeningQsns, setScreeningQsns] = useState([]);
  const [isError, setIsError] = useState(false);
  const [customQsns, setCustomQsns] = useState([]);
  const [manageExp, setManageExp] = useState(0);
  const [topManageExp, setTopManageExp] = useState(0);
  const [resume, setResume] = useState("");
  const [step, setStep] = useState(1); //for multistep form;
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const dispatch=useDispatch();
  // const [selectedResume, setSelectedResume] = useState("");
  const [applying, setApplying] = useState(false);
  const authId = useSelector((state) => state?.auth?.user?.info?.id);
  const profile = useSelector((state) => state?.user?.profile);
  const navigate = useNavigate();
  useEffect(() => {
    setState((s) => ({ ...s, job_id: item?.id, user_id: authId }));
  }, [item, authId]);

  useEffect(() => {
    setCustomQsns(item?.custom_questions);
    setScreeningQsns(item?.screening_questions);
  }, [item]);

  //for popup Modal function
  const handleJobApplyOpen = () => {
    if (isAuth) {
      setShowJobApply(true);
    } else {
      toast.warn("Please login first !", { position: "top-center" });
      navigate("/login");
    }
  };

  const handleJobApplyClose = () => {
    setShowJobApply(false);
  };
  // Function to reset isError after 5 seconds
  const resetIsError = () => {
    setTimeout(() => {
      setIsError(false);
    }, 2000);
  };
  //for multistep form function
  const handleNext = (e, command) => {
    e.preventDefault();
    if (command === "checking") {
      const recScQsn = screeningQsns?.filter(
        (elem) => elem?.pivot?.recommended
      );
      const recCusQsn = customQsns?.filter((elem) => elem?.recommended);

      const checkRecScQsn = recScQsn?.filter((ele) => findAnswer(ele) !== "");
      const checkRecCusQsn = recCusQsn?.filter((ele) => findAnswer(ele) !== "");
      if (
        checkRecScQsn.length === recScQsn.length &&
        checkRecCusQsn.length === recCusQsn?.length
      ) {
        setStep(step + 1);
        setIsError(false);
      } else {
        setIsError(true);
        // toast.warn("Please give all starred questions.", {
        //   position: "top-center",
        // });
        resetIsError();
      }
    } else if (command === "upload") {
      if (profile?.resume) {
        if (resume) {
          if (item?.management_skill != 0) {
            if (manageExp > 0) {
              uploadResume();
            } else {
              setError("Management experience is required!");
            }
          } else {
            uploadResume();
          }
        } else {
          if (item?.management_skill != 0) {
            if (manageExp > 0) {
              if (item?.top_management_skill != 0) {
                if (topManageExp > 0) {
                  setStep(step + 1);
                } else {
                  setError("Top Management experience is required!");
                }
              } else {
                setStep(step + 1);
              }
            } else {
              setError("Management experience is required!");
            }
          } else {
            if (item?.top_management_skill != 0) {
              if (topManageExp > 0) {
                setStep(step + 1);
              } else {
                setError("Top Management experience is required!");
              }
            } else {
              setStep(step + 1);
            }
          }
        }
      } else {
        if (resume) {
          if (item?.management_skill != 0) {
            if (manageExp > 0) {
              if (item?.top_management_skill != 0) {
                if (topManageExp > 0) {
                  uploadResume();
                } else {
                  setError("Top Management experience is required!");
                }
              } else {
                uploadResume();
              }
            } else {
              if (item?.top_management_skill != 0) {
                if (topManageExp > 0) {
                  setError("Management experience is required!");
                } else {
                  setError(
                    "Management experience and Top Management experience are required!"
                  );
                }
              } else {
                setError("Management experience is required!");
              }
            }
          } else {
            uploadResume();
          }
        } else {
          setError("Please upload your resume to continue!");
        }
      }
    } else if (command === "checkNext") {
      if (screeningQsns.length === 0 && customQsns.length === 0) {
        setStep(step + 2);
      } else {
        setStep(step + 1);
      }
    } else {
      setStep(step + 1);
    }
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  }, [error]);

  const handlePrevious = (check) => {
    if (check === "check") {
      if (screeningQsns.length === 0 && customQsns.length === 0) {
        setStep(step - 2);
      } else {
        setStep(step - 1);
      }
    } else {
      setStep(step - 1);
    }
  };

  // const progress = ((step - 1) / 3) * 100; // Calculate the progress percentage
  useEffect(() => {
    if (screeningQsns?.length > 0 || customQsns?.length > 0) {
      setProgress(((step - 1) / 3) * 100);
    } else {
      if (step == 3) {
        setProgress(((step - 2) / 2) * 100);
      } else {
        setProgress(((step - 1) / 2) * 100);
      }
    }
  }, [step, screeningQsns, customQsns]);
  //store qsn and answer
  const handleAnswerChange = (e, row) => {
    if (e.target.value) {
      if (qsnAns.length > 0) {
        const check = qsnAns?.filter((elem) => elem?.qsn === row?.question);
        if (check.length > 0) {
          const filter = qsnAns?.filter((elem) => elem?.qsn !== row?.question);
          setQsnAns([...filter, { qsn: row?.question, ans: e.target.value }]);
        } else {
          setQsnAns([...qsnAns, { qsn: row?.question, ans: e.target.value }]);
        }
      } else {
        setQsnAns([{ qsn: row?.question, ans: e.target.value }]);
      }
    } else {
      const newFilter = qsnAns?.filter((elem) => elem?.qsn !== row?.question);
      setQsnAns(newFilter);
    }
  };

  const findAnswer = (row) => {
    if (qsnAns?.length > 0) {
      const check = qsnAns?.filter((elem) => elem?.qsn === row?.question)[0];
      if (check?.ans) {
        return check?.ans;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  //fuction to apply job
  const handleSubmitApplication = async () => {
    setApplying(true);
    const newQsns = screeningQsns?.map((elem, index) => ({
      screening_question_id: elem.id,
      tag_name: elem?.tag_name,
      question: elem?.question,
      min_value: elem?.pivot?.min_value,
      answer: elem?.answer,
      recommended: elem?.pivot?.recommended,
      candidate_answer: findAnswer(elem),
    }));
    const newCusQsns = customQsns?.map((elem, index) => ({
      id: elem.id,
      tag_name: elem.tag_name,
      question: elem.question,
      min_value: elem.min_value,
      answer: elem.answer,
      recommended: elem.recommended ? 1 : 0,
      candidate_answer: findAnswer(elem),
    }));
    const payload = {
      ...state,
      screening_questions: newQsns,
      custom_questions: newCusQsns,
      created_at: new Date(Date.now()).toISOString(),
    };

    try {
      await authApi.post(`/users/${authId}/jobs/${item?.id}`, payload);
      getAppliedJobs();
      setApplying(false);
      setShowJobApply(false);
      toast.success("Job applied successfully.");
    } catch (err) {
      setApplying(false);
      setShowJobApply(false);
      setStep(1);
      toast.error(err?.response?.data?.error);
    }
  };

  const uploadResume = async () => {
    setUploading(true);
    // e.preventDefault();
    const myFormData = new FormData();
    myFormData.append("resume", resume);
    try {
      await authApi.post(`users/${authId}?_method=PUT`, myFormData);
      //setSelectedResume(res?.data?.resume);
      setUploading(false);
      setResume('');
      dispatch(updateUser())
      setStep(step + 1);
    } catch (error) {
      setUploading(false);
    }
  };

  const isAnsThere = (qsn) => {
    const flag =
      qsnAns.length > 0
        ? qsnAns?.filter((elem) => elem.qsn === qsn).length > 0
          ? qsnAns?.filter((elem) => elem.qsn === qsn)[0]?.ans
          : ""
        : "";
    if (flag != "") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {appliedJobs?.includes(item?.id) ? (
        <button className="btn btn-gary fw-500 fa-16 fa-mdd-14 py-2 mr-3">
          Applied
        </button>
      ) : (
        <button
          className="btn blue-btn fw-500 fa-16 fa-mdd-14 py-2 mr-3 mb-2"
          onClick={handleJobApplyOpen}
        >
          Apply Now <ImArrowUpRight2 />
        </button>
      )}

      {showJobApply && (
        <div className="modal " style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                {error ? (
                  <div className="d-flex justify-content-between w-100">
                    <h4 className="modal-title">Apply To Crossover</h4>
                    <span className="text-danger fa-14">{error} </span>
                  </div>
                ) : (
                  <div>
                    <h4 className="modal-title">Apply To Crossover</h4>
                  </div>
                )}

                <button
                  type="button"
                  className="close"
                  onClick={handleJobApplyClose}
                >
                  &times;
                </button>
              </div>

              <div className="form-container">
                <div className="modal-body">
                  <div className="progress" style={{ height: "10px" }}>
                    <div
                      className="progress-bar bg-success rounded-0"
                      style={{ width: `${progress}%`, height: "10px" }}
                    />
                  </div>

                  {step === 1 && (
                    <div className="pt-3">
                      <form onSubmit={(e) => handleNext(e, "checkNext")}>
                        <div className="form-container pr-3 custom-scrollbar ">
                          <div className="profile-intro">
                            <h4 className="fa-18 fa-sm-16 fw-600 light-black">
                              Contact info
                            </h4>
                            <div className="Posted-by d-flex mt-4 mb-4">
                              <div className="hiring-profile img mr-2">
                                <img
                                  src={
                                    profile?.avatar
                                      ? `${IMAGEURL}${profile?.avatar}`
                                      : NOIMAGE
                                  }
                                  alt="profile "
                                />
                              </div>
                              <div className="Posted-profile-detail">
                                <h4 className="fa-18 fa-mdd-16 fa-sm-14 black-shades-1 fw-500 mb-0">
                                  {(profile?.first_name ?? "") +
                                    (" " + profile?.last_name) ?? ""}
                                </h4>
                                <p className="fw-400 fa-16 fa-mdd-14 fa-sm-12 light-black mb-0">
                                  {profile?.about_me ?? ""}
                                </p>

                                {(profile?.city ||
                                  profile?.state ||
                                  profile?.country) && (
                                  <p className="fw-400 fa-14 fa-sm-12 gray mb-0">
                                    {profile?.city ?? ""} {profile?.state ?? ""}{" "}
                                    {profile?.country ?? ""}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="firstname ">
                              First Name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              id="firstname"
                              value={profile?.first_name}
                              disabled
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="lastname ">
                              Last Name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              id="lastname"
                              value={profile?.last_name}
                              disabled
                            />
                          </div>

                          {/* <div className="form-group">
                            <label htmlFor="lastname ">
                              Phone Country Code
                            </label>
                            <select className="form-control">
                              <option>Select An Option</option>
                              <option selected>India (+91)</option>
                              <option>Thailand (+66)</option>
                              <option>Singapor (+65)</option>
                            </select>
                          </div> */}
                          {profile?.phone && (
                            <div className="form-group">
                              <label htmlFor="phone ">
                                Mobile phone number
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="9918569201"
                                id="phone"
                                value={profile?.phone}
                                disabled
                              />
                            </div>
                          )}

                          {profile?.email && (
                            <div className="form-group">
                              <label htmlFor="title ">
                                Email address{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Designdot@gmail.com"
                                id="Email"
                                value={profile?.email}
                                disabled
                              />
                            </div>
                          )}

                          <p className="small-text gray">
                            Submitting this application won’t change your
                            profile.
                          </p>
                        </div>

                        <div className="pt-4 text-right">
                          <button type="submit" className="btn blue-btn">
                            Next
                          </button>
                        </div>
                      </form>
                    </div>
                  )}

                  {step === 2 && (
                    <div className="pt-3">
                      <form onSubmit={(e) => handleNext(e, "checking")}>
                        <div className="form-container pr-3 custom-scrollbar ">
                          <div className="profile-intro">
                            <h4 className="fa-18 fa-sm-16 fw-600 light-black">
                              Screening Questions
                            </h4>
                          </div>
                          {screeningQsns?.map((row, index) => (
                            <div key={index} className="form-group">
                              <label htmlFor={row?.question}>
                                {row?.question}
                                {+row?.pivot?.recommended === 1 && (
                                  <span className="text-danger">*</span>
                                )}
                              </label>
                              {row?.input_type === "text" && (
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  id={row?.question}
                                  value={
                                    qsnAns.length > 0
                                      ? qsnAns?.filter(
                                          (elem) => elem.qsn === row.question
                                        ).length > 0
                                        ? qsnAns?.filter(
                                            (elem) => elem.qsn === row.question
                                          )[0]?.ans
                                        : ""
                                      : ""
                                  }
                                  onChange={(e) => handleAnswerChange(e, row)}
                                />
                              )}

                              {row?.input_type === "select" && (
                                <select
                                  onChange={(e) => handleAnswerChange(e, row)}
                                  className="form-control"
                                  value={
                                    qsnAns.length > 0
                                      ? qsnAns?.filter(
                                          (elem) => elem.qsn === row.question
                                        ).length > 0
                                        ? qsnAns?.filter(
                                            (elem) => elem.qsn === row.question
                                          )[0]?.ans
                                        : ""
                                      : ""
                                  }
                                >
                                  <option value="">Select Answer </option>
                                  {row?.input_options?.map((ele, index) => (
                                    <option key={index} value={ele}>
                                      {ele}{" "}
                                    </option>
                                  ))}
                                </select>
                              )}
                              {!isAnsThere(row?.question) &&
                                +row?.pivot?.recommended === 1 &&
                                isError && (
                                  <div className="fade-in-out-text">
                                    <p
                                      className="text-danger"
                                      style={{ fontSize: "12px" }}
                                    >
                                      This question is mandatory!
                                    </p>
                                  </div>
                                )}
                            </div>
                          ))}
                          {customQsns?.map((row, index) => (
                            <div key={index} className="form-group">
                              <label htmlFor={row?.question}>
                                {row?.question}
                                {row?.recommended && (
                                  <span className="text-danger">*</span>
                                )}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                id={row?.question}
                                value={
                                  qsnAns.length > 0
                                    ? qsnAns?.filter(
                                        (elem) => elem.qsn === row.question
                                      ).length > 0
                                      ? qsnAns?.filter(
                                          (elem) => elem.qsn === row.question
                                        )[0]?.ans
                                      : ""
                                    : ""
                                }
                                onChange={(e) => handleAnswerChange(e, row)}
                              />
                              {!isAnsThere(row?.question) &&
                                row?.recommended &&
                                isError && (
                                  <div className="fade-in-out-text">
                                    <p
                                      className="text-danger"
                                      style={{ fontSize: "12px" }}
                                    >
                                      This question is mandatory!
                                    </p>
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>

                        <div className="pt-4 text-right">
                          <button
                            type="button"
                            className="btn btn-gary text-black mr-2 px-4 fw-500 "
                            onClick={handlePrevious}
                          >
                            Back
                          </button>
                          <button type="submit" className="btn blue-btn">
                            Next
                          </button>
                        </div>
                      </form>
                    </div>
                  )}

                  {step === 3 && (
                    <div className="pt-3">
                      {(item?.management_skill != 0 ||
                        item?.top_management_skill != 0) && (
                        <div>
                          <h4 className="fa-18 fw-600  fa-sm-16 light-black">
                            Manageent Skills
                          </h4>
                          {+item?.years_as_manager_of_individual_contributor >
                            0 && (
                            <div className="form-group">
                              <label>
                                How much experience do you have as a Manager?{" "}
                                {item?.management_skill != 0 && (
                                  <span className="text-danger">*</span>
                                )}
                              </label>
                              <input
                                className="form-control"
                                placeholder="Experience in year"
                                value={manageExp}
                                onChange={(e) => setManageExp(e.target.value)}
                              />
                            </div>
                          )}
                          {+item?.years_as_manager_of_other_managers > 0 && (
                            <div className="form-group">
                              <label>
                                How much experience do you have as a Top
                                Manager?{" "}
                                {item?.top_management_skill != 0 && (
                                  <span className="text-danger">*</span>
                                )}
                              </label>
                              <input
                                className="form-control"
                                placeholder="Experience in year"
                                value={topManageExp}
                                onChange={(e) =>
                                  setTopManageExp(e.target.value)
                                }
                              />
                            </div>
                          )}

                          {/* <form onSubmit={(e) => handleNext(e, "upload")}>
                      
                        
                         

                          <div className="pt-4 text-right">
                            <button
                              type="button"
                              className="btn btn-gary text-black mr-2 px-4 fw-500 "
                              onClick={() => handlePrevious("check")}
                            >
                              Back
                            </button>
                            <button
                              type="submit"
                              disabled={uploading}
                              className="btn blue-btn"
                            >
                              Next
                            </button>
                          </div>
                        </form> */}
                        </div>
                      )}
                      <hr />
                      <div>
                        <h4 className="fa-18 fw-600  fa-sm-16 light-black">
                          Resume
                        </h4>
                        <p className="fa-14 fw-400 gray">
                          Be sure to include an updated resume
                        </p>
                        <form onSubmit={(e) => handleNext(e, "upload")}>
                          {/* upload resume */}

                          <UploadResume resume={resume} setResume={setResume} />
                          {/* upload resume */}
                        
                          <div className="pt-4 text-right">
                            <button
                              type="button"
                              className="btn btn-gary text-black mr-2 px-4 fw-500 "
                              onClick={() => handlePrevious("check")}
                            >
                              Back
                            </button>
                            <button
                              type="submit"
                              disabled={uploading}
                              className="btn blue-btn"
                            >
                              {uploading ? "Processing" : "Next"}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                  {step === 4 && (
                    <div className="pt-3 ">
                      <div className="form-container custom-scrollbar  pr-3">
                        <h4 className="fa-18  fa-sm-16 fw-600 light-black">
                          Review your application
                        </h4>
                        <p className="fa-14 fw-400 gray">
                          The employer will also receive a copy of your profile.
                        </p>
                        <hr />

                        <div className="review">
                          <div className="Posted-by d-flex mt-4 mb-4">
                            <div className="hiring-profile img mr-2">
                              <img
                                src={
                                  profile?.avatar
                                    ? `${IMAGEURL}${profile?.avatar}`
                                    : NOIMAGE
                                }
                                alt="profile "
                              />
                            </div>
                            <div className="Posted-profile-detail">
                              <h4 className="fa-18 fa-mdd-14 black-shades-1 fw-500 mb-0">
                                {(profile?.first_name ?? "") +
                                  (" " + profile?.last_name) ?? ""}
                              </h4>
                              <p className="fw-400 fa-16 fa-mdd-14 fa-sm-12 light-black mb-0">
                                {profile?.about_me ?? ""}
                              </p>

                              {(profile?.city ||
                                profile?.state ||
                                profile?.country) && (
                                <p className="fw-400 fa-14 fa-sm-12 gray mb-0">
                                  {profile?.city ?? ""} {profile?.state ?? ""}{" "}
                                  {profile?.country ?? ""}
                                </p>
                              )}
                            </div>
                          </div>

                          {profile?.email && (
                            <div className="form-group">
                              <span className="fa-14 gray fw-300">
                                Email address
                              </span>
                              <p className="fa-16 light-black fw-300">
                                {profile?.email ?? ""}
                              </p>
                            </div>
                          )}

                          {profile?.phone && (
                            <div className="form-group">
                              <span className="fa-14 gray fw-300">
                                Mobile phone number
                              </span>
                              <p className="fa-16 light-black fw-300">
                                {profile?.phone ?? ""}
                              </p>
                            </div>
                          )}
                        </div>

                        <hr />

                        <div className="review">
                          <h4 className="fa-18 fa-sm-16 fw-600 light-black">
                            Resume
                          </h4>
                          <span className="fa-14 gray fw-300">
                            Be sure to include an updated resume *
                          </span>

                          <div
                            class="file-icon p-3"
                            style={{ borderRadius: "6px" }}
                          >
                            <p class="mb-0">
                              <FaFilePdf
                                className="pdf-icon"
                                style={{ borderRadius: "4px" }}
                              />
                              <Link
                                className="mb-0 ml-2"
                                target="_blank"
                                style={{ color: "#0A66C2", fontSize: "18px" }}
                                to={`${IMAGEURL}${profile?.resume}`}
                              >
                                Your Resume (click to view)
                              </Link>
                            </p>
                          </div>
                        </div>

                        <div className="pt-4 text-right">
                          <button
                            className="btn btn-gary text-dark mr-2 px-4 fw-500 mb-sm-0 mb-1"
                            type="button"
                            onClick={handlePrevious}
                          >
                            Back
                          </button>
                          <button
                            onClick={() => handleSubmitApplication()}
                            className="btn blue-btn"
                            type="submit"
                            disabled={applying}
                          >
                            {applying
                              ? "Submitting Application"
                              : "Submit Application"}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobApply;
