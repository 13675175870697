import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import JobListTitle from "./JobListTitle";
import "./JobList.css";
import JobCardList from "./JobCardList";
import authApi from "../../../../utils/authApi";
import CandCardList from "./CandCardList";

const JobList = ({ allSearchedJobs }) => {
  const [totalLiveJobs, setTotalLiveJobs] = useState(0);
  const [allLiveJobs, setAllLiveJobs] = useState([]);
  const [totalLiveUsers, setTotalLiveUsers] = useState(0);
  const [allLiveUsers, setAllLiveUsers] = useState([]);
  //hook to get all live jobs
  useEffect(() => {
    const getAllLiveJobs = async () => {
      try {
        const res = await authApi.get(`get-live-jobs`);
        setAllLiveJobs(res?.data?.jobs?.data);
      } catch (error) {}
    };
    getAllLiveJobs();
  }, []);
  //hook to get total live jobs
  useEffect(() => {
    const getTotalLiveJobs = async () => {
      try {
        const res = await authApi.get(`get-total-live-jobs`);
        setTotalLiveJobs(res?.data?.total_jobs);
      } catch (error) {}
    };
    getTotalLiveJobs();
  }, []);
  //hook to get all live users
  useEffect(() => {
    const getAllLiveUsers = async () => {
      try {
        const res = await authApi.get(`get-active-users`);
        setAllLiveUsers(res?.data?.candidate?.data);
      } catch (error) {}
    };
    getAllLiveUsers();
  }, []);
  //hook to get total live users
  useEffect(() => {
    const getTotalLiveUsers = async () => {
      try {
        const res = await authApi.get(`get-total-active-user`);
        setTotalLiveUsers(res?.data?.total_candidate);
      } catch (error) {}
    };
    getTotalLiveUsers();
  }, []);

  const SliderCandList = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: allLiveUsers?.length < 4 ? allLiveUsers?.length : 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1299,
        settings: {
          slidesToShow: allLiveUsers?.length < 3 ? allLiveUsers?.length : 3,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },

      {
        breakpoint: 770,
        settings: {
          slidesToShow: allLiveUsers?.length < 2 ? allLiveUsers?.length : 2,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: allLiveUsers?.length < 1 ? allLiveUsers?.length : 1,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };

  const SliderJobList = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: allLiveJobs?.length < 4 ? allLiveJobs?.length : 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1299,
        settings: {
          slidesToShow: allLiveJobs?.length < 3 ? allLiveJobs?.length : 3,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },

      {
        breakpoint: 770,
        settings: {
          slidesToShow: allLiveJobs?.length < 2 ? allLiveJobs?.length : 2,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: allLiveJobs?.length < 1 ? allLiveJobs?.length : 1,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };

  return (
    <>
      <div className="container-fluid side-space">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4">
            <JobListTitle
              number={totalLiveJobs}
              link="/all-jobs/live"
              title={totalLiveJobs > 1 ? "live jobs" : "live job"}
              subpara="Here are our latest >"
            />
            <JobListTitle
              link="/all-candidates/live"
              number={totalLiveUsers}
              title="active"
              subpara="Candidates"
            />
          </div>

          <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 p-0 ">
            <div >
              <Slider {...SliderJobList}>
                {allLiveJobs?.map((row, index) => (
                  <JobCardList row={row} key={index} />
                ))}
              </Slider>
            </div>
          </div>
        </div>

        <div className="row">
          {/* <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4">
            <JobListTitle
              link="/all-candidates/live"
              number={totalLiveUsers}
              title="active"
              subpara="Candidates"
            />
          </div> */}

          <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 p-0">
            <div className="">
              {/* <Slider {...SliderCandList}>
               {allLiveUsers?.map((row,index)=><CandCardList row={row} key={index} />)}
              </Slider> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobList;
