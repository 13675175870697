import React, { useEffect, useState } from 'react';
import ApplyJobsCard from './ApplyJobsCard';
import { useSelector } from 'react-redux';
import authApi from '../../utils/authApi';

const AppliedJobsData = ({title}) => {
  const [recentJobApplied,setRecentJobApplied]=useState([]);
  const [isLoading,setIsLoading]=useState(true);
  //react redux
  const authId=useSelector((state)=>state?.auth?.user?.info?.id);
  useEffect(()=>{
    const getAllJobPosts=async()=>{
      setIsLoading(true)
      try{
  const res=await authApi.get(`users/${authId}/jobs`);
  setIsLoading(false)
  setRecentJobApplied(res?.data?.data)
      }catch(err){
  setIsLoading(false)
      }
    }
    getAllJobPosts()
  },[authId]) 




    // const AppliedjobsData = [
    //     {
    //       title:title,
    //       items: [
    //         {
    //           jobTitle: 'Embedded Software Developer - Contract',
    //           location: 'UCLA Health, Hampshire, England',
    //           icon: <BiNotepad />,
    //           status: 'Be an early applicant',
    //           time: '1 Hour Ago',
    //         },
    //         {
    //           jobTitle: 'Embedded Software Developer',
    //           location: 'UCLA Health, Hampshire, England',
    //           icon: <BiNotepad />,
    //           status: 'Be an early applicant',
    //           time: '1 Hour Ago',
    //         },
    //         {
    //           jobTitle: 'Embedded Software Developer - Contract',
    //           location: 'UCLA Health, Hampshire, England',
    //           icon: <BiNotepad />,
    //           status: 'Be an early applicant',
    //           time: '1 Hour Ago',
    //         },
    //       ],
    //     },
    //   ];
      const jobs = [
        {
          title: 'Recent Job Applied',
          items: recentJobApplied,
        },
      ];
    
  return (
    <>
   <ApplyJobsCard isLoading={isLoading} jobs={jobs} boxspace='mb-4' />
    </>
  )
}

export default AppliedJobsData

