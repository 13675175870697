import React, { useEffect, useState } from "react";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import NotificationCard from "./NotificationCard";
import "./notification.css";
import authApi from "../../../../utils/authApi";
import { DotSpinner } from "@uiball/loaders";
import { useSelector } from "react-redux";
import Loader from "../../../CommonComponents/Loader/Loader";
const getAllNotifications = async (setIsLoading, setAllNotifs, role) => {
  try {
    if (role === "Candidate") {
      const res = await authApi.get(`get-notifications `);
      setAllNotifs(res?.data?.notifications);
    } else {
      const res = await authApi.get(`recruiter/get-notifications `);
      setAllNotifs(res?.data?.notifications);
    }

    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
  }
};

const Notofication = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allNotifs, setAllNotifs] = useState([]);
  const [flag, setFlag] = useState(false);
  const role = useSelector((state) => state?.auth?.loginAs);

  useEffect(() => {
    getAllNotifications(setIsLoading, setAllNotifs, role);
  }, [flag]);

  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="grid-3-1-container">
            <div className="left-side grid-left">
              <LeftSidebar />
            </div>

            <div className="main-content grid-middle mt-smm-0 mt-3 ">
              {isLoading && (
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              )}
              {!isLoading && allNotifs?.length > 0 && (
                <div className="card">
                  <div className="card-body">
                    {allNotifs?.map((row, index) => (
                      <NotificationCard
                        row={row}
                        index={index}
                        getAllNotifications={getAllNotifications}
                        setFlag={setFlag}
                        flag={flag}
                        role={role}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Notofication;
