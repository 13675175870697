import React, { useEffect, useState } from "react";
import ApplyJobsCard from "./ApplyJobsCard";

import authApi from "../../utils/authApi";
import { useSelector } from "react-redux";

const RecentJobsPost = ({ title }) => {
  const [recentJobPosts, setRecentJobPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  //react redux
  const authId = useSelector((state) => state?.auth?.user?.info?.id);
  useEffect(() => {
    const getAllJobPosts = async () => {
      try {
        const res = await authApi.get(`recruiter/get-recent-job/${authId}`);
        setRecentJobPosts(res?.data?.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    getAllJobPosts();
  }, [authId]);

  const jobs = [
    {
      title: "Recent Job Posts",
      items: recentJobPosts,
    },
  ];

  return (
    <>
      <ApplyJobsCard jobs={jobs} isLoading={isLoading} boxspace="mb-4" />
    </>
  );
};

export default RecentJobsPost;
