import React, { useState } from "react";
import "./Newsletter.css"; // You can create a separate CSS file for styling
import authApi from "../../../utils/authApi";
import { toast } from "react-toastify";

const NewsletterForm = () => {
  const [email, setEmail] = useState("");
  const [saving, setSaving] = useState(false);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      if (email) {
        const res = await authApi.post(`subscribe`, { email });
        setSaving(false);
        setEmail("");
        toast.success("Subscribed successfully.");
      } else {
        setSaving(false);
        toast.warn("Enter your email address!");
      }
    } catch (error) {
      setSaving(false);
      toast.error(error?.response?.data?.message);
      setEmail("");
    }
  };

  return (
    <div className="newsletter footer-list mb-2 d-flex flex-column justify-content-end  mt-2 mt-lg-0">
      <h6>Newsletter</h6>
      <p className="mt-sm-3 mt-2 mb-sm-0 mb-2">Want to know what we're up to? Sign up for the newsletter and join our tribe.</p>
      <form onSubmit={handleSubmit} className="mt-2">
        <div className="input-group">
          <input
            type="email"
            className="input-style"
            id="Email"
            onChange={handleInputChange}
            name="Email"
            required
            value={email}
            placeholder="Email address"
            autocomplete="off"
          />
          <input
            className="button--submit"
            value={saving ? "Processing" : "Subscribe"}
            type="submit"
            disabled={saving}
          />
        </div>
      </form>
    </div>
  );
};

export default NewsletterForm;
