// userSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import authApi from "../utils/authApi";
import { store } from "../Redux/store";
// import api from "../utils/api";
export const updateUser = createAsyncThunk(
  "updateUser",
  async (data, thunkAPI) => {
    const state = store.getState();
    const authId = state.auth?.user?.info?.id;

    try {
      // const authId = useSelector((state) => state?.auth?.user?.info?.id); //user id
      const response = await authApi.get(`users/${authId}`);
      return response?.data?.data;
    } catch (error) {
      console.log("Error is", error);
    }
  }
);
// User slice
const userSlice = createSlice({
  name: "user",
  initialState: {
    profile: {},
    jobState:{},
    preview:{}
  },
  reducers: {
    saveProfile: (state, action) => {
      state.profile = action.payload;
    },
    setJobState:(state,action)=>{
      state.jobState=action.payload;
    },
    setPreviewState:(state,action)=>{
      state.preview=action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
  },
});

export const { saveProfile ,setJobState,setPreviewState} = userSlice.actions;

export default userSlice.reducer;
