import React, { useEffect, useState } from "react";
import LeftSidebar from "../LeftSideBar/LeftSidebar";
import JobPipeline from "../MyJob/JobPipeline";
import JobPostList from "./JobPostList";
import "../../../../assets/global.css";
import { useSelector } from "react-redux";
import authApi from "../../../../utils/authApi";
import { DotSpinner } from "@uiball/loaders";
import { Link } from "react-router-dom";
import Loader from "../../../CommonComponents/Loader/Loader";

const JobTracker = () => {
  const [allJobs, setAllJobs] = useState([]);
  const [page, setPage] = useState(1);
  const [allPages, setAllPages] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("DRAFT");

  //react redux
  const authId = useSelector((state) => state?.auth?.user?.info?.id);

  const getAllJobPosts = async () => {
    setIsLoading(true);
    try {
      if (title) {
        const res = await authApi.get(
          `recruiter/companies/${authId}/jobs?page=${page}&search=${title}`
        );
        setIsLoading(false);
        setAllJobs(res?.data?.data);
        setAllPages(
          Math.ceil(res?.data?.meta?.total / res?.data?.meta?.per_page)
        );
        setTotalItem(res?.data?.meta?.total);
      } else {
        const res = await authApi.get(
          `recruiter/companies/${authId}/jobs?page=${page}`
        );
        setIsLoading(false);
        setAllJobs(res?.data?.data);
        setAllPages(
          Math.ceil(res?.data?.meta?.total / res?.data?.meta?.per_page)
        );
        setTotalItem(res?.data?.meta?.total);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllJobPosts();
  }, [authId, page, title]);

  // JobPipeline

  const jobs = [
    { bgClass: "light-blue-bg", title: "DRAFT", count: "" },
    { bgClass: "light-gray-bg", title: "ONGOING", count: "-" },
    { bgClass: "light-gray-bg", title: "PAUSED", count: "-" },
    { bgClass: "light-gray-bg", title: "CLOSED", count: "-" },
  ];

  return (
    <>
      <section className="pb-4 pt-4">
        <div className="fluid-container side-space">
          <div className="grid-2-container">
            <div className="left-side">
              <LeftSidebar />
            </div>
            {isLoading && (
              <div className="d-flex justify-content-center mt-5">
                {" "}
                <Loader />
              </div>
            )}
            {!isLoading && (
              <div className="manage-job-main">
                <div className="job-pipeline-container ">
                  <div class="heading mb-sm-2 mt-3 mb-3  mt-smm-0">
                    <h3 class="job-card-title fa-20"> Posted Jobs</h3>
                  </div>

                  <div className="d-grid-sm d-grid-lg d-grid-md gap-30">
                    {jobs.map((job, index) => (
                      <div
                        className={`job-pipline-box flex-fill light-${
                          title === job.title ? "blue" : "gray"
                        }-bg`}
                        style={{ cursor: "pointer" }}
                        key={index}
                        onClick={() => setTitle(job?.title)}
                      >
                        <Link to="#" className="black">
                          {!isLoading && title === job.title && (
                            <span className="fa-24 fa-mdd-20 black fw-600">
                              {allJobs?.length}
                            </span>
                          )}
                          <p>{job.title}</p>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="border-bottom-2 my-4" />
               {allJobs?.length === 0 && <div className="d-flex justify-content-center p-5 fa-20">
                  No data available !
                </div>}
                {allJobs?.length > 0 && (
                  <JobPostList
                    totalItem={totalItem}
                    allJobs={allJobs}
                    page={page}
                    setPage={setPage}
                    allPages={allPages}
                    getAllJobPosts={getAllJobPosts}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default JobTracker;
