import React from "react";
import { Link } from "react-router-dom";
import { ImArrowUpRight2 } from "react-icons/im";

const JobCardList = ({row}) => {
  return (
    <>
    <div style={{ margin: "0 10px" }}>
      <div className="job-card-list mb-6 mb-small-3">
        <h3>{row?.title??""} {row?.job_type?.type? `- ${row?.job_type?.type}`:""}</h3>

        <p>{row?.company?.name}, {row?.company?.state?.name?`${row?.company?.state?.name}, ${row?.company?.country?.name}`:''}</p>

        <h4 className="pt-3">{row?.employment_type?.title??""}</h4>
        <p>{row?.salary??""} USD</p>

        {/* <div className="linkbtn pt-3">
          <Link to="#">
            View All <ImArrowUpRight2 className="ml-3 arrow-icon" />
          </Link>
        </div> */}
      </div>
      </div>
    </>
  );
};

export default JobCardList;
