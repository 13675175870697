import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FaFilePdf, FaFileWord, FaFile } from "react-icons/fa";
//import authApi from '../../../../utils/authApi';
import { useSelector } from "react-redux";
import { IMAGEURL } from "../../../../constant";
//import { Modal } from 'react-bootstrap';

const UploadResume = ({ resume, setResume }) => {
  const fileInputRef = useRef(null);
  const profile = useSelector((state) => state?.user?.profile);
  const [showR,setShowR]=useState('')
  //const [uploading,setUploading]=useState(false);
  //const [viewResume,setViewResume]=useState(false);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setResume(file);
    // Create a URL for the uploaded file
    const fileURL = URL.createObjectURL(file);
    setShowR(fileURL)
    // // Set the URL as the src attribute of the iframe 
    // const iframe = document.getElementById("pdfPreview");
    // iframe.src = fileURL;
  };

  const getFileExtension = (filename) => {
    return filename.substring(filename.lastIndexOf(".") + 1).toLowerCase();
  };

  const getIconComponent = (extension) => {
    if (extension === "pdf") {
      return <FaFilePdf className="pdf-icon" />;
    } else if (extension === "doc" || extension === "docx") {
      return <FaFileWord className="doc-icon" />;
    } else {
      return <FaFile className="doc-icon" />;
    }
  };

  const handleDownloadFile = () => {
    const downloadLink = URL.createObjectURL(resume);
    const a = document.createElement("a");
    a.href = downloadLink;
    a.download = resume.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(downloadLink);
  };
  return (
    <>
      <div>
        {resume && (
          <div className="file-icon">
            {/* <p className="mb-0" onClick={handleDownloadFile}>
              {getIconComponent(getFileExtension(resume.name))}{" "}
              <span className="pl-2"> {resume.name}</span>
            </p> */}
            <iframe
              width="100%"
              height="500px"
              id="pdfPreview"
              frameBorder="0"
              src={showR}
            ></iframe>
          </div>
        )}
        {!resume && profile?.resume && (
          <div className="file-icon p-2" style={{ cursor: "pointer" }}>
            <iframe
              src={`${IMAGEURL}${profile?.resume}`}
              width="100%"
              height="500px"
              frameBorder="0"
            ></iframe>
          </div>
        )}

        <Link className="btn btn-blue-outline mt-3" onClick={handleButtonClick}>
          Upload Resume
        </Link>
        <p className="fa-12 gray mt-2">DOC, DOCX, PDF (2 MB)</p>
        <input
          type="file"
          ref={fileInputRef}
          accept=".pdf,.doc,.docx"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>
    </>
  );
};

export default UploadResume;
