import React, { useEffect, useState } from "react";
import authApi from "../../../../utils/authApi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { DotSpinner } from "@uiball/loaders";
import { IMAGEURL } from "../../../../constant";
import Loader from "../../../CommonComponents/Loader/Loader";
const initialData = {
  user_id: "",
  name: "",
  tagline: "",
  description: "",
  phone: "",
  industry_id: "",
  company_size_id: "",
  company_type_id: "",
  website: "",
  linkedin: "",
  year_founded: "",
  country_id: "",
  state_id: "",
  banner: "",
  logo: "",
  document: "",
};
//function to get all states based on country
const getAllStates = async (countryId, setActiveStates) => {
  if (countryId) {
    try {
      const res = await authApi.get(`countries/${countryId}/states`);
      setActiveStates(res?.data?.data);
    } catch (error) {}
  }
};
//get current Company Profile
const getCompanyProfile = async (userId, setProfile, setIsLoading, company) => {
  try {
    const res = await authApi.get(`recruiter/companies/${company?.id}`);
    setIsLoading(false);
    setProfile(res?.data?.data);
  } catch (error) {
    setIsLoading(false);
  }
};
const CompanyForm = () => {
  const authId = useSelector((state) => state?.auth?.user?.info?.id); //user id
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState(initialData); //state
  const [companySizes, setCompanySizes] = useState([]); //state for corporate titles
  const [industries, setIndustries] = useState([]); //state for all active industries
  const [companyTypes, setCompanyTypes] = useState([]);
  const [activeCountries, setActiveCountries] = useState([]); //state for saving countries
  const [activeStates, setActiveStates] = useState([]); //state for saving states
  const company = useParams();
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCompanyProfile(authId, setProfile, setIsLoading, company);
  }, [authId, company]);

  useEffect(() => {
    if (company?.id) {
      setData({
        name: profile?.name ?? "",
        tagline: profile?.tagline ?? "",
        description: profile?.description ?? "",
        phone: profile?.phone ?? "",
        industry_id: profile?.industry_id ?? "",
        company_size_id: profile?.company_size_id ?? "",
        company_type_id: profile?.company_type_id ?? "",
        website: profile?.website ?? "",
        linkedin: profile?.linkedin ?? "",
        year_founded: profile?.year_founded ?? "",
        country_id: profile?.country_id ?? "",
        state_id: profile?.state_id ?? "",
        banner: profile?.banner ?? "",
        logo: profile?.logo ?? "",
        document: profile?.document ?? "",
      });
    }
  }, [profile, company]);

  //function to get all active countries

  const getAllCountries = async () => {
    try {
      const res = await authApi.get(`countries`);
      setActiveCountries(res?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    getAllCountries();
  }, []);

  //useEffect to get all active states
  useEffect(() => {
    getAllStates(data.country_id, setActiveStates);
  }, [data.country_id]);

  // fetch company sizes
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await authApi.get(`get-active-company-size`);
        setCompanySizes(res?.data?.data);
      } catch (err) {}
    };
    fetch();
  }, []);
  // fetch company types
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await authApi.get(`get-active-company-type`);
        setCompanyTypes(res?.data?.data);
      } catch (err) {}
    };
    fetch();
  }, []);
  // fetch industries
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await authApi.get(`get-active-industry`);
        setIndustries(res?.data?.data);
      } catch (err) {}
    };
    fetch();
  }, []);

  const handleLogoChange = (event) => {
    const file = event.target.files[0];

    setData({ ...data, logo: file });
  };
  const handleBannerChange = (event) => {
    const file = event.target.files[0];
    setData({ ...data, banner: file });
  };
  const handleDocChange = (event) => {
    const file = event.target.files[0];
    setData({ ...data, document: file });
  };

  const handleAddCompany = async (e) => {
    data.user_id = authId;
    setSaving(true);
    e.preventDefault();
    const formData = new FormData();

    formData.append("user_id", data?.user_id ?? "");
    formData.append("name", data?.name ?? "");
    formData.append("tagline", data?.tagline ?? "");
    formData.append("description", data?.description ?? "");
    formData.append("phone", data?.phone ?? "");

    formData.append("industry_id", data?.industry_id ?? "");

    formData.append("company_size_id", data?.company_size_id ?? "");
    formData.append("company_type_id", data?.company_type_id ?? "");
    formData.append("website", data?.website ?? "");
    formData.append("linkedin", data?.linkedin ?? "");
    formData.append("year_founded", data?.year_founded ?? "");
    formData.append("country_id", data?.country_id ?? "");

    formData.append("state_id", data?.state_id ?? "");

    formData.append("banner", data?.banner ?? "");
    formData.append("logo", data?.logo ?? "");

    formData.append("document", data?.document ?? "");

    try {
     
       await authApi.post(`recruiter/companies/${authId}?_method=PUT`, formData);
        toast.success("Company added successfully.");
        setSaving(false);
      
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setSaving(false);
    }
  };

  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center mt-5">
          {" "}
          <Loader />
        </div>
      )}
      {!isLoading && (
        <div className="form-container">
          <form action="">
            <div className="form-group">
              <label htmlFor="name "> Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                id="name"
                value={data?.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="customFile"> Company Logo </label>
              <div class="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="customFile"
                  onChange={handleLogoChange}
                />
                <label
                  className="custom-file-label lineheight-28"
                  htmlFor="customFile"
                >
                  Choose file
                </label>
                {data?.logo && !data?.logo?.name && company?.id ? (
                  <div className="mt-2 ml-2 font-weight-bold">
                    <span
                      className="text-primary"
                      data-toggle="modal"
                      data-target="#myModal1"
                      style={{ cursor: "pointer" }}
                    >
                      View Image
                    </span>
                  </div>
                ) : (
                  <div className="mt-2 ml-2 font-weight-bold">
                    {data?.logo?.name ?? ""}
                  </div>
                )}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="banner"> Banner </label>
              <div class="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="banner"
                  onChange={handleBannerChange}
                />

                <label
                  className="custom-file-label lineheight-28"
                  htmlFor="customFile"
                >
                  Choose file
                </label>
                {data?.banner && !data?.banner?.name && company?.id ? (
                  <div className="mt-2 ml-2 font-weight-bold">
                    <span
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      View Image
                    </span>
                  </div>
                ) : (
                  <div className="mt-2 ml-2 font-weight-bold">
                    {data?.banner?.name ?? ""}
                  </div>
                )}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="tagline ">Tag Line</label>
              <input
                type="text"
                className="form-control"
                placeholder="Tag Line"
                id="namtaglinee"
                value={data?.tagline}
                onChange={(e) => setData({ ...data, tagline: e.target.value })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone ">Phone</label>
              <input
                type="number"
                className="form-control"
                placeholder="phone"
                id="namtaglinee"
                value={data?.phone}
                onChange={(e) => setData({ ...data, phone: e.target.value })}
              />
            </div>

            <div class="form-group flex-fill">
              <label htmlFor="industry">Industry</label>
              <select
                class="form-control w-100"
                value={data.industry_id}
                onChange={(e) =>
                  setData({ ...data, industry_id: e.target.value })
                }
                id="industry"
              >
                <option value="">Please Select </option>
                {industries?.map((elem, index) => (
                  <option value={elem.id} key={index}>
                    {elem.label}{" "}
                  </option>
                ))}
              </select>
            </div>

            <div class="form-group flex-fill">
              <label htmlFor="company-size">Company Size</label>
              <select
                class="form-control w-100"
                value={data.company_size_id}
                onChange={(e) =>
                  setData({ ...data, company_size_id: e.target.value })
                }
                id="company-size"
              >
                <option value="">Please Select </option>
                {companySizes?.map((elem, index) => (
                  <option value={elem.id} key={index}>
                    {elem.size}{" "}
                  </option>
                ))}
              </select>
            </div>

            <div class="form-group flex-fill">
              <label htmlFor="company-type">Company Type</label>
              <select
                class="form-control w-100"
                value={data.company_type_id}
                onChange={(e) =>
                  setData({ ...data, company_type_id: e.target.value })
                }
                id="company-type"
              >
                <option value="">Please Select </option>
                {companyTypes?.map((elem, index) => (
                  <option value={elem.id} key={index}>
                    {elem.type}{" "}
                  </option>
                ))}
              </select>
            </div>

            <div class="form-group flex-fill">
              <label htmlFor="State">State</label>
              <select
                class="form-control w-100"
                disabled={!data?.country_id}
                value={data.state_id}
                onChange={(e) => setData({ ...data, state_id: e.target.value })}
                id="company-type"
              >
                <option value="">
                  {data.country_id ? "Select State" : "Select Country First."}{" "}
                </option>
                {activeStates?.map((elem, index) => (
                  <option value={elem.id} key={index}>
                    {elem.name}{" "}
                  </option>
                ))}
              </select>
            </div>
            <div class="form-group flex-fill">
              <label htmlFor="country">Country</label>
              <select
                class="form-control w-100"
                value={data.country_id}
                onChange={(e) =>
                  setData({ ...data, country_id: e.target.value })
                }
                id="company-type"
              >
                <option value="">Please Select </option>
                {activeCountries?.map((elem, index) => (
                  <option value={elem.id} key={index}>
                    {elem.name}{" "}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="website "> Website </label>
              <input
                type="text"
                className="form-control"
                placeholder="Website"
                id="website"
                value={data?.website}
                onChange={(e) => setData({ ...data, website: e.target.value })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="linkedIn "> LinkedIn </label>
              <input
                type="text"
                className="form-control"
                placeholder="LinkedIn"
                id="linkedIn"
                value={data?.linkedin}
                onChange={(e) => setData({ ...data, linkedin: e.target.value })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="yearfounded "> Year Founded </label>
              <input
                type="number"
                className="form-control"
                placeholder="Year Founded"
                id="yearfounded"
                value={data?.year_founded}
                onChange={(e) =>
                  setData({ ...data, year_founded: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="description "> Description</label>
              <textarea
                className="form-control"
                value={data?.description}
                onChange={(e) =>
                  setData({ ...data, description: e.target.value })
                }
                placeholder="Description..."
              />
            </div>

            <div className="form-group">
              <label htmlFor="doccument"> Document </label>
              <div class="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="doccument"
                  onChange={handleDocChange}
                />
                <label
                  className="custom-file-label lineheight-28"
                  htmlFor="customFile"
                >
                  Choose file
                </label>
                {data?.document && !data?.document?.name && company?.id ? (
                  <div className="mt-2 ml-2 font-weight-bold">
                    <Link
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      to={`${IMAGEURL}${profile?.document}`}
                      target={"_blank"}
                    >
                      View Document
                    </Link>
                  </div>
                ) : (
                  <div className="mt-2 ml-2 font-weight-bold">
                    {data?.document?.name ?? ""}
                  </div>
                )}
              </div>
            </div>

            <div className="text-right w-5 mt-4">
              <button
                type="submit"
                onClick={(e) => handleAddCompany(e)}
                className="btn blue-btn"
                disabled={saving}
              >
                {saving ? "Submitting" : "Submit"}
              </button>
            </div>
          </form>
        </div>
      )}

      {/* modal to show image */}
      {company?.id && data?.banner && (
        <div class="modal fade" id="myModal">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Banner Image</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div class="modal-body">
                <img
                  src={`${IMAGEURL}${data?.banner}`}
                  alt=""
                  className="w-100"
                />
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {company?.id && data?.logo && (
        <div class="modal fade" id="myModal1">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Logo Image</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div class="modal-body">
                <img
                  src={`${IMAGEURL}${data?.logo}`}
                  alt=""
                  className="w-100"
                />
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyForm;
