import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import authApi from "../../../utils/authApi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateUser } from "../../../Redux/userSlice";
import axios from "axios";
const initialData = {
  year_of_experience: "",
  month_or_year: "",
};
const AddSkills = ({ editIt, linkText, icon, className }) => {
  const authId = useSelector((state) => state?.auth?.user?.info?.id); //user id
  const [data, setData] = useState(initialData);
  const [searchedSkills, setSearchedSkills] = useState([]); //state for searched titles
  const [showAddSkills, setShowAddSkills] = useState(false);
  const [skillId, setSkillId] = useState("");
  const [saving, setSaving] = useState(false);
  const [searchIt, setSearchIt] = useState("");
  const [deleting, setDeleting] = useState(false);
  const profile = useSelector((state) => state.user.profile); //profile of user from redux
  const dispatch = useDispatch();
  useEffect(() => {
    if (editIt?.id) {
      setData({
        year_of_experience: editIt?.year_of_experience,
        month_or_year: editIt?.month_or_year === "Month" ? "month" : "year",
      });
      setSkillId(editIt?.id);
    }
  }, [editIt]);

  const handleAddSkillssOpen = () => {
    setShowAddSkills(true);
  };

  const handleAddSkillssClose = () => {
    setShowAddSkills(false);
  };

  //function to fetch all searched skills
  let cancelToken;
  const getAllSearchedSkills = async (query) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    try {
      const res = await authApi.get(`search-skills/${searchIt}`, {
        cancelToken: cancelToken.token,
      });
      setSearchedSkills(res?.data?.results);
    } catch (error) {
      setSearchedSkills([]);
    }
  };

  useEffect(() => {
    if (searchIt) {
      getAllSearchedSkills();
    } else {
      setSearchedSkills([]);
    }

    return () => {
      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to component unmounting.");
      }
    };
  }, [searchIt]);
  


  const handleAddToSkills = (row) => {
    document.getElementById("skill").value = row.title;
    setSkillId(row?.id);
    setSearchedSkills([]);
  };

  const handleAddSkill = async (e) => {
    setSaving(true);
    e.preventDefault();
    try {
      const check = profile?.skills?.filter((ele) => ele.id === skillId);
      if (check.length > 0) {
        setSaving(false);
        setShowAddSkills(false);
        toast?.warn("Skill already exist.", { position: "top-center" });
      } else {
        await authApi.post(`users/${authId}/skills/${skillId}`, data);
        dispatch(updateUser());
        setShowAddSkills(false);
        toast.success("skill added successfully.");
        setData(initialData);
        setSkillId("");
        setSaving(false);
        document.getElementById("skill").value = "";
      }
    } catch (err) {
      setShowAddSkills(false);
      toast.error(err?.response?.data?.message);
      setSaving(false);
    }
  };

  

  //delete function
  const handleSkillDelete = async (e) => {
    setDeleting(true);
    e.preventDefault();
    try {
      await authApi.delete(`users/${authId}/skills/${skillId}`, data);
      dispatch(updateUser());
      setDeleting(false);
      setShowAddSkills(false);
      toast.success("Skill deleted successfully.");
    } catch (error) {
      setDeleting(false);
      setShowAddSkills(false);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Link
        to=""
        className={`add-new-label ${className}`}
        onClick={handleAddSkillssOpen}
      >
        {icon} {linkText}
      </Link>

      {showAddSkills && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {editIt?.id ? "Edit / Delete" : "Add"} Skills
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleAddSkillssClose}
                >
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body custom-scrollbar">
                  <div className="form-container  mb-3">
                    <div className="form-group">
                      <label htmlFor={`project_title`}>
                        Skill title <span className="text-danger">*</span>
                      </label>
                      <>
                        <div className="position-relative">
                          {editIt?.id ? (
                            <input
                              type="text"
                              id="editSkill"
                              value={editIt?.title}
                              disabled
                              className="form-control"
                            />
                          ) : (
                            <input
                              type="text"
                              id="skill"
                              onChange={(e) => setSearchIt(e.target.value)}
                              placeholder="Enter a Skill (ex: Project Management) and press Enter"
                              autoFocus
                              className="form-control"
                            />
                          )}

                          {searchedSkills.length > 0 && (
                            <div
                              className="position-absolute w-100 bg-light col"
                              style={{
                                top: "40px",
                                border: "1px solid lightgray",
                                borderRadius: "4px",
                                minHeight: "max-content",
                                maxHeight: "150px",
                                overflowY: "scroll",
                                boxShadow:
                                  " rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                              }}
                            >
                              {searchedSkills?.map((elem, index) => (
                                <div
                                  className="pt-4 pb-4 pl-2 row"
                                  style={{
                                    cursor: "pointer",
                                    borderTop:
                                      index > 0 && "1px solid lightgray",
                                  }}
                                  onClick={() => handleAddToSkills(elem)}
                                >
                                  {elem.title}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </>
                    </div>
                    <div className="d-sm-flex">
                      <div class="form-group flex-fill mr-sm-3 mr-0">
                        <label htmlFor={`experiance`}>
                          Experiance <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Ex. 5 Yrs."
                          id={`experiance`}
                          value={data.year_of_experience}
                          onChange={(e) =>
                            setData({
                              ...data,
                              year_of_experience: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div class="form-group flex-fill  ml-sm-3 ml-0">
                        <label htmlFor="start_date">
                          Month/Year <span className="text-danger">*</span>
                        </label>
                        <select
                          value={data.month_or_year}
                          onChange={(e) =>
                            setData({ ...data, month_or_year: e.target.value })
                          }
                          className="form-control w-100"
                        >
                          <option value="">Select</option>
                          <option value="year">Year</option>
                          <option value="month">Month</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer  text-right">
                  {editIt?.id ? (
                    <button
                      className="btn blue-btn"
                      onClick={(e) => handleSkillDelete(e)}
                      disabled={deleting}
                    >
                      {deleting ? "Deleting" : "Delete"}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      disabled={
                        !data.month_or_year ||
                        !data.year_of_experience ||
                        !skillId ||
                        saving
                      }
                      className="btn blue-btn"
                      onClick={(e) => handleAddSkill(e)}
                    >
                      {saving ? "Submitting" : "Submit"}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddSkills;
