import React, { useEffect, useState } from "react";
import "../../../CommonComponents/InputTag.css";
import { AiOutlineEdit, AiOutlinePlusCircle } from "react-icons/ai";
//import Dummy from "../../../../assets/dummy.jpg";
import AwardsRecognition from "./ProfileModal/AwardsRecognition";
import { useSelector } from "react-redux";
import { MdOutlineModeEditOutline } from "react-icons/md";
const ProfileAchievements = () => {
  const [achievements, setAchievements] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false); //flag to track that new achievement added
  const profile = useSelector((state) => state.user.profile); //profile of user from redux
  //hook to call getAllAchievements
  // useEffect(() => {
  //   getAllAchievements(authId, setAchievements,setIsLoading);
  // }, [updateFlag, authId]);
  useEffect(()=>{
    setAchievements(profile?.achievements)
    },[profile])

  return (
    <div className="profile-summary-container">
      <div className="profile-summary-header line-height-0 d-flex mb-2">
        <div className="profile-summary-heading">
          <h2>Awards & Recognition</h2>
        </div>
        <div className="profile-summary-icon">
          {" "}
          <AwardsRecognition
            updateFlag={updateFlag}
            setUpdateFlag={setUpdateFlag}
            icon={<AiOutlinePlusCircle className="gray fa-28 fa-mdd-24" />}
            className="icon-deafult"
          />
        </div>
      </div>
    
      {achievements && 
        achievements?.map((row, index) => (
          <div className="profile-summary-body d-flex justify-content-between mt-1" key={index}>
            <div className="profile-summary-inner-container d-flex">
              <div className="profile-view-content">
                <h4>{row?.title}</h4>
                <p>
                  {row?.from_year}-{row?.to_year}
                </p>
              </div>
            </div>
            <div>
              <AwardsRecognition
                updateFlag={updateFlag}
                setUpdateFlag={setUpdateFlag}
                icon={
                  <MdOutlineModeEditOutline className="gray ml-1 ml-sm-3 fa-28 fa-mdd-24" />
                }
                className="icon-deafult"
                editIt={row}
              />
            </div>
          </div>
        ))}
                     {achievements?.length === 0 && (
        <div className="d-flex justify-content-center w-100 mb-2">
          No Awards or Achievement, Add Now!
        </div>
      )}
    </div>
  );
};

export default ProfileAchievements;
