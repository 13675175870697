import React from 'react';
import loader from '../../../assets/loader.gif';
import './Loader.css'; // Import your CSS file for styling

const Loader = () => {
  return (
    <>
      
        <div className="loader-overlay side-spce">
           <div className='d-flex gap-5 align-items-center'>
             <div class="spinner-grow text-primary spinner-grow-md"></div>
             <div class="spinner-grow text-primary spinner-grow-sm"></div>
             <div class="spinner-grow text-primary spinner-grow-xs"></div>
             <div class="spinner-grow text-primary spinner-grow-xxs"></div>
           </div>
        </div>
   
    </>
  );
};

export default Loader;
