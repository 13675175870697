import React, { useEffect, useState } from "react";
// import {  useSelector } from "react-redux";
import authApi from "../../../../utils/authApi";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import AddScreeningQuestions from "../../PostYourJob/AddScreeningQuestions";
import { toast } from "react-toastify";
import { saveProfile } from "../../../../Redux/userSlice";
import Select from "react-select";

import { useDispatch, useSelector } from "react-redux";
import InputTag from "../../../CommonComponents/InputTag";
const initialData = {
  title: "",
  company_id: "",
  job_type_id: "",
  employment_type_id: "",
  about_company: "",
  role_responsibility: "",
  requirements: "",
  required_skills: [],
  skill_wise_experience: [],
  required_experience: "",
  salary: "",
  known_from: "",
  send_application_by: "",
  send_email_to: "",
  state_id: "",
  country_id: "",
  city_id: "",
};
const EditJobPost = ({ editThisPost, setShow, getAllJobPosts }) => {
  const [data, setData] = useState(initialData);
  const [updating, setUpdating] = useState(false);

  //react redux
  const authId = useSelector((state) => state?.auth?.user?.info?.id); //user id;
  const dispatch = useDispatch();
  //state for phase 1
  const [allCorporateTitles, setAllCorporateTitles] = useState([]);
  const [allJobTypes, setAllJobTypes] = useState([]);
  const [allEmpTypes, setAllEmpTypes] = useState([]);
  const [titleQuery, setTitleQuery] = useState(""); //query to search title
  const [searchedTitle, setSearchedTitle] = useState([]); //state for searched titles;
  const [job_type, setJob_type] = useState("");
  const [activeCountries, setActiveCountries] = useState([]); //state for saving countries
  const [activeStates, setActiveStates] = useState([]); //state for saving states
  const [activeCities, setActiveCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  //state for phase 2
  const [savedSkills, setSavedSkills] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [skill, setSkill] = useState("");
  const [expSkills, setExpSkills] = useState([
    { skill: "", experience: "", month_or_year: "" },
  ]);
  const [flag, setFlag] = useState(false);

  //state for phase 3
  //const [saving, setSaving] = useState(false);

  useEffect(() => {
    // console.log("Inside useEffect - editThisPost:", editThisPost);
    if (editThisPost) {
      // console.log("Setting data with editThisPost:", editThisPost);
      setData({
        title: editThisPost?.title,
        company_id: editThisPost?.company_id ?? "",
        job_type_id: editThisPost?.job_type_id ?? "",
        employment_type_id: editThisPost?.employment_type_id ?? "",
        city_id: editThisPost?.city_id ?? "",
        state_id: editThisPost?.state_id ?? "",
        country_id: editThisPost?.country_id ?? "",
        about_company: editThisPost?.about_company ?? "",
        role_responsibility: editThisPost?.role_responsibility ?? "",
        requirements: editThisPost?.requirements ?? "",
        required_skills: editThisPost?.required_skills ?? [],
        required_experience: editThisPost?.required_experience ?? "",
        skill_wise_experience:
          editThisPost?.skill_wise_experience ??
          JSON.stringify([{ skill: "", experience: "", month_or_year: "" }]),
        salary: editThisPost?.salary ?? "",
        status: editThisPost?.status ?? "",
        known_from: editThisPost?.known_from ?? "",
        send_application_by: editThisPost?.send_application_by ?? "",
        send_email_to: editThisPost?.send_email_to ?? "",
      });

      setSavedSkills(editThisPost?.required_skills);
      setTitleQuery(editThisPost?.title);
    }
  }, [editThisPost]);
  // console.log(editThisPost, expSkills);
  // console.log(editThisPost, data);

  //operation in phase 1
  //get All Corporat titles
  useEffect(() => {
    const getAllCorporateTitles = async () => {
      try {
        const res = await authApi.get(`get-active-corporate-title`);
        setAllCorporateTitles(res?.data?.data);
      } catch (error) {}
    };
    getAllCorporateTitles();
  }, []);
  //get All Job Type
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await authApi.get(`get-active-job-type`);
        setAllJobTypes(res?.data?.data);
      } catch (err) {}
    };
    fetch();
  }, []);
  useEffect(() => {
    if (data?.job_type_id && allJobTypes) {
      const filter = allJobTypes?.filter(
        (ele) => +ele?.id == +data?.job_type_id
      );
      if (filter?.length > 0) {
        setJob_type(filter[0]?.type);
      }
    }
  }, [data?.job_type_id, allJobTypes]);
  //set All Emp Types
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await authApi.get(`get-active-employment-type`);
        setAllEmpTypes(res?.data?.data);
      } catch (err) {}
    };
    fetch();
  }, []);
  //title change
  const handleTitleSearch = (e) => {
    const val = e.target.value;
    setTitleQuery(val);
    const filter = allCorporateTitles?.filter((ele) =>
      ele?.title?.toLowerCase()?.includes(val.toLowerCase())
    );
    setSearchedTitle(filter);
  };

  //get country

  const getAllCountries = async () => {
    try {
      const res = await authApi.get(`countries`);
      setActiveCountries(res?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    getAllCountries();
  }, []);

  //get state
  const getAllStates = async () => {
    if (data?.country_id) {
      try {
        const res = await authApi.get(`countries/${data?.country_id}/states`);
        setActiveStates(res?.data?.data);
      } catch (error) {}
    }
  };
  useEffect(() => {
    getAllStates();
  }, []);
  //get city
  const getAllCities = async () => {
    if (data?.state_id) {
      try {
        const res = await authApi.get(`states/${data?.state_id}/cities`);
        setActiveCities(res?.data?.data);
      } catch (error) {}
    }
  };
  useEffect(() => {
    getAllCities();
  }, [data?.state_id]);

  useEffect(() => {
    if (data?.country_id && activeCountries) {
      const filter = activeCountries?.filter(
        (ele) => ele?.id == data?.country_id
      );
      if (filter?.length > 0) {
        setSelectedCountry({ value: filter[0], label: filter[0]?.name });
      }
    }
  }, [data?.country_id, activeCountries]);

  useEffect(() => {
    if (data?.state_id && activeStates) {
      const filter = activeStates?.filter((ele) => ele?.id == data?.state_id);
      if (filter?.length > 0) {
        setSelectedState({ value: filter[0], label: filter[0]?.name });
      }
    }
  }, [data?.state_id, activeStates]);

  useEffect(() => {
    if (data?.city_id && activeCities) {
      const filter = activeCities?.filter((ele) => ele?.id == data?.city_id);
      if (filter?.length > 0) {
        setSelectedCity({ value: filter[0], label: filter[0]?.name });
      }
    }
  }, [data?.city_id, activeCities]);
  //operation in phase 2
  //
  useEffect(() => {
    if (typeof data?.skill_wise_experience === "string") {
      if (JSON.parse(data?.skill_wise_experience) == null) {
        setFlag(!flag);
        setExpSkills([{ skill: "", experience: "", month_or_year: "" }]);
      } else {
        setFlag(!flag);
        setExpSkills(JSON.parse(data?.skill_wise_experience));
      }
    }
  }, [data?.skill_wise_experience]);
  //skill add and remove
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const check = savedSkills?.filter(
        (elem) => elem.toLowerCase() === skill.toLowerCase()
      );
      if (check?.length === 0) {
        setSavedSkills([...savedSkills, skill]);
      } else {
        toast.warn("Skill already exists.", { position: "top-center" });
      }
      setShowInput(false);
      setSkill("");
    }
  };
  // to add skill with exp
  const handleAddMore = () => {
    setExpSkills([...expSkills, { skill: "", experience: "", type: "" }]);
  };
  //to remove skill with exp
  const handleRemoveRow = (e, ind) => {
    e.preventDefault();
    const filter = expSkills?.filter((ele, index) => index !== ind);
    setExpSkills(filter);
    setFlag(!flag);
  };
  useEffect(() => {
    if (expSkills?.length > 0) {
      const filter = expSkills?.filter(
        (ele) => ele?.skill && ele?.experience && ele?.month_or_year
      );
      if (filter?.length > 0) {
        const arr = filter?.map((ele) => ({
          skill: ele?.skill,
          experience: ele?.experience,
          month_or_year: ele?.month_or_year,
        }));
        setData({ ...data, skill_wise_experience: arr });
      }
    }
  }, [expSkills]);
  //removev skill
  const handleRemoveOption = (elem) => {
    const check = savedSkills?.filter(
      (ele) => ele.toLowerCase() !== elem.toLowerCase()
    );
    setSavedSkills(check);
  };

  //update job
  const handleUpdateJob = async () => {
    data.required_skills = savedSkills;
    setUpdating(true);
    try {
      await authApi.put(`recruiter/jobs/${editThisPost?.id}`, data);
      setUpdating(false);
      getAllJobPosts();
      setShow(false);
      toast.success("Job updated successfully.");
    } catch (err) {
      setUpdating(false);
      setShow(false);
      toast.error(err?.response?.data?.message);
    }
  };
  return (
    <>
      <div>
        <div className="form-group">
          <label htmlFor="title">
            Job Title
            <span className="text-danger">*</span>
          </label>
          <div className="position-relative">
            <input
              type="text"
              className="form-control"
              placeholder="Add the title you are hiring for"
              id="title"
              value={titleQuery}
              onChange={(e) => handleTitleSearch(e)}
            />
            {searchedTitle.length > 0 && (
              <div
                className="position-absolute w-100 bg-light col"
                style={{
                  top: "40px",
                  border: "1px solid lightgray",
                  borderRadius: "4px",
                  minHeight: "max-content",
                  maxHeight: "150px",
                  overflowY: "scroll",
                  boxShadow:
                    " rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                }}
              >
                {searchedTitle?.map((elem, index) => (
                  <div
                    className="pt-4 pb-4 pl-2 row"
                    style={{
                      cursor: "pointer",
                      borderTop: index > 0 && "1px solid lightgray",
                    }}
                    onClick={() => {
                      setTitleQuery(elem.title);
                      setSearchedTitle([]);
                      setData({
                        ...data,
                        title: elem.title,
                      });
                    }}
                  >
                    {elem.title}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="workpalce-type">
            Employment Type<span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            value={data?.employment_type_id}
            onChange={(e) =>
              setData({
                ...data,
                employment_type_id: e.target.value,
              })
            }
          >
            <option value="">Select Employment Type</option>
            {allEmpTypes?.map((elem, index) => (
              <option value={elem?.id} key={index}>
                {elem?.title}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="job-type">
            Job Type
            <span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            value={data?.job_type_id}
            onChange={(e) =>
              setData({
                ...data,
                job_type_id: e.target.value,
              })
            }
          >
            <option value="">Select Job Type</option>
            {allJobTypes?.map((elem, index) => (
              <option value={elem?.id} key={index}>
                {elem?.type}
              </option>
            ))}
          </select>
        </div>
        {(job_type === "Onsite" || job_type === "Hybrid") && (
          <div className="form-group">
            <label htmlFor="job-type">
              City
              <span className="text-danger">*</span>
            </label>
            <Select
              value={selectedCity}
              onChange={(e) => {
                setData({
                  ...data,
                  city_id: e.value?.id,
                });
                setSelectedCity(e);
              }}
              options={activeCities?.map((ele) => ({
                label: ele?.name,
                value: ele,
              }))}
            />
          </div>
        )}
        {(job_type === "Onsite" || job_type === "Hybrid") && (
          <div className="form-group">
            <label htmlFor="job-type">
              State
              <span className="text-danger">*</span>
            </label>
            <Select
              value={selectedState}
              onChange={(e) => {
                setData({
                  ...data,
                  state_id: e.value?.id,
                });
                setSelectedState(e);
              }}
              options={activeStates?.map((ele) => ({
                label: ele?.name,
                value: ele,
              }))}
            />
          </div>
        )}
        {(job_type === "Onsite" || job_type === "Hybrid") && (
          <div className="form-group">
            <label htmlFor="job-type">
              Country
              <span className="text-danger">*</span>
            </label>
            <Select
              value={selectedCountry}
              onChange={(e) => {
                setData({
                  ...data,
                  country_id: e.value?.id,
                });
                setSelectedCountry(e);
              }}
              options={activeCountries?.map((ele) => ({
                label: ele?.name,
                value: ele,
              }))}
            />
            {/* <select
              className="form-control"
              value={data?.job_type_id}
              onChange={(e) =>
                setData({
                  ...data,
                  job_type_id: e.target.value,
                })
              }
            >
              <option value="">Select Job Type</option>
              {allJobTypes?.map((elem, index) => (
                <option value={elem?.id} key={index}>
                  {elem?.type}
                </option>
              ))}
            </select> */}
          </div>
        )}

        <div>
          <div className="form-group">
            <label htmlFor="description">About the company</label>
            <textarea
              rows="6"
              className="form-control"
              value={data.about_company}
              onChange={(e) =>
                setData({ ...data, about_company: e.target.value })
              }
            ></textarea>
          </div>

          <div className="form-group">
            <label htmlFor="description">Role and responsibility</label>
            <textarea
              rows="6"
              className="form-control"
              value={data.role_responsibility}
              onChange={(e) =>
                setData({
                  ...data,
                  role_responsibility: e.target.value,
                })
              }
            ></textarea>
          </div>

          <div className="form-group">
            <label htmlFor="description">Requirement</label>
            <textarea
              rows="6"
              className="form-control"
              value={data.requirements}
              onChange={(e) =>
                setData({ ...data, requirements: e.target.value })
              }
            ></textarea>
          </div>

          <div className="form-group">
            <label htmlFor="description">Skills</label>
            <p className="para-text">
              Add skill keywords to make your job more visible to the right
              candidates.
            </p>
            <InputTag
              title="Add Skill"
              savedSkills={savedSkills}
              setSavedSkills={setSavedSkills}
            />
            {/* <div
              style={{
                border: "1px solid lightgrey",
                borderRadius: "6px",
                padding: "12px",
              }}
            >
              {savedSkills?.map((elem, index) => (
                <span
                  style={{
                    borderRadius: "12px",
                    border: "0.5px solid lightgray",
                    padding: "4px 8px",
                    marginRight: "8px",
                  }}
                >
                  {elem}
                  <AiFillCloseCircle
                    style={{
                      fontSize: "18px",
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemoveOption(elem)}
                  />
                </span>
              ))}
              {showInput ? (
                <input
                  autoFocus
                  onKeyDown={handleKeyPress}
                  onChange={(e) => setSkill(e.target.value)}
                  size="small"
                  placeholder="enter option..."
                  style={{
                    borderRadius: "4px",
                    outline: "none",
                    border: "0.5px solid lightgray",
                  }}
                />
              ) : (
                <span
                  style={{
                    borderRadius: "12px",
                    backgroundColor: "#673AB7",
                    cursor: "pointer",
                    border: "0.5px solid lightgray",
                    padding: "4px 8px",
                    color: "white",
                  }}
                  onClick={() => setShowInput(true)}
                >
                  Add Skill
                  <BsFillPlusCircleFill
                    style={{
                      fontSize: "18px",
                      marginLeft: "8px",
                    }}
                  />
                </span>
              )}
            </div> */}
          </div>
          <div className="form-group">
            <div className="d-flex justify-content-between align-items-center ">
              <label htmlFor="experiance">Skill with experience</label>
              <button
                className="btn btn-primary mb-1"
                onClick={() => handleAddMore()}
              >
                Add More
              </button>
            </div>
            {expSkills?.map((row, index) => (
              <SingleRow
                row={row}
                index={index}
                handleRemoveRow={handleRemoveRow}
                expSkills={expSkills}
                setExpSkills={setExpSkills}
                flag={flag}
              />
            ))}
          </div>

          <div className="form-group">
            <label htmlFor="experiance">Experiance</label>
            <input
              className="form-control"
              placeholder="Experiance"
              id="experiance"
              value={data.required_experience}
              onChange={(e) =>
                setData({
                  ...data,
                  required_experience: e.target.value,
                })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="salary">Salary</label>
            <input
              className="form-control"
              placeholder="Salary"
              id="salary"
              value={data.salary}
              onChange={(e) => setData({ ...data, salary: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">
              How did you hear about LinkedIn jobs?
            </label>
            <select
              className="form-control"
              value={data?.known_from}
              onChange={(e) => setData({ ...data, known_from: e.target.value })}
            >
              <option value="">Select Method</option>
              <option value="in_the_mail">In the mail</option>
              <option value="podcast">Podcast</option>
              <option value="ad_on_linked_in">Ad on LinkedIn.com</option>
              <option value="online_ad/search-engine">
                Online ad / Search engine
              </option>
              <option value="streaming_audio">
                Streaming Audio (Spotify/Pandora/iHeart/Deezer)
              </option>
              <option value="other">Other</option>
            </select>
          </div>
          <hr />
        </div>
        <div>
          <h3 className="form-title mb-3 ">Applicant collection </h3>
          <div className="d-flex flex-md-nowrap flex-wrap justify-content-between">
            <div className="w-100 mr-sm-2">
              <div className="form-group">
                <label htmlFor="description">
                  Receive applicants
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  value={data?.send_application_by}
                  onChange={(e) => {
                    if (e.target.value === "At an external website") {
                      setData({
                        ...data,
                        send_email_to: "",
                      });
                    }
                    setData({
                      ...data,
                      send_application_by: e.target.value,
                    });
                  }}
                >
                  <option value="">Select Method</option>
                  <option value="By email">By email</option>
                  <option value="At an external website">
                    At an external website
                  </option>
                </select>
              </div>
            </div>

            <div className="w-100 ml-sm-2">
              {data?.send_application_by === "By email" && (
                <div className="form-group">
                  <label htmlFor="email">
                    Email address
                    <span className="text-danger">*</span>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="example@gmail.com"
                    id="email"
                    value={data?.send_email_to}
                    onChange={(e) =>
                      setData({
                        ...data,
                        send_email_to: e.target.value,
                      })
                    }
                  />
                </div>
              )}
            </div>
          </div>

          {/* Conditional rendering based on the selected option */}

          {/* {data?.send_application_by === "By email" ? (
            <div className="mt-3">
              <h3 className="form-title m2-3 ">Screening questions</h3>
              <p className="para-text">
                We recommend adding 3 or more questions. Applicants must answer
                each question.
              </p>
              <p className="para-text mt-4">Add screening questions:</p>
              {/* Add Screening Questions */}
          {/* <AddScreeningQuestions /> 
              Add Screening Questions
            </div>
          ) : (
            <div className="mt-3">
              <p className="para-text">
                <b>Receive applicants by email to use screening questions.</b>
                Screening questions can’t be collected from applicants when they
                apply on an external site. If you would like to collect answers
                to screening questions, please choose to receive applicants by
                email.
              </p>
            </div>
          )} */}

          <hr />

          <div className="d-flex d-for-mob-block w-100 justify-content-between ">
            <div className="d-flex w-100 justify-content-between">
              <button
                className="btn btn-outline-secondary border-radius-50 w-sm-100 px-4 py-2 border-width fw-500 mr-2 mb-2 mb-sm-0"
                onClick={() => setShow(false)}
              >
                Cancel
              </button>
              <button
                onClick={() => handleUpdateJob()}
                className="btn blue-btn ml-sm-2 ml-0 fw-500 w-sm-100 mb-2 mb-sm-0"
                disabled={updating}
              >
                {updating ? "Updating" : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const SingleRow = ({
  row,
  index,
  handleRemoveRow,
  expSkills,
  setExpSkills,
  flag,
}) => {
  const [state, setState] = useState({});
  const [expSavedSkills, setExpSavedSkills] = useState([]);

  useEffect(() => {
    setState(row);
    if (row?.skill) {
      setExpSavedSkills([row?.skill]);
    }
  }, [flag]);

  useEffect(() => {
    if (expSavedSkills.length > 0) {
      setState({ ...state, skill: expSavedSkills[0] });
    }
  }, [expSavedSkills]);

  useEffect(() => {
    const updatedVariations = expSkills.map((v, i) =>
      i === index ? state : v
    );
    setExpSkills(updatedVariations);
  }, [state, index, setExpSkills]);
  return (
    <>
      <div className="d-flex mb-2 justify-content-around align-items-center">
        <div>
          <InputTag
            marginTop="mt-0"
            paddingTop="pt-0"
            marginRight="mr-2"
            single={true}
            title="Add Skill"
            width="120px"
            savedSkills={expSavedSkills}
            setSavedSkills={setExpSavedSkills}
          />
        </div>
        <input
          className="form-control mr-2"
          placeholder="experience"
          id="experiance"
          type="number"
          value={state?.experience}
          onChange={(e) => setState({ ...state, experience: e.target.value })}
        />
        <select
          className="form-control"
          placeholder="Experiance"
          id="experiance"
          value={state?.month_or_year}
          onChange={(e) =>
            setState({ ...state, month_or_year: e.target.value })
          }
        >
          <option value="">Select duration</option>
          <option value="year">Year</option>
          <option value="month">Month</option>
        </select>
        {expSkills?.length > 1 && (
          <button
            className="btn btn-danger ml-1"
            onClick={(e) => handleRemoveRow(e, index)}
          >
            -
          </button>
        )}
      </div>
    </>
  );
};

export default EditJobPost;
