import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../Profile/ProfileModal/Modal.css";
import authApi from "../../../../utils/authApi";
import "./GettingStarted.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../Redux/userSlice";
const initialState = {
  target_job: "",
  target_date: "",
  min_salary: "",
  max_salary: "",
};
const EditTargetGoal = ({ linkText, icon, className }) => {
  const [state, setState] = useState(initialState);
  const [showNewEducation, setShowNewEducation] = useState(false); //for popup Modal;
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [saving, setSaving] = useState(false);
  const dropdownRef = useRef(null);
  const profile = useSelector((state) => state?.user?.profile);
  const dispatch = useDispatch();



  useEffect(() => {
    if (profile?.target_job?.id) {
      setState({
        ...state,
        target_job: profile?.target_job?.target_job,
        target_date: profile?.target_job?.target_date,
        min_salary: profile?.target_job?.min_salary,
        max_salary: profile?.target_job?.max_salary,
      });
      setQuery(profile?.target_job?.target_job)
    }
  }, [profile]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  //debounce function
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleInputSearch = (event) => {
    const valu = event.target.value;

    if (valu.trim() !== "" && valu?.length >= 1) {
      setResults([]);
      searchTitle(valu);
      setShowDropdown(true);
    } else {
      setResults([]);
      setShowDropdown(false);
      // setSkillId("");
    }
  };

  const handleSearch = debounce(handleInputSearch, 500);

  const searchTitle = async (value) => {
    setSearching(true);
    try {
      const res = await authApi.get(`search-corporate/${value}`);
      setResults(res?.data);
      setSearching(false);
    } catch (error) {
      setSearching(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      await authApi.post(`create-update-target-job`, state);
      dispatch(updateUser());
      setShowNewEducation(false);
      setSaving(false);
      toast.success("Taget title added successfully.");
    } catch (err) {
      setSaving(false);
      setShowNewEducation(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleOpenNewEducation = () => {
    setShowNewEducation(true);
  };

  const handleCloseNewEducation = () => {
    setShowNewEducation(false);
  };
  // console.log(showDropdown);
  const handleSelectDropdownItem = (selectedItem) => {
    setQuery(selectedItem?.title); // You may need to adjust this based on your data structure;
    setState({ ...state, target_job: selectedItem?.title });
    setShowDropdown(false);
    // Handle the selected item as needed
  };
  return (
    <>
      <Link to="" className={`${className}`} onClick={handleOpenNewEducation}>
        {icon} {linkText}
      </Link>

      {showNewEducation && (
        <div className="modal " style={{ display: "block" }}>
          <div className="modal-dialog modal-lg opne">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Goals </h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleCloseNewEducation}
                >
                  &times;
                </button>
              </div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="form-container text-left">
                  <div className="modal-body custom-scrollbar">
                    <div className="form-group">
                      <label htmlFor="title ">Target Title</label>
                      <div className="position-relative">
                        <div className="d-flex align-items-center" style={{gap:"6px"}}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Target Title"
                          id="title"
                          value={query}
                          onChange={(e) => {
                            setQuery(e.target.value);
                            handleSearch(e);
                          }}
                        />
                     {searching &&   <div class="spinner-border text-primary"></div>}
                        </div>
                      
                        {results?.length > 0 && showDropdown && (
                          <div
                            ref={dropdownRef}
                            style={{ position: "absolute", width: "100%" }}
                          >
                            <div className="Dropdown">
                              {results.map((result) => (
                                <div
                                  key={result.id}
                                  onClick={() => {
                                    handleSelectDropdownItem(result);
                                  }}
                                >
                                  {result.title}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="date ">Target Date </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Target Date"
                        id="date"
                        value={state?.target_date}
                        onChange={(e) => {
                          setState({ ...state, target_date: e.target.value });
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="salary "> Salary Min (USD) </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Salary Min"
                        id="salary"
                        value={state?.min_salary}
                        onChange={(e) => {
                          setState({ ...state, min_salary: e.target.value });
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="salary "> Salary Max (USD) </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Salary Max"
                        id="salary"
                        value={state?.max_salary}
                        onChange={(e) => {
                          setState({ ...state, max_salary: e.target.value });
                        }}
                      />
                    </div>

                    {/* <div class="form-group flex-fill">
                      <label htmlFor="currency">Currency</label>
                      <select class="form-control w-100" id="currency">
                        <option>Please Select </option>
                        <option>Indian Rupee</option>
                        <option>US Dollar</option>
                      </select>
                    </div> */}
                  </div>

                  <div className="modal-footer  text-right">
                    <button
                      type="submit"
                      disabled={saving}
                      className="btn blue-btn"
                    >
                      {saving ? "Updating" : "Update"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditTargetGoal;
