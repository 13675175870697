import React from 'react'
import EditPageWrapper from '../EditPageWrapper'

const EditExperience = () => {
  return (
    <EditPageWrapper>
    Hello
   </EditPageWrapper>
  )
}

export default EditExperience