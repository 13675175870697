import React, { useState } from 'react';
import './AddMediaDropdown.css';
import { AiOutlinePlus } from 'react-icons/ai';

const AddMediaDropdown = ({media,setMedia}) => {
  const [showFileField, setShowFileField] = useState(false);

  const handleAddMediaClick = () => {
    setShowFileField(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setMedia(file);
  };

  return (
    <>
      <div className='outline-btn pt-2'>
        {showFileField ? (
          <>
            <input type="file" className='form-control' onChange={handleFileChange} />
          </>
        ) : (
          <button className='btn btn-blue-outline' onClick={handleAddMediaClick}>
            <AiOutlinePlus /> Add Media
          </button>
        )}
      </div>
    </>
  );
};

export default AddMediaDropdown;
