import React, { useEffect, useState } from "react";
import "../../../CommonComponents/InputTag.css";
import { AiOutlineEdit, AiOutlinePlusCircle } from "react-icons/ai";
//import Dummy from "../../../../assets/dummy.jpg";
import AddInterest from "./ProfileModal/AddInterest";
import { useSelector } from "react-redux";
import { MdOutlineModeEditOutline } from "react-icons/md";

const ProfileInterest = () => {
  const [listItems, setListItems] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false); //flag to track that new achievement added

  const profile = useSelector((state) => state.user.profile); //profile of user from redux
  //hook to call getAllHobbies
  // useEffect(() => {
  //   getAllHobbies(authId, setListItems, setIsLoading);
  // }, [updateFlag, authId]);
  useEffect(() => {
    setListItems(profile?.hobbies);
  }, [profile]);

  const section = {
    heading: "Interest",
    paragraphs: [
      {
        content: "Game",
        className: "gray",
      },
    ],
    icons: [
      
      <AddInterest
        icon={
          <MdOutlineModeEditOutline className="gary-text ml-1 ml-sm-3 fa-28 fa-mdd-24" />
        }
        className="icon-deafult"
      />,
    ],
  };
  return (
    <div className="profile-summary-container">
      <div className="profile-summary-header line-height-0 d-flex">
        <div className="profile-summary-heading">
          <h2>{section.heading}</h2>
        </div>
        <div className="profile-summary-icon">
          <AddInterest
            updateFlag={updateFlag}
            setUpdateFlag={setUpdateFlag}
            icon={<AiOutlinePlusCircle className="gray fa-28 fa-mdd-24" />}
            className="icon-deafult"
          />
        </div>
      </div>

      {listItems &&
        listItems?.map((row, index) => (
          <div
            className="profile-summary-body d-flex justify-content-between mt-1"
            key={index}
          >
            <div className="profile-summary-inner-container d-flex">
              <div className="profile-view-content">
                <h4>{row?.name}</h4>
              </div>
            </div>
            <div>
              <AddInterest
                updateFlag={updateFlag}
                setUpdateFlag={setUpdateFlag}
                editIt={row}
                icon={
                  <MdOutlineModeEditOutline className="gary-text ml-1 ml-sm-3 fa-28 fa-mdd-24" />
                }
                className="icon-deafult"
              />
            </div>
          </div>
        ))}
            {listItems?.length === 0 && (
        <div className="d-flex justify-content-center w-100 mb-2">
          No Interest, Add Now!
        </div>
      )}
    </div>
  );
};

export default ProfileInterest;
