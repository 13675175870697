import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { RxCrossCircled } from "react-icons/rx";
import "./InputTag.css";
import authApi from "../../utils/authApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import "ldrs/ring2";
import axios from "axios";

// let cancelToken;
//   const handleSearchChange = async (e) => {
//     const searchTerm = e.target.value;

//     //Check if there are any previous pending requests
//     if (typeof cancelToken != typeof undefined) {
//       cancelToken.cancel("Operation canceled due to new request.");
//     }

//     //Save the cancel token for the current request
//     cancelToken = axios.CancelToken.source();

//     try {
//       const results = await axios.get(
//         `http://localhost:4000/animals?q=${searchTerm}`,
//         { cancelToken: cancelToken.token } //Pass the cancel token to the current request
//       );
//       console.log("Results for " + searchTerm + ": ", results.data);
//     } catch (error) {
//       console.log(error);
//     }
//   }
// Default values shown

const InputTag = ({
  title,
  savedSkills,
  setSavedSkills,
  single,
  marginTop,
  paddingTop,
  marginRight,
  width
}) => {
  const inputRef = useRef(null);
  const [inputFocused, setInputFocused] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false);
  const [showAddBtn, setShowAddBtn] = useState(true);
  const loginAs = useSelector((state) => state.auth.loginAs);
  const [searching, setSearching] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [searchIt,setSearchIt]=useState('')
  const [searchedSkills, setSearchedSkills] = useState([]);

  //function to fetch all searched skills
  let cancelToken;
  const getAllSearchedSkills = async () => {
    setSearching(true);
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    cancelToken = axios.CancelToken.source();
    try {
      if (loginAs === "Recruiter") {
        const res = await authApi.get(`recruiter/search-skills/${searchIt}`, {
          cancelToken: cancelToken.token,
        });
        const data=res?.data?.results;
        let newData = data.flatMap(({ title, alternate_names }) => {
          let names = [];
          try {
              names = JSON.parse(alternate_names);
          } catch (error) {
              console.error("Error parsing alternate names:", error);
          }
          if(names?.length>0){
            return [title, ...names].map(name => ({ title: name }));
          }else{
            return [title].map(name => ({ title: name }));
          }
         
      });
      
        setSearchedSkills(newData);
        setSearching(false);
      } else {
        const res = await authApi.get(`search-skills/${searchIt}`, {
          cancelToken: cancelToken.token,
        });
        const data=res?.data?.results;
        let newData = data.flatMap(({ title, alternate_names }) => {
          let names = [];
          try {
              names = JSON.parse(alternate_names);
          } catch (error) {
              console.error("Error parsing alternate names:", error);
          }
          if(names?.length>0){
            return [title, ...names].map(name => ({ title: name }));
          }else{
            return [title].map(name => ({ title: name }));
          }
         
      });
      
        setSearchedSkills(newData);
        setSearching(false);
      }
    } catch (error) {
      setSearchedSkills([]);
      setSearching(false);
    }
  };

  useEffect(() => {
    if (single) {
      if (savedSkills?.length > 0) {
        setShowAddBtn(false);
      } else {
        setShowAddBtn(true);
      }
    }
  }, [single,savedSkills]);


  const handleSkillSearch = (event) => {
    const valu = event.target.value;
    setSearchIt(valu)
    // if (valu.trim() !== "" && valu?.length >= 1) {
    //   setSearchedSkills([]);
    //   getAllSearchedSkills(valu);
    // } else {
    //   setSearchedSkills([]);
    // }
  };

  useEffect(()=>{
    if(searchIt){
      getAllSearchedSkills();
    }else{
      setSearchedSkills([]);
    }
    return ()=>{
      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to component unmounting.");
      }
    }
  },[searchIt])

  // const handleSkillSearch = debounce(handleSkillInputSearch, 500);

  const handleAddSkillClick = () => {
    setBtnClicked(true);
    setShowInput(true);
  };
  const handleInputBlur = () => {
    if (searchedSkills.length === 0) {
      if (!inputRef.current.contains(document.activeElement)) {
        setShowInput(false);
      }
    }
  };
  const handleAddToSkills = (event) => {

    const check = savedSkills?.filter((ele) => ele === event?.title);
    if (check.length > 0) {
      toast.warn("Skill already exist!", { position: "top-center" });
    } else {
      setSavedSkills([...savedSkills, event.title.trim()]);
    }
    setShowInput(false);
    setSearchedSkills([]);
  };


const handleAddThis=(e)=>{
 if (e.key === "Enter") {
    const inputValue = e.target.value.trim();
    if (inputValue !== "") {
      setSavedSkills([...savedSkills, inputValue]);
      setShowInput(false);
      setSearchedSkills([]);
      e.target.value = ""; // Clear the input field after adding the skill
    }
  }
}
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     console.log("ji",inputRef.current,event.target);
  //     if (
  //       inputRef.current && !inputRef.current.contains(event.target)
  //     ) {
  //    console.log("yes");
  // console.log("show input", showInput,searchedSkills);

  //       if(searchedSkills.length>0){
  //         setShowInput(false);
  //       }

  //     }
  //   };
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  const handleInputFocus = () => {
    setInputFocused(true);
  };
  // useEffect(()=>{
  //   if(!showInput){
  //     setSearchedSkills([])
  //   }
  // },[showInput])

  return (
    <>
      <div className={`tag-list ${marginTop ? marginTop : "mt-2"} ${marginRight ? marginRight : "mr-0"}`}>
        {savedSkills?.map((tag, index) => (
          <div key={index} className="tag">
            {tag}
            <button
              className="tag-remove"
              onClick={() =>
                setSavedSkills(savedSkills?.filter((_, i) => i !== index))
              }
            >
              &#x2716;
            </button>
          </div>
        ))}
      </div>

      {!showInput && showAddBtn && (
        <div
          className={`outline-btn ${paddingTop ? paddingTop : "pt-2"} ${marginRight ? marginRight : "mr-0"}`}
          style={{ width: "max-content" }}
        >
          <Link
            to=""
            className="btn btn-blue-outline"
            onClick={handleAddSkillClick}
          >
            <AiOutlinePlus /> {title}
          </Link>
        </div>
      )}

      {showInput && (
        <>
          <div className="position-relative">
            <div className="position-relative">
              {" "}
              <input
                type="text"
                onChange={handleSkillSearch}
                placeholder="Enter a Skill (ex: Project Management) and press Enter"
                autoFocus
                style={{width:width??''}}
                className={`form-control ${marginRight ? marginRight : "mr-0"}`}
                ref={inputRef}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onKeyDown={(e)=>handleAddThis(e)}
              />
              {searching && (
                <div style={{ position: "absolute", right: 10, top: 1 }}>
                  <div className="loader"></div>
                </div>
              )}
              {searchedSkills.length > 0 && (
                <div
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowInput(false);
                    setSearchedSkills([]);
                  }}
                >
                  <RxCrossCircled className="fa-30" />
                </div>
              )}
            </div>

            {searchedSkills.length > 0 && (
              <div
                className="position-absolute w-100 bg-light col mb-2"
                style={{
                  top: "40px",
                  border: "1px solid lightgray",
                  borderRadius: "4px",
                  minHeight: "max-content",
                  zIndex:100,
                  maxHeight: "150px",
                  
                  overflowY: "scroll",
                  boxShadow:
                    " rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                }}
              >
                {searchedSkills?.map((elem, index) => (
                  <div
                    className="pt-2 pb-2 pl-2 row single-skill"
                    style={{
                      cursor: "pointer",
                      borderTop: index > 0 && "1px solid lightgray",
                      height:"30px",
                      verticalAlign:"middle",
                      display:"flex",
                      alignItems:"center"
                    }}
                    onClick={() => handleAddToSkills(elem)}
                  >
                    {elem.title}
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default InputTag;
