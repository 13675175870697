import React, { useEffect, useState } from "react";
import authApi from "../../../../utils/authApi";
import { useNavigate } from "react-router-dom";
import { DotSpinner } from "@uiball/loaders";
import Loader from "../../../CommonComponents/Loader/Loader";

const SuggestedJobSearches = () => {
  const [data, setData] = useState([]);
  const [loading,setLoading]=useState(true)
  const navigate = useNavigate();
  const getRecomJobs = async () => {
    setLoading(true)
    try {
      const res = await authApi.get(`getRecommendateJobSearch?limit=10`);
      setData(res?.data?.random_corporate_titles);
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  };
  useEffect(() => {
    getRecomJobs();
  }, []);
  return (
    <>
      {loading && (
              <div className="d-flex justify-content-center mt-5 mb-5">
                {" "}
                <Loader />
              </div>
            )}
    {!loading &&  <div className="card border-0 profile-bg mb-3">
        <div className="card-header border-bottom-0 bg-transparent">
          <h3 className="card-title border-bottom-2 pb-3 pb-lg-2">
            Suggested job searches
          </h3>
        </div>
        <div className="card-body pt-2">
          <div className="Suggested-job-list d-flex flex-wrap gap-5">
            {data?.map((row, index) => (
              <span
                key={index}
                className="fa-14 black fw-400 border-1 px-3 py-1 border-radius-50 "
                style={{cursor:"pointer"}}
                onClick={() => navigate(`/job-search?title=${row}`)}
              >
                {row}{" "}
              </span>
            ))}
          </div>
        </div>
      </div>}
    </>
  );
};

export default SuggestedJobSearches;
