import React from 'react';
import {Link} from 'react-router-dom';
import { AiFillQuestionCircle } from 'react-icons/ai';
import Tooltip from '../../../CommonComponents/ToolTips';

const BillingCard = () => {
  return (
    <>
      <div className='card mt-3'>
        <div className='card-body'>
            <div className="billing-card post-job-info">
               <h4 className='fw-400'>Billing information <Tooltip content="Your billing information applies to all your active job posts.">
        <AiFillQuestionCircle className='gray fa-20' />
      </Tooltip></h4> 
                <div className='mt-3'>
                   <p className='link-text mb-1'><Link to="/packages">Payment method</Link></p> 
                   <p className='link-text'><Link to="/purchase-history">Purchase history</Link></p> 
                </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BillingCard
