import React, { useEffect, useState } from "react";
import authApi from "../../../../utils/authApi";
import Loader from "../../../CommonComponents/Loader/Loader";
import './about-us.css';

const AboutUs = () => {
  const [about, setAbout] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const getAboutUs = async () => {
    try {
      const res = await authApi.get(`get-aboutus`);
      setAbout(res?.data?.aboutus?.description);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }  
  };
  useEffect(() => {
    getAboutUs();
  }, []);
  return (

    <>
    <section>
        <div className="fluid-container side-space">
          <div className="header-contact-body ptb-5">
            <div className="header-contact-form-new">
              {isLoading && (
                <div className="d-flex justify-content-center">
                   <Loader />
                </div>
              )}
              {!isLoading && (
                <div className="heading-policy">
                   <div
                className="privecy-setting"
                  dangerouslySetInnerHTML={{
                    __html: about ?? "<p>No Content</p>",
                  }}
              ></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
  </>
  );
};

export default AboutUs;
