import React from "react";
import Icon from "../../assets/icon.png";
import "./ApplyJobsCard.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { DotSpinner } from "@uiball/loaders";
import Loader from "./Loader/Loader";
const ApplyJobsCard = ({
  jobs,
  className,
  classNameCard,
  Classtitle,
  boxspace,
  iocn,
  headingstyle,
  isLoading
}) => {
  const loginAs = useSelector((state) => state.auth.loginAs);
  function getTimeDifferenceString(timeString) {
    const currentTime = new Date();
    const pastTime = new Date(timeString);
    const timeDifference = currentTime - pastTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 30) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    }
  }
  //  to={`/view-job-post/${row.id}`}
  return (
    <>
       {isLoading && (
              <div className="d-flex justify-content-center mt-5 mb-5">
                {" "}
                <Loader />
              </div>
            )}
    {!isLoading &&  <div className={`${boxspace}`}>
        {jobs.map((job, index) => (
          <div key={index}>
            <div className="heading">
              <h3 className={`job-card-title ${Classtitle}`}>{job.title}</h3>
            </div>
            <div className={`${className}`}>
              {job?.items?.length === 0 && !isLoading&&
                job?.title === "Recent Job Applied" && (
                  <div className="d-flex justify-content-center my-2 fa-16">
                    {" "}
                    You haven't applied any job yet.{" "}
                  </div>
                )}

              {job?.items?.length === 0 &&!isLoading&&
                job?.title === "Recent Job Posts" && (
                  <div className="d-flex justify-content-center my-2 fa-16">
                    {" "}
                    You haven't posted any job yet.{" "}
                  </div>
                )}

              {job?.items?.length > 0 &&
                job.items.map((item, itemIndex) => (
                  <Link
                    className={`job-card ${classNameCard}`}
                    to={
                      loginAs === "Candidate"
                        ?   `/view-job/${item?.id}`
                        : `/view-job-post/${item?.id}`
                    }
                    key={itemIndex}
                  >
                    <div className="job-card-icon">
                      <img src={Icon} className="img-fluid" alt="job icon" />
                    </div>
                    <div className="job-card-content">
                      <h4 className={`${headingstyle}`}>{item.title}</h4>
                      <p>
                        {item?.job_type?.type}

                        {item?.employment_type?.title
                          ? ", " + item?.employment_type?.title
                          : ""}
                      </p>
                    { item?.salary && <p>{item?.salary??'-'} </p>}
                      <div className="job-content-inner d-sm-flex d-block align-items-center mt-2 justify-content-between">
                        {/* <div className="job-content-inner-icon d-flex  align-items-center">
                        <BiNotepad />
                      </div> */}
                        <div className="job-content-inner-content d-flex justify-content-start justify-content-sm-end mt-sm-0 mt-2">
                          <p>{getTimeDifferenceString(item?.created_at)}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        ))}
      </div>}
    </>
  );
};

export default ApplyJobsCard;
