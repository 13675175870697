import React, { useEffect, useState } from "react";
import ApplyJobsCard from "../../../CommonComponents/ApplyJobsCard";
import "./RecommendedJobs.css";
import { BiNotepad } from "react-icons/bi";
import authApi from "../../../../utils/authApi";
import { DotSpinner } from "@uiball/loaders";
import Loader from "../../../CommonComponents/Loader/Loader";

const RecommendedJobs = () => {
  const [data,setData]=useState([]);
  const [loading,setLoading]=useState(true)
  const getRecomJobs = async () => {
    setLoading(true);
    try {
  

      const res = await authApi.get(`getJobRecommendations`);
      setLoading(false)
      setData(res?.data)
    } catch (error) {
      setLoading(false)
    }
  };
  useEffect(() => {
    getRecomJobs();
  }, []);
  const RecommendedJobs = [
    { 
      title: " Recommended for you",
      subtitle: "Based on your profile and search history",
      items: data,
    },
  ];

  const jobgride = "job-grid";
  const jobcardbg = "job-card-gredient";

  return (
    <>
     {loading && (
              <div className="d-flex justify-content-center mt-5">
                {" "}
                <Loader />
              </div>
            )}
    { !loading && <div className="card">
        <div className="card-body">
          <div className="recommended-job">
            <ApplyJobsCard
              jobs={RecommendedJobs}
              className={jobgride}
              classNameCard={jobcardbg}
              Classtitle="fa-20"
            />
          </div>
        </div>
      </div>}
    </>
  );
};

export default RecommendedJobs;
