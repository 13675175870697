import React, { useEffect, useState } from "react";

const ViewScreeningquestion = ({ job }) => {
  const [cusQsns, setCusQsns] = useState([]);
  const [scrQsns, setScrQsns] = useState([]);
  const [mustScrQsns, setMustScrQsns] = useState([]);
  const [prefScrQsns, setPrefScrQsns] = useState([]);
  const [mustCusQsns, setMustCusQsns] = useState([]);
  const [prefCusQsns, setPrefCusQsns] = useState([]);

  useEffect(() => {
    setCusQsns(job?.custom_questions);
    setScrQsns(job?.screening_questions);
  }, [job, setCusQsns, setScrQsns]);

  //set must have qsns
  useEffect(() => {
    if (scrQsns?.length > 0) {
      const filter = scrQsns?.filter((ele) => ele.pivot.recommended === 1);
      const filter1 = scrQsns?.filter((ele) => ele.pivot.recommended === 0);
      setMustScrQsns(filter);
      setPrefScrQsns(filter1);
    }
  }, [scrQsns]);

  useEffect(() => {
    if (cusQsns?.length > 0) {
      const filter = cusQsns?.filter((ele) => ele.recommended === true);
      const filter1 = cusQsns?.filter((ele) => ele.recommended === false);
      setMustCusQsns(filter);
      setPrefCusQsns(filter1);
    }
  }, [cusQsns]);

  return (
    <>
      {(mustCusQsns?.length > 0 ||
        mustScrQsns?.length > 0 ||
        prefCusQsns?.length > 0 ||
        prefScrQsns?.length > 0) && (
        <div className="card mt-3">
          <div className="card-header bg-transparent border-bottom-0">
            <h3 className="card-title fw-500 light-black">
              Screening question{" "}
            </h3>
          </div>
          <div className="card-body pt-0">
            {(mustCusQsns?.length > 0 || mustScrQsns?.length > 0) && (
              <h3 className="fw-600 fa-20 light-black fa-md-18">
                Must-have qualifications{" "}
              </h3>
            )}
            {mustCusQsns?.length > 0 &&
              mustCusQsns?.map((row, index) => (
                <div className="post-job-info mb-3">
                  <h4>{row?.question ?? "-"}</h4>
                  <p>Ideal answer: {row?.min_value ?? row?.answer}</p>
                </div>
              ))}
            {mustScrQsns?.length > 0 &&
              mustScrQsns?.map((row, index) => (
                <div className="post-job-info mb-3">
                  <h4>{row?.question ?? "-"}</h4>
                  <p>
                    Ideal answer: {row?.pivot?.min_value ?? row?.pivot?.answer}
                  </p>
                </div>
              ))}

            {(prefCusQsns?.length > 0 || prefScrQsns?.length > 0) && (
              <h3 className="fw-600 fa-20 light-black">
                Preferred qualifications{" "}
              </h3>
            )}

            {prefCusQsns?.length > 0 &&
              prefCusQsns?.map((row, index) => (
                <div className="post-job-info mb-3">
                  <h4>{row?.question ?? "-"} </h4>
                  <p>Ideal answer: {row?.min_value ?? row?.answer}</p>
                </div>
              ))}
            {prefScrQsns?.length > 0 &&
              prefScrQsns?.map((row, index) => (
                <div className="post-job-info mb-3">
                  <h4>{row?.question ?? "-"}</h4>
                  <p>
                    Ideal answer: {row?.pivot?.min_value ?? row?.pivot?.answer}
                  </p>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ViewScreeningquestion;
