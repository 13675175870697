import React from "react";
import LeftSidebar from '../LeftSideBar/LeftSidebar';
import BeInTheBest from '../../Homepage/BeInTheBest/BeInTheBest';
import AppliedJobsData from "../../../CommonComponents/AppliedJobsData";
import RecommendedJobs from "./RecommendedJobs";
import SuggestedJobSearches from "./SuggestedJobSearches";
import RecentJobsPostData from "../../../CommonComponents/RecentJobsPostData";

const MyJob = () => {
  return (
    <>
      <section>
        <div className="container-fluid side-space">
          <div className="grid-container pt-4 pb-4">
            <div className="left-side grid-left">
               <LeftSidebar />
            </div>

            <div className="main-content grid-middle mt-smm-0 mt-3">  
              <SuggestedJobSearches/>
              <RecommendedJobs/>
            </div>

            <div className="right-side grid-right"> 
                  <AppliedJobsData  title="Applied Jobs"/>
            </div>
          </div>
        </div>
      </section>

      {/* BeInTheBest */}

      <section className="pt-4 pb-75">
        <BeInTheBest />
      </section>
    </>
  );
};

export default MyJob;
