import React from 'react'
import EditPageWrapper from '../EditPageWrapper'

const EditSkills = () => {
  return (
   <EditPageWrapper>
    Hello
   </EditPageWrapper>
  )
}

export default EditSkills