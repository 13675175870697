import React from "react";
import { Link } from "react-router-dom";
import { ImArrowUpRight2 } from "react-icons/im";

const JobListTitle = ({number , title, subpara ,link}) => {
  return (
    <div className="job-list-title mb-6 mb-small-3">
      <h3>
        <span className="fw-700 theme-color">{number}</span> {title}
      </h3>
      <p>{subpara}</p>

      {/* <div className="linkbtn mt-35">
        <Link to={link}>
          View All <ImArrowUpRight2 className="ml-3 arrow-icon" />
        </Link>
      </div> */}
    </div>
  );
};

export default JobListTitle;
