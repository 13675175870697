// import axios from "axios";
// import { store } from "../Redux/store";
// import { setUserToken } from "../Redux/authSlice"; 

// import jwt_decode from 'jwt-decode';
// import { APIBASE } from "../constant";

// function parseToken(token) {
//   try {
//     const decodedToken = jwt_decode(token);
//     return decodedToken;
//   } catch (error) {
//     console.error('Error decoding token:', error);
//     return null;
//   }
// }

// const authApi = axios.create({
//   baseURL: process.env.REACT_APP_API_URL + "api/",
//   headers: {
//     Accept: "application/json",
//     // Add any other common headers here
//   },
// });

// // Add a request interceptor
// authApi.interceptors.request.use(
//   (config) => {
//     const state = store.getState();
//     const authToken = state.auth.user._token; // Adjust this based on your Redux store structure;
//     if (authToken) {
//       config.headers.Authorization = `Bearer ${authToken}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // Function to check if the token is expired
// function isTokenExpired(token) {
//   if (!token) {
//     return true;
//   }

//   const tokenData = parseToken(token); // Implement this function to parse the token
//   if (!tokenData || !tokenData.exp) {
//     return true;
//   }

//   const currentTime = Math.floor(Date.now() / 1000);

//   return tokenData.exp < currentTime;
// }

// // Axios interceptor to refresh the token
// authApi.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
    
//     const state = store.getState();
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const yourAccessToken = state.auth.user._token;
//       // Check if the token is expired
//       if (isTokenExpired(yourAccessToken)) {
//         try {
//           // Attempt to refresh the token using your refresh token
//           const response = await axios.post(`${APIBASE}/refresh-token`, {
//             refreshToken: yourAccessToken,
//           },{ headers: { Authorization: `Bearer ${yourAccessToken}` }});

//           // Update the access token in your application state
//           const newAccessToken = response.data.accessToken;
//           // Dispatch the action to update the token in the Redux store
//           // const dispatch=useDispatch();
//           store.dispatch(setUserToken(newAccessToken));
//           // Update your Axios instance headers with the new access token
//           authApi.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;

//           // Retry the original request with the new access token
//           return authApi(originalRequest);
//         } catch (refreshError) {
//           // Handle refresh token failure, e.g., log the user out
//           // Redirect to the login page or show an error message
//           // Clear the tokens from your application state
//         }
//       }
//     }

//     return Promise.reject(error);
//   }
// )
// export default authApi;
import axios from "axios";
import { store } from "../Redux/store"; // Import your Redux store
import { setUserToken } from "../Redux/authSlice";

const authApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "api/",
  headers: {
    Accept: "application/json",
    // Add any other common headers here
  },
});

// Add a request interceptor
authApi.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const authToken = state.auth.user.access_token; // Adjust this based on your Redux store structure
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token refresh
authApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the response status is 401 (unauthorized) and the request was not a token refresh request
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Make an API request to refresh the token
        const state = store.getState();
        if(state.loginAs==="Candidate"){
          const refreshedTokenResponse = await authApi.post("/refresh-token"); // Adjust the API endpoint as needed
          // Update the token in localStorage
          // const newAccessToken = refreshedTokenResponse.data.access_token;
          // Update the access token in your application state
          const newAccessToken = refreshedTokenResponse.data.access_token;
          // Dispatch the action to update the token in the Redux store
          // const dispatch = useDispatch();
          store.dispatch(setUserToken(newAccessToken));
  
          // Update the Authorization header with the new token
          authApi.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${newAccessToken}`;
  
          // Retry the original request
          return authApi(originalRequest);
        }else{
          
        }
       
      } catch (refreshError) {
        // Handle token refresh error (e.g., redirect to login page)
        // You can also log the user out or display a message
        console.error("Token refresh failed:", refreshError);

        // Logout the user or redirect to the login page
        // Example: window.location.href = '/login';
        // Example: logout();
      }
    }

    return Promise.reject(error);
  }
);

export default authApi;
