import React from "react";

const JobDescription = ({ item }) => {
  return (
    <>
      <div className="job-content">
        {item?.required_skills && (
          <>
            <h4>Required Skills</h4>
            {item?.required_skills?.map((ele, index) => (
              <p key={index}>• {ele} </p>
            ))}
          </>
        )}
        {item?.about_company && (
          <>
            <h4>About Company</h4>
            <p> {item?.about_company} </p>
          </>
        )}

        {item?.requirements && (
          <>
            <h4>Requirements</h4>
            <p>{item?.requirements}</p>
          </>
        )}

        {item?.role_responsibility && (
          <>
            <h4>Roles & Responsibility</h4>
            <p>{item?.role_responsibility} </p>
          </>
        )}

        {item?.salary && (
          <>
            <h4>Salary</h4>
            <p>{item?.salary}</p>
          </>
        )}
      </div>
    </>
  );
};

export default JobDescription;
