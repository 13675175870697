import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InputTag from "../../../../CommonComponents/InputTag";
import "./Modal.css";
import authApi from "../../../../../utils/authApi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateUser } from "../../../../../Redux/userSlice";
import axios from "axios";
const initialState = {
  user_id: "",
  corporate_title_id: "",
  employment_type_id: "",
  job_type_id: "",
  company_name: "",
  start_date: "",
  end_date: "",
  present_job: false,
  status: true,
  current_position: false,
  industry_id: "",
  state_id: "",
  country_id: "",
  description: "",
  profile_headline: "",
  skills: [],
};
//function to get all states based on country
const getAllStates = async (countryId, setActiveStates) => {
  if (countryId) {
    try {
      const res = await authApi.get(`countries/${countryId}/states`);
      setActiveStates(res?.data?.data);
    } catch (error) {}
  }
};

const AddNewExperience = ({
  linkText,
  editIt,
  updateFlag,
  setUpdateFlag,
  icon,
  className,
}) => {
  const authId = useSelector((state) => state?.auth?.user?.info?.id); //user id
  const [data, setData] = useState(initialState); //state
  const [corpTitles, setCorpTitles] = useState([]); //state for corporate titles
  const [industries, setIndustries] = useState([]); //state for all active industries
  const [jobTypes, setJobTypes] = useState([]); //state from all job types
  const [empTypes, setEmpTypes] = useState([]); //state for all employment types
  const [savedSkills, setSavedSkills] = useState([]); //state for selected skills
  const [searchedTitle, setSearchedTitle] = useState([]); //state for searched titles
  const [titleQuery, setTitleQuery] = useState(""); //query to search title
  const [activeCountries, setActiveCountries] = useState([]); //state for saving countries
  const [activeStates, setActiveStates] = useState([]); //state for saving states
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [searchIt,setSearchIt]=useState('');
  const [searching,setSearching]=useState(false);
  const dispatch=useDispatch();
  console.log('editThis',editIt);
  //useEfffect to fill current position for editing
  useEffect(()=>{
    if(editIt?.id){
      setData({ user_id: editIt?.user_id??"",
      corporate_title_id: editIt?.corporate_title?.id??"",
      employment_type_id: editIt?.employment_type_id??"",
      job_type_id: editIt?.job_type_id??"",
      company_name: editIt?.company_name??"",
      start_date: editIt?.start_date??"",
      end_date: editIt?.end_date??"",
      present_job: editIt?.present_job??"",
      status: true,
      current_position: editIt?.current_position??false,
      industry_id: editIt?.industry_id??"",
      state_id: editIt?.state_id??"",
      country_id: editIt?.country_id??"",
      description: editIt?.description??"",
      profile_headline: editIt?.profile_headline??"",
      skills: [],});
      if(editIt?.skills){
        setSavedSkills(JSON.parse(JSON.parse(editIt?.skills)))
      }
      if(editIt?.corporate_title){
        setTitleQuery(editIt?.corporate_title?.title);
      }
      
    }

  },[editIt])
// console.log(editIt)
  //function to get all active countries

  const getAllCountries = async () => {
    try {
      const res = await authApi.get(`countries`);
      setActiveCountries(res?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    getAllCountries();
  }, []);

  //useEffect to get all active states
  useEffect(() => {
    getAllStates(data.country_id, setActiveStates);
  }, [data.country_id]);

  // fetch corporate titles
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await authApi.get(`get-active-corporate-title`);
        setCorpTitles(res?.data?.data);
      } catch (err) {}
    };
    fetch();
  }, []);

  // fetch industries
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await authApi.get(`get-active-industry`);
        setIndustries(res?.data?.data);
      } catch (err) {}
    };
    fetch();
  }, []);
  // fetch Job types
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await authApi.get(`get-active-job-type`);
        setJobTypes(res?.data?.data);
      } catch (err) {}
    };
    fetch();
  }, []);

  // fetch Employment type
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await authApi.get(`get-active-employment-type`);
        setEmpTypes(res?.data?.data);
      } catch (err) {}
    };
    fetch();
  }, []);

  // add media Dropdown
  const [showNewPosition, setShowNewPosition] = useState(false); //for popup Modal

  // function for  position

  const handleNewPositionOpen = () => {
    setShowNewPosition(true);
  };

  const handleNewPositionClose = () => {
    setShowNewPosition(false);
  };

  let cancelToken;
  const searchTitles = async () => {
    try {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("cancelled");
      }
      cancelToken = axios.CancelToken.source();
      // 
      if (searchIt!='') {
        setSearching(true);
        const res = await authApi.get(
          `search-corporate/${searchIt}`,
          { cancelToken: cancelToken.token }
        );
        const data=res?.data;
        let newData = data.flatMap(({ title, alternate_names,id }) => {
          let names = [];
          try {
              names = JSON.parse(alternate_names);
          } catch (error) {
              console.error("Error parsing alternate names:", error);
          }
          if(names?.length>0){
            return [title, ...names].map(name => ({ title: name,id:id }));
          }else{
            return [title].map(name => ({ title: name, id:id}));
          }
         
      });
      if(newData?.length>0){
        setSearchedTitle(newData);
        setSearching(false);
      }else{
        setSearchedTitle(newData);
        setSearching(false);
      }
        
      }
      
    } catch (error) {
      // setSearching(false);
    }
  };

  useEffect(() => {
    if (searchIt) {
      searchTitles();
    } else {
      setSearching(false);
      setSearchedTitle([]);
    }
    return () => {
      if (cancelToken) {
        cancelToken.cancel("Cancelled");
      }
    };
  }, [searchIt]);

  //title change
  const handleTitleSearch = (e) => {
    setSearchIt(e.target.value);
    // const val = e.target.value;
    // setTitleQuery(val);
    // const filter = corpTitles?.filter((ele) =>
    //   ele?.title?.toLowerCase()?.includes(val.toLowerCase())
    // );
    // setSearchedTitle(filter);
  };

  //function to add experience
  const handleAddExperience = async (e) => {
    setSaving(true);
    e.preventDefault();
    data.user_id = authId;
    data.skills = JSON.stringify(savedSkills);
    if (editIt?.id) {//for Edit
      try {
        await authApi.put(`work-experiences/${editIt?.id}`, data);
        setSaving(false);
        setUpdateFlag(!updateFlag);
        setShowNewPosition(false);
        setData(initialState);
        dispatch(updateUser());
        toast.success("Work-Experience updated successfully.");
      } catch (err) {
        setSaving(false);
        setShowNewPosition(false);
        toast.success(err?.response?.data?.message);
      }
    } else {//For Add
      try {
        await authApi.post(`work-experiences`, data);
        setSaving(false);
        setUpdateFlag(!updateFlag);
        setShowNewPosition(false);
        setData(initialState);
        dispatch(updateUser());
        toast.success("Work-Experience added successfully.");
      } catch (err) {
        setSaving(false);
        setShowNewPosition(false);
        toast.success(err?.response?.data?.message);
      }
    }
  };
  //function to delete
  const handleDelete = async (e) => {
    e.preventDefault()
    setDeleting(true)
    try {
      await authApi.delete(`work-experiences/${editIt?.id}`);
      dispatch(updateUser());
      setDeleting(false)
      setShowNewPosition(false);
    } catch (err) {
      setDeleting(false)
      setShowNewPosition(false);
      toast.error(err?.response?.data?.message);
    }
  };


  return (
    <>
      <Link
        to=""
        className={`gray ${className}`}
        onClick={handleNewPositionOpen}
      >
        {icon} {linkText}
      </Link>

      {showNewPosition && (
        <div className="modal " style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {editIt?.id ? "Edit" : "Add"} experience
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleNewPositionClose}
                >
                  &times;
                </button>
              </div>
              <form>
                <div className="form-container">
                  <div className="modal-body custom-scrollbar">
                    <div className="form-group">
                      <label htmlFor="title ">
                        Role <span className="text-danger">*</span>
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ex: Front End Developer "
                          id="Title"
                          // value={titleQuery}
                          onChange={(e) => handleTitleSearch(e)}
                        />
                        {searchedTitle.length > 0 && (
                          <div
                            className="position-absolute w-100 bg-light col"
                            style={{
                              top: "40px",
                              border: "1px solid lightgray",
                              borderRadius: "8px",
                              minHeight: "max-content",
                              maxHeight: "150px",
                              overflowY: "scroll",
                              boxShadow:
                                " rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                            }}
                          >
                            {searchedTitle?.map((elem, index) => (
                              <div
                                className="pt-4 pb-4 pl-2 row"
                                style={{
                                  cursor: "pointer",
                                  borderTop: index > 0 && "1px solid lightgray",
                                }}
                                onClick={() => {
                                  document.getElementById(
                                    "Title"
                                  ).value = elem.title;
                                  setTitleQuery(elem.title);
                                  setSearchedTitle([]);
                                  setData({
                                    ...data,
                                    corporate_title_id: elem.id,
                                  });
                                }}
                              >
                                {elem.title}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="form-group">
                      <label htmlFor="employment">
                        Employment type <span className="text-danger">*</span>
                      </label>
                      <select
                        class="form-control"
                        value={data.employment_type_id}
                        onChange={(e) =>
                          setData({
                            ...data,
                            employment_type_id: e.target.value,
                          })
                        }
                        id="employment"
                      >
                        <option value="">Please select </option>
                        {empTypes?.map((elem, index) => (
                          <option value={elem.id}>{elem.title} </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="company">
                        Company name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex: Microsoft"
                        id="company"
                        value={data.company_name}
                        onChange={(e) =>
                          setData({ ...data, company_name: e.target.value })
                        }
                      />
                    </div>
                    <div className="d-sm-flex justify-content-between">
                      <div className="form-group flex-fill mr-sm-3 mr-0">
                        <label htmlFor="state">
                          State <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-control"
                          value={data.state_id}
                          onChange={(e) =>
                            setData({
                              ...data,
                              state_id: e.target.value,
                            })
                          }
                          id="state"
                          disabled={!data.country_id}
                        >
                          <option value="">
                            {data.country_id
                              ? "Select State"
                              : "Select Country First"}{" "}
                          </option>
                          {activeStates?.map((elem, index) => (
                            <option value={elem.id}>{elem.name} </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group flex-fill mr-sm-3 mr-0">
                        <label htmlFor="country">
                          Country <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-control"
                          value={data.country_id}
                          onChange={(e) =>
                            setData({
                              ...data,
                              country_id: e.target.value,
                            })
                          }
                          id="country"
                        >
                          <option value="">
                            Select Country{" "}
                            <span className="text-danger">*</span>
                          </option>
                          {activeCountries?.map((elem, index) => (
                            <option value={elem.id}>{elem.name} </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div class="form-group">
                      <label htmlFor="location">
                        Location type <span className="text-danger">*</span>
                      </label>
                      <select
                        class="form-control"
                        id="location"
                        value={data.job_type_id}
                        onChange={(e) =>
                          setData({ ...data, job_type_id: e.target.value })
                        }
                      >
                        <option value="">Please Select </option>
                        {jobTypes?.map((elem, index) => (
                          <option value={elem.id}>{elem.type} </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group pt-2 pb-2">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck"
                          name="work_role"
                          checked={data.present_job}
                          onChange={(e) =>
                            setData({ ...data, present_job: e.target.checked })
                          }
                        />
                        <label
                          class="custom-control-label pl-3 -sm-2 pt-0"
                          htmlFor="customCheck"
                        >
                          I am currently working in this role
                        </label>
                      </div>
                    </div>

                    <div className="d-sm-flex">
                      <div class="form-group flex-fill mr-sm-3 mr-0">
                        <label htmlFor="start_date">
                          Start <span className="text-danger">*</span>
                        </label>

                        <input
                          className="form-control w-100"
                          value={data.start_date?.split("T")[0]}
                          onChange={(e) =>
                            setData({ ...data, start_date: e.target.value })
                          }
                          id="start_date"
                          type="date"
                        />
                      </div>

                      <div class="form-group flex-fill  ml-sm-3 ml-0">
                        <label htmlFor="start_date">
                          End <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control w-100"
                          id="end_date"
                          value={data.end_date?.split("T")[0]}
                          onChange={(e) =>
                            setData({ ...data, end_date: e.target.value })
                          }
                          type="date"
                          disabled={data.present_job}
                        />
                      </div>
                    </div>

                    <div className="form-group pt-sm-2 pt-0 pb-2">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck1"
                          name="current_position"
                          checked={data.current_position}
                          onChange={(e) =>
                            setData({
                              ...data,
                              current_position: e.target.checked,
                            })
                          }
                        />
                        <label
                          class="custom-control-label pl-3 pt-sm-2 pt-0"
                          htmlFor="customCheck1"
                        >
                          End current position as of now
                        </label>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="industry">
                        Industry <span className="text-danger">*</span>
                      </label>
                      <select
                        value={data.industry_id}
                        onChange={(e) =>
                          setData({ ...data, industry_id: e.target.value })
                        }
                        class="form-control"
                        id="industry"
                      >
                        <option value="">Please Select </option>
                        {industries?.map((elem, index) => (
                          <option value={elem.id}>{elem?.label} </option>
                        ))}
                      </select>
                    </div>

                    <div class="form-group">
                      <label htmlFor="Description">Description:</label>
                      <textarea
                        className="form-control"
                        rows="5"
                        id="Description"
                        value={data.description}
                        onChange={(e) =>
                          setData({ ...data, description: e.target.value })
                        }
                      ></textarea>
                    </div>

                    <div className="form-group">
                      <label htmlFor="profile_headline">
                        Profile headline <span className="text-danger">*</span>
                      </label>
                      <input
                        value={data.profile_headline}
                        onChange={(e) =>
                          setData({ ...data, profile_headline: e.target.value })
                        }
                        type="text"
                        className="form-control"
                        placeholder="Web Designer at DesignDot Technologies Pvt Ltd"
                        id="profile_headline"
                      />
                      <p className="small-text pt-3">
                        Appears below your name at the top of the profile
                      </p>
                    </div>

                    <h4 className="form-title pt-2 pb-2">Skill </h4>
                    <p className="small-text">
                      We recommend adding your top 5 used in this role. They’ll
                      also appear in your Skills section.
                    </p>

                    {/* InputTag component */}

                    <InputTag
                      title="Add Skill"
                      savedSkills={savedSkills}
                      setSavedSkills={setSavedSkills}
                    />

                    {/* end InputTag component */}
                  </div>
                  <div className="modal-footer  d-flex justify-content-between text-right">
                    {editIt?.id ? (
                      <button
                        className="btn blue-btn"
                        onClick={(e) => handleDelete(e)}
                        disabled={deleting}
                      >
                        {" "}
                        {deleting ? "Deleting" : "Delete"}
                      </button>
                    ) : (
                      <b></b>
                    )}
                    <button
                      type="submit"
                      disabled={
                        !data.company_name ||
                        !data.start_date ||
                        !data.corporate_title_id ||
                        !data.employment_type_id ||
                        !data.job_type_id ||
                        !data.industry_id ||
                        !data.state_id ||
                        !data.country_id ||
                        saving
                      }
                      onClick={(e) => handleAddExperience(e)}
                      className="btn blue-btn"
                    >
                      {saving ? "Submitting" : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddNewExperience;
