import { all } from "axios";
import React, { useEffect, useState } from "react";
import { FiCheck } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";

const ApplicantScreeningQuestionRespons = ({ allQsns }) => {
  const [mustHave, setMustHave] = useState([]);
  const [preffered, setPreffered] = useState([]);

  useEffect(() => {
    if (allQsns.length > 0) {
      const m = allQsns?.filter((elem) => +elem?.recommended === 1) || [];
      const p = allQsns?.filter((elem) => +elem?.recommended === 0) || [];
      setMustHave(m);
      setPreffered(p);
    }
  }, [allQsns]);

  //check answer is right or not
  // const checkAnswer=(data)=>{

  //   return "x"
  // }

  return (
    <>
      {(preffered?.length || mustHave?.length > 0) && (
        <div className="card mt-3">
          <div className="card-header bg-transparent border-bottom-0">
            <h3 className="card-title fw-500 light-black">
              Screening question responses
            </h3>
          </div>
          <div className="card-body pt-0">
            {mustHave?.length > 0 && (
              <div className="Screening-innner-card">
                <h3 className="fw-600 fa-20 light-black mt-2">
                  Must-have qualifications
                  {/* (6 out of 6 met) */}
                </h3>

                <div className="sreening-grid mt-3">
                  {mustHave?.map((row, index) => (
                    <div className="post-job-info mb-3 d-flex">
                      {/* {checkAnswer(row)} */}
                      {/* <FiCheck className="text-success fa-20 mr-1" /> */}
                      <div>
                        <h4>{row?.question} </h4>
                        <p>
                          Ideal answer:{" "}
                          {row?.min_value ? row?.min_value : row?.answer}
                        </p>
                        <h4>{row?.candidate_answer}</h4>
                      </div>
                    </div>
                  ))}

                  {/* <div className="post-job-info mb-3 d-flex">
                <FiCheck className="text-success fa-20 mr-1" />
                <div className="w-90">
                  <h4>
                    How many years of IT Services and IT Consulting experience
                    do you currently have?
                  </h4>
                  <p>Ideal answer: 1</p>
                  <h4>3</h4>
                </div>
              </div> */}

                  {/* <div className="post-job-info mb-3 d-flex">
                <FiCheck className="text-success fa-20 mr-1" />
                <div className="w-90">
                  <h4>What is your CTC?</h4>
                  <p>Ideal answer: 1 </p>
                  <h4>500000</h4>
                </div>
              </div> */}

                  {/* <div className="post-job-info mb-3 d-flex">
                <RxCross2 className="text-danger fa-20 mr-1" />

                <div className="w-90">
                  <h4>What is your average deal size in USD (in thousands)?</h4>
                  <p>Ideal answer: 1000 </p>
                  <h4>50</h4>
                </div>
              </div> */}
                </div>
              </div>
            )}

            {preffered?.length > 0 && (
              <div className="Screening-innner-card">
                <h3 className="fw-600 fa-20  mt-2 light-black">
                  Preferred qualifications
                  {/* (1 out of 2 met) */}
                </h3>

                <div className="sreening-grid mt-3">
                  {preffered?.map((row, index) => (
                    <div className="post-job-info mb-3 d-flex">
                      {/* {checkAnswer(row)} */}
                      {/* <FiCheck className="text-success fa-20 mr-1" /> */}
                      <div>
                        <h4>{row?.question} </h4>
                        <p>
                          Ideal answer:{" "}
                          {row?.min_value ? row?.min_value : row?.answer}
                        </p>
                        <h4>{row?.candidate_answer}</h4>
                      </div>
                    </div>
                  ))}
                  {/* <div className="post-job-info mb-3 d-flex">
                <FiCheck className="text-success fa-20 mr-1 " />
                <div className="w-90">
                  <h4>
                    Have you completed the following level of education: Master
                    of Business Administration?
                  </h4>
                  <p>Ideal answer: Yes </p>
                  <h4>Yes</h4>
                </div>
              </div> */}

                  {/* <div className="post-job-info mb-3 d-flex">
                <FiCheck className="text-success fa-20 mr-1 " />
                <div className="w-90">
                  <h4>Are you comfortable working in a remote setting? </h4>
                  <p>Ideal answer: Yes</p>
                  <h4>Yes</h4>
                </div>
              </div>

              <div className="post-job-info mb-3 d-flex">
                <FiCheck className="text-success fa-20 mr-1 " />
                <div className="w-90">
                  <h4>What is your desired salary? </h4>
                  <p>Ideal answer: 1</p>
                  <h4>550000</h4>
                </div>
              </div> */}

                  {/* <div className="post-job-info mb-3 d-flex">
                <FiCheck className="text-success fa-20 mr-1 " />
                <div className="w-90">
                  <h4>
                    Are you willing to undergo a background check, in accordance
                    with local law/regulations?{" "}
                  </h4>
                  <p>Ideal answer: Yes</p>
                  <h4>Yes</h4>
                </div>
              </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ApplicantScreeningQuestionRespons;
