import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import "./Modal.css";
import authApi from "../../../../../utils/authApi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../../Redux/userSlice";
import { MdOutlineModeEditOutline } from "react-icons/md";

const EditProfileSummary = ({ updateFlag, setUpdateFlag }) => {
  const [showSummary, SetshowSummary] = useState(false);

  const [saving, setSaving] = useState(false);
  const authId = useSelector((state) => state?.auth?.user?.info?.id);
  const user = useSelector((state) => state.user.profile);
  const [summary, setSummary] = useState(user?.about_me ?? "");
  const dispatch = useDispatch();
  const handleAddSummary = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      await authApi.put(`users/${authId}`, { about_me: summary });
      toast.success("Summary added successfully.");
      dispatch(updateUser());
      setUpdateFlag(!updateFlag);
      SetshowSummary(false);
      setSaving(false);
      SetshowSummary(false);
    } catch (err) {
      setSaving(false);
      setUpdateFlag(!updateFlag);
      SetshowSummary(false);
    }
  };

  const handleShowSummaryOpan = () => {
    SetshowSummary(true);
  };

  const handleShowSummaryClose = () => {
    SetshowSummary(false);
  };

  return (
    <>
      <Link
        data-toggle="modal"
        data-target="#editintro"
        onClick={handleShowSummaryOpan}
      >
        <MdOutlineModeEditOutline className="gary-text fa-28" />
      </Link>
      {showSummary && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit about (summary)</h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleShowSummaryClose}
                >
                  &times;
                </button>
              </div>
              <form action="">
                <div className="modal-body">
                  <div className="form-container">
                    <div className="form-group">
                      <label htmlFor="summary">
                        You can write about your years of experience, industry,
                        or skills. People also talk about their achievements or
                        previous job experiences.
                      </label>
                      <textarea
                        className="form-control"
                        rows="10"
                        id="summary"
                        value={summary}
                        onChange={(e) => setSummary(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer  text-right">
                  <button
                    type="submit"
                    onClick={(e) => handleAddSummary(e)}
                    className="btn blue-btn"
                    disabled={saving}
                  >
                    {saving ? "Submitting" : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditProfileSummary;
